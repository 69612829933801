import { FlushInformation } from 'src/app/models/flushInformation';

export class AddFlushInformation {
  static readonly type = '[FlushInformation] Add';

  constructor(public payload: FlushInformation[]) {}
}

export class DeleteFlushInformation {
  static readonly type = '[FlushInformation] Delete';

  constructor() {}
}
