import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WorkRequestRow } from 'src/app/interfaces/work-request-row';

@Component({
  selector: 'app-supervisor-hub-panel-bar',
  templateUrl: './supervisor-hub-panel-bar.component.html',
  styleUrls: ['./supervisor-hub-panel-bar.component.scss']
})
export class SupervisorHubPanelBarComponent implements OnInit {

  @Input() workRequestRows: WorkRequestRow[];
  @Input() disableActionItems: boolean = false;
  @Output() actionClicked = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  editBtnClicked(dataItem: any) {
    this.actionClicked.emit({ action: 'Edit', value: dataItem });
  }
  closeBtnClicked(dataItem: any) {
    this.actionClicked.emit({ action: 'Close', value: dataItem });
  }
}
