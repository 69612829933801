import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { StylerService } from 'src/app/services/styler/styler.service';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
})
export class BaseComponent implements OnInit, OnDestroy {
  flushSubscriptions: Subscription[];
  constructor(private baseStyler: StylerService) {
    this.flushSubscriptions = [];
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.flushSubscriptions.forEach((s) => s.unsubscribe());
  }
}
