import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { Store, Select, Actions } from '@ngxs/store';
import { DeleteJobDetails } from 'src/app/app-state/actions/job-details.actions';
import { DeleteAccessInformation } from 'src/app/app-state/actions/access-info.actions';
import { DeleteFlushInformation } from 'src/app/app-state/actions/flush-process-info.actions';
import { DeleteStructureInformation } from 'src/app/app-state/actions/structure-info.actions';
import {
  DeleteStartJobPhoto,
  DeleteWorkRequestDetail,
  DeleteWorkDescription,
} from 'src/app/app-state/actions/start-job.actions';
import * as moment from 'moment';
import { AppState } from 'src/app/app-state/app.state';
import { BehaviorSubject, combineLatest, forkJoin, Observable } from 'rxjs';
import { StructureInformation } from 'src/app/models/structureInformation';
import { BaseComponent } from 'src/app/common/base/base.component';
import { StylerService } from 'src/app/services/styler/styler.service';
import { JobDetail } from 'src/app/models/jobDetail';
import { InternetCheckerService } from 'src/app/services/internet-checker/internet-checker.service';
import { Photo } from 'src/app/models/photo';
import { WorkRequestDetail } from 'src/app/models/work-request-detail';
import { AccessInformation } from 'src/app/models/accessInformation';
import { FlushInformation } from 'src/app/models/flushInformation';
import { Structure } from 'src/app/models/structure.model';
import { CcRequestsService } from 'src/app/services/cc-requests/cc-requests.service';
import { filter, map, take, tap } from 'rxjs/operators';
import { FormValidationService } from 'src/app/services/forms/form-validation.service';
import { BaseService } from 'src/app/services/base/base.service';
import * as _ from 'lodash';
import { WorkRequestsResponse } from 'src/app/models/work-requests-response';
import { StructureInfo } from 'src/app/models/construction-crew/structure-info';
import { MapService } from 'src/app/services/map/map.service';
import { Alert } from '@ce-lib/alert';

@Component({
  selector: 'app-review-request',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
})
export class FlushReviewComponent extends BaseComponent implements OnInit, OnDestroy {
  today = moment().format('MM/DD/YYYY');
  showModal = false;
  isSummary = false;
  showSubmitSuccess = false;
  showCancel = false;
  showSubmit = false;
  isOnline = true;
  isPhotosValid = true;
  isJobDetailsValid = false;
  structureInfoCols: { field: string; title: string; actionType?: string; action?: string }[];
  accessInfoCols: { field: string; title: string }[];
  structureInfoData: Structure[] = [];
  jobDetails: JobDetail;
  photos: Photo[];
  wrDetails: WorkRequestDetail;
  dashboardDetailsEntity: any;
  accessInfo: AccessInformation;
  flushInfoArr: FlushInformation[] = [];
  structureInfo: StructureInfo[] = [];
  sourceWrNo = '';
  isSupervisorApproved: boolean = false;
  isMobile: boolean;
  submitFeedback = '';
  showSubmitError = false;
  isReviewValid = true;
  showSubmitTimeOut = false;
  showMapModal = false; //Flag to show/hide the modal popup for map
  assetTag: string; 
  mapLoaded = false; //Variable used to check whether the map is loaded or not before triggering any future events.
  
  @Select(AppState.getStructureInformation)
  getStructureInfo$: Observable<StructureInformation>;
  @Select(AppState.getJobDetails) getJobDetails$: Observable<JobDetail>;
  @Select(AppState.getStartJobPhotos) getStartJobPhotos$: Observable<Photo[]>;
  @Select(AppState.getWorkRequestDetail)
  getWorkRequestDetail$: Observable<WorkRequestDetail>;
  @Select(AppState.getWorkComponentGlobalId)
  getWorkComponentGlobalId$: Observable<string>;
  @Select(AppState.getWorkRequestGlobalId)
  getWorkRequestGlobalId$: Observable<string>;
  @Select(AppState.getAccessInformation)
  getAccessInformation$: Observable<AccessInformation>;
  @Select(AppState.getFlushInformation)
  getFlushInformation$: Observable<FlushInformation[]>;
  @Select(AppState.getDashboardWorkRequests)
  getDashboardWorkRequests$: Observable<WorkRequestsResponse>;
  @ViewChild('flushCrewSummary') flushCrewSummary;
  showIconForSubmitTimeout = true;

  constructor(
    private ccRequestsService: CcRequestsService,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    styler: StylerService,
    private internetCheckerService: InternetCheckerService,
    private formValidationService: FormValidationService,
    private service: BaseService,
    private cdr: ChangeDetectorRef,
    private actions: Actions,
    private mapService: MapService,
    private alert: Alert
  ) {
    super(styler);
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    this.adjustToScreenSize(window.innerWidth);
  }

  ngOnInit() {
    this.flushSubscriptions.push(
      this.formValidationService.listenToValidityOfRequest().subscribe(([detailsValid, photosValid]) => {
        console.log('photosValid :>> ', photosValid);
        this.isPhotosValid = photosValid;
      }),
      this.internetCheckerService.getStatus.subscribe((isOnline) => {
        this.isOnline = isOnline;
      }),
      this.service.submissionTimeoutModal.asObservable().subscribe((showSubmitTimeOut) => {
        this.showSubmitTimeOut = showSubmitTimeOut;
      })      
    );
    
    this.adjustToScreenSize(window.innerWidth);
    this.checkIsSummary();
    this.structureInfoCols = [
      { field: 'type', title: 'Type' },
      { field: 'structureId', title: 'Structure No.', actionType: 'anchor', action: 'assetTag'  },
      { field: 'borough', title: 'Borough' },
      { field: 'frontAddress', title: 'Front Address'},
      { field: 'isCustomerOwned', title: 'Customer Owned' },
    ];
    this.accessInfoCols = [
      { field: 'assetTagFacilities', title: 'Asset Tag(s)' },
      { field: 'restriction', title: 'Restriction' },
      { field: 'days', title: 'Days' },
      { field: 'start_time', title: 'Start time' },
      { field: 'end_time', title: 'End time' },
    ];
    //Get dashboard
    combineLatest([
      this.getWorkRequestGlobalId$,
      this.getDashboardWorkRequests$,
      this.getWorkComponentGlobalId$
    ])
    .pipe(
      take(1)
    )
    .subscribe(([wrId, dashboard,workComponentGlobalId]) => {
        this.dashboardDetailsEntity = dashboard?.entities?.find((x) => x.workRequestGlobalId == wrId && x.workComponentGlobalID == workComponentGlobalId)
    });
    // Get data from store
    combineLatest([
      this.getWorkRequestDetail$,
      this.getJobDetails$,
      this.getAccessInformation$,
      this.getStructureInfo$,
      this.getFlushInformation$,
    ])
    .pipe(
      map(data => data),
      tap(([wrDetails, details, access, structures, flush]) => {
        console.log('Review Structures: ', structures);
        this.structureInfoData = [];
        this.wrDetails = wrDetails;
        this.accessInfo = access;
        let tempFlush : FlushInformation[] = _.clone(flush);
        this.flushInfoArr = tempFlush?.map((flushVal : FlushInformation) => {
          let tempVal = _.cloneDeep(flushVal);
          if (typeof tempVal.structureDebrisTypeId === 'string') {
            tempVal.structureDebrisTypeId = (tempVal.structureDebrisTypeId as string).split(',');
          }
          return tempVal;
        });
        if (Object.keys(details).length > 0) {
          this.jobDetails = details;
        }
        if (Object.keys(structures).length > 1 && structures.structures != undefined) {
          for (const struct of structures?.structures) {
            if (struct) {
              this.structureInfoData.push(struct);
              this.structureInfo.push({
                structureNumber: struct.structureId,
                address: struct.frontAddress,
                facilityType: struct.type,
                facilityId: struct.facilityId,
                isManuallyAdded: struct.isManual,
                isCustomerOwned: struct.isCustomerOwned,
                id: struct.id,
                borough: struct.borough,
              } as StructureInfo);
            }
          }
        }
      }),
      take(1)
    ).subscribe(data => {});
    this.getStartJobPhotos$.subscribe(photos => {
      this.photos = photos.filter(photo => photo.mediaType != 'Video');;
    }).unsubscribe();
  }
  private adjustToScreenSize(innerWidth) {
    this.isMobile = innerWidth <= 768 ? true : false;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
  ngAfterViewInit() {
    this.flushCrewSummary.handleMobileNav();
    this.cdr.detectChanges();
    if (this.isSummary == true) {
      this.formValidationService.$flushSummary.next(true);
    }
  }

  navigateToDashboard() {
    this.router.navigateByUrl('/');
  }

  toggleCancel() {
    this.showCancel = true;
    this.showSubmit = false;
    this.isSupervisorApproved = false;
  }
  toggleSubmit() {
    this.showCancel = false;
    this.showSubmit = true;
    this.isSupervisorApproved = true;
  }
  handleModalClose() {
    if (this.showCancel) {
      this.ccRequestsService
        .cancelCCRequest()
        .pipe(
          tap((done) => {
            // Clear our store if we cancel a request
            this.store.dispatch(DeleteJobDetails);
            this.store.dispatch(DeleteAccessInformation);
            this.store.dispatch(DeleteFlushInformation);
            this.store.dispatch(DeleteStructureInformation);
            this.ccRequestsService.deletePhotos(this.photos);
            this.store.dispatch(DeleteStartJobPhoto);
            this.store.dispatch(DeleteWorkRequestDetail);
            this.store.dispatch(DeleteWorkDescription);
          })
        )
        .subscribe(() => {
          this.router.navigate(['/requests']);
        });
    } else {
      // SUBMIT REQUEST DATA THEN NAVIGATE to confirmation modal
      let searchwrflag = this.store.selectSnapshot((store) => store.AppState.searchwrflag);
      this.ccRequestsService
        .saveCCReview(
          this.jobDetails,
          this.structureInfo,
          this.accessInfo,
          this.flushInfoArr,
          this.photos,
          this.isSupervisorApproved,
          searchwrflag
        )
        .subscribe(
          () => {
            this.showModal = false;
            this.showSubmitSuccess = true;
          },
          (err) => {
            // Handle error when trying to submit flush request
            console.log(err);
            this.showSubmitError = true;
          }
        );
    }
  }
  closeRequest() {
    this.router.navigate(['/requests']);
  }
  navigateToDetails() {
    const bTicket = this.store.selectSnapshot(store => store.AppState.workRequestDetail as WorkRequestDetail).bTicket;
    if (!!bTicket && bTicket.length > 4) {
      // Is a BTicket
      this.router.navigate(['/flush-information/job-environment', { isEdit: true }]);
    } else {
      // Not a BTicket
    this.router.navigate(['/flush-information/job-details', { isEdit: true }]);
    }
    
  }
  checkIsSummary() {
    //Set values to true if summary
    this.activatedRoute.params.subscribe((params) => {
      if (params['isSummary'] == 'true') {
        this.formValidationService.$flushInformationValid.next(true);
        this.formValidationService.$flushPhotos.next(true);
        this.formValidationService.$flushRequestValid.next(true);
        this.isPhotosValid = true;
        this.isJobDetailsValid = true;
        this.isSummary = true;
      }
    });
  }

  arrayUnique(array: Photo[]) {
    let a = array.concat();
    for (let i = 0; i < a.length; ++i) {
      for (let j = i + 1; j < a.length; ++j) {
        if (a[i].id === a[j].id) {
          a.splice(j--, 1);
        }
      }
    }

    return a;
  }
  toggleCheckBox() {
    this.isSupervisorApproved = !this.isSupervisorApproved;
  }

  getStructureType(structureNumber) : string {
    return this.structureInfoData?.find((structure) =>  structure.structureId == structureNumber)?.type;
  }

  mapLoadedEvent(status: boolean) {
    document.querySelector('.map-wrapper').addEventListener('touchmove', function(event) {
      // Prevent page scrolling if touch device is on
      event.preventDefault();
    })
    this.mapLoaded = true;
  }

  showMapCoordinates(dataItem) {
    this.showMapModal = true;

    if (this.mapLoaded) {
      this.assetTag = dataItem?.structureId;

      const longitude = dataItem?.longitude;
      const latitude = dataItem?.latitude;

      const undefinedCoords = !longitude || !latitude;
      const invalidCoords = (lat, lng) => { return Math.abs(lat) > 85 || Math.abs(lng) > 180 }
      
      console.log('Longtitute: ', longitude, ' Latitute: ', latitude );
      if (!undefinedCoords && !invalidCoords(latitude, longitude)) {
        document.getElementById('main-navigation').scrollIntoView();
        
        this.mapService.queueNewPan(parseFloat(longitude), parseFloat(latitude));
      } else {
        this.alert.urgent('Map coordinates not found: This request is missing coordinates to show on the map. If no address is provided, reference the Structure Number.', 'Dismiss')
      }
    }else{
      setTimeout(this.showMapCoordinates.bind(this, dataItem), 2000);
    }
  }

  onStructureClicked(event: { action: any; value: any }){
    if (event.action === 'assetTag') {
      console.log('Value: ', event.value);
      this.showMapCoordinates(event.value);
    }
  }
}
