import { Component, OnInit, Input, HostListener, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Photo } from '../../../models/photo';
import { PhotoTag } from '../../../models/photo-tag';
import { Select } from '@ngxs/store';
import { AppState } from 'src/app/app-state/app.state';
import { BehaviorSubject, Observable, combineLatest, concat } from 'rxjs';
import { StructureInformation } from 'src/app/models/structureInformation';
import { filter, map, tap } from 'rxjs/operators';
import { Banner, BannerService } from 'src/app/services/banner/banner.service';
import { OptionModel } from '../../selectbox/selectbox.component';

import { PhotoService } from 'src/app/services/photo/photo.service';
import { StylerService } from 'src/app/services/styler/styler.service';


@Component({
  selector: 'app-photo-review',
  templateUrl: './photo-review.component.html',
})
export class PhotoReviewComponent implements OnInit {
  @Input() photo: Photo;
  @Input() canDelete = false;
  @Output() changes = new EventEmitter();
  @Select(AppState.getStructureInformation)
  getStructureInfo$: Observable<StructureInformation>;
  // errorBanner$: BehaviorSubject<Banner> = new BehaviorSubject<Banner>(null);
  banner$: Observable<Banner>;
  structureOptions: Array<OptionModel> = [];
  photoDetails = new FormGroup({
    assetTag: new FormControl(''),
    comments: new FormControl(''),
  });
  photoIndexCheck = 0;
  selectedAsset = -1;
  tags: PhotoTag[] = [];

  constructor(
    private photoService: PhotoService,
    private banner: BannerService,
    private styler: StylerService
  ) { }
  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    // Check dimensions of the screen if the user has changed view between landscape/portrait
    // this.isLandscape = window.innerWidth > window.innerHeight ? true : false;
  }
  ngOnInit(): void {
    this.banner.resetBanner();
    this.banner$ = this.banner.banner$;

    this.styler.isPhotoReview.next(true);
    //Get existing structures for selectbox
    this.getStructureInfo$.subscribe((info) => {
      if (info?.structures) {
        this.structureOptions = [];
        this.selectedAsset = info?.structures.length === 1 ? 0 : -1;
        if (info?.structures.length < 1) {
          this.banner.banner.next(
            {
              type: 'error-notification',
              show: true,
              showIcon: true,
              details: {
                header: null,
                body: ["None. You'll have a chance to add a structure during the Flush Details flow."]
              }
            }
          )
        }
        else if (info?.structures.length === 1) {
          this.photoDetails.get('assetTag').setValue(info?.structures[0].structureId);
        } else {
          this.photoDetails.controls.assetTag.setValidators(Validators.required);
        }
        info?.structures.forEach(structure => {
          let option = {
            option: structure?.structureId,
            value: structure?.structureId
          } as OptionModel;
          this.structureOptions.push(option);
        });
      }
    }
    );
    this.photoService.getShowingPhotoIndex()
      .subscribe(pIndex => {
        if (pIndex !== this.photoIndexCheck) {
          this.selectedAsset = -1;
          this.photoIndexCheck = pIndex;
          this.photoDetails.reset();
        }
      })
    this.photoDetails.get('assetTag').valueChanges
      .pipe(
        filter((details: string) => details != null || details?.length > 0),
        tap(d => {
          const search = this.structureOptions.length === 1 ? this.structureOptions[0].value : d;
          this.selectedAsset = this.structureOptions.map(opt => opt.value).indexOf(search);
        }),
        map(details => {
          return {
            comments: this.photoDetails.get('comments').value ?? '',
            assetTag: details,
            isValid: true
          }
        })
      )
      .subscribe((details: { comments: string, assetTag: string }) => {
        this.emitPhotoDetails(details);
      })
    this.photoDetails.get('comments').valueChanges
      .pipe(
        map(details => {
          if (this.structureOptions.length === 1) {
            return {
              comments: details ?? '',
              assetTag: this.structureOptions[0].value,
              isValid: true
            }
          }
          return {
            comments: details ?? '',
            assetTag: this.photoDetails.get('assetTag').value ?? '',
            isValid: this.photoDetails.valid
          }
        })
      )
      .subscribe((details: { comments: string, assetTag: string }) => {
        this.emitPhotoDetails(details);
      })
  }

  ngAfterViewInit(): void {
    // Default emission
    this.emitPhotoDetails({
      comments: this.photoDetails.get('comments').value,
      assetTag: this.photoDetails.get('assetTag').value,
      isValid: this.photoDetails.valid
    });
  }

  ngOnDestroy() {
    this.styler.isPhotoReview.next(false);
  }
  emitPhotoDetails(val) {
    this.changes.emit(val);
  }

  deletePhoto() {
    this.changes.emit({ delete: this.photo });
  }
}
