import { Pipe, PipeTransform } from '@angular/core';
import { ParkingRestriction } from 'src/app/models/parking-restriction.model';

@Pipe({
  name: 'atomicAssetTagsForParkingRes',
})
export class AtomicAssetTagsForParkingResPipe implements PipeTransform {
  transform(parkingResArr: ParkingRestriction[]): Array<any> {
    let atomicParkingResArr: ParkingRestriction[] = [];
    if(parkingResArr) {
      parkingResArr.forEach((parkingRes: any) => {
        let assetTags = parkingRes.assetTagFacilities as Array<string>;
        let parkingResArr = [];
        let pushAssetToParkingArr = (assetTag) => {
          let tempParkingRes = {
            assetTagFacilities: assetTag,
            restriction: parkingRes.restriction,
            days: parkingRes.days,
            start_time: parkingRes.start_time,
            end_time: parkingRes.end_time,
          };
          parkingResArr.push(tempParkingRes);
        };
        if (typeof assetTags === 'string') {
          pushAssetToParkingArr(assetTags);
        } else if(assetTags) {
          assetTags.forEach((assetTag) => {
            pushAssetToParkingArr(assetTag);
          });
        } else {
          pushAssetToParkingArr("");
        }
        atomicParkingResArr = atomicParkingResArr.concat(parkingResArr);
      });
      return atomicParkingResArr;
    } else {
      return [];
    }
  }
}
