import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CEFooterModule } from '@ce-lib/footer';
import { CEHeaderModule } from '@ce-lib/header';
import { CEButtonModule } from '@ce-lib/button';
import { CEInputModule } from '@ce-lib/input';
import { CERadioButtonModule } from "@ce-lib/radio-button";
import { CEModalModule } from "@ce-lib/modal";
import { CEAlertModule } from "@ce-lib/alert";
import { CESelectModule } from '@ce-lib/select';
import { CETabsModule } from '@ce-lib/tabs';
import { CEDatePickerModule } from '@ce-lib-progress/datepicker';
import { CETimepickerModule } from '@ce-lib-progress/timepicker';
import { CEMediaModule } from "@ce-lib/media";
import { CEA11yModule } from "@ce-lib/a11y";
import { CECheckboxModule } from '@ce-lib/checkbox';
import { CEPagerModule } from '@ce-lib-progress/pager';
import { CEInAppSurveyModule  } from '@ce-lib/in-app-survey';

const CCLModules = [
  CEButtonModule,
  CEFooterModule,
  CEHeaderModule,
  CEInputModule,
  CERadioButtonModule,
  CEModalModule,
  CEAlertModule,
  CESelectModule,
  CETabsModule,
  CEDatePickerModule,
  CETimepickerModule,
  CEMediaModule,
  CEA11yModule,
  CECheckboxModule,
  CEPagerModule,
  CEInAppSurveyModule 
];
@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        CCLModules
    ],
    exports: [
        CCLModules
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CclModule { }
    