import { BehaviorSubject } from 'rxjs';

export class FormValidationModel {
  name: string;
  parent: string = null;
  $isValid: BehaviorSubject<boolean> = new BehaviorSubject(false);
  get isValid() {
    return this.$isValid.value;
  }
  // serves as a way to externally set valid state
  setValidity(isValid: boolean) {
    this.$isValid.next(isValid);
  }

  // by default all forms are not valid
  constructor(formName: string, parentForm?: string) {
    if (parentForm) {
      this.parent = parentForm;
    }
    this.name = formName;
    this.setValidity(false);
  }
}
