import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VideothumbnailService {
  constructor() { }

  getThumbnail(videoBase64: string): Promise<any> {
    const seekTo = 0.1;
    return new Promise((resolve, reject) => {
      let canvas = document.createElement('canvas');
      let context = canvas.getContext('2d');
      let videoPlayer = document.createElement('video');
      videoPlayer.muted = true;
      videoPlayer.src = videoBase64;
      videoPlayer.load();
      videoPlayer.addEventListener('error', (ex) => {
        reject("error when loading video file"+ ex);
      });
      videoPlayer.addEventListener('loadedmetadata', () => {
        // seek to user defined timestamp (in seconds) if possible
        if (videoPlayer.duration < seekTo) {
            reject("video is too short.");
            return;
        }
         // delay seeking or else 'seeked' event won't fire on Safari
        setTimeout(() => {
          videoPlayer.currentTime = seekTo;
        }, 200);

        // extract video thumbnail once seeking is complete
        videoPlayer.addEventListener('seeked', () => {
          console.log('video is now paused at %ss.', seekTo);
          context.drawImage(videoPlayer, 0,0, canvas.width, canvas.height);
          // convert the canvas image as base64
          let thumbnailImage = canvas.toDataURL('image/jpeg');
          // return the canvas image as base64
          resolve(thumbnailImage);
        });
      });            
    });
  }
}