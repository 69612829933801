<div class="container-fluid card">
  <h2>Flush App Test links</h2>
  <div *ngFor="let link of links" class="flush-test-links">
    <h3>{{link.mainTitle}}</h3>
    <div *ngFor="let category of link.categories">
      <strong *ngIf="category.name" class="flush-test-link-subcategory">{{category.name}}</strong>
      <div *ngFor="let sublink of category.sublinks">
        <a [routerLink]='[sublink.refPath]' [queryParams]="sublink.queryParams">
          {{sublink.name}}
        </a>
        <span *ngIf="sublink.subtext">({{sublink.subtext}})</span>
      </div>
    </div>
  </div>
</div>
