import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class CallbackGuard implements CanActivate {
  constructor(private store: Store, private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): 
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree  {
      const user = this.store.selectSnapshot(store => store.AppState.userInfo?.user as User);
      if (!!user && user.isLoggedin) {
        this.router.navigate([''])
        return false;
      }
      return true;
  }
  
}
