<ce-header logo='ce' [title]="'Environmental Resources App'" id="main-navigation">
  <ce-header-menu *ngIf="role != 'CN'" label="Photo Library" (clickEvent)="miaPhotoLibraryClick()">
  </ce-header-menu>
  <ce-header-menu [label]="buttonText" (clickEvent)="iconClick()" *ngIf="!!userName">
  </ce-header-menu>
  <ce-header-menu-standout [label]="userName" iconName="icon-logged-in" *ngIf="!!userName"> 
    <ce-header-menu-item label="Need Help?" (clickEvent)="support()">
    </ce-header-menu-item>
    <ce-header-menu-item label="Crew Code" (clickEvent)="crewCodeClick()" *ngIf="showCrewCodeOption">
    </ce-header-menu-item>
    <ce-header-menu-item label="Log Out" (clickEvent)="logOut()">
    </ce-header-menu-item>
  </ce-header-menu-standout>
</ce-header>