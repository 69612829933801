import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { forkJoin, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AddMasterData } from 'src/app/app-state/actions/master-data.action';
import { Feature } from 'src/app/interfaces/feature';
import { FlushSubtype } from 'src/app/interfaces/flush-subtype';
import { MasterData } from 'src/app/interfaces/master-data';
import { ParkingRestriction } from 'src/app/interfaces/parking-restriction';
import { StructureMenuOptions } from 'src/app/interfaces/structure-menu-options';
import { BaseService } from '../base/base.service';

@Injectable({
  providedIn: 'root',
})
export class MasterDataService {
  constructor(private baseService: BaseService, private store: Store) {}

  private fetchAll(isPwaEnabled: boolean): Observable<MasterData> {
    return this.baseService.getMasterData().pipe(
      tap(({calendarNames,featureToggles, priorities, flushSubTypes, structureMenuOptions, parkingRestrictions}) => {
        // if (isPwaEnabled) {
        //   if (featureToggles?.filter((features) => features.feature === 'offlinemode')[0]?.isActive) {
        //     navigator.serviceWorker
        //       .getRegistrations()
        //       .then((registrations) => {
        //         if (registrations?.length < 1) {
        //           navigator.serviceWorker.register('/ngsw-worker.js');
        //         }
        //       })
        //       .catch((err) => console.log(err));
        //   } else {
        //     navigator.serviceWorker
        //       .getRegistrations()
        //       .then((registrations) => {
        //         for (let registration of registrations) {
        //           registration.unregister();
        //         }
        //       })
        //       .catch((err) => console.log(err));
        //     caches
        //       .keys()
        //       .then((keyList) => {
        //         return Promise.all(
        //           keyList.map((key) => {
        //             return caches.delete(key);
        //           })
        //         );
        //       })
        //       .catch((err) => console.log(err));
        //   }
        // }
      }),
      map(({calendarNames,featureToggles, priorities, questions, priorityItems ,flushSubTypes, structureMenuOptions, parkingRestrictions}) => (
        { 
          calendarNames: calendarNames,
          questions: questions,
          priorityItems: priorityItems,
          featureToggles,
          priorities,
          structureMenuOptions,
          parkingRestrictions,
          flushSubTypes
        } as MasterData
      )
      )
    )
  }
  
  public getCacheItem(name: string): Observable<any>  {
    return this.store.select(store => store.AppState.masterData as MasterData)
      .pipe(
        map(dataItems => dataItems[name]))
  }

  saveMasterData(isSwUpdateEnabled) : Observable<any> {
    return this.fetchAll(isSwUpdateEnabled).pipe(
      tap((data) => {
        console.log('saveMasterData.data :>> ', data);
        let mapPriority = {
          ...data,
          priorities: this.mapPriorities(data.priorities),
          priorityItems: this.mapPriorityItems(data.priorities),
        };

        this.store.dispatch(new AddMasterData(
          mapPriority
        ));
      }) 
    )
  }

  mapPriorities(arr) {
    return arr?.map((priority) => {
      let level = parseInt(priority?.priorityLevel?.match(/\d+/)[0]);
      return {
        flushPriorityId: level,
        priorityLevel: priority?.priorityLevel,
        priorityDesc: `Priority Level ${level}`
      }
    })
  }
  mapPriorityItems(arr) {
    let index = 0;
    return arr.map((obj) => {
      let level = parseInt(obj?.priorityLevel?.match(/\d+/)[0]);
      return obj.priorities.map((priority) => {
        return {
          priorityItemID: index++,
          flushPriorityID: level,
          flushPriorityName: priority,
          flushPriorityValue: priority
        }
      })
    }).flat(1)
  }

  setScrollYPosition(posY) {
    localStorage.setItem('hub-scroll-posY', posY);
  }

  getScrollYPostition() {
    return parseInt(localStorage.getItem('hub-scroll-posY'));
  }
}
