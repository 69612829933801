import { Injectable } from '@angular/core';
import { FlushReview } from 'src/app/models/flush-review';
import { BaseService } from 'src/app/services/base/base.service';
import { PhotoService } from 'src/app/services/photo/photo.service';
import { Photo } from 'src/app/models/photo';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { AppState } from 'src/app/app-state/app.state';
import { Select } from '@ngxs/store';
import { StructureHistoryRequest } from 'src/app/models/structure-history';
import { map, switchMap, take } from 'rxjs/operators';
import { User } from 'src/app/models/user.model';
import { UserInfo } from 'src/app/interfaces/user-info';

@Injectable({
  providedIn: 'root',
})
export class FlushReviewService {
  $isEdit = new BehaviorSubject(false);
  @Select(AppState.getCrewCode) getCrewCode$: Observable<string>;
  @Select(AppState.getUserInfo) getUserInfo$: Observable<UserInfo>;

  constructor(private baseService: BaseService, private photoService: PhotoService) {}

  saveFlushRequests(workRequestId: string, payload: { comments:string, isCrewConductingRepairs: boolean, canOriginalWorkContinue: boolean, hasStrayVoltage: boolean, hasLeadStabilizerUsed:boolean,
    noOfStabilizers: number }, images: Photo[]) {
    return forkJoin([
      this.getCrewCode$.pipe(map(res => res), take(1)),
      this.getUserInfo$.pipe(map(res => res), take(1)),
      of(this.photoService.MapToFlushPhotos(images))
    ]).pipe(
      map(res => res),
      switchMap(([crewCode, userInfo, images]) => this.baseService.saveFlushReviewRequest(
        new FlushReview(workRequestId, crewCode, payload.comments, images, userInfo.user.email.split('@')[0], payload.isCrewConductingRepairs, payload.canOriginalWorkContinue, payload.hasStrayVoltage, payload.hasLeadStabilizerUsed, payload.noOfStabilizers)
      ))
    )
  }
  getStructureHistory(workRequestNumber: string, structureNumber: string) {
    return this.baseService.getStructureHistory(workRequestNumber, structureNumber)
  }
}
