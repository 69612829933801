import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Observable, config } from 'rxjs';
import { EnvironmentConfig } from './environment-config.model';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  private config: EnvironmentConfig;

  public get configuration(): EnvironmentConfig {
    return this.config;
  }
}

export let adalConfig;
export let OKTA_CONFIG;

export function getConfig() {
  return adalConfig;
}

export function getOktaConfig() {
  return OKTA_CONFIG;
}

export function initSettings() {
  return () => {
    adalConfig = {
      tenant: environment.tenant,
      clientId: environment.clientId,
      redirectUri: environment.redirectUri,
      cacheLocation: 'localStorage',
    };
    OKTA_CONFIG = {
      issuer: environment.oktaIssuer,
      clientId: environment.oktaClientId,
      redirectUri: environment.oktaRedirectUri,
      scopes: ['openid', 'profile', 'email'],
    };
  };
}
