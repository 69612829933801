import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-ftux',
  templateUrl: './ftux.component.html',
  styleUrls: ['./ftux.component.scss']
})
export class FtuxComponent implements OnInit {
  @ViewChild('slider') sliderRef: ElementRef;
  @Output() skipped = new EventEmitter();
  @Input() slides = [];
  currentSlide = 0;
  constructor() { }

  ngOnInit(): void {
  }

  showSlide(i: number) {
    if ((i < this.slides.length) && (i > -1)) {
      this.currentSlide = i;
    }
  }

  skipFTUX() {
    let response = false;
    if(this.currentSlide < this.slides?.length - 1) {
      response = true;
    }
    this.skipped.emit(response);
  }
  onSwipeLeft(event) {
    console.log(event);
  }
  onSwipeRight(event) {
    console.log(event)
  }
}
