import {
  Component,
  OnInit,
  OnDestroy,
  HostListener,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import * as moment from 'moment';
import { BaseService } from 'src/app/services/base/base.service';
import { BaseComponent } from 'src/app/common/base/base.component';
import { StylerService } from 'src/app/services/styler/styler.service';
import { Photo } from 'src/app/models/photo';
import { FormValidationService } from 'src/app/services/forms/form-validation.service';
import { Router, ActivationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { AppState, AppStateModel } from 'src/app/app-state/app.state';
import { forkJoin, Observable } from 'rxjs';
import { FlushReviewService } from 'src/app/services/flush-review/flush-review.service';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AddWorkDescription } from 'src/app/app-state/actions/start-job.actions';
import { InternetCheckerService } from 'src/app/services/internet-checker/internet-checker.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WorkRequestEntity } from 'src/app/models/work-request-entity';
import { PhotoService } from 'src/app/services/photo/photo.service';
import { WorkRequestsResponse } from 'src/app/models/work-requests-response';
import { FlushComments } from 'src/app/models/edit-history';
import { TimestampPipe } from 'src/app/common/pipes/timestamp.pipe';
import * as _ from 'lodash';
import { LoggingService } from 'src/app/services/logging/logging.service';
import { UserInfo } from 'src/app/interfaces/user-info';
import { EoRequestsService } from 'src/app/services/eo-requests/eo-requests.service';
import { FlushReview } from 'src/app/models/flush-review';
import { WorkRequestRow } from 'src/app/interfaces/work-request-row';
import { WorkRequest } from 'src/app/interfaces/work-request';
import { WorkRequestDetail } from 'src/app/models/work-request-detail';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
  providers: [TimestampPipe]
})
export class ReviewComponent extends BaseComponent implements OnInit, OnDestroy {
  today = moment().format('MM/DD/YYYY');
  photos: Photo[] = [];
  numberPhotos = 0;
  flushUserRole: string;
  flushReviewForm: FormGroup;
  workRequest: WorkRequestDetail;
  isEnabled = false;
  showingConfirmSubmit = false;
  isStructurePhotosValid = false;
  isJobDetailsValid = false;
  showingSubmitResult = false;
  submitResult = false;
  isMobile = false;
  showErrorModal = false;
  isSummary = false;
  isOnline = true;
  // Aka pausedJob (incomplete)
  showSaveProgress = false;
  flushComments : FlushComments[] = [];
  flushCommentsCols = [];
  isPhotoReview = false;

  @Select(AppState.getUserInfo) getUserInfo$: Observable<UserInfo>;
  @Select(AppState.getStartJobPhotos) getStartJobPhotos$: Observable<Photo[]>;
  @Select(AppState.getPausedJob) getPausedJob$: Observable<boolean>;
  @Select(AppState.getWorkRequestGlobalId)
  getWorkRequestGlobalId$: Observable<string>;
  @Select(AppState.getWorkDescription) getWorkDescription$: Observable<string>;
  @Select(AppState.getDashboardWorkRequests) getDashboardWorkRequests$: Observable<WorkRequestsResponse>;
  @Select(AppState.getFlushReviewInfo) getFlushReviewInfo$: Observable<FlushReview>;
  @ViewChild('flushCrewSummary') flushCrewSummary;
  wrGlobalId = '';
  showConfirmSaveProgress: boolean;
  userInfo: User;
  wrDetails: WorkRequestRow;
  showStrayVoltageQuestions = false;
  showLeadStabilizerUsedQuestions = false;
  noOfStabilizersOptions = [""];
  constructor(
    private styler: StylerService,
    private service: BaseService,
    private flushReviewService: FlushReviewService,
    private formValidationService: FormValidationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private store: Store,
    private internetCheckerService: InternetCheckerService,
    private photoService: PhotoService,
    private timestampPipe: TimestampPipe,
    private loggingService: LoggingService,
    private eoRequestsService: EoRequestsService
  ) {
    super(styler);
    this.flushSubscriptions.push(
      this.styler.isPhotoReview.subscribe((val) => {
        this.isPhotoReview = val;
      }),
      this.internetCheckerService.getStatus.subscribe((isOnline) => {
        this.isOnline = isOnline;
      })
    );
    this.flushCommentsCols = [
      { field: 'createdOn', title: 'Date Saved' },
      { field: 'userName', title: 'Crew Member' },
      { field: 'description', title: 'Description of Work' }
    ];
    for (let i = 1; i < 10; i++) {
      this.noOfStabilizersOptions.push(i.toString());
    }
  }
  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    this.adjustToScreenSize(event.target.innerWidth);
  }
  private adjustToScreenSize(innerWidth) {
    this.isMobile = innerWidth <= 768 ? true : false;
  }

  ngOnInit(): void {
    //FormControl for review comments
    this.flushReviewForm = new FormGroup({
      comments: new FormControl(''),
      hasStrayVoltage: new FormControl(''),
      isCrewConductingRepairs: new FormControl(''),
      canOriginalWorkContinue: new FormControl(''),
      hasLeadStabilizerUsed: new FormControl(''),
      noOfStabilizers: new FormControl(''),
    });
    //this.storeStateWhenNavigate();
    this.getWorkDescription$.subscribe((val) => {
      this.flushReviewForm.controls.comments.setValue(val);
    });

    this.getFlushReviewInfo$.subscribe((review: FlushReview) => {
      this.flushReviewForm.patchValue({
        comments: review?.comments,
        hasStrayVoltage: review?.hasStrayVoltage,
        isCrewConductingRepairs: review?.isCrewConductingRepairs,
        canOriginalWorkContinue: review?.canOriginalWorkContinue,
        hasLeadStabilizerUsed: review?.hasLeadStabilizerUsed,
        noOfStabilizers: review?.noOfStabilizers?.toString(),
      });
      this.showStrayVoltageQuestions = review?.hasStrayVoltage;
      this.showLeadStabilizerUsedQuestions = review?.hasLeadStabilizerUsed;
    });

    this.isMobile = window.innerWidth <= 768 ? true : false;
    this.store.select(store => store.AppState as AppStateModel)
    .pipe(
      take(1)
    )
    .subscribe((state) => {
      this.photos = this.arrayUnique(this.photoService.sortImages(state?.startJobPhotos?.map(p => p)));
      if (state?.pausedJob) {
        this.flushReviewForm.get('comments').addValidators([Validators.required]);
        this.showSaveProgress = state?.pausedJob;
        
        // Need to set for formValidationService as well since we subscribe to it (isJobDetailsValid returning false for pausedJob)
        this.isJobDetailsValid = true;
        this.isStructurePhotosValid = true;
        this.formValidationService.$flushInformationValid.next(true);
        this.formValidationService.$flushPhotos.next(true);
      } else {
        this.flushReviewForm.get('comments').removeValidators([Validators.required]);
        this.isStructurePhotosValid = (state?.startJobPhotos?.filter(p => p.status === 'Before').length > 0) 
          && (state?.startJobPhotos?.filter(p => p.status === 'After').length > 0 );
      }
    }).unsubscribe();
    this.formValidationService.$flushInformationValid.subscribe((isValid) => {
      this.isJobDetailsValid = isValid;
    }).unsubscribe();
    this.getUserInfo$
    .pipe(
      tap(({user, workAssignments}) => { 
        this.userInfo = user;
        // We dont want contractors to fill out if it is a pausedJob
        if (user.flushRoleType == 'CN' && !this.showSaveProgress) {
          this.flushReviewForm.get('hasStrayVoltage').addValidators([Validators.required]);
          this.flushReviewForm.get('hasLeadStabilizerUsed').addValidators([Validators.required]);
        }
       }),
      switchMap(user => this.getWorkRequestGlobalId$),
      tap(sourceWrNumber => { this.wrGlobalId = sourceWrNumber; }),
      switchMap(sourceWrNumber => this.getDashboardWorkRequests$),
      tap(dashboardWorkRequests => {
        // Get flush comments from dashboard entity
        this.flushComments = _.cloneDeep(dashboardWorkRequests?.entities?.find((x) => x.workRequestGlobalId == this.wrGlobalId)?.flushComments ?? []);
        if(this.flushComments && this.flushComments?.length > 0) {
          this.flushComments = this.flushComments?.slice(-3);
          this.flushComments?.forEach((flushComment) => {
            flushComment.createdOn = this.timestampPipe.transform(flushComment.createdOn, 'MMM D, YYYY HH:mm');
          });
        }
        this.workRequest = this.store.selectSnapshot(store => store.AppState.workRequestDetail as WorkRequestDetail);
        this.wrDetails = _.cloneDeep(dashboardWorkRequests?.entities?.find((x) => x.workRequestGlobalId == this.wrGlobalId));
      }),
      take(1)
    )
    .subscribe((dashboardWorkRequests) => {

      this.checkIsSummary();
    });
  }

  arrayUnique(array: Photo[]) {
    var a = array.concat();
    for (var i = 0; i < a.length; ++i) {
      for (var j = i + 1; j < a.length; ++j) {
        if (a[i].id === a[j].id) a.splice(j--, 1);
      }
    }

    return a;
  }

  ngAfterViewInit() {
    this.flushCrewSummary.handleMobileNav();
    this.cdr.detectChanges();
    if (this.isSummary == true) {
      this.formValidationService.$flushSummary.next(true);
    }
  }

  checkIsSummary() {
    //Set values to true if summary
    this.router.events.pipe(filter((event) => event instanceof ActivationEnd));

    this.activatedRoute.params.subscribe((params) => {
      if (params['isSummary'] == 'true') {
        this.formValidationService.$flushInformationValid.next(true);
        this.formValidationService.$flushPhotos.next(true);
        this.formValidationService.$flushRequestValid.next(true);
        this.isStructurePhotosValid = true;
        this.isJobDetailsValid = true;
        this.isSummary = true;
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.flushReviewForm.patchValue({
      hasStrayVoltage: '',
      isCrewConductingRepairs: '',
      canOriginalWorkContinue: '',
      hasLeadStabilizerUsed: '',
      noOfStabilizers: '',
    });
    this.store.dispatch(new AddWorkDescription(this.flushReviewForm.value));
  }
  handleModalClose() {
    this.showingConfirmSubmit = false;
    this.showConfirmSaveProgress = false;
  }
  handleSubmit() {
    this.loggingService.logEvent({
      name: 'Confirming flush job is complete'
    }, {
      action: 'Submit - Complete Job',
      userName: this.userInfo.name,
      userEmail: this.userInfo.email,
      userRole: this.userInfo.role,
      userFlushRoleType: this.userInfo.flushRoleType,
      wrNo: this.wrDetails.wrNo,
      wrDate: this.wrDetails.date,
      status: this.wrDetails.status,
      eventDate: new Date()
    });
    if (this.isJobDetailsValid && this.isStructurePhotosValid) {
      this.showingConfirmSubmit = true;
    }
  }
  handleSave() {
    this.loggingService.logEvent({
      name: 'Save My Progress - Incomplete Job'
    }, {
      action: 'Save My Progress - Incomplete Job',
      userName: this.userInfo.name,
      userEmail: this.userInfo.email,
      userRole: this.userInfo.role,
      userFlushRoleType: this.userInfo.flushRoleType,
      wrNo: this.wrDetails.wrNo,
      wrDate: this.wrDetails.date,
      status: this.wrDetails.status,
      eventDate: new Date()
    });
    if (this.flushReviewForm.controls['comments'].valid) {
      this.showConfirmSaveProgress = true;
    }
  }
  submitJob() {
    this.handleModalClose();
    if(this.flushReviewForm?.controls?.hasStrayVoltage?.value === "false") {
      this.flushReviewForm.patchValue({
        isCrewConductingRepairs: '',
        canOriginalWorkContinue: ''
      });
    }

    // Log AppInsights customEvent for successful submit of Flush job
    let userInfo = this.store.selectSnapshot(store => store.AppState.userInfo);
    let workRequestDetail: WorkRequestDetail = this.store.selectSnapshot(store => store.AppState.workRequestDetail);

    this.getWorkRequestGlobalId$
    .pipe(
      switchMap(stateWorkRequestGlobalId => this.flushReviewService.saveFlushRequests(stateWorkRequestGlobalId, this.flushReviewForm?.value, this.photos)),
      take(1)
    )
    .subscribe(
      (res) => {
        this.showingSubmitResult = true;
        this.submitResult = true;
        this.loggingService.logEvent(
          {
            name: 'Flush job complete'
          },
          {
            action: "Successfully submitted a Flush Job",
            eventDate: new Date(),
            userName: userInfo?.user?.name,
            userEmail: userInfo?.user?.email,
            userRole: userInfo?.user?.role,
            workRequestDetail: {
              ...workRequestDetail,
              images: []
            }
          }
        );
      },
      (e) => { 
        this.showErrorModal = true;
        this.loggingService.logEvent(
          {
            name: 'Submit Flush Job Failed'
          },
          {
            action: "Unsuccessfully submitted a Flush Job",
            eventDate: new Date(),
            userName: userInfo?.user?.name,
            userEmail: userInfo?.user?.email,
            userRole: userInfo?.user?.role,
            workRequestDetail: {
              ...workRequestDetail,
              images: []
            }
          }
        );
      }
    );
    this.photos = []; // Clear photos after submitting
  }

  closeErrorModal() {
    this.showErrorModal = false;
  }

  closeJob() {
    this.showingSubmitResult = false;
    this.router.navigate(['requests']);
  }

  storeStateWhenNavigate() {
    if(this.flushReviewForm?.controls?.hasStrayVoltage?.value === "false") {
      this.flushReviewForm.patchValue({
        isCrewConductingRepairs: '',
        canOriginalWorkContinue: '',
        noOfStabilizers: '',
      });
    }
    // Subscribe to router to send form values on change
    this.flushSubscriptions.push(
      this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((val) => {
        this.store.dispatch(new AddWorkDescription(this.flushReviewForm.value));
      })
    );
  }

  hasStrayVoltageSelectionChange(hasStrayVoltage) {
    this.showStrayVoltageQuestions = hasStrayVoltage.toLowerCase() == 'true' ? true : false;
    if(this.showStrayVoltageQuestions) {
      this.flushReviewForm.controls.isCrewConductingRepairs.setValidators([Validators.required]);
      this.flushReviewForm.controls.canOriginalWorkContinue.setValidators([Validators.required]);
    } else {
      this.flushReviewForm.controls.isCrewConductingRepairs.setValidators(null);
      this.flushReviewForm.controls.canOriginalWorkContinue.setValidators(null);     
      this.flushReviewForm.patchValue({
        isCrewConductingRepairs: '',
        canOriginalWorkContinue: ''
      });
    }
    this.cdr.detectChanges();
  }

  hasLeadStabilizerUsedSelectionChange(hasLeadStabilizerUsed) {
    this.showLeadStabilizerUsedQuestions = hasLeadStabilizerUsed.toLowerCase() == 'true' ? true : false;
    if(this.showLeadStabilizerUsedQuestions) {
      this.flushReviewForm.controls.noOfStabilizers.setValidators([Validators.required]);
    } else {
      this.flushReviewForm.controls.noOfStabilizers.setValidators(null);
      this.flushReviewForm.patchValue({
        noOfStabilizers: '',
      });
    }
    this.cdr.detectChanges();
  }
}
