import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InternetCheckerService {
  getStatus = new BehaviorSubject<boolean>(window.navigator.onLine);

  constructor() {
    this.updateOnlineStatus();
  }

  addOnlineEventListeners(): void {
    window.addEventListener('online', () => {
      this.updateOnlineStatus();
    });
    window.addEventListener('offline', () => {
      this.updateOnlineStatus();
    });
  }

  updateOnlineStatus(): void {
    this.getStatus.next(navigator.onLine);
  }
}
