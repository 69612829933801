<div class="row">
  <div class="col">
    <h3 class="mb-3">Flush Review</h3>
  </div>
</div>
<div class="row">
  <div class="col">
    <p class="body2">{{today | dash}}</p>
  </div>
</div>
<div class="container-fluid" [ngStyle]="isPhotoReview && {'display': 'none'}">
  <div class="row">
    <div class="col-auto">
      <b>Source WR#</b>
      <p [ngClass]="{'dash-text': !workRequest?.sourceWrNumber}">{{workRequest?.sourceWrNumber | dash}}</p>
    </div>
    <div class="col-auto"
    
    
    >
      <b>Flush WR#</b>
      <p [ngClass]="{'dash-text': !workRequest?.workRequestNo}">{{workRequest?.workRequestNo | dash}}</p>
    </div>
    <div class="col-auto" *ngIf="!isSummary">
      <b>Flush Description</b>
      <p [ngClass]="{'dash-text': !workRequest?.workRequestName}">{{workRequest?.workRequestName | dash}}</p>
    </div>
  </div>
  <div class="row">
    <h4>Flush Information</h4>
  </div>
  <div class="form-group row" *ngIf="isSummary">
    <div class="col-12 col-md-6">
      <p><b>Description of the work that was performed</b></p>
      <p>{{workRequest?.workRequestName}}</p>
    </div>
  </div>
  <!-- Work History demo -->
  <div *ngIf="flushComments.length > 0">
    <div class="row">
      <h5>Work History</h5>
    </div>
    <div class="row mb-5">
      <app-flush-grid [isSelectable]="false" [isFlushGridSimple]="true" [ngClass] ="{'no-highlight' : true}" [allowVerticalDataTable]="true"
        [data]="flushComments | dash" [columns]="flushCommentsCols"
        [actions]="[]"></app-flush-grid>
    </div>
    <div class="row" *ngIf="!isSummary">
      <h4>Update Description of Work</h4>
    </div>
  </div>
  <div class="row" *ngIf="!isSummary">
    <div class="col-12 col-md-6 mb-2">
      <p>Please provide a description of the work that was performed</p>
    </div>
  </div>
  <form [formGroup]="flushReviewForm">
    <div class="mb-5 row no-gutters" *ngIf="!isSummary">
      <div class="col-12 col-md-6" [formGroup]="flushReviewForm">
        <ce-input inputType="textarea" label="Description of work" formControlName="comments" class="form-control" [showError]="flushReviewForm.get('comments').errors"></ce-input>
      </div>
    </div>
    <ng-container 
      *ngIf="!!userInfo && ((userInfo?.flushRoleType == 'CN' && !showSaveProgress) || (isSummary && !!this.flushReviewForm.controls['hasStrayVoltage'].value))">
      <div class="form-group row">
        <div class="col-lg-5">
          <p><b>Has Stray Voltage been found?</b><a *ngIf="!this.flushReviewForm.controls['hasStrayVoltage'].valid" class="flush-process-error"> *</a></p>
          <div class="row">
            <div class="col-12">
              <ce-radio-group size="large" [isDisabled]="isSummary" formControlName="hasStrayVoltage" (selectionChange)="hasStrayVoltageSelectionChange($event)">
                <ce-radio-button size="large" label="No" id="NoStrayVoltage" value=false groupName="hasStrayVoltageGroup" [isChecked]="this.flushReviewForm.controls['hasStrayVoltage'].value == 'false' || this.flushReviewForm.controls['hasStrayVoltage'].value == false" [isDisabled]="false" [isError]="false"></ce-radio-button>
                <ce-radio-button size="large" label="Yes" id="ThereAreStayVoltage" value=true groupName="hasStrayVoltageGroup" [isChecked]="this.flushReviewForm.controls['hasStrayVoltage'].value == 'true' || this.flushReviewForm.controls['hasStrayVoltage'].value == true" [isDisabled]="false" [isError]="false"></ce-radio-button>
              </ce-radio-group>       
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showStrayVoltageQuestions">
        <div class="form-group row">
          <div class="col-lg-5">
            <p><b>Will the crew be conducting repairs?</b><a *ngIf="!this.flushReviewForm.controls['isCrewConductingRepairs'].valid" class="flush-process-error"> *</a></p>
            <div class="row">
              <div class="col-12">
                <ce-radio-group size="large" [isDisabled]="isSummary" formControlName="isCrewConductingRepairs">
                  <ce-radio-button size="large" label="No" id="NoStrayVoltage" value=false groupName="isCrewConductingRepairsGroup" [isChecked]="this.flushReviewForm.controls['isCrewConductingRepairs'].value == 'false' || this.flushReviewForm.controls['isCrewConductingRepairs'].value == false" [isDisabled]="false" [isError]="false"></ce-radio-button>
                  <ce-radio-button size="large" label="Yes" id="ThereAreStayVoltage" value=true groupName="isCrewConductingRepairsGroup" [isChecked]="this.flushReviewForm.controls['isCrewConductingRepairs'].value == 'true' || this.flushReviewForm.controls['isCrewConductingRepairs'].value == true" [isDisabled]="false" [isError]="false"></ce-radio-button>
                </ce-radio-group>       
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-lg-5">
            <p><b>Can the original work be performed?</b><a *ngIf="!this.flushReviewForm.controls['canOriginalWorkContinue'].valid" class="flush-process-error"> *</a></p>
            <div class="row">
              <div class="col-12">
                <ce-radio-group size="large" [isDisabled]="isSummary" formControlName="canOriginalWorkContinue">
                  <ce-radio-button size="large" label="No" id="NoStrayVoltage" value=false groupName="canOriginalWorkContinueGroup" [isChecked]="this.flushReviewForm.controls['canOriginalWorkContinue'].value == 'false' || this.flushReviewForm.controls['canOriginalWorkContinue'].value == false" [isDisabled]="false" [isError]="false"></ce-radio-button>
                  <ce-radio-button size="large" label="Yes" id="ThereAreStayVoltage" value=true groupName="canOriginalWorkContinueGroup" [isChecked]="this.flushReviewForm.controls['canOriginalWorkContinue'].value == 'true' || this.flushReviewForm.controls['canOriginalWorkContinue'].value == true" [isDisabled]="false" [isError]="false"></ce-radio-button>
                </ce-radio-group>       
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-lg-5">
          <p><b>Was a lead stabilizer used?</b><a *ngIf="!this.flushReviewForm.controls['hasLeadStabilizerUsed'].valid" class="flush-process-error"> *</a></p>
          <div class="row">
            <div class="col-12">
              <ce-radio-group size="large" [isDisabled]="isSummary" formControlName="hasLeadStabilizerUsed" (selectionChange)="hasLeadStabilizerUsedSelectionChange($event)">
                <ce-radio-button size="large" label="No" id="NoLeadStabilizerUsed" value=false groupName="hasLeadStabilizerUsedGroup" [isChecked]="this.flushReviewForm.controls['hasLeadStabilizerUsed'].value == 'false' || this.flushReviewForm.controls['hasLeadStabilizerUsed'].value == false" [isDisabled]="false" [isError]="false"></ce-radio-button>
                <ce-radio-button size="large" label="Yes" id="ThereAreLeadStabilizerUsed" value=true groupName="hasLeadStabilizerUsedGroup" [isChecked]="this.flushReviewForm.controls['hasLeadStabilizerUsed'].value == 'true' || this.flushReviewForm.controls['hasLeadStabilizerUsed'].value == true" [isDisabled]="false" [isError]="false"></ce-radio-button>
              </ce-radio-group>       
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showLeadStabilizerUsedQuestions">
        <div class="col-lg-5">
          <p><b>How many lead stabilizer were used?</b><a *ngIf="!this.flushReviewForm.controls['noOfStabilizers'].valid" class="flush-process-error"> *</a></p>
          <div class="row">
              <ce-select [disabled]="isSummary" formControlName="noOfStabilizers" class="scope-select" errorMessage='*' placeholder="Select" >
                <ce-option *ngFor="let option of noOfStabilizersOptions" [value]="option">
                  {{ option }}
                </ce-option>
              </ce-select>
          </div>
        </div>
      </div>
    </ng-container>
  </form>
</div>
<div class="mb-5 row">
  <div class="col-12">
    <app-details-box [header]="'Photos ('+photos.length+')'" [link]="{url: '/start-job/photos', text:'Edit', params: {}, icon:'icon-edit'}" *ngIf='!isSummary' #flushCrewSummary>
      <div>
        <app-photos [cameraEnabled]="false" [isSummary]="isSummary"></app-photos>
      </div>
    </app-details-box>
    <app-details-box [header]="'Photos ('+photos.length+')'" *ngIf='isSummary' #flushCrewSummary>
      <div>
        <app-photos [cameraEnabled]="false" [isSummary]="isSummary"></app-photos>
      </div>
    </app-details-box>
  </div>
</div>
<div *ngIf="!isSummary" class="review-btns row">
  <div class="col-12 col-md-6 col-lg-4" *ngIf="showSaveProgress">
    <ce-button label="Save My Progress"
    [disabled]="!isOnline"
    type="secondary"
    [disabled]="!(isJobDetailsValid && isStructurePhotosValid && this.flushReviewForm.valid) || !isOnline || !showSaveProgress"
    [override]="true"
    (clickEvent)="handleSave()"></ce-button>
  </div>
  <div class="col-12 col-md-3 col-lg-1">
    <ce-button label="Submit" 
      [disabled]="!(isJobDetailsValid && isStructurePhotosValid && this.flushReviewForm.valid) || !isOnline || showSaveProgress"
      (clickEvent)="handleSubmit()"
      [override]="true"
      ></ce-button>
  </div>
</div>
<!-- Summary close button  -->
<div *ngIf="isSummary" class="form-group row ">
  <div class="col-auto">
    <ce-button label="Close" (clickEvent)="closeJob()" type="secondary"></ce-button>
  </div>
</div>
<app-modal [showModal]="showingConfirmSubmit" modalHeading="Are you ready to submit this job?"
  (closeDialogClick)="handleModalClose()">
  <div class="container">
    <div class="mb-5 row">
      <div class="col">
        <span>Confirm all the information is correct before submitting your job.</span><br>
        <span>Once you submit your job, a notification will be sent to your management dl with the information and photos taken.</span>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-auto style-guide-close-modal-btn">
        <ce-button type="secondary" label="Cancel" (clickEvent)="handleModalClose()"></ce-button>
      </div>
      <div class="col-auto">
        <ce-button label="Confirm" data-custom-id="FMReviewPage" data-custom-bhvr="COMPLETEPROCESS" (clickEvent)="submitJob()"></ce-button>
      </div>
    </div>
  </div>
</app-modal>
<app-modal [showSuccessIcon]="true" [showModal]="showingSubmitResult" modalHeading="Job successfully submitted" [showClose]="false">
  <div class="container">
    <div class="mb-5 row">
      <div class="col">
        <span>Your job was successfully submitted</span><br>
        <span>The customer will be alerted with the details of the job.</span>
      </div>
    </div>
    <div class="mb-4 row">
      <div class="col-md-3 col-lg-2">
        <ce-button label="Close" (clickEvent)="closeJob()"></ce-button>
      </div>
    </div>
  </div>
</app-modal>
<app-modal [showErrorIcon]="true" [showModal]="showErrorModal" modalHeading="Job Not submitted" [showClose]="false">
  <div class="container">
    <div class="mb-5 row">
      <div class="col">
      <span>An error has occurred. Please try submitting your job again.</span>
    </div>
  </div>
    <div class="form-group row">
      <div class="col-md-3 col-lg-2">
        <ce-button label="Close" (clickEvent)="closeErrorModal()"></ce-button>
      </div>
    </div>
  </div>
</app-modal>
<app-modal [showModal]="showConfirmSaveProgress" modalHeading="Are you ready to save this Job?"
  (closeDialogClick)="handleModalClose()">
  <div class="container">
    <div class="mb-5 row">
      <div class="col">
        <span>Confirm all the information is correct before saving your job.</span><br>
        <span>Once you submit your job a notification will be sent to the customer.</span>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-auto style-guide-close-modal-btn">
        <ce-button type="secondary" label="Cancel" (clickEvent)="handleModalClose()"></ce-button>
      </div>
      <div class="col-auto">
        <ce-button label="Confirm" data-custom-id="FMReviewPage" data-custom-bhvr="COMPLETEPROCESS" (clickEvent)="submitJob()"></ce-button>
      </div>
    </div>
  </div>
</app-modal>