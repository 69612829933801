<div class="row top-tracker-row" [ngClass]="{ 'top-tracker-row-dashboard': isDashboard }">
  <div *ngFor="let tracker of trackerArr; let i = index" [ngClass]="{
    'top-tracker-inactive' : !trackerArr[i].isActive,
    'top-tracker-active' : trackerArr[i].isActive,
    'col-auto' : isDashboard,
    'dashboard-col' : isDashboard,
    'col' : !isDashboard
  }" (click)=selectTopCol(i)>
    <label *ngIf="!isDashboard" class="body2"><b>{{i+1}}. </b></label>
    <label *ngIf="!isDashboard" class="body2 tracker-text"><b>{{tracker.text}}</b></label>
    <h4 *ngIf="isDashboard" class="dashboard-tracker">{{tracker.text}}</h4>
  </div>
</div>
