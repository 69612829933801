<div class="new-request-canvas-card">
  <h3 class="new-request-header">Please enter your crew code</h3>
  <p class="new-request-description">To begin, please enter your crew code to view the jobs that are assigned.</p>
  <form [formGroup]="crewCodeForm">
    <div class="form-group row new-request-description">
        <div class="col-12 col-md-10 col-lg-4 col-xl-4">
            <ce-input label='Crew code *' formControlName="crewCode" [showError]="crewCodeForm.get('crewCode')?.invalid 
            && !crewCodeForm.get('crewCode')?.pristine" [errorMessage]="getErrorMessages()"></ce-input>
        </div>
    </div>
    <div class="form-group row">
        <div class="col-12 col-md-6 col-lg-3 col-xl-2">
            <ce-button label="Next" size="large" [disabled]="crewCodeForm?.invalid" (clickEvent)="submit()"></ce-button>
        </div>
    </div>
</form>
</div>
