import { WorkRequest } from 'src/app/models/work-request';
import { WorkRequestRow } from '../interfaces/work-request-row';

export class WorkRequestsResponse {
  status: string;
  message: string;
  crewCode: string;
  exception: string;
  entities: WorkRequestRow[];
  flushSubmittedEntities: WorkRequest[];
}
