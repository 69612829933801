import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { ContractorAuthService } from './contractor-auth.service';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private $isSessionExpired = new Subject();
  public getSession = this.$isSessionExpired.asObservable() as Observable<boolean>;

  public get accesstoken() {
    if (this.employeeauth.instance.getAllAccounts()?.length > 0) {
      return this.getMsalAccessToken();
    } else if (this.contractorAuth.isAuthenticated()) {
      return this.contractorAuth.getAccessTokenValue;
    }
    return null;
  }

  public isEmployeeAuthenticated: boolean;
  public isContractorAuthenticated: boolean;

  constructor(
    private employeeauth: MsalService,
    private contractorAuth: ContractorAuthService,
    private router: Router
  ) {}
  isAuthenticated() {
    if (this.employeeauth.instance.getAllAccounts()?.length > 0) {
      return (!!this.employeeauth.instance.getActiveAccount() && !(this.getAccessTokenStatus() === 'EXPIRED'));
    } else if (this.contractorAuth.isAuthenticated()) {
      // OKTA Token still saved, time to check if the token should be remove
      // Need to decode okta token in order to retrieve expiration date
      const token = this.contractorAuth.getAccessTokenValue;
      let decoded = null;
      try {
        decoded = JSON.parse(atob(token.split('.')[1]));
        const issuedDate = moment.unix(decoded.iat);
        const expiredDate = moment.unix(decoded.exp);
        const jwtOffset = expiredDate.diff(issuedDate, 'minutes') - 60 < 1 ? 0 : expiredDate.diff(issuedDate, 'minutes') - 60;
        const currentDate = moment();
        const diff = expiredDate.diff(currentDate, 'seconds');
        if (diff - jwtOffset < 0) {
          // Token has expired, need to remove token and force contractor to sign back in
          localStorage.clear();
          sessionStorage.clear();
          return false;
        }
        return true;
      } catch (error) {
        console.log(`Error occured trying to decode OKTA token:\n`, error);
        return false;
      }
    }
    // Otherwise, user is truly unauthenticated
    return false;
  }
  getSessionData() {
    let exp = moment();
    if (this.employeeauth.instance.getAllAccounts()?.length > 0) {
      this.isEmployeeAuthenticated = true;
      this.isContractorAuthenticated = false;
      const token = this.getMsalAccessToken();
      try {
        const decoded = JSON.parse(atob(token.split('.')[1]));
        return Object.assign({}, { ...decoded});
      } catch (error) {
        return { iat: exp.unix(),  exp: exp.subtract(2, 'h').unix()};
      }
    } else if (this.contractorAuth.getAccessTokenValue && this.contractorAuth.isAuthenticated()) {
      this.isEmployeeAuthenticated = false;
      this.isContractorAuthenticated = true;
      // Need to decode okta token in order to retrieve expiration date
      const token = this.contractorAuth.getAccessTokenValue;
      let decoded = null;
      try {
        decoded = JSON.parse(atob(token.split('.')[1]));
      } catch (error) {
        console.log(`Error occured trying to decode OKTA token:\n`, error);
      }
      return Object.assign({}, { ...decoded, id: this.contractorAuth.getIdTokenValue });
    } else {
      console.log('employee auth and contractor auth token values now null');
      return { iat: exp.unix(),  exp: exp.subtract(2, 'h').unix()};
    }
  }
  updateSessionStatus(sessionValid) {
    this.$isSessionExpired.next(!sessionValid);
  }
  loginEmployee() {
    this.employeeauth.loginRedirect();
  }
  getMsalAccessToken() {
    const t = Object.keys(localStorage).filter(item => item.indexOf('accesstoken') > 0 );
    
    return t.length > 0 ? JSON.parse(localStorage.getItem(t[0])).secret : '';
  }
  getMsalIdToken() {
    const t = Object.keys(localStorage).filter(item => item.indexOf('idtoken') > 0 );
    
    return t.length <=1 ? JSON.parse(localStorage.getItem(t[0])).secret : '';
  }
  getAccessTokenStatus() {
      const sessionData = this.getSessionData();
      const expiredDate = moment.unix(sessionData.exp);
      const currentDate = moment();
      const timeLeft = this.getAccessTokenExpireTime('s', currentDate, expiredDate)
      if (timeLeft < 0) {
        console.log('Session has timed out. Detected from getAccessTokenStatus');
        console.log(
          `%cNow: ${currentDate.format('HH:mm:ss')}\n Expires: ${expiredDate} `,
          `color: blue; font-weight: bold;`
        );
        console.log(
          `%cSeconds until session timeout: ${timeLeft}`,
          `color: red`
        );
        return "EXPIRED"
      } else if (timeLeft <= 120) {
        return `EXPIRING;${this.getAccessTokenExpireTime('s', currentDate, expiredDate)}`
      } else {
        return "VALID"
      }
  }
  getAccessTokenExpireTime(t: string = 's', currentDate: moment.Moment, expiredDate: moment.Moment) {
    let unit = "" as moment.unitOfTime.Diff;
    switch (t) {
      case 's':
        unit = 'seconds';
        break;
      default:
        unit = 'minutes';
        break;
    }
    return expiredDate.diff(currentDate, unit);
  }
  removeSessionIfCrewCodesUnassigned() {
    const m = Object.keys(localStorage).filter(item => item.indexOf('msal') > -1 );
    const l =Object.keys(localStorage).filter(item => item.indexOf('login') > -1 );
    
    [...m, ...l].forEach(k => {
      localStorage.removeItem(k);
    })
  }
  getIdToken() {
    if (this.employeeauth.instance.getAllAccounts()?.length > 0) {
      return this.getMsalIdToken();
    } else if (this.contractorAuth.isAuthenticated()) {
      return this.contractorAuth.getIdTokenValue
    }
  }

  logout() {
    if (this.employeeauth.instance.getAllAccounts()?.length > 0) {
      this.employeeauth.logout()
      .pipe(take(1))
      .subscribe(data => {
        this.router.navigate(['', 'sign-in']);
      });
    } else if (this.contractorAuth.isAuthenticated()) {
      this.contractorAuth.logout().subscribe(
        (res) => {
          window.location.replace(res.toString());
          localStorage.clear();
          sessionStorage.clear();
          this.router.navigate(['', 'sign-in']);
        },
        (error) => {
          console.log(`Error occured on contractor auth logout\n${JSON.stringify(error)}`);
          localStorage.clear();
          sessionStorage.clear();
          this.$isSessionExpired.next(false);
          this.router.navigate(['', 'sign-in']);
        }
      );
    }
  }
}
