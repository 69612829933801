import { Pipe, PipeTransform } from '@angular/core';
import { Photo } from '../../models/photo';
/*
 * Formats number of seconds into readable countdown timer for user
 *
 * Usage:
 *   seconds | countdown
 * Example:
 *   {{ 120 | countdown }}
 *
 */
@Pipe({
  name: 'countdown',
})
export class CountdownPipe implements PipeTransform {
  transform(seconds: number): string {
    if (!seconds) {
      return '0:00';
    }
    return `${Math.floor(seconds / 60)}:${(seconds % 60).toString().padStart(2, '0')}`;
  }
}
