import { Injectable } from '@angular/core';
import { Md5 } from 'ts-md5';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class HashService {
  // get the last saved hash value
  getHash(key: string) {
      const app = sessionStorage.getItem(key);
      if (app) {
          return  app;
      }
      return null;
  }

  // save the hash value
  saveHash(key: string, value: any) {
      // create a copy so the JS reference updates
      const app = this.generateHash(value);
      sessionStorage.setItem(key, app);
  }

  // generate the hash value
  generateHash(value: any): string {
      const hashToGenerate = _.cloneDeep(value);
      return Md5.hashStr(JSON.stringify(hashToGenerate)).toString();
  }

  // check the hash has changed from the last saved.
  hasChanged(key: string, value: any): boolean {
      const currentHashValue = this.getHash(key);
      const newHashValue = this.generateHash(value);
      return currentHashValue === newHashValue;
  }
}