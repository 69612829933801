import {
  Component,
  OnInit,
  Input,
  HostListener,
  EventEmitter,
  Output,
  OnChanges,
  ViewChild,
  ElementRef,
  OnDestroy,
  AfterViewInit,
  ChangeDetectorRef,
  AfterViewChecked,
  AfterContentChecked,
} from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, take, tap } from 'rxjs/operators';
import { PhotoTag } from '../../models/photo-tag';
import { BaseService } from 'src/app/services/base/base.service';
import { LoggingService } from 'src/app/services/logging/logging.service';
import { Photo } from 'src/app/models/photo';
import { animate, style, transition, trigger } from '@angular/animations';
import { MasterDataService } from 'src/app/services/master-data/master-data.service';
import { CONFIG } from 'src/app/global/config';
import { StructureMenuOptions } from 'src/app/interfaces/structure-menu-options';



@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [style({ opacity: 0 }), animate('150ms 50ms ease-out', style({ opacity: 1 }))]),
      transition('* => void', [animate('150ms 0ms ease', style({ opacity: 0 }))]),
    ]),
  ]
})
export class PhotoComponent implements OnInit, OnDestroy {
  _image: Photo;
  // Need to reset image to null, so video-player can reload
  @Input() set image(image) {
    this._image = null;
    this.cdr.detectChanges();
    this._image = image;
  }
  get image() {
    return this._image;
  }
  @Input() private photoAdded: EventEmitter<boolean>;
  @Input() taggingEnabled = true;
  @Input() showTags = true;
  @Input() set inputPhoto(value: any) {
    // Clear tags if showing video 
    if (value?.photo?.mediaType === this.mediaTypes.VIDEO) {
      this.renderedTags = [];
      this.tags = [];
    }

    this.$inputPhoto.next(value);
  }
  get inputPhoto() {
    return this.$inputPhoto.getValue();
  }
  // @Input() addLabel = false;
  @Output() labelsAdded = new EventEmitter();
  @ViewChild('imageTag') imageRef: ElementRef;

  tags: PhotoTag[] = []; // What we acually save
  renderedTags: PhotoTag[] = []; // What we display
  $inputPhoto = new BehaviorSubject<any>({});
  selectedTag = 0;
  showLabels = false;
  isLandscape = false;
  isMobile = false;
  isImageLandscape = false;
  hideCTA = false;
  tagX = '';
  tagY = '';
  cardX = '';
  cardY = '';
  // TODO: Fetch list of labels
  labels = [];
  imageDims: DOMRect;
  get mediaTypes() {
    return CONFIG.MEDIA_TYPE;
  }

  mediaWidth = 700;
  mediaHeight = 450;
  
  constructor(private baseService: BaseService, private logger: LoggingService,private cdr: ChangeDetectorRef, private masterData: MasterDataService) {
    fromEvent(window, 'resize')
    .pipe(
      tap(e => {
        this.renderedTags = [];
      }),
      debounceTime(750),
      distinctUntilChanged(),
      tap(e => {
        this.dynamicVideoSize();

        // We detect that viewport dimensions have stopped changing based on time elapsed and last non-distinct value
        if (this.tags.length > 0) {
          this.renderedTags = this.calculateTagPosition(this.tags, this.imageRef.nativeElement.getBoundingClientRect());
        }
      })
    ).subscribe(() => {
      // Done resizing and calculating new rendered positions
    })
  }
  @HostListener('window:resize', ['$event'])
  private onResize() {
    // Check dimensions of the screen if the user has changed view between landscape/portrait
    this.isLandscape = window.innerWidth > window.innerHeight ? true : false;
    this.isMobile = window.innerWidth < 768;
  }

  ngOnInit(): void {
    if (this.photoAdded) {
      this.photoAdded.subscribe((data) => {
        // Do something in the childComponent after parent emits the event.
        this.tags = [];
        this.renderedTags = [];
      });
    }
    setTimeout(() => {
      this.hideCTA = true;
    }, 7000);
    // When flush process is disabled
    this.masterData.getCacheItem(CONFIG.MASTER_DATA.STRUCTURE_OPTIONS)
      .subscribe((res: StructureMenuOptions) => {
          if (res != null) {
            this.labels = res.debrisType;
          }
        },
        (err) => this.logger.logException(err)
      );

    this.onResize();
  }

  ngAfterViewChecked() {
    //this.dynamicVideoSize();
  }

  ngOnDestroy() {
    this.$inputPhoto.unsubscribe();
  }
  loadedImage(image) {
    console.log(image);
    const getImagePath = (image: Event) => {
      var path = (image.composedPath && image.composedPath()) || (image as any).path,
      target = image.target;

      if (path != null) {
          // Safari doesn't include Window, but it should.
          return (path.indexOf(window) < 0) ? path.concat(window) : path;
      }

      if (target === window) {
          return [window];
      }

      function getParents(node, memo) {
          memo = memo || [];
          var parentNode = node.parentNode;

          if (!parentNode) {
              return memo;
          }
          else {
              return getParents(parentNode, memo.concat(parentNode));
          } 
      }

      return [target].concat(getParents(target, []), window);
    }
    const path = getImagePath(image);
    this.renderedTags = [];
    this.isImageLandscape = path[0].naturalWidth > path[0].naturalHeight;
    this.$inputPhoto
    .pipe(
      debounceTime(600),
      filter(p => Object.keys(p).length > 0),
      map(photo => {
        // Could come in as SelectedPhoto or Photo
        if (Object.keys(photo).some(p => p === 'dims')) {
          return photo.photo.tags;
        } else if (!Object.keys(photo).some(p => p === 'dims')) {
          return (photo as Photo).tags;
        } else {
          return [] as PhotoTag[];
        }
      }),
      tap(tags => {
        this.tags = tags.map(t => t);
        this.getImageDims().then(dims => {
          this.renderedTags = this.calculateTagPosition(tags, dims)
        }).catch(err => {
          this.renderedTags = []
        });
      }),
      take(1)
    )
    .subscribe((tags) => { });
  }

  async getImageDims(): Promise<DOMRect> {
    // Leverage request animation frame to wait for repaint of image so we can get correct DOMRect
    while (Math.ceil(this.imageRef.nativeElement.getBoundingClientRect().width) < this.imageRef.nativeElement.offsetWidth) {
      await new Promise((resolve) => window.requestAnimationFrame(resolve))
    }
    return this.imageRef.nativeElement.getBoundingClientRect() as DOMRect;
  }
  calculateTagPosition(tags: PhotoTag[], dims: DOMRect) {
    const transformedTags: PhotoTag[] = tags.map((tag: PhotoTag) => {
      // Make sure position of tag is maintained when view across different viewport dimensions
      // Scale the new position of tag based on current screen dims and original dims of positions
      const _x = (Number(tag.x) * (dims.width/Number(tag.w))) 
        + (dims.left <=0 ? 0 : dims.left);
      const _y = Number(tag.y) * (dims.height/Number(tag.h))
        + (dims.top <=0 ? 0 : dims.top);     
      return {
        labels: _.cloneDeep(tag.labels),
        x: tag.x,
        y: tag.y,
        posX: `calc(${_x}px - ${!this.isLandscape && this.isMobile ? '0.5' : '1'}rem)`,
        posY: `calc(${_y}px - ${!this.isLandscape && this.isMobile ? '0.5' : '1'}rem)`,
        w: tag.w,
        h: tag.h,
      };
    });

    return transformedTags;
  }
  addTag(event) {
    // Need to calculate where the point is that way the point stays in correct place while changing orientation/viewport

    // Fetch dimensions of the <img> canvas to help us with stay within bounds of the image when calculating the position of point where we add a tag,
    const imageDims = event.target.getBoundingClientRect();
    // Detect the amount of offset between the image (<img>) and where it currently sits relative to the viewport.
    /* Rectangle's boundary edges (top, right, bottom, left) change in value if you scroll
        imageDims.left = # of px from edge of viewport to left border of <img>
        imageDims.top = # of px from edge of viewport to top border of <img> 
        More info on MDN under getBoundingClientRect docs
    */

    if (this.taggingEnabled && !this.showLabels) {
      this.showLabels = true;
      this.selectedTag = this.tags.length;

      // First we find position of point clicked and provide offest from image
      const _x = event.offsetX + (imageDims.left <=0 ? 0 : imageDims.left);
      const _y = event.offsetY + (imageDims.top <=0 ? 0 : imageDims.top);

      // We subtract rem to center the circle that is drawn at the point selected desktop-(2rem) | mobile-(1rem)   
      this.tags.push({
        w: imageDims.width,
        h: imageDims.height,
        x: event.offsetX,
        y: event.offsetY,
        posX: `calc(${_x }px - ${!this.isLandscape && this.isMobile ? '0.5' : '1'}rem)`,
        posY: `calc(${_y }px  - ${!this.isLandscape && this.isMobile ? '0.5' : '1'}rem)`,
        labels: [],
      });
      this.renderedTags.push({
          w: imageDims.width,
          h: imageDims.height,
          x: event.offsetX,
          y: event.offsetY,
          posX: `calc(${_x }px - ${!this.isLandscape && this.isMobile ? '0.5' : '1'}rem)`,
          posY: `calc(${_y }px  - ${!this.isLandscape && this.isMobile ? '0.5' : '1'}rem)`,
          labels: [],
        })
      this.propogateTags();
    }
  }
  closeLabels() {
    this.showLabels = false;
    if (this.renderedTags[this.selectedTag]?.labels.length === 0) {
      this.tags.splice(this.selectedTag, 1);
      this.renderedTags.splice(this.selectedTag,1)
    }
    this.propogateTags();
  }
  showTagDetails(i) {
    this.selectedTag = i;
    this.showLabels = true;
  }
  async handleLabelSelect(label) {
    const positionOfLabel = await this.tags[this.selectedTag].labels.findIndex((tagLabel) => tagLabel === label);
    if (positionOfLabel < 0) {
      // The label selected is not part of that tag. add it
      this.tags[this.selectedTag].labels.push(label);
      this.renderedTags[this.selectedTag].labels.push(label);
    } else {
      this.tags[this.selectedTag].labels.splice(positionOfLabel, 1);
      this.renderedTags[this.selectedTag].labels.splice(positionOfLabel, 1);
    }
    this.propogateTags();
  }
  calculateLabelClass(label) {
    if (this.renderedTags.length > 0) {
      return {
        selected: this.renderedTags[this.selectedTag]?.labels.findIndex((lbl) => lbl === label) > -1,
      };
    }
  }
  propogateTags() {
    this.labelsAdded.emit(_.cloneDeep(this.tags.map(t => t)));
  }
  handleCTA() {
    this.hideCTA = true;
  }
  getDeviceSpecificManifest(streamingUrls: string[]): string {
    if (streamingUrls?.length <= 0) {
      return '';
    }
    
    var ua = navigator.userAgent;
    if (/iPhone|iPad|iPod|Mobile|mobile|CriOS/i.test(ua)) {
      return streamingUrls.find((url) => url.includes('(format=m3u8-aapl)')) ?? '';
    }
    else {
      return streamingUrls?.find((url) => {
        return !url?.includes('(format=m3u8-aapl)') && !url?.includes('(format=mpd-time-csf)')
      }) ?? '';
    }
      
  }

  dynamicVideoSize() {
    const container = document?.getElementById('media-container');
    this.mediaHeight = container?.clientHeight ?? 700;
    this.mediaWidth = container?.clientWidth ?? 450;
  }
}
