import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { UserInfo } from '../interfaces/user-info';

@Injectable({
  providedIn: 'root'
})
export class AxisGuard implements CanActivate {
  constructor(private router: Router, private store: Store) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const userInfo = this.store.selectSnapshot(store => store.AppState.userInfo as UserInfo);
    if (!userInfo?.user) {
      return false;
    }
    else if (!(userInfo.user.flushRoleType ==='sup') && !state.url.includes('axis')) {
      // if not supervisor but accessing non axis page, should allow
      return true;
    }
    else if (!(userInfo.user.flushRoleType ==='sup') && state.url.includes('axis')) {
      // if not supervisor but accessing axis page, should redirect to requests
      this.router.navigate([''])
      return false;
    }
    else if ((userInfo.user.flushRoleType ==='sup') && (!state.url.includes('axis'))) {
      this.router.navigate(['era-axis'])
      return false;
    }
    // User is flush supervisor and is already on axis page
    return true;
  }
}
