import { Component, OnInit } from '@angular/core';
import { ContractorAuthService } from '../../services/auth/contractor-auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseService } from '../../services/base/base.service';
import { Store } from '@ngxs/store';
import { AddCrewCode, AddFlushUserRole } from 'src/app/app-state/actions/work-request.actions';
import { AddUserInfo } from 'src/app/app-state/actions/user-info.actions';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { FlushHttpRequestService } from 'src/app/services/requests/flush-http-request.service';
import { MasterDataService } from 'src/app/services/master-data/master-data.service';

@Component({
  selector: 'app-okta-callback',
  templateUrl: './okta-callback.component.html',
})
export class OktaCallbackComponent implements OnInit {
  constructor(
    private contractorAuth: ContractorAuthService,
    private baseService: BaseService,
    private activatedRoute: ActivatedRoute,
    private httpService: FlushHttpRequestService,
    private store: Store,
    private router: Router,
    private masterData: MasterDataService
  ) {}

  ngOnInit() {
    const oktaCode = this.activatedRoute.snapshot.queryParams?.code;

    if (!!oktaCode) {
      this.contractorAuth
      .authCallback(oktaCode)
      .pipe(
        catchError((err) => {
          console.log(`Error occured in authCallback`);
          console.log(err);
          return throwError(err);
        }),
        map((res: any) => {
          if (res.access_token !== null) {
            localStorage.setItem('okta-access-token', res.access_token);
            this.contractorAuth.currentAccessToken.next(res.access_token);
            localStorage.setItem('okta-id-token', res.id_token);
            this.contractorAuth.currentIdToken.next(res.id_token);
            this.httpService.buildHeaders();
          } else {
            this.contractorAuth.currentAccessToken.next(null);
            this.contractorAuth.currentIdToken.next(null);
          }
          return res;
        }),
        concatMap((isCreated) => this.baseService.getUserInfo()),
        catchError((err) => {
          console.log(`Error occured in getUserInfo`);
          console.log(err);
          this.router.navigate(['crew-code']);
          return throwError(err);
        }),
        tap((info) => {
          this.store.dispatch([new AddUserInfo(info), new AddFlushUserRole('eo'), new AddCrewCode(info.user.crewCode),]);
        }),
        catchError((err) => {
          console.log(`Error occured in store action`);
          console.log(err);
          this.router.navigate(['crew-code']);
          return throwError(err);
        }),
        switchMap(() => this.masterData.saveMasterData(true))
      )
      .subscribe(
        (user) => {
          this.router.navigate(['crew-code']);
        },
        (error) => {
          console.log('Error passed: ', error);
        }
      );
    }
  }
}
