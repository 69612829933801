<ng-container *ngIf="skip; else ftux">
    <!-- Incoming -->
    <app-banner class="axis-hub-banner" [(banner)]="banner$">
    </app-banner>
    <!-- Location -->
    <div class="map-container" #mapcontainer>
        <app-esri-map (mapLoaded)="mapLoadedEvent($event)">
        </app-esri-map>
        <!-- <ce-spinner *ngIf="!mapReady" type="spinner" brand="ce" size="small" [show]="true"></ce-spinner> -->
    </div>
    <ng-container *ngIf="($user | async) as userInfo">
        <!-- Settings Modal -->
        <div class="supervisor-settings">
            <ce-modal [visible]="showSettings" [title]="'Welcome ' +  userInfo.user.name"
                (visiblilityChange)="changeVisibility($event, 'settings')">
                <ce-modal-body>
                    <form [formGroup]="settingsForm">
                        <p>Set your preferences and the timespan of jobs you'd like to see on your dashboard.
                            <b>Note:</b> this won't affect any other system.
                        </p>
                        <div class="mb-4"></div>
                        <p><b>What time does this shift start?</b></p>
                        <div class="row">
                            <div class="col-4">
                                <app-selectbox formControlName="hour" placeholder="Hour"
                                    [icon]="'icon icon-caret-button'" [options]="[{value: '0', option: '00'}, {value: '1', option: '01'},
                                    {value: '2', option: '02'},
                                    {value: '3', option: '03'},
                                    {value: '4', option: '04'},
                                    {value: '5', option: '05'},
                                    {value: '6', option: '06'},
                                    {value: '7', option: '07'},
                                    {value: '8', option: '08'},
                                    {value: '9', option: '09'},
                                    {value: '10', option: '10'},
                                    {value: '11', option: '11'},
                                    {value: '12', option: '12'}]"></app-selectbox>
                            </div>
                            <div class="col-4">
                                <app-selectbox formControlName="minute" placeholder="Minute"
                                    [icon]="'icon icon-caret-button'" [options]="[
                                    {value: '00', option: '00'},
                                    {value: '15', option: '15'},
                                    {value: '30', option: '30'},
                                    {value: '45', option: '45'}]"></app-selectbox>
                            </div>
                            <div class="col-4">
                                <app-selectbox formControlName="ampm" placeholder="AM/PM" [options]="[
                                    {option: 'AM', value: 'AM'},
                                    {option: 'PM', value: 'PM'}
                                    ]"></app-selectbox>
                            </div>
                        </div>
                        <div class="mb-4"></div>
                        <p><b>How long is this shift?</b></p>
                        <div class="row">
                            <div class="col">
                                <p class="body2">Current Shift</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-3">
                                <app-selectbox formControlName="currentShiftLength" placeholder="Hour"
                                    [icon]="'icon icon-caret-button'" [options]="[
                                    {value: 8, option: '8 Hours'},
                                    {value: 10, option: '10 Hours'},
                                    {value: 12, option: '12 Hours'},
                                    {value: 16, option: '16 Hours'},
                                    {value: 24, option: '24 Hours'},
                                    ]"></app-selectbox>
                            </div>
                        </div>
                        <!-- <ce-select formControlName="currentShiftLength"  placeholder="Time" (selectionChange)="handleSelection($event)">    
                                <ce-option value="hi">8 Hours</ce-option>  
                            </ce-select> -->
                        <div class="mb-4"></div>
                        <div class="row">
                            <div class="col">
                                <p class="body2">Prior Shift</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-3">
                                <app-selectbox formControlName="priorShiftLength" placeholder="Hour"
                                    [icon]="'icon icon-caret-button'" [options]="[
                                    {value: 8, option: '8 Hours'},
                                    {value: 10, option: '10 Hours'},
                                    {value: 12, option: '12 Hours'},
                                    {value: 16, option: '16 Hours'},
                                    {value: 24, option: '24 Hours'},
                                    ]"></app-selectbox>
                            </div>
                        </div>
                        <!-- <ce-select formControlName="priorShiftLength" placeholder="Time" (selectionChange)="handleSelection($event)">    
                                <ce-option value="hi">24 Hours</ce-option>  
                            </ce-select> -->
                        <div class="mb-4"></div>
                        <hr class="mb-4">
                        <div class="mb-4"></div>
                        <p><b>Which calendar(s) would you like to use?</b></p>
                        <p style="margin:0px">Up to 2 calendars to import information from</p>
                        <div class="row">
                            <div class="col-12 col-md-4">
                                <app-multi-selectbox formControlName="calendarname" placeholder="Select Calendar"
                                [isValid]="isCalendarSelValid" [errorMsg]="'Please select only 2 calendar(s)'" [options]="calendarOptions" [defaultIndexes] = "calendarDefaultIndexes"></app-multi-selectbox>
                            </div>
                        </div>
                        <div class="mb-4"></div>
                        <hr class="mb-4">
                        <div class="mb-4"></div>
                        <p>Location Awareness allows the Supervisor Hub to show your current location in relation to the
                        <p>Location Awareness allows the AXIS Hub to show your current location in relation to the
                            open jobs. You can toggle this on or off at anytime. <b>Note:</b> ConEd is not tracking or
                            recording location.</p>
                        <p><b>Would you like to enable Location Awareness?</b></p>
                        <div class="row">
                            <div class="col-12 col-md-3">
                                <app-selectbox formControlName="isLocationEnabled" placeholder="Location Awareness"
                                    [icon]="'icon icon-caret-button'" [options]="[
                                    {value: 'true', option: 'Yes'},
                                    {value: 'false', option: 'No'},
                                    ]"></app-selectbox>
                            </div>
                        </div>
                        <ng-container *ngIf="($geolocationPerm | async) === 'denied'">
                            <p>To edit Location Sharing, you'll need to do so through your browser.</p>
                            <div class="settings-link">
                                <a target="_blank"
                                    href="https://support.google.com/chrome/answer/142065?hl=en&co=GENIE.Platform%3DDesktop">Google
                                    Chrome</a>
                                <a target="_blank"
                                    href="https://support.mozilla.org/en-US/kb/does-firefox-share-my-location-websites#:~:text=denied%20location%20permission.-,How%20do%20I%20stop%20all%20sites%20from%20asking%20to%20access,to%20all%20websites%2C%20without%20asking.&text=the%20menu%20button-,and%20select%20Settings.,down%20to%20the%20Permissions%20section).">Firefox</a>
                                <a target="_blank"
                                    href="https://support.microsoft.com/en-us/microsoft-edge/location-and-privacy-in-microsoft-edge-31b5d154-0b1b-90ef-e389-7c7d4ffe7b04#:~:text=To%20block%20Microsoft%20Edge%20from,precise%20location%20with%20any%20website.">Microsoft
                                    Edge</a>
                                <a target="_blank"
                                    href="https://support.apple.com/guide/personal-safety/stop-sharing-iphone-ipad-location-ips3dbc70436/web">Apple
                                    Safari</a>
                            </div>
                        </ng-container>
                    </form>
                </ce-modal-body>
                <ce-modal-footer style="display: flex;">
                    <br>
                    <ce-button [disabled]="!isCalendarSelValid" type="primary" label="Save Settings" size="medium"
                        (clickEvent)="saveSettings()"></ce-button>
                </ce-modal-footer>
            </ce-modal>
        </div>
        <div class="supervisor-lists" #supervisorlists>
            <ce-tabs type="primary" #tabs>
                <ce-tab label="Manage" iconDirection="left">
                    <!-- New Requests -->
                    <p>New Requests</p>
                    <ng-container *ngIf="($new | async) as new; else loading">
                        <app-sortable [items]="new" id='newJobs' zones="['newJobs']">
                            <ng-template #content let-itemData let-i="dataColIdx" let-itemRow="itemRow">
                                <ng-container
                                    *ngTemplateOutlet="sortableDataDefault; context: { $implicit: itemData, dataColIdx: i, itemRow: itemRow }"></ng-container>
                            </ng-template>
                        </app-sortable>
                        <ng-container *ngTemplateOutlet="decision"></ng-container>
                        <div class="mb-4"></div>
                    </ng-container>
                    <!-- Scheduled -->
                    <p>Scheduled
                        <ng-container *ngIf="userInfo?.user?.setting as userSetting; else setUp">
                            <small>For next <a type="button" (click)="showSettingsModal()"
                                    (touchstart)="showSettingsModal()">{{userSetting.currentShiftLength}}
                                    hours</a></small>
                        </ng-container>
                    </p>
                    <ng-container *ngIf="($appts | async) as appts; else loading">
                        <ng-container *ngIf="appts?.length > 0; else emptyState">
                            <app-sortable [items]="appts" id='scheduledJobs' zones="['scheduledJobs']">
                                <ng-template #title let-item>
                                    <div style="display:flex">
                                        <a class="btn-flex" [ngClass]="{ 'disabled': isJobNotReassignable(item) }" (click)="reassignJob($event,item)">
                                            Reassign
                                        </a>
                                        <div [ngClass]="isJobNotReassignable(item) ? 'grayed-out' : 'not-grayed-out'" style="font-size: 30px;"
                                            class="icon icon-person">
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template #content let-itemData let-i="dataColIdx" let-itemRow="itemRow">
                                    <ng-container
                                        *ngTemplateOutlet="sortableDataDefault; context: { $implicit: itemData, dataColIdx: i, itemRow: itemRow }"></ng-container>
                                </ng-template>
                            </app-sortable>
                        </ng-container>
                        <div class="mb-4"></div>
                    </ng-container>
                    <!-- popup-modal -->
                    <div class="job-modal">
                        <ce-modal [visible]="showModal" [title]="!!modal ? modal?.title ?? '' : ''"
                            (visiblilityChange)="changeVisibility($event, 'modal')">
                            <ce-modal-body *ngIf="showModal">
                                <p><b>Flush - {{modal?.status}}</b></p>
                                <div class="row">
                                    <div class="col-md-3">
                                        <b>Source WR#</b>
                                        <p [ngClass]="{'dash-text': !modal?.sourceWrNumber}">{{modal?.sourceWrNumber |
                                            dash}}</p>
                                    </div>
                                    <div class="col-md-3">
                                        <b>Flush WR#</b>
                                        <p [ngClass]="{'dash-text': !modal?.wrNo}">{{modal?.wrNo | dash}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <b>Facility ID</b>
                                        <p [ngClass]="{'dash-text': !modal?.facilityId}">{{modal?.facilityId | dash}}
                                        </p>
                                    </div>
                                    <div class="col-md-3">
                                        <b>Asset Tag</b>
                                        <p [ngClass]="{'dash-text': !modal?.structureId}">{{modal?.structureId | dash}}
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <b>Address</b>
                                        <p [ngClass]="{'dash-text': !modal?.address}">{{modal?.address | dash}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <b>Crew Lead</b>
                                        <p [ngClass]="{'dash-text': !modal?.crewLead}">{{modal?.crewLead | dash}}</p>
                                    </div>
                                    <div class="col-md-3" *ngIf="this.modal?.workStatus == 'Pending-Confirmed'">
                                        <div style="display: flex; align-items: center;">
                                            <a class="btn-flex"
                                                [ngClass]="{'disabled': isCrewCodeNotAssignable(modal), 'btn-danger-link': isCrewCodeReassigned}"
                                                (click)="this.isCrewCodeReassigned = !this.isCrewCodeReassigned">
                                                {{isCrewCodeReassigned ? 'Close' : 'Reassign'}}
                                            </a>
                                            <div class="icon icon-person"
                                                [ngStyle]="{ 'font-size': '30px', 'color': isCrewCodeReassigned ? '#f44336' : '#0078cf', 'opacity': isCrewCodeNotAssignable(modal) ? '0.5' : '1' }">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <app-photos *ngIf="this.modal?.images?.length > 0" [cameraEnabled]="false"
                                    [isSummary]="true" [enableAfterPhotos]="true"></app-photos>
                                <hr>
                                <div class="mb-4"></div>
                                <ng-container *ngIf="!isEmergencyJob || !isCrewCodeReassigned; else assignment"></ng-container>
                                <ng-template #assignment>
                                    <form [formGroup]="assignmentForm" style="position: sticky;">
                                        <b class="mb-2" *ngIf="showAssignLeadOrCrewCode">Assign by Lead Name or Crew Code</b>
                                        <div *ngIf="showAssignLeadOrCrewCode" class="mb-2" style="padding-top: 10px;">
                                            <ce-input label="Search Assignee" formControlName="searchLeadOrCrewCode">
                                            </ce-input>
                                            <div class="search-suggestions"
                                                *ngIf="this.assignmentForm.get('searchLeadOrCrewCode').value?.length > 0">
                                                <div
                                                    *ngFor="let suggestion of searchSuggestions | filter: this.assignmentForm.get('searchLeadOrCrewCode').value; let index = index">
                                                    <div class="row">
                                                        <div class="col-2 col-lg-1">
                                                            <ce-checkbox
                                                                (selectionChange)="changeSearchSelection(suggestion.crewCode, index, (selectedSearchIndex === index))"
                                                                [isChecked]="selectedSearchIndex === index">
                                                            </ce-checkbox>
                                                        </div>
                                                        <div class="col-5 col-md-4 col-lg-3 col-xl-2"
                                                            style="overflow-wrap: anywhere;">
                                                            <strong>Crew Lead</strong>
                                                            <p>{{ suggestion.crewLead }}</p>
                                                        </div>
                                                        <div class="col-5 col-md-4 col-lg-3 col-xl-2"
                                                            style="overflow-wrap: anywhere;">
                                                            <strong>Crew Code</strong>
                                                            <p>{{ suggestion.crewCode }}</p>
                                                        </div>
                                                    </div>
                                                    <hr>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-2" *ngIf="showApproveButton">
                                                <ce-button type="primary"
                                                    [disabled]="showAssignLeadOrCrewCode && assignmentForm.get('assignLeadOrCrewCode').invalid"
                                                    label="{{approveLabelText}}" size="large"
                                                    (clickEvent)="assignNewWorkRequest(modal.appointmentID, assignmentForm.get('assignLeadOrCrewCode').value)"></ce-button>
                                            </div>
                                            <div class="col-lg-2" *ngIf="showRejectButton">
                                                <ce-button type="secondary" label="Reject" size="large"
                                                    (clickEvent)="displayRejectReasonModal(true, 'modal')"></ce-button>
                                            </div>
                                            <div class="col-lg-2"  *ngIf="showCloseButton">
                                                <ce-button type="secondary" label="Close" size="large" (clickEvent)="showModal = false">
                                                </ce-button>
                                            </div>

                                        </div>
                                    </form>
                                    <ng-container *ngTemplateOutlet="decision"></ng-container>
                                </ng-template>
                            </ce-modal-body>
                            <ce-modal-footer *ngIf="!isCrewCodeReassigned && showCloseButton" style="display: flex;">
                                 <ce-button type="secondary" label="Close" size="large" (clickEvent)="showModal = false">
                                </ce-button>
                            </ce-modal-footer>
                        </ce-modal>
                    </div>
                    <!-- Prior Shifts -->
                    <div class="flex flex-row">
                        <p>Prior Shifts
                            <ng-container *ngIf="userInfo?.user?.setting as userSetting; else setUp">
                                <small>For previous <a type="button" (click)="showSettingsModal()"
                                        (touchstart)="showSettingsModal()">{{userSetting.priorShiftLength}}
                                        hours</a></small>
                            </ng-container>
                        </p>
                        <ce-switch size="large" checked="true" labelOn="" LabelOff="" hintMessage="Show prior jobs?"
                            (selectionChange)="onSelectionChange($event)">
                        </ce-switch>
                    </div>
                    <ng-container *ngIf="showPrior">
                        <ng-container *ngIf="($prior | async) as prior; else loading">
                            <ng-container *ngIf="prior?.length > 0; else emptyState">
                                <app-sortable [items]="prior" id='priorJobs' zones="['priorJobs']">
                                    <ng-template #title let-item>
                                        <div style="display:flex">
                                            <a class="btn-flex" [ngClass]="{ 'disabled': isJobNotReassignable(item) }" (click)="reassignJob($event,item)">
                                                Reassign
                                            </a>
                                            <div [ngClass]="isJobNotReassignable(item) ? 'grayed-out' : 'not-grayed-out'"
                                                style="font-size: 30px;" class="icon icon-person">
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template #content let-itemData let-i="dataColIdx" let-itemRow="itemRow">
                                        <ng-container
                                            *ngTemplateOutlet="sortableDataDefault; context: { $implicit: itemData, dataColIdx: i, itemRow: itemRow }"></ng-container>
                                    </ng-template>
                                </app-sortable>
                            </ng-container>
                            <div class="mb-4"></div>
                        </ng-container>
                    </ng-container>
                    <ng-template #loading>
                        <p class="centered">Jobs are still loading, this can take up to 20 seconds.</p>
                    </ng-template>
                    <ng-template #emptyState>
                        <p class="centered">There are no jobs assigned right now. If you believe this is an error, <a href="#" onclick="location.reload()">please refresh the page</a></p>
                    </ng-template>
                    <ng-template #setUp>
                        <!-- <small><a type="button" (click)="showSettingsModal()" (touchstart)="showSettingsModal()">Configure time length</a></small> -->
                    </ng-template>
                </ce-tab>
                <ce-tab label="Close" iconDirection="left" >
                    <!-- Completed in ERA -->
                    <p>Completed in ERA</p>
                    <app-flush-grid class="display-grid-view" [data]="($closedInERA | async)"
                        (actionClicked)="onCloseRowClick($event)" [displayActionsHeader]="true"
                        [columns]="closeGridColumns" [isSelectable]="false" [actions]="closeActionsInERA">
                    </app-flush-grid>
                    <app-supervisor-hub-panel-bar class="display-panel-view" [workRequestRows]="($closedInERA | async)"
                        (actionClicked)="onCloseRowClick($event)"></app-supervisor-hub-panel-bar>
                    <br>
                    <!-- Not Completed in ERA -->
                    <p>Not Completed in ERA</p>
                    <app-flush-grid class="display-grid-view" [data]="($notClosedInERA | async)"
                        (actionClicked)="onCloseRowClick($event)" [displayActionsHeader]="true"
                        [columns]="closeGridColumns" [isSelectable]="false" [actions]="closeActionsNotInERA">
                    </app-flush-grid>
                    <app-supervisor-hub-panel-bar class="display-panel-view" [disableActionItems]="true"
                        [workRequestRows]="($notClosedInERA | async)" (actionClicked)="onCloseRowClick($event)">
                    </app-supervisor-hub-panel-bar>
                    <div class="job-modal">
                        <ce-modal [visible]="showCloseModal" [title]="!!modal ? modal?.title ?? '' : ''"
                            (visiblilityChange)="changeVisibility($event, 'showClose')">
                            <ce-modal-body *ngIf="showCloseModal">
                                <p><b>Flush - {{modal?.status}}</b></p>
                                <p>WR {{modal?.sourceWrNumber | dash}}</p>
                                <div class="row">
                                    <div class="col-md-3 col-xs-2">
                                        <b>Source WR#</b>
                                        <p [ngClass]="{'dash-text': !modal?.sourceWrNumber}">{{modal?.sourceWrNumber |
                                            dash}}</p>
                                    </div>
                                    <div class="col-md-3  col-xs-2">
                                        <b>Flush WR#</b>
                                        <p [ngClass]="{'dash-text': !modal?.wrNo}">{{modal?.wrNo | dash}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3  col-xs-2">
                                        <b>Facility ID</b>
                                        <p [ngClass]="{'dash-text': !modal?.workRequest?.facilityId}">
                                            {{modal?.workRequest?.facilityID | dash}}</p>
                                    </div>
                                    <div class="col-md-3 col-xs-2">
                                        <b>Asset Tag</b>
                                        <p [ngClass]="{'dash-text': !modal?.structureId}">{{modal?.structureId | dash}}
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <b>Address</b>
                                        <p [ngClass]="{'dash-text': !modal?.address}">{{modal?.address | dash}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3 col-xs-2">
                                        <b>Crew Lead</b>
                                        <p [ngClass]="{'dash-text': !modal?.crewLead}">{{modal?.crewLead | dash}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3 col-xs-2">
                                        <b>Job Breifing Comments</b>
                                        <p [ngClass]="{'dash-text': !closeDesc}">{{closeDesc | dash}}</p>
                                    </div>
                                </div>
                                <app-photos *ngIf="this.modal?.workRequest?.images?.length > 0" [cameraEnabled]="false"
                                    [isSummary]="true" [enableAfterPhotos]="true"></app-photos>
                                <hr>
                                <div class="mb-4"></div>
                                <div class="action-btn-group">
                                    <ce-button styleClass="large-btn" type="primary" label="Close Appointment"
                                        size="large"
                                        (clickEvent)="closeAppointment(this.modal?.workRequest?.crmsAppointmentId)">
                                    </ce-button>
                                    <ce-button styleClass="large-btn" type="secondary" label="Cancel" size="large"
                                        (clickEvent)="showCloseModal = false">
                                    </ce-button>
                                </div>
                            </ce-modal-body>
                        </ce-modal>
                    </div>
                </ce-tab>
            </ce-tabs>
        </div>
    </ng-container>
</ng-container>

<ng-template #ftux>
    <app-ftux [slides]="slides" (skipped)="onSkip($event)"></app-ftux>
</ng-template>
<ng-container *ngIf="showTutorial">
   <div class="axis-map-tutorial" id="axismaptutorial">
        <ng-container *ngFor="let tutorial of tutorials; let i = index">
            <ng-container *ngIf="currentTutorial === i">
                <div class="content">
                    <h3>{{tutorial.heading}}</h3>
                    <p *ngFor="let paras of tutorial.paragraphs">{{paras}}</p>
                </div>
                <div class="action-button" [ngClass]="{ 'secondary-btn' : i === (tutorials.length -1)}">
                    <button type="button" *ngIf="i !== (tutorials.length-1)" >
                        <i class="map-btn-1 material-icons tile-action" (click)="viewTutorial(i)">chevron_right</i>
                    </button>
                    <ce-button *ngIf="i === (tutorials.length -1)"
                        type="secondary" 
                        display="stack" 
                        styleClass="skip-button" 
                        size="large" 
                        [label]="'End Tutorial'"
                        (clickEvent)="viewTutorial(i)">
                    </ce-button>
                </div>
            </ng-container>            
        </ng-container>
        <!-- <div class="content">
            <h3>Map</h3>
            <p>The map displays all WR’s, a satellite view, and select ArcGIS layers. If there is spotty service, the map may take a long time to load.</p>
            <p>You can turn on and off Location Awareness in the Settings page.</p>
        </div>
        <div class="action-button">
            <button type="button" >
                <i class="map-btn-1 material-icons tile-action" (click)="toggleLegendWidget()">chevron_right</i>
            </button>
        </div> -->
   </div>
</ng-container>


<ng-template #sortableDataDefault let-itemData let-i="dataColIdx" let-itemRow="itemRow">
    <div class="col item-header"
        [ngClass]="{'item-cta': itemData.header === '', 'hide': (i > 2 && (i < itemRow?.data.length- 1)) && isMobile }"
        *ngIf="(i < itemRow?.data?.length) || !isMobile">
        <ng-container *ngIf="itemData.header != ''; else icon">
            <div class="header-item">
                <small>{{itemData.header}}</small>
            </div>
            <div class="item-content">
                <div class="content-item">
                    <ng-container *ngFor="let row of itemData.value">
                        <!-- TODO: Once we find out where in template to hyperlink override locations, update this -->
                        <ng-container *ngIf="(row | sortableType) === 'text'">
                            <ng-container *ngIf="true; else locationHyperlink">
                                <b>{{row}}</b>
                            </ng-container>
                            <ng-template #locationHyperlink>
                                <ng-container *ngIf="itemData.header !== 'Network'; else network">
                                    <b>{{row}}</b>
                                </ng-container>
                                <ng-template #network>
                                    <a (click)="iconClicked(itemRow?.data[0].value[0], i)">{{row}}</a>
                                </ng-template>
                            </ng-template>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <!-- Template for the type of icon to be displayed (Location icon, right caret, checkmark, cancel) -->
        <ng-template #icon>
            <div class="content-item">
                <ng-container *ngFor="let row of itemData.value; let rowIdx = index;">
                    <ng-container *ngIf="(row | sortableType) === 'icon'; else cta">
                        <!-- Show location icons -->
                        <ng-container *ngIf="itemRow.itemWorkStatus !== 'Pending-Approval'; else override">
                            <!-- <span class="line-connect"></span> -->
                            <i [class]="'icon ' + row.icon" [ngStyle]="{'color': row.iconColor}"
                                *ngIf="!row.isClickable"></i>
                            <button *ngIf="row.isClickable" (click)="iconClicked(row,rowIdx)"
                                (touchstart)="iconClicked(row, rowIdx)">
                                <i [class]="'icon ' + row.icon + ' clickable'" [ngStyle]="{'color': row.iconColor}"></i>
                            </button>
                        </ng-container>
                    </ng-container>
                    <ng-template #cta>
                        <div class="arrow-button">
                            <button type="button" (click)="ctaClick(row, itemData.value, itemRow, isItemNewOrWIP(itemRow))"
                                (touchstart)="ctaClick(row, itemData.value, itemRow, isItemNewOrWIP(itemRow))"><i
                                    class="material-icons tile-action">chevron_right</i></button>
                        </div>
                    </ng-template>
                    <ng-template #override>
                        <button *ngIf="itemRow.itemWorkStatus === 'Pending-Approval'"  (click)="ctaClick(row, itemRow.data, itemRow, true)"
                            (touchstart)="ctaClick(row, itemRow.data, itemRow, true)">
                            <i [class]="'icon icon-check-status large'" [ngStyle]="{'color': '#00882B'}"></i>
                        </button>
                        <button *ngIf="itemRow.itemWorkStatus === 'Pending-Approval'"   (click)="ctaClick(row, itemRow.data, itemRow, true, true)"
                            (touchstart)="ctaClick(row, itemRow.data, itemRow, true, true)">
                            <i [class]="'icon icon-close large'" [ngStyle]="{'color': '#d93927'}"></i>
                        </button>
                    </ng-template>
                </ng-container>
            </div>
        </ng-template>
    </div>
</ng-template>
<ng-template #decision let-modalData>
    <ce-modal [visible]="showReject" [title]="'Are you sure you want to reject this request?'"
        (visiblilityChange)="changeVisibility($event, 'reject')">
            <ce-modal-body>
                <form [formGroup]="rejectReasonForm">
                <p>Rejecting this request will place the job back into the CRMS queue for review by Work organizer.</p>
                <ce-input inputType="textarea" maxLength="1000" label="Reason for rejecting"
                    name="rejectReason" id="rejectReason" formControlName="rejectReason"></ce-input>
                </form>
            </ce-modal-body>
            <ce-modal-footer>
                <ce-button type="primary" label="Reject Request" size="large"
                    (clickEvent)="RejectNewWorkRequest(modal.appointmentID)"
                    [disabled]="this.rejectReasonForm.controls['rejectReason'].value === ''"></ce-button>
                <ce-button type="secondary" label="Back" size="large" (clickEvent)="displayRejectReasonModal(false)"></ce-button>
            </ce-modal-footer>
    </ce-modal>
</ng-template>