import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Answer, Survey } from 'src/app/models/survey.modal';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  baseUrlQuestions =  `${environment.commonSurveyApiUrl}`; 
  
  constructor(private http: HttpClient) {
  }

  getSurveys(email): Observable<Survey> {
    const params = new HttpParams().set('groupService', 'flush').set('getActiveSurvey', 'true').set('userId', email).set('surveyID', environment.surveyId); //
    return this.http.get<Survey>(this.baseUrlQuestions + environment.endpoints.surveyQuestions, {params: params});
  }

  addAnswers(answer): Observable<Answer> {
    return this.http.post<Answer>(this.baseUrlQuestions + environment.endpoints.surveyAnswers, answer);
  }
}

