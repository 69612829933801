import { Component, Input, Output, OnChanges, EventEmitter,ViewEncapsulation } from '@angular/core';
import { WorkRequest } from 'src/app/interfaces/work-request';
import { BannerService } from 'src/app/services/banner/banner.service';
import { CcRequestsService } from 'src/app/services/cc-requests/cc-requests.service';
import { RequestsService } from 'src/app/services/requests/requests.service';
@Component({
  selector: 'app-construction-crew-panel-bar',
  templateUrl: './construction-crew-panel-bar.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ConstructionCrewPanelBarComponent implements OnChanges {
  flushData: any[];
  @Input() data: any[];
  @Input() userTypeRole: string = '';
  @Output() structureClicked = new EventEmitter();
  constructor(
    private ccRequestsService: CcRequestsService,
    private banner: BannerService,
    private requestsService: RequestsService,
    ) { }

  ngOnChanges(change): void {
    console.log('User Type Role: ', this.userTypeRole);
    this.flushData = this.data;
  }

  navigateToCCInfo(dataItem) {
    this.banner.resetBanner();
    this.requestsService.onOpenHandler(dataItem)
    .subscribe((res: WorkRequest ) => {
      console.log('job-details-finished');
      this.ccRequestsService.handleNavigationFromDashboard(dataItem, 'cc');
    })
  }

  openMap(dataItem: any) {
    this.structureClicked.emit({ action: 'assetTag', value: dataItem });
  }
}
