<form [formGroup]="photoDetails">
<h4 class="mb-2">Media Details</h4>
<p class="mb-2 border">{{photo?.timestamp | timestamp:'MMM D, YYYY HH:mm'}}</p>
<h5 class="mb-1">Structure</h5>
<app-banner *ngIf="structureOptions?.length < 1" [(banner)]="banner$"></app-banner>
<p *ngIf="structureOptions?.length === 1" class="mb-0 border"><strong>{{structureOptions[0].value}}</strong></p>
<div class="form-group row" *ngIf="structureOptions?.length > 1">
  <div class="col-lg-4 col-md-6 col-sm-8 col-10">
    <app-selectbox formControlName="assetTag" placeholder="Asset Tag" [options]="structureOptions" [defaultIndex]="selectedAsset"></app-selectbox>
  </div>
  <div class="col-2" *ngIf="!this.photoDetails.controls['assetTag'].valid">
    <P><a class="flush-process-error"> *</a></P>
  </div>
  <p class="border"></p>
</div>
<p><b>Additional comments</b></p>
  <div class="mb-5 row">
    <div class="col-12">
      <ce-input inputType="textarea" class="photo-comments" formControlName="comments" name="comments" id="comments"></ce-input>
    </div>
  </div>
  <div class="form-group row" *ngIf="canDelete">
    <div class="col-12 col-lg-4">
      <a class='btn-danger-link' (click)="deletePhoto()"><div class="icon icon-waste"></div> Delete Photo</a>
    </div>
  </div>
</form>