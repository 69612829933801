<div class='container-fluid mobile-view'>
  <div class='col-12 carousel-wrapper'>
    <ngb-carousel>
      <ng-template ngbSlide *ngFor="let item of carouselItems; let i = index;">
        <div class="carousel-image">
          <div class="image-wrapper">
            <img [src]="item.image" alt="1st slide">
          </div>
        </div>
        <div class="carousel-caption">
          <p><b>{{item.title}}</b></p>
          <p>{{item.description}}</p>
        </div>
      </ng-template>
    </ngb-carousel>
  </div>
</div>
