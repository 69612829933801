import { AccessInformation } from 'src/app/models/accessInformation';

export class AddAccessInformation {
  static readonly type = '[AccessInformation] Add';

  constructor(public payload: AccessInformation) {}
}

export class DeleteAccessInformation {
  static readonly type = '[AccessInformation] Delete';

  constructor() {}
}

export class AddEmergencyInformation {
  static readonly type = '[AccessInformation] Add Emergency Info';

  constructor(public payload: {isOnLocation: string, isAccessObtained: string}) {}
}
export class DeleteEmergencyInformation {
  static readonly type = '[AccessInformation] Delete Emergency Info';

  constructor() {}
}