import { Component, OnInit, OnDestroy, HostListener, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { BaseService } from 'src/app/services/base/base.service';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import * as _ from 'lodash';
import { StylerService } from 'src/app/services/styler/styler.service';
import { BaseComponent } from 'src/app/common/base/base.component';
import { GridService } from 'src/app/services/grid/grid.service';
import { Select, Store } from '@ngxs/store';
import { AppState } from 'src/app/app-state/app.state';
import { forkJoin, iif, Observable, throwError, of } from 'rxjs';
import { StructureInformation } from 'src/app/models/structureInformation';
import { AddStructureInformation } from 'src/app/app-state/actions/structure-info.actions';
import { CONFIG } from 'src/app/global/config';
import { Location } from '@angular/common';
import { FormValidationService } from 'src/app/services/forms/form-validation.service';
import { FlushReviewService } from 'src/app/services/flush-review/flush-review.service';
import { WorkRequestsResponse } from 'src/app/models/work-requests-response';
import { CcRequestsService } from 'src/app/services/cc-requests/cc-requests.service';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { WorkRequestDetail } from 'src/app/models/work-request-detail';
import { AddStartJobPhotos, AddWorkRequestDetail } from 'src/app/app-state/actions/start-job.actions';
import { StructureInfo } from 'src/app/models/construction-crew/structure-info';
import { Structure } from 'src/app/models/structure.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Banner, BannerService } from 'src/app/services/banner/banner.service';
import { AddFlushInformation } from 'src/app/app-state/actions/flush-process-info.actions';
import { FlushInformation } from 'src/app/models/flushInformation';
import { EditStructureHistory, PostEditStructureHistory } from 'src/app/models/edit-history';
import { TimestampPipe } from 'src/app/common/pipes/timestamp.pipe';
import { Photo } from 'src/app/models/photo';
import { Feature } from 'src/app/interfaces/feature';
import { MasterDataService } from 'src/app/services/master-data/master-data.service';
import { Alert } from '@ce-lib/alert';
import { MapperService } from 'src/app/services/mapper/mapper.service';
import { WorkRequest } from 'src/app/models/work-request';

export interface KendoGrid {
  field: string;
  title: string;
}

export interface FlushStructure extends Structure {
  customerOwned: boolean;
}
@Component({
  selector: 'app-structure-info',
  templateUrl: './structure-info.component.html',
  styleUrls: ['./structure-info.component.scss'],
  providers: [TimestampPipe]
})
export class StructureInfoComponent extends BaseComponent implements OnInit, OnDestroy {
  globalId = '';
  wrNumber = '';
  zipcode = null;
  showingLookup = false;
  editStructureForm: FormGroup;
  structureLookupForm: FormGroup;
  structureLookupData: Structure[] = [];
  structureIsCustomerOwnedIndex = -1;
  mobileStructureIsCustomerOwned = new FormArray([]);
  get mobileCheckboxes(): FormArray {
    return this.mobileStructureIsCustomerOwned as FormArray;
  }
  isMobile: boolean;
  structuresHeader: KendoGrid[] = [];
  multiStructuresHeader: KendoGrid[] = [];
  structures: Structure[] = [];
  mobileSelections = [];
  isEditBtnClicked = false;
  assetTagName = new FormControl('');
  multiAddStructure = [];
  isAddStructure = false;
  multiAddStructureData = [];
  saveMultiStructureData: any = {};
  banner$: Observable<Banner>;
  isEdit = false;
  labelText = 'Next';

  // Demo edit structure history
  editStructureHistory: EditStructureHistory[] = [];
  editStructureHistoryCols = [];getWorkComponentGlobalId
  @Select(AppState.getStructureInformation)
  getStructureInfo$: Observable<StructureInformation>;
  @Select(AppState.getDashboardWorkRequests)
  getDashboardWorkRequests$: Observable<WorkRequestsResponse>;
  @Select(AppState.getWorkRequestDetail)
  getWorkRequestDetail$: Observable<WorkRequestDetail>;
  @Select(AppState.getWorkRequestGlobalId)
  getWorkRequestGlobalId$: Observable<string>;
  @Select(AppState.getWorkComponentGlobalId)
  getWorkComponentGlobalId$: Observable<string>;
  @Select(AppState.getFlushInformation)
  getFlushInformation$: Observable<FlushInformation[]>;
  @Select(AppState.getStartJobPhotos) getStartJobPhotos$: Observable<Photo[]>;
  @ViewChild('facilityType') facilityType;
  @ViewChild('borough') borough;
  @Select(AppState.getWorkComponentId)
  getWorkComponentId$: Observable<string>;
  workComponentId;
  workComponentGlobalId;
  isOnline;
  constructor(
    private baseService: BaseService,
    private activatedRoute: ActivatedRoute,
    private flushReviewService: FlushReviewService,
    private service: BaseService,
    private ccService: CcRequestsService,
    private router: Router,
    private store: Store,
    styler: StylerService,
    private location: Location,
    private formValidationService: FormValidationService,
    private banner: BannerService,
    private timestampPipe: TimestampPipe,
    private masterData: MasterDataService,
    private mapperService: MapperService,
    private alert: Alert
  ) {
    super(styler);

    // In constructor so it runs before top-tracker
    this.checkIsEdit();
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    this.adjustToScreenSize(window.innerWidth);
  }

  private adjustToScreenSize(innerWidth) {
    this.isMobile = innerWidth <= 768 ? true : false;
  }

  ngOnInit() {
    // WARNING: Need to use selectors in OnInit, NOT IN CONSTRUCTOR
    this.getWorkRequestGlobalId$.pipe(take(1)).subscribe((i) => {
      this.globalId = i;
    });
    this.getWorkComponentId$.pipe(take(1)).subscribe((i) => {
      this.workComponentId = i;
    });
    this.getWorkComponentGlobalId$.pipe(take(1)).subscribe((i) => {
      this.workComponentGlobalId = i;
    });
    this.isOnline = navigator.onLine;
    this.getWorkRequestDetail$.pipe(take(1)).subscribe((d) => {
      this.wrNumber = d.workRequestNo;
      const ind = d?.concatenatedWRAddress?.indexOf('NY') ?? -1;
      // d.concatenatedWRAddress.substr(ind) => "NY 10001"
      this.zipcode = ind < 0 ? null : d.concatenatedWRAddress
        .substr(ind).split(' ')[1]?.replace(/\D/g, '');
      if (this.zipcode != null && isNaN(this.zipcode)) {
        // Sometimes substring might be => '-', ',', or some value that doesnt represent a valid zip code
        this.zipcode = null;
      }
    });
    this.flushSubscriptions.push(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationStart))
        .subscribe((event: NavigationStart) => {
          if (!event.url.includes('structure-info')) {
            console.log('%cPUT gets fired on navigation away from page', 'font-weight: bold; color: purple');
            this.updateStructures().subscribe((s) => {
              console.log(s);
              let form = _.cloneDeep(this.prepareStructuresForStore(this.structures));
              this.store.dispatch(new AddStructureInformation(form));
            });
          }
        })
    );

    // When flush process is disabled
    this.masterData.getCacheItem(CONFIG.MASTER_DATA.FEATURES)
      .subscribe((res: Feature[]) => {
        res.forEach((toggleObj) => {
          if (toggleObj.feature == CONFIG.API.FEATURE_TOGGLES.STRUCTURE_INFORMATION) {
            if (!toggleObj.isActive) {
              // when it is not active
              this.location.back();
            }
          }
        });
      })
      .unsubscribe();
    this.banner.resetBanner();
    this.banner$ = this.banner.banner$;

    this.adjustToScreenSize(window.innerWidth);
    this.structureLookupForm = new FormGroup({
      assetTag: new FormControl('', Validators.required),
    });
    this.editStructureForm = new FormGroup({
      currentStructure: new FormControl(''),
      frontAddress: new FormControl('', Validators.required),
    });
    this.structuresHeader = [
      // { field: 'facilityID', title: 'Facility ID' },
      { field: 'type', title: 'Type' },
      { field: 'structureId', title: 'Asset Tag' },
      { field: 'borough', title: 'Borough' },
      { field: 'frontAddress', title: 'Front Address' },
    ];
    this.multiStructuresHeader = [
      { field: 'assetTag', title: 'Asset Tag' },
      { field: 'facilityType', title: 'Type' },
      { field: 'borough', title: 'Borough' },
      { field: 'address', title: 'Front Address' },
    ];
    this.editStructureHistoryCols = [
      { field: 'dateSaved', title: 'Date Saved' },
      { field: 'crewName', title: 'Crew Member' },
      { field: 'address', title: 'Front Address' },
    ];
    this.getStructures();
    this.flushSubscriptions.push(
      this.assetTagName.valueChanges
        .pipe(
          debounceTime(1250),
          filter((assetTag) => !!assetTag || (assetTag as string).length > 0),
          distinctUntilChanged(),
          tap((assetTag) => {
            this.banner.resetBanner();
          }),
          map((assetTag: string) => assetTag?.toUpperCase()),
          switchMap((assetTag) => this.service.getOrdsFacility(assetTag, this.zipcode,this.wrNumber)),
          catchError((err) => {
            return throwError(err);
          })
        )
        .subscribe(
          (val) => {
            // wait first, make sure its a distinct to previous value, then fetch endpoint
            this.banner.resetBanner();
            if (val?.status === 500 || val?.entities?.length < 1) {
              const msgs = this.zipcode ? [`We searched for structures inside the ${this.zipcode} area code.`, 'If your location is not in this area code, updates must be made in WMS to update the zip code associated to this item']
                : ['This asset tag does not seem to exist anywhere within WMS.']
              this.banner.createBanner('info', {
                header: 'No structures found',
                body: msgs,
                originalError: '',
                originalServerMessage: '',
                originalErrorName: '',
                originalUrl: '',
                httpStatus: 200
              })
            }
            this.multiAddStructureData = [];
            this.multiAddStructureData = val.entities;

          },
          (error: HttpErrorResponse) => {
            this.banner.createBanner('error', {
              header: 'Could not connect to ORDS',
              body: [
                'Having trouble retrieving facility information from WMS. Try again later or reach out to ERA team so we can notify our IT partner.',
              ],
              originalUrl: error?.url,
              httpStatus: error?.status,
              originalErrorName: error?.name,
              originalError: error?.error,
              originalServerMessage: error?.message,
            });
          }
        ),
      this.mobileStructureIsCustomerOwned.valueChanges.subscribe((val) => {
        // Observable will emit when we delete a structure, we want to avoid subscribing to that instance
        if (val.length === this.structures.length && this.isMobile) {
          this.relayAction({
            action: 'customerOwned',
            value: val.map((val) => (typeof val === 'string' ? false : true)),
          });
        }
      })
    );
    const notSavedToEra = this.structures.filter((s) => !s?.id && s.submissionStatus !== 'Submitted');
    if (notSavedToEra.length == 1) {
      this.saveStructureInfo()
        .subscribe(info => {
          console.log('%cSaved initial structure', 'font-weight: bold; color: purple');
          this.getStructures();
        })
    }
  }

  cancelRequestInProgres(){
    this.baseService.showCancelConfirmation$.next(true);   
  }

  checkIsEdit() {
    this.activatedRoute.params.subscribe((params) => {
      this.isEdit = params.isEdit === 'true';
      this.flushReviewService.$isEdit.next(this.isEdit);
      this.labelText = this.isEdit ? 'Save' : 'Next';
    });
  }

  getStructures() {
    this.structures = [];
    // Get state and get API and merge results before appending to structures Array
    //const structureInfoStore$ = this.getStructureInfo$.pipe(map((store) => store.structures));    
    const assignedWR$ = this.store.select((store) => (store.AppState.assignedWRDetails));
    // assignedWR$.subscribe((resp) => {
    //   const wr = resp.find((response) => {
    //     return response.armWorkRequestID === this.wrNumber && response.workRequestGlobalID.indexOf(this.globalId) > 0;
    //   });
    //   console.log('wr :>> ', wr);
    //   console.log('wr.structureInfo :>> ', wr.structureInfo);
    // });
    const structureInfoStore$ = this.store.select((store) => (store.AppState.structureInfo.structures) as Structure[]);
    // const structureInfoAPI$ = this.ccService.getCCRequest().pipe(
    //   map((ccRequestDetails) => ccRequestDetails.structureInfo),
    //   catchError((err) => {
    //     console.log(err);
    //     return throwError(err);
    //   }),
    //   map((structures) => {
    //     return structures.map((struct) => {
    //       return {
    //         structureId: struct.structureNumber,
    //         frontAddress: struct.address,
    //         facilityId: struct.facilityId,
    //         isCustomerOwned: struct.isCustomerOwned,
    //         isManual: struct.isManuallyAdded,
    //         type: struct.facilityType,
    //         borough: struct.borough,
    //         id: struct.id,
    //       } as Structure;
    //     });
    //   })
    // );
    
    structureInfoStore$
      .pipe(
        //mergeMap((structures) => iif(() => structures?.length > 0, of(structures))),
        mergeMap(structures => assignedWR$.pipe(map((resp) => {
          //Get strucutes from Workrequest from dashboard. This will not have newly added strucutures
          const wr = resp.find((response) => {
            return response.armWorkRequestID === this.wrNumber && response.workComponentGlobalID == this.workComponentGlobalId;
          });
          let structs = [...wr.structureInfo];
          structs = structs.map((struct) => {
            return {
              structureId: struct.structureNumber,
              frontAddress: struct.address,
              facilityId: struct.facilityId,
              isCustomerOwned: struct.isCustomerOwned,
              isManual: struct.isManuallyAdded,
              type: struct.facilityType,
              borough: struct.borough,
              id: struct.id,
              longitude: struct?.longitude,
              latitude: struct?.latitude,
              submissionStatus: struct.submissionStatus,
              status:  struct.submissionStatus
            };
          });
          const filtered = this.filterOperation(structures, structs);
          console.log('filtered :>> ', filtered);         
          if(filtered.length>0) {
            structs = structs.concat(filtered);
          }          
          return structs;
        })))
       )
      .subscribe((structures) => {
        console.log('mergeMap.structures :>> ', structures);
        for (let i = 0; i < structures.length; i++) {
          let form = {
            facilityId: structures[i].facilityId,
            type: structures[i].type,
            borough: structures[i].borough,
            frontAddress: structures[i].frontAddress,
            isCustomerOwned: structures[i].isCustomerOwned,
            isManual: structures[i].isManual,
            structureId: structures[i].structureId,
            id: structures[i]?.id != undefined ? structures[i]?.id : undefined,
            latitude: structures[i]?.latitude,
            longitude: structures[i]?.longitude,
            submissionStatus: structures[i]?.submissionStatus,
            status: structures[i]?.submissionStatus
          } as Structure;

          if (!this.structures.some((el) => el.id === form.id)) {    
            this.structures.push(form); 
            this.formValidationService.setValidity(
              this.structures.length > 0,
              CONFIG.FORMS.FLUSH_REQUEST.INFORMATION.STRUCTURE,
              CONFIG.FORMS.PARENT_FORMS.INFORMATION
            );
          }
        }
      })
      .unsubscribe();
  }

  ngOnDestroy() {
    // let structures = _.cloneDeep(this.prepareStructuresForStore(this.structures));
    // this.store.dispatch(new AddStructureInformation(structures));
    super.ngOnDestroy();
  }

  isNextDisabled(){
    return (this.structures.filter((st) => st.status !== 'Submitted')?.length < 1);
  }

  isAddStructureDisabled(){
    return (this.structures.filter((st) => st.status !== 'Submitted')?.length > 3);
  }

  filterOperation(list1, list2, isUnion = false) {
    return list1.filter( a => isUnion === list2.some( b => a.structureId === b.structureId) );
  }
    
  //Below are functions related to edit structure modal
  editStructure() {
    this.isEditBtnClicked = true;
  }
  closeEditStructure() {
    this.isEditBtnClicked = false;
  }
  saveEditStructure() {
    // Set new address to selected structure
    let currentStructure: Structure = this.editStructureForm.controls.currentStructure.value;
    currentStructure.frontAddress = this.editStructureForm.controls.frontAddress.value;
    currentStructure.isManual = true;
    let searchwrflag = this.store.selectSnapshot((store) => store.AppState.searchwrflag);
    // PUT currentStructure
    this.ccService.editStructureInfo(this.mapperService.mapStructureToStructureInfo(currentStructure, this.wrNumber, this.globalId, this.workComponentId, searchwrflag))
      .subscribe((val) => {
        console.log('SUCCESSFULLY PUT STRUCTURE');
        // On success save to localStorage
        let form = _.cloneDeep(this.prepareStructuresForStore(this.structures));
        this.store.dispatch(new AddStructureInformation(form));

        // Update structure global reference
        const ind = this.structures.findIndex((el) => el.id === currentStructure.id);
        this.structures[ind] = currentStructure;

        // Close Modal
        this.closeEditStructure();
      }, (err) => { console.error(err) });

  }

  //Below are functions related to add structure modal
  addStructure() {
    this.isAddStructure = true;
  }
  closeAddStructure() {
    this.isAddStructure = false;
  }

  saveStructureInfo(structure: Structure = null) : Observable<Structure> {
    const structuresToSave = !!structure ? [structure] : this.structures.filter((s) => !s?.id);
    let searchwrflag = this.store.selectSnapshot((store) => store.AppState.searchwrflag);
    return this.ccService.saveStructureInfo(structuresToSave.map((struc) => this.mapperService.mapStructureToStructureInfo(struc, this.wrNumber, this.globalId, this.workComponentId, searchwrflag)))
      .pipe(
        map((structureInfo) => structureInfo.reduce((prevVal, curVal) => this.mapperService.mapStructureInfoToStructure(curVal), {}) as Structure),
        tap((newStructure) => {
          // Update global variable, either replace or push
          let structIndex = this.structures.findIndex((struct) => struct.structureId === newStructure.structureId);
          if (structIndex < 0) {
            this.structures.push(newStructure);
          } else {
            this.structures[structIndex] = newStructure;
          }

          // Save to local storage
          let form = _.cloneDeep(this.prepareStructuresForStore(this.structures));
          this.store.dispatch(new AddStructureInformation(form));
        }),
        take(1)
      );
  }

  updateStructures() {
    const structuresToPut = this.structures
      .filter((s) => s.id != undefined)
      .map((s) => {
        return {
          facilityId: s.facilityId,
          facilityType: s.type,
          structureNumber: s.structureId,
          address: s.frontAddress,
          isCustomerOwned: s.isCustomerOwned,
          isManuallyAdded: s.isManual,
          borough: s.borough,
          wrNumber: this.wrNumber,
          workRequestId: this.globalId,
          id: s.id,
          longitude: s.longitude,
          latitude: s.latitude,
          submissionStatus: s.submissionStatus
        } as StructureInfo;
      });
    return forkJoin(
      structuresToPut.map((s) => {
        return this.ccService.editStructureInfo(s);
      })
    );
  }

  prepareStructuresForStore(structures: Structure[]) : StructureInformation {
    return {
      structures: structures.filter((struct) => (struct.submissionStatus?.toLowerCase() !== 'submitted')).map((struct) => {
        return {
          structureId: struct.structureId,
          frontAddress: struct.frontAddress,
          facilityId: struct.facilityId,
          isCustomerOwned: struct.isCustomerOwned,
          isManual: struct.isManual,
          type: struct.type,
          borough: struct.borough,
          id: struct.id,
          longitude: struct.longitude,
          latitude: struct.latitude,
          submissionStatus: struct.submissionStatus,
          status:  struct.submissionStatus
        };
      }),
      isValid: true,
      isManual: false,
      selectedOptionDefaultIndex: null,
    } as StructureInformation;
  }

  getMobileStructureFormControl(i) {
    if (this.mobileCheckboxes.at(i) != undefined) {
      return this.mobileCheckboxes.at(i);
    } else {
      this.mobileCheckboxes.push(new FormControl(''));
      return this.mobileCheckboxes.at(i);
    }
  }

  removeMobileStructureFormControl(i) {
    this.mobileCheckboxes.removeAt(i);
  }

  //Relay action from table items
  relayAction(item) {
    const ind = this.structures.findIndex((el) => (item.value.facilityId === el.facilityId)); // Only used for add/delete!
    this.getWorkRequestGlobalId$.pipe(take(1)).subscribe((id) => {
      this.globalId = id;
    });
    switch (item.action) {
      case 'add':
        const value = _.cloneDeep(item.value);
        const data = {
          frontAddress: value.address,
          structureId: value.assetTag,
          borough: value.borough,
          isCustomerOwned: false,
          facilityId: value.facilityID.toString(),
          type: value.facilityType,
          isManual: true,
        } as Structure;
        if (ind < 0) {
          // Item does not exist in current list
          this.saveStructureInfo(data)
            .subscribe(
              (newStructure) => {
                this.assetTagName.setValue('');
                this.isAddStructure = false;
                this.multiAddStructureData = [];
                this.getFlushInformation$
                  .pipe(take(1))
                  .subscribe(info => {
                    const defaultForm = new FlushInformation(null, null, null, null, null, null, null, [], null, null, null, null, null, null, data.structureId, false)
                    this.store.dispatch(new AddFlushInformation([...info, defaultForm]))
                  }).unsubscribe();
              },
              (err) => {
                console.error(err);
                this.alert.urgent(`Could not save structure: ${err}`, "Dismiss")
              }
            );
        }
        break;
      case 'delete':
        if (ind > -1) {
          // Item exists, can delete
          this.structureIsCustomerOwnedIndex = ind;
          // Delete photos associated to structure
          let photosArray = [];
          this.getStartJobPhotos$.subscribe((photos: Photo[]) => {
            photosArray = photos.filter(photo => photo.mediaType != 'Video');
          });

          forkJoin([
            this.ccService
              .deleteCCStructureInfo(
                {
                  facilityId: this.structures[ind].facilityId,
                  facilityType: this.structures[ind].type,
                  structureNumber: this.structures[ind].structureId,
                  address: this.structures[ind].frontAddress,
                  isCustomerOwned: this.structures[ind].isCustomerOwned,
                  isManuallyAdded: this.structures[ind].isManual,
                  borough: this.structures[ind].borough,
                  id: this.structures[ind].id,
                } as StructureInfo,
                this.wrNumber
              ),
            this.ccService.deletePhotos(photosArray?.filter((photo) => photo?.structureId == this.structures[ind]?.structureId))
          ]).pipe(
            catchError((err) => throwError(err)),
            take(1),
          ).subscribe((v) => {
            // Update photos state
            let savePhotosArray = photosArray?.filter((photo) => photo?.structureId != this.structures[ind]?.structureId)
            this.store.dispatch(new AddStartJobPhotos(savePhotosArray));

            // Delete structure
            this.structures.splice(ind, 1);
            this.removeMobileStructureFormControl(ind);
            let form = _.cloneDeep(this.prepareStructuresForStore(this.structures));
            this.store.dispatch(new AddStructureInformation(form));
            this.formValidationService.setValidity(
              this.structures.length > 0,
              CONFIG.FORMS.FLUSH_REQUEST.INFORMATION.STRUCTURE,
              CONFIG.FORMS.PARENT_FORMS.INFORMATION
            );
            // Falsify Flush and Photos validation, so the user must check flush-info and photos page whenever structure is added
            this.formValidationService.setValidity(
              false,
              CONFIG.FORMS.FLUSH_REQUEST.INFORMATION.FLUSH,
              CONFIG.FORMS.PARENT_FORMS.INFORMATION
            );
            this.formValidationService.setValidity(
              false,
              CONFIG.FORMS.FLUSH_REQUEST.PHOTOS,
              CONFIG.FORMS.PARENT_FORMS.PHOTOS
            );
            // Delete flush information associated to structure
            let tempFlushInfo = [];
            this.getFlushInformation$.subscribe((flushInfo: FlushInformation[]) => {
              tempFlushInfo = flushInfo?.filter((info) => {
                return this.structures?.findIndex((struc) => {
                  return struc.structureId == info.structureNumber;
                }) >= 0;
              });
            }).unsubscribe();
            this.store.dispatch(new AddFlushInformation(tempFlushInfo ?? []));
          }, (err) => {
            // Handle error here
            console.log(err);
            this.alert.urgent(`Could not save structure: ${err}`, "Dismiss")
          });
        }
        break;
      case 'customerOwned':
        // item.value: boolean[]
        for (let index = 0; index < item.value.length; index++) {
          const isOwned = item.value[index];
          this.structures[index].isCustomerOwned = _.clone(isOwned);
        }
        // This change gets picked up by our API on updateStructures
        break;
      case 'edit':
        let currentStructure: Structure = this.structures[ind];

        //Update editStructureForm to current structure
        this.editStructureForm.controls.currentStructure.setValue(currentStructure);
        this.editStructureForm.controls.frontAddress.setValue(currentStructure.frontAddress);

        this.ccService.getStructureEditHistory(currentStructure.structureId).pipe(take(1)).subscribe((val: EditStructureHistory[]) => {
          this.editStructureHistory = _.cloneDeep(val ?? []);
          if (this.editStructureHistory && this.editStructureHistory?.length > 0) {
            this.editStructureHistory = this.editStructureHistory?.slice(-3);
            this.editStructureHistory?.forEach(element => {
              if (element.dateSaved) {
                element.dateSaved = this.timestampPipe.transform(element.dateSaved, 'MMM D, YYYY HH:mm');
              }
            });
          }

        }, (err) => {
          console.error('GET STRUCTURE HISTORY ERROR: ', err);
        });
        //Show edit modal
        this.editStructure();
        break;
      default:
        break;
    }
    
    if (item.action !== "edit") {
      console.log('Item Action: ', item.action);
      this.getFlushInformation$
        .subscribe((structures) => {
          const hasInValidStructure = structures.length === 0 || structures.some(item => !item.isValid);
          this.isEdit = !hasInValidStructure;
          this.flushReviewService.$isEdit.next(this.isEdit);
          this.labelText = this.isEdit ? 'Save' : 'Next';
        }).unsubscribe();
    }
  }

  nextClick() {
    window.scrollTo(0, 0);
    if(this.isEdit){
      this.router.navigateByUrl('/flush-information/review');
    }else{
      this.router.navigate(['flush-information', 'access-information']);
    }
  }
  calculateMobileHighlight(structure) {
    if (this.mobileSelections.findIndex((el) => el.facilityID === structure.facilityID) > -1) {
      return 'highlight';
    }

    return 'no-highlight';
  }

  previousClick() {
    const bTicket = this.store.selectSnapshot(store => store.AppState.workRequestDetail as WorkRequestDetail)?.bTicket;
    if (!!bTicket && bTicket?.length > 4) {
      this.router.navigate(['/flush-information/job-environment', { isPrevious: true }]);
    } else {
      this.router.navigate(['/flush-information/job-details', { isPrevious: true }]);
    }
  }

  //Below are helper functions
  evauluateBoolean(val) {
    if (typeof val === 'string') {
      if (val === '') {
        return false;
      } else {
        return Boolean(JSON.parse(val));
      }
    } else {
      return val;
    }
  }

  emptyIfNull(value) {
    return value != null ? value : '';
  }

  resetAssetTagSearch() {
      this.assetTagName.setValue('');
  }
}
