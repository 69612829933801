import { Pipe, PipeTransform } from '@angular/core';
import { Photo } from '../../models/photo';
/*
 * Reduces the number of labels within the labels array and formats it to text
 *
 * Usage:
 *   labels | labelsCount
 * Example:
 *   {{ [{..., labels: []}] | labelsCount }}
 *
 */
@Pipe({
  name: 'labelsCount',
  pure: false,
})
export class LabelsCountPipe implements PipeTransform {
  transform(photo: Photo): string {
    const num = photo?.tags?.reduce((sum, cur) => sum + cur.labels.length, 0);
    return num === 1 ? `${num} label` : `${num} labels`;
  }
}
