export class AddFlushWorkRequestId {
  static readonly type = '[FlushWorkRequestId] Add';

  constructor(public payload: string) {}
}
export class DeleteFlushWorkRequestId {
  static readonly type = '[FlushWorkRequestId] Delete';

  constructor() {}
}

export class AddFacilityGlobalId {
  static readonly type = '[FacilityGlobalId] Add';

  constructor(public payload: string) {}
}
export class DeleteFacilityGlobalId {
  static readonly type = '[FacilityGlobalId] Delete';

  constructor() {}
}

export class AddFlushUserRole {
  static readonly type = '[FlushUserRole] Add';

  constructor(public payload: string) {}
}
export class DeleteFlushUserRole {
  static readonly type = '[FlushUserRole] Delete';

  constructor() {}
}

export class AddWorkRequestGlobalId {
  static readonly type = '[WorkRequestGlobalId] Add';

  constructor(public payload: string) {}
}
export class DeleteWorkRequestGlobalId {
  static readonly type = '[WorkRequestGlobalId] Delete';

  constructor() {}
}

export class AddWorkComponentGlobalId {
  static readonly type = '[WorkComponentGlobalId] Add';

  constructor(public payload: string) {}
}
export class AddWorkComponentId {
  static readonly type = '[WorkComponentId] Add';

  constructor(public payload: string) {}
}
export class DeleteWorkComponentGlobalId {
  static readonly type = '[WorkComponentGlobalId] Delete';

  constructor() {}
}

export class AddFlushWRNumber {
  static readonly type = '[FlushWRNumber] Add';

  constructor(public payload: string) {}
}
export class DeleteFlushWRNumber {
  static readonly type = '[FlushWRNumber] Delete';

  constructor() {}
}

export class AddCrewCode {
  static readonly type = '[CrewCode] Add';

  constructor(public payload: string) {}
}
export class DeleteCrewCode {
  static readonly type = '[CrewCode] Delete';

  constructor() {}
}
