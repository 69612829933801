import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { AppState } from 'src/app/app-state/app.state';
import { LaunchCameraModalComponent } from 'src/app/common/launch-camera-modal/launch-camera-modal.component';
import { FlushInformation } from 'src/app/models/flushInformation';

@Component({
  selector: 'app-flush-landing',
  templateUrl: './flush-landing.component.html',
})
export class FlushLandingComponent implements OnInit {
  dFaultElement = true;
  showNavigateToPhotos = true;
  @Select(AppState.getFlushInformation)
  getFlushInformation$: Observable<FlushInformation[]>;
  @ViewChild('launchCameraModalComponent') launchCameraModal: LaunchCameraModalComponent;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
    ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.showNavigateToPhotos = params['showNavigateToPhotos'] === 'true';
    });
  }

  // Need to determine how the d-Fault flag will be set ? API call to WMS ?
  setDefaultCondition() {
    // this.dFaultElement= true;
  }

  // Show modal once per session
  navigateToPhotos() {
    this.launchCameraModal.takePhoto();
  }
  navigateToFlushQuestions() {
    this.router.navigate(['', 'flush-information']);
  }
}
