import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectboxComponent } from './selectbox/selectbox.component';
import { CameraAppComponent } from './camera/camera.component';
import { PhotoComponent } from './photo/photo.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CarouselComponent } from './carousel/carousel.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TagComponent } from './photo/tag/photo-tag';
import { PhotoReviewComponent } from './photo/review/photo-review.component';
import { TimestampPipe } from './pipes/timestamp.pipe';
import { LabelsCountPipe } from './pipes/labels-count.pipe';
import { GridModule } from '@progress/kendo-angular-grid';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { SortableModule } from "@progress/kendo-angular-sortable";
import { ModalComponent } from './modal/modal.component';
import { TopTrackerComponent } from './top-tracker/top-tracker.component';
import { FlushCrewPanelBarComponent } from './panel-bar/flush-crew-panel-bar/flush-crew-panel-bar.component';
import { FlushGridComponent } from './grid/flush-grid.component';
import { ConstructionCrewPanelBarComponent } from './panel-bar/construction-crew-panel-bar/construction-crew-panel-bar.component';
import { BaseComponent } from './base/base.component';
import { LoaderComponent } from './loader/loader.component';
import { OktaLoginComponent } from './okta-login/okta-login.component';
import { OktaCallbackComponent } from './okta-callback/okta-callback.component';
import { DetailsBoxComponent } from './details-box/details-box.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AppRoutingModule } from '../app-routing.module';
import { SidebarMobileComponent } from './sidebar-mobile/sidebar-mobile.component';
import { MultiSelectboxComponent } from './multi-selectbox/multi-selectbox.component';
import { DashPipe } from './pipes/dash.pipe';
import { SplitDashPipe } from './pipes/split-dash.pipe';
import { CountdownPipe } from './pipes/countdown.pipe';
import { AtomicAssetTagsForParkingResPipe } from './pipes/atomic-asset-tags-for-parking-res.pipe';
import { BannerComponent } from './banner/banner.component';
import { TileComponent } from './tile/tile.component';
import { CclModule } from '../modules/ccl.module';
import { LaunchCameraModalComponent } from './launch-camera-modal/launch-camera-modal.component';
import { SortableComponent } from './sortable/sortable.component';
import { SortableItemTypePipe } from './pipes/sortable-item-type.pipe';
import { EsriMapComponent } from './esri-map/esri-map.component';
import { UploadModule } from '@progress/kendo-angular-upload';
import { SupervisorHubPanelBarComponent } from './panel-bar/supervisor-hub-panel-bar/supervisor-hub-panel-bar.component';
import { FilterPipe } from './pipes/filter.pipe';

@NgModule({
  declarations: [
    SelectboxComponent,
    CheckboxComponent,
    CameraAppComponent,
    PhotoComponent,
    NavbarComponent,
    FooterComponent,
    CarouselComponent,
    TagComponent,
    PhotoReviewComponent,
    LabelsCountPipe,
    TimestampPipe,
    ConstructionCrewPanelBarComponent,
    ModalComponent,
    TopTrackerComponent,
    FlushCrewPanelBarComponent,
    FlushGridComponent,
    BaseComponent,
    LoaderComponent,
    OktaLoginComponent,
    OktaCallbackComponent,
    DetailsBoxComponent,
    SidebarComponent,
    SidebarMobileComponent,
    MultiSelectboxComponent,
    DashPipe,
    SplitDashPipe,
    CountdownPipe,
    AtomicAssetTagsForParkingResPipe,
    BannerComponent,
    TileComponent,
    LaunchCameraModalComponent,
    SortableComponent,
    SortableItemTypePipe,
    EsriMapComponent,
    SupervisorHubPanelBarComponent,
    FilterPipe,
  ],
  imports: [
    CommonModule, 
    CclModule,
    FormsModule, 
    ReactiveFormsModule, 
    NgbModule, 
    GridModule, 
    LayoutModule, 
    AppRoutingModule,
    SortableModule,
    UploadModule
  ],
  exports: [
    SelectboxComponent,
    CheckboxComponent,
    CameraAppComponent,
    PhotoComponent,
    NavbarComponent,
    FooterComponent,
    CarouselComponent,
    PhotoReviewComponent,
    LabelsCountPipe,
    TimestampPipe,
    ConstructionCrewPanelBarComponent,
    FlushCrewPanelBarComponent,
    FlushGridComponent,
    ModalComponent,
    TopTrackerComponent,
    BaseComponent,
    LoaderComponent,
    OktaCallbackComponent,
    DetailsBoxComponent,
    SidebarComponent,
    SidebarMobileComponent,
    MultiSelectboxComponent,
    DashPipe,
    SplitDashPipe,
    CountdownPipe,
    AtomicAssetTagsForParkingResPipe,
    BannerComponent,
    TileComponent,
    LaunchCameraModalComponent,
    SortableComponent,
    SortableItemTypePipe,
    EsriMapComponent,
    SupervisorHubPanelBarComponent,
    FilterPipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CommonComponentsModule {}
