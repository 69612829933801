<app-banner [(banner)]="errorBanner$">
    <ce-button size="large" type="secondary" label="Add Structure" (clickEvent)="goToLink('/flush-information/structure-information')"></ce-button>
</app-banner>
<div *ngIf="structures?.length >= 1">
    <app-flush-process [selectedStructure]="structures[selectedIndex]" [selectedFlushProcess]="flushInformation[selectedIndex]" (outputValidity)="setFlushValidity($event)"></app-flush-process>
</div>
<div class="row" *ngIf="structures?.length >= 1">
    <div class="col-auto style-guide-close-modal-btn">
        <ce-button size='large' *ngIf="(selectedIndex == 0) && !isEdit" (clickEvent)="previousClick()" label="Previous" type="secondary"></ce-button>
        <ce-button size='large' *ngIf="(selectedIndex != 0)" (clickEvent)="previousClick()" label="Previous Structure" type="secondary"></ce-button>
    </div>
    <div class="col-auto">
        <ce-button size='large' *ngIf="(selectedIndex != structures.length - 1)" (clickEvent)="nextClick()" label="Next Structure" type="primary" [disabled]="!flushInformation[selectedIndex].isValid"></ce-button>
        <ce-button size='large' *ngIf="(selectedIndex == structures.length - 1) && !isEdit" data-custom-id="CCFlushProcessPage" data-custom-bhvr="PROCESSCHECKPOINT" (clickEvent)="nextClick()" label="Next" type="primary" [disabled]="!isFlushProcessValid"></ce-button>
        <ce-button size='large' *ngIf="(selectedIndex == structures.length - 1) && isEdit" data-custom-id="CCFlushProcessPage" data-custom-bhvr="PROCESSCHECKPOINT" (clickEvent)="nextClick()" label="Save" type="primary" [disabled]="!isFlushProcessValid"></ce-button>
        <!-- <ce-button *ngIf="isEdit" (clickEvent)="saveClick()" text="Save" type="primary" [disabled]="!flushInfoFormIsValid"></ce-button> -->
    </div>
</div>
<div class="form-group row">
    <div class="col-12 col-lg-4">
      <a class='btn-danger-link' (click)="cancelRequestInProgres()">Cancel Request</a>
    </div>
  </div>