import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  AfterViewChecked,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  HostBinding,
  ChangeDetectorRef,
} from '@angular/core';
import { trigger, state, style, transition, group, query, animateChild, animate, stagger } from '@angular/animations';
import { Observable, Subject } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { Router } from '@angular/router';

export class LinkBtn {
  url: string;
  text: string;
  params: Object;
  icon: string;
}
@Component({
  selector: 'app-details-box',
  templateUrl: './details-box.component.html',
  animations: [
    trigger('openClose', [
      // ...
      state(
        'open',
        style({
          height: '*',
        }),
        { params: { startHeight: 0 } }
      ),
      state(
        'closed',
        style({
          height: '0px',
        })
      ),
      transition('open => closed', [animate('0.3s')]),
      transition('closed => open', [animate('0.3s')]),
    ]),
  ],
})
export class DetailsBoxComponent implements OnInit {
  @Input() header = '';
  showItems = false;
  @Input() hideLink = false;
  animationType = 'closed';
  @Output() headerClicked = new EventEmitter();
  @Input() link: LinkBtn;
  @Input() iconInfo = '';
  constructor(private router: Router) {}

  ngOnInit(): void {}

  handleMobileNav() {
    this.showItems = !this.showItems;
    this.animationType = this.showItems ? 'open' : 'closed';
    this.headerClicked.emit(this.header);
  }

  linkClick() {
    if (this.link.params) {
      this.router.navigate([this.link.url, this.link.params]);
    } else {
      this.router.navigate([this.link.url]);
    }
  }
}
