import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private requestsInFlight = 0;
  private requestsInFlight$: BehaviorSubject<number>;
  constructor() {
    this.requestsInFlight$ = new BehaviorSubject(this.requestsInFlight);
  }

  addRequest() {
    this.requestsInFlight += 1;
    this.requestsInFlight$.next(this.requestsInFlight);
  }

  completeRequest() {
    this.requestsInFlight -= this.requestsInFlight > 0 ? 1 : 0;
    this.requestsInFlight$.next(this.requestsInFlight);
  }

  clearLoaders() {
    this.requestsInFlight = 0;
    this.requestsInFlight$.next(this.requestsInFlight);
  }

  getLoadingStatus() {
    return this.requestsInFlight$.asObservable();
  }
  get hasRequestsInFlight() {
    return this.requestsInFlight > 0;
  }
  get numRequestsInFlight() {
    return this.requestsInFlight;
  }
}
