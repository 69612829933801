import {
  Component,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  HostListener,
  Input,
} from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Photo } from '../../models/photo';
import { FormControl, FormGroup } from '@angular/forms';
import { trigger, transition, style, animate } from '@angular/animations';
import { Select } from '@ngxs/store';
import { AppState } from 'src/app/app-state/app.state';
import { concat, Observable, of, throwError, timer } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { PhotoService } from 'src/app/services/photo/photo.service';
import { catchError, concatMap, map, switchMap, tap  } from 'rxjs/operators';
import { StructureInformation } from 'src/app/models/structureInformation';
import { FlushPhoto } from 'src/app/models/flushPhoto';
import { LoggingService } from 'src/app/services/logging/logging.service';
import { Alert } from '@ce-lib/alert';
import { environment } from 'src/environments/environment';
import { CONFIG } from 'src/app/global/config';
import { HashService } from 'src/app/services/hashing/hashing.service';
import { VideothumbnailService } from 'src/app/services/video-thumbnail/video-thumbnail.service';
@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  animations: [
    trigger('photoSaved', [transition(':leave', [style({ opacity: 1 }), animate('200ms', style({ opacity: 0 }))])]),
    trigger('photoSavedConfirmed', [
      transition(':enter', [style({ opacity: 0 }), animate('200ms 200ms ease-in', style({ opacity: 1 }))]),
    ]),
  ],
})
export class CameraAppComponent implements OnInit, OnDestroy {
  @Select(AppState.getStartJobPhotos) getStartJobPhotos$: Observable<Photo[]>;
  @Select(AppState.getStructureInformation)
  getStructureInfo$: Observable<StructureInformation>;
  
  @ViewChild('video') video: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('videoUpload') videoUpload: ElementRef;
  @Output() emitPhotosAdded = new EventEmitter();
  @Output() emitCloseCamera = new EventEmitter();
  @Input() photoFacing: string;
  @Input() photoBefore = true;
  @Input() capacityReached = false;
  environment = environment;
  capturedPhoto: Photo[] = []; //string | ArrayBuffer = '';
  videoUploaded = false;
  showingIndex = 0;
  photos: Photo[] = [];
  cameraDevices: MediaStreamTrack[] = [];
  buffering = true;
  errorBuffering = false;
  showCanvas = false;
  showComments = false;
  showPane = true;
  cameraHeight = 0;
  cameraWidth = 0;
  isCameraLandscape = true;
  isCameraDisabled = false;
  isShowMultiPhotoInfo = false;
  photoAdded: EventEmitter<boolean> = new EventEmitter();
  localstream: MediaStream;
  cameraFacing = 'environment';
  photoStatusForm: FormGroup;
  selectedPhoto: Photo = null;
  photoSaved = false;
  photoSaving = false;
  photoError = false;
  fileExtensions = ['.WEBM', '.MPG', '.MP2', '.MPEG', '.MPE', '.MPV'
  , '.OGG', '.MP4', '.M4P', '.M4V', '.AVI', '.WMV', '.MOV', '.QT', '.FLV', '.SWF', 'AVCHD'];
  errorDialog : any = {
    isShowing: false,
    title: null,
    msg: null
  };
  duplicatePhotos = [];

  assetTag = '';
  isPhotoValid = true;
  get mediaTypes() {
    return CONFIG.MEDIA_TYPE;
  }
  constructor(
    private photoService: PhotoService, 
    private logger: LoggingService,
    private alert: Alert,
    private hash: HashService,
    private thumbnailService: VideothumbnailService
    ) {}
  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    this.setCanvasDimensions();
  }
  ngOnInit(): void {
    //Load current photos for ticker
    this.getStructureInfo$
    .pipe(
      tap(info => {
        // Take what is stored in state or return empty string
        this.assetTag = info?.structures && info?.structures.length > 0 
          ? info?.structures[0].structureId : this.assetTag
      }),
      catchError(err => throwError(err)),
      switchMap(info => this.getStartJobPhotos$)
    )
    .subscribe((val) => {
      const photoType = this.photoBefore ? 'Before' : 'After';
      const loadPhotos = val.filter((photo) => {
        return photo.status === photoType && photo.mediaType != 'Video';
      });
      const mergeStructure = loadPhotos.map(p => {
        // Currently, for one structure, jsut make sure the asset tag is attached to each photo
        const chooseAssetTag = () => {
          const assetTagState = p.structureId;
          if (assetTagState === this.assetTag) {
            // Either no structures on file or the asset tag selected has not changed
            return assetTagState;
          } else if ((assetTagState !== this.assetTag) && (( assetTagState && assetTagState.length > 0) && this.assetTag.length < 1)) {
            // If photo state value has a value and the structure state is empty, return structure state
            // Scenario where user may have deleted structures from request
            return this.assetTag;
          } else if ((assetTagState !== this.assetTag) && ((!assetTagState || assetTagState.length < 1) && this.assetTag.length > 0)) {
            // If photo state value is empty but structure state value has a value, return the structure state
            return this.assetTag;
          } else {
            // Non-empty photo and structure states, which dont match anymore; so must be that a new structure was selected
            return this.assetTag;
          }
        }
        return {...p, structureId:  chooseAssetTag()}
      })
      this.photos = _.clone(mergeStructure ?? new Array<Photo>(), true);
    });

    this.setCanvasDimensions();
    this.photoStatusForm = new FormGroup({
      photoStatus: new FormControl('Before'),
    });
    this.startCamera();
  }
  ngOnDestroy(): void {
    this.stopCamera();
  }
  setCanvasDimensions() {
    // Check dimensions of the screen if the user has changed view between landscape/portrait
    this.isCameraLandscape = window.innerWidth > window.innerHeight ? true : false;
    this.isCameraDisabled =
      (!this.isCameraLandscape && window.innerWidth < 720) || (this.isCameraLandscape && window.innerWidth < 1024)
        ? true
        : false;
    // this.cameraWidth = window.innerWidth;
    // this.cameraHeight = window.innerHeight * 0.7; // Multiply by max height of pane (~70%)
  }
  async startCamera() {
    // check getUserMedia is not supported by the browser that has the app open
    const supported = navigator.mediaDevices && navigator.mediaDevices.getUserMedia;
    if (supported && !this.isCameraDisabled && !this.localstream && this.photos.length < 11) {
      const getCameraDevice = async () => {
        // Create a promise that rejects in 5000 milliseconds
        const timeout = new Promise((resolve, reject) => {
          const id = setTimeout(() => {
            clearTimeout(id);
            reject('Timeout promise resolved before getUserMedia()');
          }, 8000);
        });
        // Create a promise that resolves when the camera is started
        const promise = new Promise(async (resolve, reject) => {
          // start video stream via MediaDevices API
          let constraints: MediaStreamConstraints;
          const supports = navigator.mediaDevices.getSupportedConstraints(); 
          // const devices = (await navigator.mediaDevices.enumerateDevices())
          //   .filter(device => device.kind === 'videoinput');
          // this.cameraFacing = devices.length === 1 ? 'user' : 'environment';
          navigator.mediaDevices.enumerateDevices().then(devices => { console.log(devices);
           })
          if (!supports["facingMode"]) {
            constraints = Object.assign({},{
              video: {
                width: {
                  min: 1280,
                  ideal: 1920,
                  max: 2560,
                },
                height: {
                  min: 720,
                  ideal: 1080,
                  max: 1440,
                },
              },
              audio: false,
            })
          } else {
            constraints = Object.assign({},{
              video: {
                facingMode: { ideal: this.cameraFacing },
                width: {
                  min: 1280,
                  ideal: 1920,
                  max: 2560,
                },
                height: {
                  min: 720,
                  ideal: 1080,
                  max: 1440,
                },
              },
              audio: false,
            })
          }
          const mediaStream = navigator.mediaDevices
            .getUserMedia(constraints)
            .then(async (stream) => {
              // on success, get all video input options
              // find input whose type is 'videoinput'
              console.log(stream);
              
              return stream;
            });

          resolve(mediaStream);
        });

        // Returns a race between our timeout and camera promises
        return Promise.race([promise, timeout]);
        // we get a new promise that gets resolved or rejected as soon as either promise resolves/rejects
      };
      await getCameraDevice()
        .then((mediaStream: MediaStream) => {
          // Set up MediaStream if the Promise resolves in time
          console.log(mediaStream);
          
          this.applyStreamData(mediaStream);
          this.video.nativeElement.play(); // Start video stream onto tag
        })
        .catch((error) => {
          // Camera did not start in time
          console.error(`getCameraDevice() timeout:\n${error}`);
          this.stopCamera();
          this.errorBuffering = true;
        });
    } else {
      // either camera is disabled, feature unsupported, or instance of localstream already exists
      this.buffering = false;
    }
    this.photoSaved = false;
  }
  swapCamera() {
    const supported = navigator.mediaDevices && navigator.mediaDevices.getUserMedia;
    const supports = navigator.mediaDevices.getSupportedConstraints(); 
    let constraints: MediaStreamConstraints;

    if (supported) {
      let filteredDevices = [];
      navigator.mediaDevices.enumerateDevices()
      .then(devices => {
        return new Promise((resolve, reject) => {
          filteredDevices = devices.filter(device => device.kind === 'videoinput');
          if (supports["facingMode"] && filteredDevices.length > 1) {
            this.cameraFacing = this.cameraFacing === 'user' ? 'environment' : 'user'
            constraints = Object.assign({},{
              video: {
                facingMode: { ideal: this.cameraFacing },
                width: {
                  min: 1280,
                  ideal: 1920,
                  max: 2560,
                },
                height: {
                  min: 720,
                  ideal: 1080,
                  max: 1440,
                },
              },
              audio: false,
            })
            resolve(constraints as MediaStreamConstraints);
          }
          reject(supports["facingMode"] ? 'Device length < 2' : 'Not supported');
        })    
      })
      .then(constrains => {
        return navigator.mediaDevices.getUserMedia(constrains)
      })
      .then((stream) => {
        // on success, get all video input options
        // find input whose type is 'videoinput'
        this.localstream.getVideoTracks().forEach((track) => track.stop());
        this.localstream = null;
        this.applyStreamData(stream);
        this.video.nativeElement.play(); // Start video stream onto tag
      })
      .catch(err => {
        console.error(err);
      });
    }
  }
  clickPhoto() {
    const timestamp = moment();
    if (this.photos.length < 10) {
      const context = this.canvas.nativeElement.getContext('2d');
      context.drawImage(this.video.nativeElement, 0, 0, this.cameraWidth, this.cameraHeight);
      this.photoService.compressFile(this.canvas.nativeElement.toDataURL()).then((base64) => {      
      this.showCanvas = true;    
      this.capturedPhoto = [
        {
          // uploadedPhotoSrc: blobSrc, Blob spec in working draft
          src: base64,
          id: uuid(),
          timestamp: timestamp.format('YYYY-MM-DD[T]HH:mm:ss'),
          facing: this.photoFacing,
          status: this.photoBefore ? 'Before' : 'After',
          tags: [],
          isUploaded: false,
          additionalComments: '',
          structureId: this.assetTag,
          contentSize: '',
          isProcessed: false,
          mediaId: '',
          mediaStatus: '',
          mediaType: this.mediaTypes.IMAGE,
          streamingUrls: [],
          thumbnailUrl: ''
        },
      ];
      this.photos.push({
        // uploadedPhotoSrc: blobSrc,
        src: base64,
        id: uuid(),
        timestamp: timestamp.format('YYYY-MM-DD[T]HH:mm:ss'),
        facing: this.photoFacing,
        status: this.photoBefore ? 'Before' : 'After',
        tags: [],
        isUploaded: false,
        additionalComments: '',
        structureId: this.assetTag,
        contentSize: '',
        isProcessed: false,
        mediaId: '',
        mediaStatus: '',
        mediaType: this.mediaTypes.IMAGE,
        streamingUrls: [],
        thumbnailUrl: ''
      });
      this.selectedPhoto = this.photos[this.photos.length - 1];
      this.stopCamera();
      this.turnOffVideoTracks();
      });
    }
  }

  addVideo() {
    this.photoSaving = true;
    this.photoAdded.emit(true);
    this.uploadVideo(this.selectedPhoto).subscribe(() => {
      this.emitPhotosAdded.emit([_.cloneDeep(this.selectedPhoto)]);
      this.closeCamera();
    },
    err => {
      this.photoSaving = false;
      this.photoSaved = false;
      this.photoError = true;
      this.alert.urgent("Could not upload video. Please try to upload a different video.", "Close");
      console.log(err);
    });
  }

  addPhoto() {
    this.photoSaving = true;
    this.photoAdded.emit(true);
    //Upload photos
    this.uploadPhoto()
    .subscribe(cphotos => {
      console.log('Successfully uploaded photo');
      this.photoSaving = false;
      this.photoSaved = true;
      this.selectedPhoto.isUploaded = true;
      this.photos = [...this.photos];
      this.emitPhotosAdded.emit(cphotos.map(photo => photo));
      setTimeout(() => {
        this.buffering = true;
        this.showCanvas = false;
        this.capturedPhoto = [];
        this.photoSaved = false;
        this.startCamera();
      }, 250);
    }, 
    err => {
      this.photoSaving = false;
      this.photoSaved = false;
      this.photoError = true;
      this.alert.urgent("Could not upload image. Please try to upload a different image.", "Close");
      console.log(err);
    });
  }

  uploadVideo(video: Photo): Observable<Photo> {
    if (video) {      
      return concat(
        this.photoService.uploadCCLVideo(this.photoService.MapToFlushPhotos([video])[0]),
        this.photoService.uploadFlushVideo(this.photoService.MapToFlushPhotos([video])[0]).pipe(
          map((flushPhoto: FlushPhoto) => {
            return this.photoService.MapFlushPhotoToPhoto(flushPhoto);
          })));
    } else {
      return of(video);
    }
  }

  uploadPhoto() {
    const capturedPhotos = this.capturedPhoto.map(p => p);
    if (capturedPhotos.length > 0) {
      return this.photoService.uploadPhotos(capturedPhotos)
      .pipe(
        catchError((err) => {
          console.log(`unable to upload images due to ${JSON.stringify(err)}`)
          this.logger.logException(err)
          return throwError(err);
        }),
        map((res: FlushPhoto[]) => {
          // Update capturedPhoto array with updated src value
          const returnPhotos = this.capturedPhoto.map(photo => {
            const p = res.filter(p => p.id === photo.id)[0];
            return { 
              ...photo, 
              isUploaded: true,
              src: p.imageData, 
              facing: this.photoService.mapPhotoClassificationToStatus(p.classification), 
              status: this.photoService.mapPhotoClassificationToStatus(p.classification),
              uploadedPhotoSrc: photo?.uploadedPhotoSrc as string,
            } as Photo
          });
          return returnPhotos;
        }),
        catchError(err => {
          console.log('unable to update photo array');
          this.logger.logEvent({name: 'Upload Photo - Unable to Update Array'},
          {
            action: 'Upload Photo - Update Client Array',
            message: err,
            eventDate: new Date()
          })
          return throwError(err);
        })
      )
    } else {
      return of(this.capturedPhoto);
    }
  }
  retakePhoto() {
    this.photos.pop();
    this.photoAdded.emit(true);
    this.showCanvas = false;
    this.capturedPhoto = [];
    this.photoError = false;
    this.buffering = true;
    this.startCamera();
  }
  onCameraMetadataLoaded(event) {
    this.cameraHeight = event.target.videoHeight;
    this.cameraWidth = event.target.videoWidth;
  }
  applyStreamData(stream: MediaStream) {
    this.buffering = false;
    this.localstream = stream;
    this.video.nativeElement.srcObject = stream;
  }

  stopCamera() {
    this.video.nativeElement.pause();
    this.video.nativeElement.src = '';
  }
  async turnOffVideoTracks() {
    try {
      await this.localstream?.getVideoTracks().forEach((track) => track.stop());
      this.localstream = null;
    } catch (error) {
      // Couldn't stop the camera bc MediaStream isnt set
      // We get here if camera could not load in time
      console.error(`closeCamera() failed because localStream: MediaStream === undefined`);
    }
  }
  closeCamera() {
    this.turnOffVideoTracks();
    this.emitCloseCamera.emit(true);
  }

  getFileData(file) {
    const f = new FileReader();
    return new Promise((resolve, reject) => {
      f.readAsDataURL(file);
      f.onload = (e) => {
        const timestamp = moment();
        this.photoService.compressFile(e.target.result).then((base64) => {
          resolve({ result: base64, file, timestamp });
        });        
      };
      f.onerror = () => {
        console.log(f.error);
      };
    });
  }

  onFileChange(fileInput) {
    const files = fileInput.target.files;
    this.duplicatePhotos = [];
    const _this = this;
    this.errorDialog.isShowing = false;
    //console.log('files :>> ', files);
    if (!!files && files.length > 0) {
      if (files[0].type === 'video/mp4') {
        this.videoUploaded = true;
        this.showCanvas = true;
        let video = document.createElement('video');
        video.preload = 'metadata';
      
        video.onloadedmetadata = function() {
          window.URL.revokeObjectURL(video.src);
          let duration = video.duration;
          if (duration > 120) {
            _this.errorBuffering = true;   
            _this.errorDialog = {
              "isShowing" : true,
              "title" : 'Video is too long',
              "msg" : `<p>Video must not be longer than <b>2</b> minutes. Go back if you wish to retake it.</p>`
            };
          } else {
            _this.errorBuffering = false;   
            _this.displayVideo(files[0], _this.videoUpload.nativeElement);            
            _this.capturedPhoto.push({
              uploadedPhotoSrc: '',
              src: _this.videoUpload.nativeElement.currentSrc,
              timestamp: moment(),
              id: uuid(),
              facing: _this.photoFacing,
              status: _this.photoBefore ? 'Before' : 'After',
              tags: [],
              isUploaded: false,
              additionalComments: '',
              structureId: _.clone(_this.assetTag),
              contentSize: '',
              isProcessed: false,
              mediaId: '',
              mediaStatus: '',
              mediaType: _this.mediaTypes.IMAGE,
              streamingUrls: [],
              thumbnailUrl: ''
            })
          }
        }
        video.src = URL.createObjectURL(files[0]);
      }
      // else if(files[0].type === 'video/quicktime') {
      //   console.log('video/quicktime :>> ');
      // }
      else  {
        //console.log('insideelse :>> ');
        this.photoService.setShowingPhotoIndex(0);
        const uploadedFiles = Object.values(files);
        Promise.all(uploadedFiles.map((fi) => _this.getFileData(fi))).then((results) => {
          _this['tempUploadedFiles'] = results;
          _this.getStartJobPhotos$
            .subscribe((res) => {
              // Check if uploading more than the allotted 10 per category
              if(res?.filter(photo => photo?.status == (this.photoBefore ? 'Before' : 'After'))?.length + _this['tempUploadedFiles']?.length > 10) {
                this.errorDialog = {
                  "isShowing" : true,
                  "title" : 'You have reached capacity.',
                  "msg" : `<p>You've exceeded the max of <b>ten</b> photos. Go back to review photos if you wish to retake them.</p>`
                };
              } else {
                _this['dbPhotos'] = res;
                _this['tempUploadedFiles'].map(async (uploadedFileResponse) => {
                  // const src = await fetch(uploadedFileResponse.result)
                  //               .then(res => res.blob())
                  //               .then(blob => blob.arrayBuffer())
                  //               .then(text => text);
                  const photoObject = {
                    uploadedPhotoSrc: uploadedFileResponse?.file?.name ?? '',
                    src: uploadedFileResponse.result.slice(0),
                    timestamp: uploadedFileResponse.timestamp.format('YYYY-MM-DD[T]HH:mm:ss'),
                    id: uuid(),
                    facing: this.photoFacing,
                    status: this.photoBefore ? 'Before' : 'After',
                    tags: [],
                    isUploaded: false,
                    additionalComments: '',
                    structureId: _.clone(this.assetTag),
                    mediaType: this.mediaTypes.IMAGE
                  } as Photo;
                  
                  const hashFile = this.hash.generateHash(photoObject.src);
                  console.log('hashFile' + photoObject.uploadedPhotoSrc, hashFile);
                  photoObject.uploadedPhotoSrc = hashFile;
                  
                  //check for duplicate photos
                  let isDuplicate = true;
                  !_this['dbPhotos'].find((jobPhoto) => jobPhoto?.uploadedPhotoSrc == photoObject.uploadedPhotoSrc) &&
                    !this.photos.find((photo) => photo?.uploadedPhotoSrc == photoObject.uploadedPhotoSrc) &&
                    _this.capturedPhoto.push({ ...photoObject }) &&
                    this.photos.push({ ...photoObject }) &&
                    (isDuplicate = false);
                  
                  isDuplicate && this.duplicatePhotos.push({ ...photoObject }) && (this.errorDialog = {
                    "isShowing" : true,
                    "title" : 'This Photo Already Exists',
                    "msg" : '<p>You have previously uploaded this photo. To continue, choose a different photo to upload or go back to edit the original photo.</p>'
                  });
                  this.logger.logTrace('uploadedPhotoSrc isDuplicate: ' + isDuplicate);
                  this.logger.logTrace('uploadedPhotoSrc this.duplicatePhotos: ' + JSON.stringify(this.duplicatePhotos));

                  this.selectedPhoto = this.photos[this.photos.length - 1];
                  this.errorBuffering = false;
                  this.showCanvas = true;
                });
              }
            })
            .unsubscribe();
        });
      }
    }
  }

  displayVideo(videoFile, videoElement) {
    console.log(videoFile);
    console.log(videoElement);
    
    const newObjectUrl = URL.createObjectURL(videoFile);
    const oldObjectUrl = videoElement.currentSrc;
    if(oldObjectUrl && oldObjectUrl.startsWith('blob:')) {
        // It is very important to revoke the previous ObjectURL to prevent memory leaks. Un-set the `src` first.
        // See https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL

        videoElement.src = ''; // <-- Un-set the src property *before* revoking the object URL.
        window.URL.revokeObjectURL(oldObjectUrl);
    }
    videoElement.src = newObjectUrl;
    videoElement.load();
  }
  onUploadComplete(videoBase64: string) {
    let photoId = uuid();
    let flushVideo : Photo = {
      uploadedPhotoSrc: '',
      src: videoBase64,
      timestamp: moment(),
      id: photoId,
      facing: this.photoFacing,
      status: this.photoBefore ? 'Before' : 'After',
      tags: [],
      isUploaded: false,
      additionalComments: '',
      structureId: _.clone(this.assetTag),
      contentSize: '',
      isProcessed: false,
      mediaId: photoId,
      mediaStatus: '',
      mediaType: this.mediaTypes.VIDEO,
      streamingUrls: [],
      thumbnailUrl: ''
    };
    this.thumbnailService.getThumbnail(videoBase64).then((thumbNail) => {      
      flushVideo.thumbnailUrl = thumbNail;
       // Push to photos and preview
      this.capturedPhoto.push(_.cloneDeep(flushVideo));
      this.photos.push(_.cloneDeep(flushVideo));
      this.selectedPhoto = _.cloneDeep(flushVideo);
      this.errorBuffering = false;
      this.showCanvas = true;
    });
  }
  addLabels(imageLabels) {
    if (this.capturedPhoto.length === 1) {
      // Uploading single photo
      this.capturedPhoto[0].tags = imageLabels.map((tag) => tag);
      this.photos[this.photos.length - 1].tags = imageLabels.map((tag) => tag);
    } else {
      // TODO: Once we support tagging after uploading a batch of images
      this.capturedPhoto = this.capturedPhoto.map((photo,index) => {
        // Map back unique copy photo, and only update the tags of photo that is currently showing on the pane
        return {...photo, tags: index === this.showingIndex ? imageLabels.map(tag => tag) : (photo.tags ?? []) } 
      });
      // this.photos = this.photos.map((photo,index) => {
      //   return {...photo, tags: index === this.showingIndex ? imageLabels.map(tag => tag) : (photo.tags ?? []) } 
      // });
    }
  }
  addComments() {
    if (this.showComments) {
      document.querySelector('.captured-photo')?.scrollIntoView({ behavior: 'smooth' });
      document.querySelector('.icon-caret-button').classList.remove('flip-up');
      this.showComments = false;
    } else {
      this.showComments = true;
      document.querySelector('.comments-section')?.scrollIntoView({ behavior: 'smooth' });
      document.querySelector('.icon-caret-button').classList.add('flip-up');
    }
  }
  handlePhotoDetails(photoDetailsObj) {
    this.isPhotoValid = photoDetailsObj?.isValid ?? true;
    try {
      this.capturedPhoto = this.capturedPhoto
      .map((photo, photoIndex) => {
        return {...photo, 
          structureId: photoIndex === this.showingIndex ? (photoDetailsObj.assetTag ?? '') : photo.structureId,
          additionalComments: photoIndex === this.showingIndex ? (photoDetailsObj.comments ?? '') : photo.additionalComments
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
    if (this.selectedPhoto) {
      this.selectedPhoto.additionalComments = photoDetailsObj.comments ? photoDetailsObj.comments : '';
      this.selectedPhoto.structureId = photoDetailsObj.assetTag ? photoDetailsObj.assetTag : '';
    }
  }

  closeErrorDialog() {
    this.errorDialog.isShowing = false;
    this.closeCamera();
    this.emitCloseCamera.emit(true);
  }
  checkUnsavedPhotoBeforeClosing() {
    if (this.capturedPhoto.toString().length > 0) {
      this.photos.pop();
    }
    this.closeCamera();
  }
  exitCamera() {
    this.checkUnsavedPhotoBeforeClosing();
  }
  selectPhoto(i) {
    //console.log(this.capturedPhoto);
    
    if (i > this.capturedPhoto.length - 1 || i < 0) {
      this.showingIndex = 0;
    }
     else {
       this.showingIndex = i;
     }
     this.photoService.setShowingPhotoIndex(this.showingIndex);
  }
}
