import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { forkJoin, from, Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { FlushPhoto } from 'src/app/models/flushPhoto';
import { concatMap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class FlushHttpRequestService {
  flushHttpHeaders: HttpHeaders;
  constructor(private httpclient: HttpClient, private authService: AuthService) {
    this.buildHeaders();
  }

  addHeader(headers: HttpHeaders, name: string, value: string) {
    headers.append(name, value);
    return headers;
  }

  removeHeader(headers: HttpHeaders, name: string) {
    headers.delete(name);
    return headers;
  }


  buildHeaders(addRetryHeader: boolean = false, textResponse= false) {
    this.flushHttpHeaders = new HttpHeaders({
      APIEnvironment: environment.apimEnvironment,
      'Ocp-Apim-Subscription-Key': environment.apiSubKey,
      'Content-Type': 'application/json',
      Authorization: this.authService.accesstoken != '' ? this.authService.accesstoken : localStorage.getItem('okta-id-token'),
      'RetryResponse': addRetryHeader.toString()
    });
  }

  /*
    Common service to be used for sending http GET requests to flush api
    url represents the flush api request endpoint,
    T represents the type T to be returned from the API
   */
  getFlushData<T>(url: string, addRetryHeader: boolean = false, textResponse: boolean = false): Observable<any> {
    this.buildHeaders(addRetryHeader, textResponse);
    let options;
    if(textResponse) {
      options = { headers: this.flushHttpHeaders, responseType: 'text' };
    } else {
      options = { headers: this.flushHttpHeaders };
    }
    return this.httpclient.get(url, options);
  }

  /*
      Common service to used for sending http POST requests to flush api
    url is the flush api endpoint
    Tin represents the type T of the data to be posted
    Tout represents the type T of data expected to return if any
  */
  postFlushRequest<Tin, Tout>(url: string, data?: Tin): Observable<Tout> {
    this.buildHeaders();
    return this.httpclient.post<Tout>(url, data, {
      headers: this.flushHttpHeaders,
    });
  }

  /* 
    Common service to be used for sending http DELETE method to flush api
    url is api endpoint
    Tin represents type T of the resource to be deleted
    Tout represents type T of data to return, if any
  */
  deleteFlushData<Tin, Tout>(url: string, data?: Tin): Observable<Tout> {
    this.buildHeaders();
    return this.httpclient.request<Tout>('delete', url, {
      headers: this.flushHttpHeaders,
      body: data,
    });
  }
  /* 
    Common service to be used for sending http DELETE method to flush api
    url is api endpoint
    Tin represents type T of the resource to be deleted
    Tout represents type T of data to return, if any
  */
  putFlushData<Tin, Tout>(url: string, data?: Tin): Observable<ArrayBuffer> {
    this.buildHeaders();
    return this.httpclient.put<ArrayBuffer>(url, data, {
      headers: this.flushHttpHeaders,
    });
  }

  public uploadCCLVideo(flushVideo: FlushPhoto): Observable<any> {
    let file = this.dataURItoBlob(flushVideo.imageData);
    let mb = 1048576
    let chunkSize = 1 * mb;
    let chunks = Math.ceil(file.size / chunkSize);
    
    let headers = new HttpHeaders({
      APIEnvironment: environment.apimEnvironment,
      'Ocp-Apim-Subscription-Key': environment.apiSubKey,
      'Accept': 'application/json',
      Authorization: this.authService.accesstoken != '' ? this.authService.accesstoken : localStorage.getItem('okta-id-token'),
    });
    
    let postArray = [];
    for (let chunkIndex = 0; chunkIndex < chunks; chunkIndex++) {
      let offset = chunkIndex * chunkSize;
      const formData = new FormData();
      formData.append('TotalChunks', chunks.toString());
      formData.append('chunkIndex', "" + chunkIndex);
      formData.append('fileId', flushVideo.id);
      formData.append('videoFileChunk', file.slice(offset, offset+chunkSize), flushVideo.id);
      postArray.push(this.httpclient.post<any>(`${environment.commonMediaApiUrl}${environment.endpoints.videos}`, formData, {
        headers: headers
      }));
    }

    return from(postArray).pipe(concatMap((observable) => observable));
  }

  private dataURItoBlob(string64: string, fileName: string = 'video') {
    const trimmedString = string64.replace('video/mp4', '');
    const imageContent = atob(trimmedString);
    const buffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(buffer);

    for (let n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
    const type = 'video/mp4';
    const blob = new Blob([buffer], { type });
    return new File([blob], fileName, { lastModified: new Date().getTime(), type });
  }
}
