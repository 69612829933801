<div id="main-wrapper">
  <app-navbar></app-navbar>
  <div id="offline-banner" class="banner-component banner-info" *ngIf="!isOnline">
    <div class="row no-gutters">
        <div class="col-3 col-sm-1">
          <div class="banner-icon-wrapper">
            <i class="icon icon-safety-precaution"></i>
          </div>
        </div>
        <div class="col-9 col-sm-11">
            <b>This device is offline.</b>
            <ng-container *ngIf="offlineType == 'sup'; else defaultOffline">
              <p class="mb-0">
                It appears this device has lost connectivity. The application has limited functionality, you will need to find service before completing work.
              </p>
            </ng-container>
            <ng-template #defaultOffline>
              <p class="mb-0">
                {{ 
                  offlineType != 'eo' ? 
                  'To effectively service your flush request,  please look for service to reconnect.' 
                  : 'It appears this device has lost connectivity. The application still functions, but you will need to find service before completing work.'
                }}
              </p>
            </ng-template>
        </div>
    </div>
  </div>
  <div id="root-container" class="container root-wrapper"  [ngClass]="{'offline-banner-offset': !isOnline}" >
    <app-loader></app-loader>
    <ce-in-app-survey *ngIf="!enableSurvey" [questions]="surveyQuestions" [(dialogShow)]="showSurvey" [isSubmitted]="isSurveySubmitted"
      [title]="surveyTitle" [subTitle]="surveySubTitle" (surveySubmit)="submitSurveyResults($event)">
    </ce-in-app-survey>
 
    <ce-button class="feedback-button" *ngIf="showFeedBackButton" label="Feedback" (clickEvent)="clickFeedbackButton()"></ce-button>
    <router-outlet></router-outlet>
    </div>
  <app-footer [ngClass]="{
    'visible': isLoggedIn,
    'invisible': !isLoggedIn
  }" (installApp)="showPrompt()" showInstallLink="openInstallPromote"
  *ngIf="isLoggedIn"></app-footer>
</div>

<app-modal
  modalHeading="Sorry! Your session is over 🙁"
  [showModal]="sessionTimeout"
  [showClose]="false"
  (closeDialogClick)="closeModal()"
>
  <div class="container">
    <div class="form-group row">
      <div class="col">
        <p>
          Please log back into ERA to continue your work.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-auto">
        <ce-button label='Login' (clickEvent)="goSignIn()" size='large'></ce-button>
      </div>
    </div>
  </div>
</app-modal>
<app-modal
  modalHeading="Are you still working?"
  [showModal]="sessionCloseToTimeout"
  [showClose]="false"
  (closeDialogClick)="closeModal()"
>
  <div class="container">
    <div class="form-group row">
      <div class="col">
        <p>
          Your session is ending soon. You will be signed out in {{ tick | countdown }}
        </p>
        <p>Do you want to stay logged in or sign out?</p>
      </div>
    </div>
    <div class="row">
      <div class="col-auto">
        <ce-button label='Sign out' type='secondary' (clickEvent)="goSignOut()" size='large'></ce-button>
      </div>
      <div class="col-auto">
        <ce-button label='Keep me logged in' (clickEvent)="goSignIn()" size='large'></ce-button>
      </div>
    </div>
  </div>
</app-modal>
<app-modal [showModal]="openInstallPromote && !openModal" modalHeading="" (closeDialogClick)="closeInstallDialog()" [showClose]='true'>
  <div class="container" *ngIf="openInstallPromote">
    <div class="row">
      <div class="col mb-5">
        <div class="row">
          <h4>Install Flush app and add it to home screen?</h4>
        </div>
        <div class="row">
          <div class="col-auto">
            <ce-button (clickEvent)="showPrompt()" label="Install App" type="primary" size='large'></ce-button>
          </div>
          <div class="col-auto style-guide-close-modal-btn">
            <ce-button (clickEvent)="closeInstallDialog()" label="Maybe Later" type="secondary" size='large'></ce-button>
          </div>
        </div>
            
      </div>
    </div>
  </div>   
</app-modal>
<app-modal [showModal]="showGlobalIdModal" modalHeading="" (closeDialogClick)="closeGlobalIdModal()" [showClose]='true'>
  <div class="container p-3">
    <div class="row" style="text-align: center;">
      <div class="col mb-5">
        <h3 class="mb-3">Multiple Requests Open</h3>
        <p class="mb-0">There can only be one work request or tab with the ERA tool open at a time. Select the active tab and close the others.</p>
        <!-- <img style="width: 10rem;" src="../../../assets/chrome_icon.png"> -->
        <div class="row mt-5">
          <div class="col-auto">
            <!-- <ce-button (clickEvent)="showPrompt()" text="Install App" type="primary"></ce-button> -->
          </div>
          <div class="col-auto style-guide-close-modal-btn">
            <ce-button (clickEvent)="closeGlobalIdModal()" label="Close" type="secondary" size='large'></ce-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-modal>

<ce-toaster-alert [show]="showToaster" (closeToster)="showToaster = false">
  <ng-template ceAlertContent>
    <div style="text-align: left; width: 100%">
      <span style="display:flex; width: 100%">App Update Successful! If you have any issues with the updates let us know</span>
      <span style="width: 20%">
        <a href="https://consolidatededison.sharepoint.com.mcas.ms/sites/EO-Flush/SitePages/Report-an-Issue.aspx " target="_blank" style="color: white;">Let us know</a>
      </span>
    </div>

  </ng-template>
</ce-toaster-alert>
<app-modal [showModal]="showReleaseNotes" modalHeading="" (closeDialogClick)="closeReleaseNotes()">
  <div class="container">
    <div class="form-group row">
      <div class="col" >
        <h2>We’ve made some updates</h2>
        <p>With this latest update we have addressed the following issues:</p>
        <div class="col" [innerHTML]="releaseNotes">
        </div>
      </div>      
    </div>
    <div class="row">
      <div class="col-auto">
        <ce-button (clickEvent)="closeReleaseNotes()" label="Continue" type="primary" size='large'></ce-button>
      </div>
    </div>      
  </div>
</app-modal>
<app-modal [showModal]="isCancel" [showClose]='true' (closeDialogClick)="isCancel = false" [showErrorIcon]="false"  modalHeading='Are you sure you want to cancel this request?'>
  <div class="container">
    <div class="form-group row">
      <div class="col">
        <p>Once you cancel this request, this action can not be undone.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-auto style-guide-close-modal-btn">
        <ce-button size='large' label="Yes" type="secondary"  (clickEvent)="closeRequestInProgress()" icon="icon-trash" styleClass="btn-danger-outline"></ce-button>
      </div>
      <!--" -->
      <div class="col-auto">
        <ce-button size='large' label="No" type="primary" (clickEvent)="isCancel = false"></ce-button>
      </div>
    </div>
  </div>
</app-modal>
