<div style="position: sticky;" [ngStyle]="showReview && {'display': 'none'}">
  <div class="row justify-content-center align-items-center">
    <div class="col">
      <h3 class="mb-3">Flush Job Details</h3>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row justify-content-center align-items-center">
      <div class="col">
        <h4>Construction Job Details</h4>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <b>Source WR#</b>
        <p [ngClass]="{'dash-text': !constructionJobDetailDataGroup?.sourceWrNumber }">{{constructionJobDetailDataGroup?.sourceWrNumber | dash}}</p>
      </div>
      <div class="col">
        <b>Job</b>
        <p [ngClass]="{'dash-text': !constructionJobDetailDataGroup?.job }">{{constructionJobDetailDataGroup?.job | dash | splitDash:1}}</p>
      </div>
      <div class="col">
        <b>Priority</b>
        <p [ngClass]="{'dash-text': !constructionJobDetailDataGroup?.priorityLvl }">{{constructionJobDetailDataGroup?.priorityLvl | dash | splitDash:0}}</p>
      </div>
      <div class="col">
        <b>Created</b>
        <p [ngClass]="{'dash-text': !constructionJobDetailDataGroup?.entryDate }">{{constructionJobDetailDataGroup?.entryDate | dash}}</p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <b>Description</b>
        <p [ngClass]="{'dash-text': !constructionJobDetailDataGroup?.workRequestDesc }">{{constructionJobDetailDataGroup?.workRequestDesc | dash}}</p>
      </div>
    </div>
  </div>
  <div class="view job-details-request-data-view job-requests-wrapper">
    <div class="row form-group">
      <div class="col">
        <app-details-box header="Structure Information" (headerClicked)='headerClicked($event)'
          [ngClass]="{ 'facility-id' : true }">
          <div class="form-group" *ngIf="structureInfoData.length > 0">
            <app-flush-grid [isSelectable]="false" [isFlushGridSimple]="true" [ngClass] ="{ 'no-highlight' : true }" [allowVerticalDataTable]="true"
            *ngIf="structureInfoData.length > 0" [data]="structureInfoData" [columns]="structureInfoCols"
            [displayActionsHeader]="true"
            [actions]="[{header: ' ', caption: '', type: 'edit'}]"
            (actionClicked)="relayAction($event)" >
          </app-flush-grid>
          </div>
          <p *ngIf="structureInfoData.length==0">There is no structure information available for this job.</p>
        </app-details-box>
      </div>
    </div>
    <div class="row form-group">
      <div class="col">
        <app-details-box header="Access Information" (headerClicked)='headerClicked($event)' [iconInfo]="sourceWrInfo?.accessInfoModel?.truckHasSufficientAccess">
          <div *ngIf="!sourceWrInfo?.accessInfoModel; else displayAcessInfo">
            <p >There is no access information available for this job.</p>
          </div>
          <ng-template #displayAcessInfo>
          <div class="access-info row">
            <div class="col-12 col-lg-1">
              <i class="icon icon-user"></i>
            </div>
            <div class="col-12 col-lg-5">
              <p><b>Point of contact for this location.</b></p>
              <div class="customer-contact" *ngFor="let contact of sourceWrInfo?.accessInfoModel?.pointOfContact">
                <p>{{contact?.name}}</p>
                <p>{{contact?.email}}</p>
                <p>{{contact?.phone}}</p>
              </div>
              <p *ngIf="sourceWrInfo?.accessInfoModel?.pointOfContact.length < 1">Point of contact is unavailable for this job.</p>
            </div>
          </div>
          <div class="access-info row">
            <div class="col-12 col-lg-1">
              <i class="icon icon-cone"></i>
            </div>
            <div class="col-12 col-lg-4">
              <p><b>Critical roadway</b></p>
              <p>{{sourceWrInfo?.accessInfoModel?.isStructureOnCriticalRoadway === true ? 'Yes' : 'No' }}</p>
              <p><b>Cone hives</b></p>
              <p>{{ sourceWrInfo?.accessInfoModel?.areConeHivesRequired === true ? 'Yes' : 'No' }}</p>
              <p><b>Flaggers</b></p>
              <p>{{ sourceWrInfo?.accessInfoModel?.flaggersWorkSafety === true ? 'Yes' : 'No' }}</p>
              <p><b>Customer access</b></p>
              <p>{{ sourceWrInfo?.accessInfoModel?.customerRequiredForAccess === true ? 'Required' : 'Not Required' }}</p>
            </div>
            <div class="col-12 col-lg-1 offset-lg-1">
              <i class="icon icon-truck"></i>
            </div>
            <div class="col-12 col-lg-4">
              <!-- <p><b>Vactor truck access</b></p> -->
              <p><b>Vactor truck access</b>  <span *ngIf="sourceWrInfo?.accessInfoModel?.truckHasSufficientAccess=='false'" class="icon icon-error spanStyle"></span>
                </p>
              <p>{{sourceWrInfo?.accessInfoModel?.truckHasSufficientAccess === true ? 'Yes': 'No'}}</p>
            </div>
          </div>
          <div class="access-info row">
            <div class="col-12 col-lg-1">
              <i class="icon icon-car"></i>
            </div>
            <div class="col-12 col-lg-11">
              <p><b>Parking restrictions</b></p>
                <app-flush-grid *ngIf="sourceWrInfo?.accessInfoModel?.parkingRestrictions?.length > 0" [isSelectable]="false" [isFlushGridSimple]="true" [allowVerticalDataTable]="true" [data]="accessInfoData | atomicAssetTagsForParkingRes" [columns]="accessInfoCols" [actions]="[]">
                </app-flush-grid>
              <p *ngIf="sourceWrInfo?.accessInfoModel?.parkingRestrictions?.length==0">There is no access information available for this job.</p>
            </div>
          </div>
          <div class="last-row row">
            <div class="col-12 col-lg-4">
              <p><b>Additional Details</b></p>
              <p [ngClass]="{'dash-text': !sourceWrInfo?.accessInfoModel?.additionalAccessDetails }">{{sourceWrInfo?.accessInfoModel?.additionalAccessDetails | dash}}</p>
            </div>
          </div>
          </ng-template>
        </app-details-box>
      </div>
    </div>
    <div class="row form-group">
      <div class="col">
        <app-details-box header="Flush Information" (headerClicked)='headerClicked($event)'>
          <div *ngIf="!sourceWrInfo?.flushInfoModel; else displayFlushInfo">
            <p *ngIf="!wmsComments">There is no flush information available for this job.</p>
            <div *ngIf="wmsComments">
              <div class="row pr-3 pl-3">
                <app-flush-grid [isSelectable]="false" [isFlushGridSimple]="true" [ngClass] ="{'no-highlight' : true}" [allowVerticalDataTable]="true"
                  [data]="wmsComments | dash" [columns]="wmsCommentsCols"
                  [actions]="[]"></app-flush-grid>
              </div>
            </div>
          </div>
          <ng-template #displayFlushInfo>
          <div class="flush-info row">
            <div class="col-12 col-lg-1">
              <i class="icon icon-eye"></i>
            </div>
            <div class="col-12 col-lg-4">
              <p><b>Equipment visibility</b></p>
              {{sourceWrInfo?.flushInfoModel?.canSeeAllEquipment === true ? 'Visible' : 'Not visible' }}
            </div>
          </div>
          <div class="flush-info row">
            <div class="col-12 col-lg-1">
              <i class="icon icon-water"></i>
            </div>
            <div class="col-12 col-lg-4">
              <p><b>Water</b></p>
              <p>{{sourceWrInfo?.flushInfoModel?.isWaterFoundOnStructure === true ? 'Yes' : 'None' }}</p>
              <p *ngIf="sourceWrInfo?.flushInfoModel?.isWaterFoundOnStructure === true && sourceWrInfo?.flushInfoModel?.structurePumpRestriction"> {{sourceWrInfo?.flushInfoModel?.structurePumpRestriction}}</p>
              <p *ngIf="sourceWrInfo?.flushInfoModel?.isWaterFoundOnStructure === true"> {{sourceWrInfo?.flushInfoModel?.structureWaterDescription}}</p>
              <p *ngIf="sourceWrInfo?.flushInfoModel?.isWaterFoundOnStructure === true"> {{sourceWrInfo?.flushInfoModel?.structureWaterQuantity}}</p>
            </div>
            <div class="col-12 col-lg-1 offset-lg-1">
              <i class="icon icon-shovel"></i>
            </div>
            <div class="col-12 col-lg-4">
              <p><b>Debris</b></p>
              <p>{{sourceWrInfo?.flushInfoModel?.isDebrisEnvironmentConditionFound === true ? 'Yes': 'None'}}</p>
              <p *ngIf="sourceWrInfo?.flushInfoModel?.isDebrisEnvironmentConditionFound"><span *ngFor="let debrisType of sourceWrInfo?.flushInfoModel?.structureDebrisType.split(','); let i=index">{{debrisType}}{{i === sourceWrInfo?.flushInfoModel?.structureDebrisType.length - 1 ? '' : ', '}}</span></p>
              <p><b>Amount</b></p>
              <p *ngIf="sourceWrInfo?.flushInfoModel?.isDebrisEnvironmentConditionFound" [ngClass]="{'dash-text': !sourceWrInfo?.flushInfoModel?.structureDebrisQuantity}">{{sourceWrInfo?.flushInfoModel?.structureDebrisQuantity | dash}}</p>
            </div>
          </div>
          <div class="flush-info row">
            <div class="col-12 col-lg-1">
              <i class="icon icon-insect"></i>
            </div>
            <div class="col-12 col-lg-4">
              <p><b>Infestation</b></p>
              <p [ngClass]="{'dash-text': !sourceWrInfo?.flushInfoModel?.structureInfestation}">{{ sourceWrInfo?.flushInfoModel?.structureInfestation | dash }}</p>
            </div>
            <div class="col-12 col-lg-1 offset-lg-1">
              <i class="icon icon-explosion"></i>
            </div>
            <div class="col-12 col-lg-4">
              <p><b>Perlite bags</b></p>
              <p>{{sourceWrInfo?.flushInfoModel?.arePearliteBagsFound === true ? 'Yes': 'None'}}</p>
              <p *ngIf="sourceWrInfo?.flushInfoModel?.arePearliteBagsFound === true"> {{sourceWrInfo?.flushInfoModel?.arePearliteBagsCompromised === true ? 'Compromised' : 'Not compromised'}}</p>
            </div>
          </div>
          <div class="last-row row">
            <div class="col-12 col-lg-4">
              <p><b>Additional Details</b></p>
              <p [ngClass]="{'dash-text': !sourceWrInfo?.flushInfoModel?.additionalDetails}">{{sourceWrInfo?.flushInfoModel?.additionalDetails | dash}}</p>
            </div>
          </div>
        </ng-template>
        </app-details-box>
      </div>
    </div>
    <div class="row form-group">
      <div class="col">
        <app-details-box [header]="'Request Photos (' + photos.length + ')'" class="start-job-details-box-photos"
          (headerClicked)='headerClicked($event)'>
          <p *ngIf="photos.length == 0">There are no photos available for this job.</p>
          <div class="form-group row">
            <div class="col-6 col-lg-3 col-xl-2" *ngFor="let photo of photos" (click)="selectPhotoReview(photo)">
                <div class="row">
                    <div class="col">
                        <div class="photo">
                            <img class="img" [src]="photo.src" alt="image">
                            <div class="img-caption"></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <p class="body2 img-caption-1">{{photo?.timestamp | timestamp:'MMM D, YYYY HH:mm'}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                      <p class="body2 img-caption-2"><span class="icon icon-tag"></span>{{ photo | labelsCount }}</p>
                    </div>
                </div>
            </div>
        </div>
        </app-details-box>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <ce-button class="col-auto" data-custom-id="FMJobDetailsPage" data-custom-bhvr="PROCESSCHECKPOINT" (clickEvent)="nextClick()" label='Next' type="primary"></ce-button>
      </div>
    </div>
  </div>
</div>
<!-- modal for photo review -->
<div class="photos" [ngStyle]="!showReview && { 'display':'none'}">
  <div class="large-card-wrapper" [ngStyle]="{'z-index': showReview ? 10000 : -1}">
      <div class="large-card" [ngClass]="{'unhide': showReview}">
          <div #imageContainer class="large-card-header">
              <app-photo class="disableElement" [image]="selectedPhoto"
                  [inputPhoto]="selectedPhoto" [taggingEnabled]="false"
                  [ngStyle]="{
                      position: 'absolute', 
                      top: '0', 
                      bottom: '0', 
                      left: '0', 
                      right: '0',
                      background: '#2a2a2a'
                  }"
                  ></app-photo>
              <!-- <div class="carousel-left-btn" *ngIf="showingIndex - 1 > -1" (click)="selectPhoto(selectedPhoto,-1)"><span>&#8249;</span></div>
              <div class="carousel-right-btn" 
                  *ngIf="selectedPhoto?.index < (selectedPhoto?.arr === 'Before' ? beforePhotos?.length - 1 : afterPhotos?.length - 1)" 
                  (click)="selectPhoto(selectedPhoto, 1)"><span>&#8250;</span></div> -->
              <div class="close-card">
                <span class="just-icon icon-close" (click)="closeReview()"></span>
              </div>
          </div>
          <div class="large-card-body">
                  <h4 class="mb-2">Media Details</h4>
                  <p class="mb-0 border"><strong>{{ selectedPhoto?.structureId }}</strong></p>
                  <p class="border">{{selectedPhoto?.timestamp | timestamp:'MMM D, YYYY HH:mm'}}</p>
                  <p><b>Labels</b></p>
                  <div class="form-group no-gutters row light-border"
                      *ngFor="let tag of selectedPhoto?.tags; let i = index">
                      <div class="col-2 col-lg-1">
                          <div class="label-header">
                              <p>{{i + 1}}</p>
                          </div>
                      </div>
                      <div class="col-10 col-lg-11 badges">
                          <span class="badge-solid" *ngFor="let label of tag.labels">{{label}}</span>
                      </div>
                  </div>
                  <p><b>Additional comments</b></p>
                  <div class="form-group row border">
                  <div class="col-12">
                      <p>{{selectedPhoto?.additionalComments}}</p>
                  </div>
                  </div>
          </div>
      </div>
  </div>
  </div>
  <!--modal for Edit Structure -->
  <app-modal [showModal]="isEditBtnClicked" modalHeading="Edit Structure" (closeDialogClick)="closeEditStructure()" [showClose]='true'>
    <div class="container" [formGroup]="editStructureForm">
      <div class="row">
        <div class="col">
          <p>You may edit the address of this structure</p>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-auto">
          <app-tile [isChecked]="true" [isDisabled]="true">
            <div class="p-4 pr-5">
              <p class="mb-1">{{ editStructureForm?.controls?.currentStructure?.value.type }}</p>
              <h4 class="mb-1 bold">{{ editStructureForm?.controls?.currentStructure?.value?.structureId }}</h4>
              <p class="mb-1" style="max-width: 200px;">{{ editStructureForm?.controls?.currentStructure?.value?.frontAddress }}</p>
        </div>
          </app-tile>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h4 class="bold">Structure History</h4>
        </div>
      </div>
      <div class="form-group row">
        <div class="col">
          <app-flush-grid [isSelectable]="false" [isFlushGridSimple]="true" [ngClass] ="{ 'no-highlight' : true }" [allowVerticalDataTable]="true"
          *ngIf="structureInfoHistory.length > 0" [data]="structureInfoHistory" [columns]="addressHistoryCols"
          [actions]="[]">
        </app-flush-grid>
        </div>
      </div>
      <div class="form-group row">
        <div class="col">
          <ce-input formControlName="address" label="Front Address"></ce-input>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-auto style-guide-close-modal-btn">
        <ce-button (clickEvent)="closeEditStructure()" label="Cancel" type="secondary"></ce-button>
      </div>
      <div class="col-auto style-guide-close-modal-btn">
        <ce-button (clickEvent)="saveEditStructure()" label="Save"></ce-button>
      </div>
    </div>
  </app-modal>
  <app-modal [showModal]="showMapModal" modalHeading="" (closeDialogClick)="showMapModal = false" [showClose]='true'>
    <div class="map-container" style="width: 80vw;"  [ngStyle]="{'padding-top': isMobile ? '40px' : '20px' }">
      <app-esri-map  [isSupervisor]="false" (mapLoaded)="mapLoadedEvent($event)">
      </app-esri-map>
    </div>
    <div class="row" style="padding-top: 20px;">
      <div class="col">
        <p><strong>Asset Tag - <span style="color:#0078cf;">{{this.assetTag}}</span></strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <ce-button type="secondary" label="Close" size="large" (clickEvent)="showMapModal = false">
        </ce-button>
      </div>
    </div>
  </app-modal>