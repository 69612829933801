import { FormControl } from '@angular/forms';

export class AccessInfoContact {
  name: FormControl;
  email: FormControl;
  phoneNum: FormControl;
  constructor(name: FormControl, email: FormControl, phoneNum: FormControl) {
    this.name = name;
    this.email = email;
    this.phoneNum = phoneNum;
  }
}
