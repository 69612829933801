import { FormGroup, FormArray, FormControl } from '@angular/forms';

export class ParkingRestriction {
  assetTagFacilities: FormArray;
  restriction: FormControl;
  days: FormGroup;
  start_time: FormGroup;
  end_time: FormGroup;
  constructor(assetTagFacilities, restriction, days, start_time, end_time) {
    this.assetTagFacilities = assetTagFacilities;
    this.restriction = restriction;
    this.days = days;
    this.start_time = start_time;
    this.end_time = end_time;
  }
}
