import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dash',
})
export class DashPipe implements PipeTransform {
  transform(value, parseObject = false): any {
    if (parseObject) {
      for (const key in value) {
        if (value.hasOwnProperty(key)) {
          value[key] = this.transformValue(value[key]);
        }
      }
      return value;
    } else {
      const transformedValue = this.transformPrimitiveValues(value);
      return transformedValue;
    }
  }

  private transformValue(value) {
    // Check for null or true / false value
    if (typeof value === 'object' && value !== null && value !== undefined) {
      // Defining a new object and assign the props to this new one as there is a bug in core.js
      // which sometimes throws an error on re-assigning the values to the same object
      const transformedObject = Object.create({});
      for (const key in value) {
        if (value.hasOwnProperty(key)) {
          Object.defineProperty(transformedObject, key, {
            value: this.transformPrimitiveValues(value[key]),
            writable: true,
            enumerable: true,
            configurable: true,
          });
        }
      }
      return transformedObject;
    } else {
      return this.transformPrimitiveValues(value);
    }
  }

  private transformPrimitiveValues(primitiveValue) {
    if (primitiveValue === true) {
      return '\u2714';
    } else if (primitiveValue === false) {
      return '\u2014';
    } else if (primitiveValue) {
      return primitiveValue;
    } else {
      return '\u2014';
    }
  }
}
