// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { flushEndpoints } from './flush-endpoints';

export const environment = {
  production: true,
  aadScopes: "#{aadScopes}#",
  authority: '#{authority}#',
  tenant: '#{tenant_id}#',
  clientId: '#{client_id}#',
  redirectUri: '#{redirect_url}#',
  navigateToLoginRequestUrl: true,
  showSurvey: false,
  cacheLocation: 'localStorage',
  flushApiBaseUrl: '#{flush_baseapi}#',
  flushOktaApiBaseUrl: '#{flush_oktaBaseUrl}#',
  flushOktaLoginUrl: '#{flush_oktaLoginUrl}#',
  flushOktaCallbackUrl: '#{flush_oktaCallbackUrl}#',
  apiSubKey: '#{apiSubKey}#',
  commonMediaApiUrl: '#{commonMediaApiUrl}#',
  commonMediaApiSubKey: '#{commonMediaApiSubKey}#',
  commonSurveyApiUrl: '#{commonSurveyApiUrl}#',
  apimEnvironment: '#{apimEnvironment}#',
  postLogoutRedirectUri: '#{logout_redirect_url}#',
  oktaIssuer: '#{okta_issuer}#',
  oktaClientId: '#{okta_client_id}#',
  oktaRedirectUri: '#{redirect_url}#',
  instrumentationKey: '#{InstrumentationKey}#',
  arcFmClientId: '#{arcFmClientId}#',
  arcFmClientSecret: '#{arcFmClientSecret}#',
  clarity_id: '#{clarity_id}#',
  surveyId: '#{surveyId}#',
  endpoints: flushEndpoints,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
