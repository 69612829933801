import { v4 as uuid } from 'uuid';
import { Photo } from 'src/app/models/photo';
import { FlushPhoto } from 'src/app/models/flushPhoto';
import { BehaviorSubject, Observable, of, throwError, timer } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/services/base/base.service';
import { catchError, concatMap, map, retry, tap } from 'rxjs/operators';
import { Select, Store } from '@ngxs/store';
import { AppState } from 'src/app/app-state/app.state';
import { WorkRequestDetail } from 'src/app/models/work-request-detail';
import { InternetCheckerService } from '../internet-checker/internet-checker.service';
import * as moment from 'moment';
import { IdbService } from '../idb/idb.service';
import { environment } from 'src/environments/environment';
import { AddStartJobPhotos } from 'src/app/app-state/actions/start-job.actions';
import * as _ from 'lodash';
import { CONFIG } from 'src/app/global/config';
import { NgxImageCompressService } from 'ngx-image-compress';
@Injectable({
  providedIn: 'root',
})
export class PhotoService {
  isOnline: boolean = true;
  private showingPhotoIndex = new BehaviorSubject<number>(0);
  private showingPhotoIndex$ = this.showingPhotoIndex.asObservable();
  pendingDeletePhotos: [Photo[]] = [[]];

  get mediaStatuses() {
    return CONFIG.MEDIA_STATUS
  }
  // pendingVideos: BehaviorSubject<FlushPhoto[]> = new BehaviorSubject<FlushPhoto[]>([]);

  @Select(AppState.getWorkRequestGlobalId)
  getWorkRequestGlobalId$: Observable<string>;
  @Select(AppState.getFacilityGlobalId)
  getFacilityGlobalId$: Observable<string>;
  @Select(AppState.getCrewCode) getCrewCode$: Observable<string>;
  @Select(AppState.getWorkRequestDetail)
  getWorkRequestDetail$: Observable<WorkRequestDetail>;
  constructor(private baseService: BaseService, private internetChecker: InternetCheckerService, private store: Store, private idbService: IdbService,private imageCompress: NgxImageCompressService, ) {
    this.internetChecker.getStatus.subscribe((isOnline) => {
      this.isOnline = isOnline;
      if (this.isOnline && !!this.pendingDeletePhotos[0] && this.pendingDeletePhotos[0].length > 0) {
        var i = this.pendingDeletePhotos.length;
        while (i--) {
          if (this.isOnline) {
            this.deletePhotos(this.pendingDeletePhotos[i]).subscribe(
              (res) => {
                console.log('Successfully deleted photo');
              },
              (err) => console.log(`unable to delete images due to ${JSON.stringify(err)}`)
            ).unsubscribe();
            this.pendingDeletePhotos.splice(i, 1);
          }
        }
      }
    });
  }

  uploadPhotos(photos: Photo[]): Observable<FlushPhoto[]> {
    const flushPhotos = this.MapToFlushPhotos(photos);
    if (this.isOnline) {
      return this.baseService.uploadPhotos(flushPhotos).pipe(
        map((response: FlushPhoto[]) => {
          const uploadedPhotos: FlushPhoto[] = [];
          response.forEach((x) => {
            uploadedPhotos.push({
              ...x,
              imageData: x.imageData,
              isUploaded: true,
            });
          });
          return uploadedPhotos;
        })
      );
    } else {
      //Add to pending
      this.idbService.savePostToIdb(environment.flushApiBaseUrl + environment.endpoints.photos, flushPhotos, photos[0].id);
      return of(flushPhotos);
    }
  }

  deletePhotos(photos: Photo[]) {
    const flushPhotos = this.MapToFlushPhotos(photos);
    if (this.isOnline) {
      return this.baseService.deletePhotos(flushPhotos).pipe(map((response) => response));
    } else {
      //Add to pending
      this.pendingDeletePhotos.push(photos);
      return throwError('Offline');
    }
  }

  MapToFlushPhotos(photos: Photo[]): FlushPhoto[] {
    // TODO: Send work component here
    const stateFacilityGlobalId = this.store.selectSnapshot(store => store.AppState.facilityGlobalId);
    const stateWorkRequestGlobalId = this.store.selectSnapshot(store => store.AppState.workRequestGlobalId);
    const  wrNumber = this.store.selectSnapshot(store => store.AppState.workRequestDetail.workRequestNo);
    const crewCode = this.store.selectSnapshot(store => store.AppState.userInfo?.user?.crewCode);
    const workComponentGlobalId = this.store.selectSnapshot(store => store.AppState.workComponentGlobalId);
    const crmsAppointmentId = this.store.selectSnapshot(store => (store.AppState.workRequestDetail as WorkRequestDetail).appointmentId);
    const isOnline = this.isOnline;
    return photos.map(
      (x) =>
        new FlushPhoto(
          x.id,
          crewCode,
          x.structureId,
          wrNumber,
          workComponentGlobalId,
          stateWorkRequestGlobalId,
          isOnline ? x.src?.toString()?.split(',')[1] : x.src?.toString(),
          x.timestamp,
          x.tags,
          x.isUploaded,
          x.status,
          x.additionalComments,
          crmsAppointmentId,
          x.contentSize,
          x.isProcessed,
          x.mediaId,
          x.mediaStatus,
          x.mediaType,
          x.streamingUrls,
          x.thumbnailUrl
        )
    );
  }

  MapFlushPhotoToPhoto(flushPhoto: FlushPhoto) : Photo {
    var img = {} as Photo;
    img.src = flushPhoto?.imageData;
    img.additionalComments = flushPhoto?.comments;
    img.tags = flushPhoto?.tags.map(t => t) ?? [];
    img.facing = this.mapPhotoClassificationToStatus(flushPhoto?.classification);
    img.status = this.mapPhotoClassificationToStatus(flushPhoto?.classification);
    img.timestamp = flushPhoto?.timestamp;
    img.id = flushPhoto?.id;
    img.isUploaded = flushPhoto?.isUploaded;
    img.structureId = flushPhoto?.structureId;
    img.mediaId = flushPhoto?.mediaId;
    img.mediaStatus = flushPhoto?.mediaStatus;
    img.mediaType = flushPhoto?.mediaType;
    img.streamingUrls = flushPhoto?.streamingUrls;
    img.thumbnailUrl = flushPhoto?.thumbnailUrl;
    img.contentSize = flushPhoto?.contentSize;
    img.isProcessed = flushPhoto?.isProcessed;
    return img;
  }

  getShowingPhotoIndex() {
    return this.showingPhotoIndex$;
  }
  setShowingPhotoIndex(i: number) {
    this.showingPhotoIndex.next(i);
  }
  sortImages(images: Photo[], criteria = 'date') {
    let sortedImages = [];
    switch (criteria) {
      default:
      // Default criteria is date
      case 'date':
        sortedImages = images.sort((a, b) => moment(a.timestamp).diff(b.timestamp));
        return sortedImages;
    }
  }
  public mapPhotoClassificationToStatus(classification: string) : string {
    if (classification?.length > 1) {
      return classification; // if classification is already a formatted string, return it
    }
    switch(classification) {
      case '1':
        return 'Before';
      default:
        return 'After';
    }
  }

  uploadCCLVideo(flushVideo: FlushPhoto) {
    return this.baseService.uploadCCLVideo(flushVideo);
  }

  uploadFlushVideo(flushVideo: FlushPhoto) : Observable<FlushPhoto> {
    const objvideo = _.cloneDeep(flushVideo);
    objvideo.imageData = null;
    return this.baseService.uploadFlushVideo(objvideo).pipe(
      tap((val) => {
        timer(30000).pipe(
          concatMap(() => this.getVideo(flushVideo.mediaId, true)),
          retry(5)
        ).subscribe({
          next: (commonApiReturnValue) => {
            if (commonApiReturnValue) {
              let photos = _.cloneDeep(this.store.selectSnapshot(store => store.AppState.startJobPhotos as Photo[]));
              let videoIndex = photos.findIndex((photo: Photo) => photo?.mediaId == flushVideo?.mediaId && commonApiReturnValue?.thumbnailUrl != null);              
              if (videoIndex > -1) {
                photos[videoIndex].mediaStatus = this.mediaStatuses.GENERATED
                photos[videoIndex].src = '';
                photos[videoIndex].thumbnailUrl = commonApiReturnValue?.thumbnailUrl ?? '';
                photos[videoIndex].streamingUrls = commonApiReturnValue?.streamingUrls ?? [];
                this.store.dispatch(new AddStartJobPhotos(photos));
                return photos[videoIndex];
              }
            }
          },
          error: (err) => {
            console.error(err);
          }
        })
      })
    );
  }

  deleteFlushVideo(imageID: string, mediaID: string) {
    return this.baseService.deleteFlushVideo(imageID, mediaID);
  }

  getVideo(id: string, retryResponse: boolean = false) {
    return this.baseService.getVideo(id, retryResponse).pipe(
      tap((resp: any) => {
        if(resp?.videoUploadStatus !== "Uploaded") {
          throw throwError(new Error('PhotoService.getVideo.resp.videoUploadStatus' + resp.videoUploadStatus));
        }
      })
    );
  }
  // deleteVideo(id: string) {
  //   return this.baseService.deleteVideo(id);
  // }

  compressFile(imagebase64) {
    const orientation = -1;
    const sizeOfOriginalImage = this.imageCompress.byteCount(imagebase64);
    console.log('sizeOfOriginalImage', sizeOfOriginalImage);    
    
    if(this.isCompressionRequired(imagebase64)) {     
      return this.imageCompress.compressFile(imagebase64, orientation, 50, 50).then((resp) => {
        const sizeOFCompressedImage = this.imageCompress.byteCount(resp);
        console.log('sizeOFCompressedImageLevel1', sizeOFCompressedImage);
        if(sizeOFCompressedImage > 1000000) {
          return this.imageCompress.compressFile(resp, orientation, 50, 50).then((resp1) => {
            const sizeOFCompressedImage = this.imageCompress.byteCount(resp1);
            console.log('sizeOFCompressedImageLevel2', sizeOFCompressedImage);
            if(sizeOFCompressedImage > 1492666) {
              return this.imageCompress.compressFile(resp1, orientation, 50, 50).then((resp2) => {
                const sizeOFCompressedImage = this.imageCompress.byteCount(resp2);
                console.log('sizeOFCompressedImageLevel3', sizeOFCompressedImage);     
                return resp2;
              });     
            }
            return resp1 ;
          });      
        }
        return resp ;
      });      
    } else {
      console.log('File Compression not required');
      return Promise.resolve(imagebase64);
    }
  }

  isCompressionRequired(imagebase64) {
    const size = this.imageCompress.byteCount(imagebase64); 
    return size > 975835;
  }

  getSizeOfImage(imagebase64) {
    return this.imageCompress.byteCount(imagebase64);
  }
}
