import { Injectable } from '@angular/core';
import { Alert } from '@ce-lib/alert';
import { FlushHttpRequestService } from '../requests/flush-http-request.service';

interface SyncManager {
  getTags(): Promise<string[]>;
  register(tag: string): Promise<void>;
}

declare global {
  interface ServiceWorkerRegistration {
    readonly sync: SyncManager;
  }

  interface SyncEvent {
    readonly lastChance: boolean;
    readonly tag: string;
  }

  interface ServiceWorkerGlobalScopeEventMap {
    sync: SyncEvent;
  }
}
interface IdbRequestItem {
  action: string,
  table: string,
  id: string,
  item: any
}
@Injectable({
  providedIn: 'root'
})
export class IdbService {

  constructor(private http: FlushHttpRequestService, private alert: Alert) { 
    const channel = new BroadcastChannel('idb-channel');
    channel.onmessage = (event) => {
      const action = event.data.action;

      if(action === 'sendPost') {
        this.handleSavedPost(event.data.request, event.data.id);
      }
    }
  }

  savePostToIdb(url: string, payload: any, id: string) {
    const messageChannel = new MessageChannel();
    const item: IdbRequestItem = {
      action: 'saveRequest',
      table: 'post-requests',
      id: id,
      item: {
        url: url,
        payload,
        method: 'POST'
      }
    }
    navigator.serviceWorker.controller.postMessage(item, [messageChannel.port2])
    this.installBackgroundSync().then(installed => { 
      console.log('Sync event registered');
    });
  }

  removePostFromIdb(id: string) {
    const messageChannel = new MessageChannel();

    navigator.serviceWorker.controller.postMessage({
      action: 'deleteRequest',
      request: {
        id: id
      }
    }, [messageChannel.port2])
  }

  handleSavedPost(request, id) {
    this.http.postFlushRequest(request.url, request.payload).subscribe(data => {
      this.alert.success("Your device is back online!", 'Dismiss');
      this.removePostFromIdb(id);
    }, err => {
      this.alert.warn("Your device is back online, but some work did not save. Error: " + request.url, 'Dismiss');
    })
  }

  async installBackgroundSync() {
    const registration = await navigator.serviceWorker.ready as ServiceWorkerRegistration;
    try {
      return registration.sync.register('sync-requests');
    } catch {
      this.alert.warn('Background sync not installed')
    }
  }
}
