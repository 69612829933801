import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import * as moment from 'moment';
import * as _ from 'lodash';
import { combineLatest, forkJoin, iif, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { AddAccessInformation, AddEmergencyInformation } from 'src/app/app-state/actions/access-info.actions';
import { AddFlushInformation } from 'src/app/app-state/actions/flush-process-info.actions';
import { AddJobDetails } from 'src/app/app-state/actions/job-details.actions';
import { AddStructureInformation } from 'src/app/app-state/actions/structure-info.actions';
import { AppState } from 'src/app/app-state/app.state';
import { AtomicAssetTagsForParkingResPipe } from 'src/app/common/pipes/atomic-asset-tags-for-parking-res.pipe';
import { AccessInformation } from 'src/app/models/accessInformation';
import { AccessInfo, AccessInfoParkingRestriction } from 'src/app/models/construction-crew/access-info';
import { CCRequestDetails } from 'src/app/models/construction-crew/cc-request-details';
import { CCReviewInfo } from 'src/app/models/construction-crew/cc-review-info';
import { FlushInfo } from 'src/app/models/construction-crew/flush-info';
import { FlushInfoSelect } from 'src/app/models/construction-crew/flush-info-select';
import { JobInfo } from 'src/app/models/construction-crew/job-info';
import { StructureInfo } from 'src/app/models/construction-crew/structure-info';
import { FlushInformation } from 'src/app/models/flushInformation';
import { FlushPhoto } from 'src/app/models/flushPhoto';
import { JobDetail } from 'src/app/models/jobDetail';
import { ParkingRestriction } from 'src/app/models/parking-restriction.model';
import { Photo } from 'src/app/models/photo';
import { PointOfContact } from 'src/app/models/pointOfContact';
import { WorkRequestDetail } from 'src/app/models/work-request-detail';
import { BaseService } from 'src/app/services/base/base.service';
import { AddStartJobPhotos, AddWorkDescription, AddWorkRequestDetail } from 'src/app/app-state/actions/start-job.actions';
import { MasterDataService } from '../master-data/master-data.service';
import { CONFIG } from 'src/app/global/config';
import { FlushSubtype } from 'src/app/interfaces/flush-subtype';
import { StructureMenuOptions } from 'src/app/interfaces/structure-menu-options';
import { FormValidationService } from '../forms/form-validation.service';
import { PriorityItem } from 'src/app/interfaces/priority-item';
import { UserInfo } from 'src/app/interfaces/user-info';
import { Structure } from 'src/app/models/structure.model';
import { WorkRequestRow } from 'src/app/interfaces/work-request-row';
import { WorkRequest } from 'src/app/interfaces/work-request';
import { PhotoService } from '../photo/photo.service';
import { Alert } from '@ce-lib/alert';
import { LoggingService } from '../logging/logging.service';
import { MapperService } from '../mapper/mapper.service';
import { User } from 'src/app/models/user.model';
import { AddWorkComponentId } from 'src/app/app-state/actions/work-request.actions';

@Injectable({
  providedIn: 'root',
})
export class CcRequestsService {
  @Select(AppState.getWorkRequestGlobalId)
  getWorkRequestGlobalId$: Observable<string>;
  @Select(AppState.getUserInfo) getUserInfo$: Observable<UserInfo>;
  @Select(AppState.getFacilityGlobalId)
  getFacilityGlobalId$: Observable<string>;
  @Select(AppState.getWorkRequestDetail)
  getWorkRequestDetail$: Observable<WorkRequestDetail>;
  @Select(AppState.getWorkComponentId)
  getWorkComponentId$: Observable<string>;
  constructor(
    private baseService: BaseService,
    private store: Store,
    private router: Router,
    private atomicAssetTagPipe: AtomicAssetTagsForParkingResPipe,
    private masterData: MasterDataService,
    private formValidationService: FormValidationService,
    private photoService: PhotoService,
    private alert: Alert,
    private logger: LoggingService,
    private mapperService: MapperService
  ) { }

  handleNavigationFromDashboard(dataItem: WorkRequestRow, roleType: string) {
    const wrDetails: Observable<WorkRequest> = this.store.selectOnce(store => store.AppState.assignedWRDetails as WorkRequest[]).pipe(
      // Using includes because ARM exists for flush mechanic workComponentGlobalID
      map(reqs => reqs.find(req => req.workRequestGlobalID.includes(dataItem.workRequestGlobalId) && req.workComponentGlobalID.includes(dataItem.workComponentGlobalID))),
      catchError(err => {
        if (err instanceof TypeError) {
          console.log(`${dataItem.workRequestGlobalId}, ${dataItem.workComponentGlobalID} does not exist inside store`);
          console.log(this.store.selectSnapshot(store => store.AppState.assignedWRDetails as WorkRequest[]))
        }
        return of(null)//throwError(`Could not find WR: ${dataItem.wrNo}.`)
      }),
      mergeMap((wr) =>
        iif(
          () => !!wr,
          of(wr),
          this.baseService.getWorkRequestDetails(dataItem.workRequestGlobalId, dataItem?.facility[0]?.facilityID.toString(), '', dataItem.id, dataItem.workComponentGlobalID)
            .pipe(
              tap((res: any) => {
                console.log('getWorkRequestDetails.res :>> ', res);
                const data = { ...res.workRequest }
                let wrDetail = {
                  ...data,
                  sourceWrNumber: data.sourceWrNumber ?? '',
                  priorityLevel: dataItem.priorityLevel ?? '',
                  priorityItem : res?.priorityItem ?? '',
                  workRequestGlobalId: dataItem.workRequestGlobalId,
                  bTicket: data.bTicket,
                  workRequestName: data.workRequestName,
                  workRequestDesc: data.workRequestDesc,
                  facility: data.facility ?? this.mapperService.mapFacilityToStructure(res.facility),
                  job: data.job ?? '',
                  workRequestId: dataItem.workComponentDescription
                } as WorkRequestDetail;
                console.log('getWorkRequestDetails.wrDetail :>> ', wrDetail);
                this.store.dispatch(new AddWorkRequestDetail(wrDetail));
                this.store.dispatch(new AddWorkComponentId(dataItem.workComponentDescription));
              }),
              map(data => {
                let facilityInfo = ((data.sourceWRInformation.facility) ? data.sourceWRInformation.facility : data.facility) ?? {};
                let wr = {
                  ...data.workRequest,
                  structureInfo: this.mapperService.mapFacilityToStructureInfo(facilityInfo, dataItem.wrNo, dataItem.workRequestGlobalId)
                }
                return wr;
              })
            )
        )
      ),
      concatMap(
        (wr) => {
          // If a cc request send jobdetails with searchwrflag
          if (roleType === 'cc' && dataItem.status.toLowerCase() !== 'submitted') {
            let searchwrflag = this.store.selectSnapshot((store) => store.AppState.searchwrflag);
            return this.saveJobDetails(wr?.jobDetail?.jobDescription, wr?.jobDetail?.job, wr?.jobDetail?.jobPriority, wr?.jobDetail?.id, searchwrflag).pipe(
                map(() => wr)
            )
          } else {
            return of(wr);
          }
      })
    )
    console.log(dataItem);

    // Run both fetchs in parallel
    forkJoin([wrDetails, this.getDropdowns(), this.GetCCCommonInfo()])
      .pipe(
        tap(([wr, [priorities, subtypes, structureOpts], commonInfo]) => {
          if (dataItem.status === 'In Progress' || dataItem.status === 'Submitted' || dataItem.status === 'Completed' || dataItem.status === 'Incomplete') {
            const flushInfo = (!Array.isArray(wr?.flushInfo)) ? [wr?.flushInfo as FlushInfo]: wr?.flushInfo as FlushInfo[];
            const accessInfo = (!Array.isArray(wr?.accessInfo)) ? [wr?.accessInfo as AccessInfo]: wr?.accessInfo as AccessInfo[];
            
            this.setImages(wr?.images, wr.workRequestNo);
            this.setJobDetails(_.cloneDeep(wr?.jobDetail), priorities, subtypes);
            this.setAccessInfo(accessInfo);
            this.setFlushInfo(flushInfo, structureOpts);
            if(roleType === "eo") {
              // Review Page Questions (Contractors)
              this.setFlushReview(wr?.wR_DESC, 
                wr?.isCrewConductingRepairs ?? ((wr?.isCrewConductingRepairs == null || wr?.isCrewConductingRepairs === '') ? null : wr?.isCrewConductingRepairs), 
                wr?.canOriginalWorkContinue ?? ((wr?.canOriginalWorkContinue == null || wr?.canOriginalWorkContinue === '') ? null :  wr?.canOriginalWorkContinue), 
                wr?.hasStrayVoltage ?? ((wr?.hasStrayVoltage == null || wr?.hasStrayVoltage === '') ? null : wr?.hasStrayVoltage), 
                wr?.hasLeadStabilizerUsed ?? ((wr?.hasLeadStabilizerUsed == null || wr?.hasLeadStabilizerUsed === '') ? null : wr?.hasLeadStabilizerUsed), 
                wr?.noOfStabilizers);
            }
          }
        }),
        catchError((err) => {
          return throwError(err);
        }),
        mergeMap(([wr, dropdowns, commonInfo]) => {
          const { crewCode, wrNumber, wrId, fac } = commonInfo;
          const viewingAsEo = (!Array.isArray(wr.structureInfo)) ? wr.structureInfo as StructureInfo : null;
          if (viewingAsEo) {
            return of([wr?.structureInfo as StructureInfo])
          }       
          if ((wr?.structureInfo as StructureInfo[] ?? []).length < 1 && parseInt(fac.facilityId) > 0) {
            // Store what is inside state (workrequestdetail.facility) into ERA if ERA does not have any data yet, otherwise return what's in ERA
            return of([fac])
          } else if((wr.structureInfo as StructureInfo[])?.length > 0) {
            return of(wr.structureInfo);
          } else {
            // empty array
            return of([])
          }
        }),
        tap((structures: StructureInfo[]) => {
          console.log('BeforeAddingToState.AddStructureInformation.structures :>> ', structures);
          // We either managed to return WMS structure info/empty array (base case), or whatever has been saved into ERA (workflow underway)
          this.store.dispatch(
            new AddStructureInformation({
              structures: structures.filter((struct) => (struct.submissionStatus?.toLowerCase() !== 'submitted')).map((struct) => {
                return {
                  structureId: struct.structureNumber,
                  frontAddress: struct.address,
                  facilityId: struct.facilityId,
                  isCustomerOwned: struct.isCustomerOwned,
                  isManual: struct.isManuallyAdded,
                  type: struct.facilityType,
                  borough: struct.borough,
                  id: struct.id,
                  longitude: struct.longitude,
                  latitude: struct.latitude,
                  submissionStatus: struct.submissionStatus,
                  status:  struct.submissionStatus
                };
              }),
              isValid: (structures.map(s => s).length) > 0,
              isManual: false,
            })
          );
        }),
        switchMap(() => this.formValidationService.checkIfFlushRequestValid()),
        take(1)
      )
      .subscribe(([formsValid, photosValid]) => {        
        this.baseService.submissionTimeoutModal.next(false);
        if (roleType === 'eo') {
          this.eoNavigation(dataItem, formsValid, photosValid);
        } else {
          this.ccNavigation(dataItem, formsValid, photosValid);
        }
      }, 
        err => {   
          this.logger.logException({
            name: 'Dashboard - Unsuccessful redirection to details page',
            message: err,

          })
          this.alert.urgent(err)
        }
      );
  }

  public ccNavigation(dataItem: WorkRequestRow, formsValid: boolean, photosValid: boolean) {
    const isBTicket = !!dataItem.bTicket && dataItem.bTicket.length > 4;
    if (dataItem.status === 'Submitted') {
      this.router.navigate(['/flush-information/review', { isSummary: true }]);
    } else if (formsValid && photosValid) {
      this.router.navigate(['flush-information', 'review']);
    } else if (dataItem.status === 'Ready To Start') {
      const facilityExists = dataItem.facility?.length > 0;
      this.router.navigate([isBTicket ? '/flush-information/job-environment' : '/flush-landing', { showNavigateToPhotos: facilityExists }]);
    } else if (formsValid) {
      this.router.navigate(['flush-information', 'photos']);
    } else {
      if (dataItem.action.lastVisitedPage === 'structureInfo') {
        this.router.navigate(['flush-information', 'structure-information']);
      } else if (dataItem.action.lastVisitedPage === 'accessInfo') {
        this.router.navigate(['flush-information', 'access-information']);
      } else if (dataItem.action.lastVisitedPage === 'flushInfo') {
        this.router.navigate(['flush-information', 'flush-process-information']);
      } else {
        this.router.navigate(['flush-information', isBTicket ? 'job-environment' : 'job-details']);
      }
    }
  }

  public eoNavigation(dataItem: WorkRequestRow, formsValid: boolean, photosValid: boolean) {
    // When Flush Mechanic navigates from dashboard send an application insight when a flush job has started
    if (dataItem.status === 'Ready To Start') {
      let userInfo: UserInfo = this.store.selectSnapshot(store => store.AppState.userInfo);
      this.logger.logEvent(
        {
          name: 'Start Flush Job'
        },
        {
          action: "Began, 'Ready To Start' Job",
          eventDate: new Date(),
          userName: userInfo?.user?.name,
          userEmail: userInfo?.user?.email,
          userRole: userInfo?.user?.role,
          workRequestRow: dataItem
        }
      )
    }

    const flushWRNumber = dataItem.workRequestGlobalId;
    const facilityGlobalId = dataItem?.facility[0]?.facilityID ?? '';
    const flushWorkRequestId = dataItem?.flushWorkRequestId ?? '';
    let params = {
      workRequestGlobalId: flushWRNumber,
      facilityGlobalId,
      flushWorkRequestId: flushWorkRequestId
    };
    if (dataItem.status !== 'Completed') {
      this.router.navigate(['/start-job/job-details-request', params]);
    } else {
      //Completed
      params['isSummary'] = true;
      this.router.navigate(['/start-job/review', params]);
    }
  }

  public getDropdowns(): Observable<[PriorityItem[], FlushSubtype[], StructureMenuOptions]> {
    return combineLatest([
      this.masterData.getCacheItem(CONFIG.MASTER_DATA.PRIORITY_ITEMS) as Observable<PriorityItem[]>,
      this.masterData.getCacheItem(CONFIG.MASTER_DATA.FLUSH_SUBTYPES) as Observable<FlushSubtype[]>,
      this.masterData.getCacheItem(CONFIG.MASTER_DATA.STRUCTURE_OPTIONS) as Observable<StructureMenuOptions>
    ]).pipe(map(dropdowns => dropdowns), take(1))
  }
  setJobDetails(details, priorities: PriorityItem[], subTypes: FlushSubtype[]) {
    try {
      this.store.dispatch(
        new AddJobDetails({
          jobPriorityId: details.jobPriority,
          jobPriorityDesc: details.job,
          jobPrioritySelectBoxIndex: -1,
          jobSubtype: "",
          jobSubtypeName: "",
          jobSubtypeSelectBoxIndex: -1,
          jobDescription: details.jobDescription,
          isValid: true,
          id: details.id
        })
      );
    } catch (error) {
      if (error instanceof TypeError) {
        // details is empty, even though our wr is 'In Progress' or 'Completed
        console.log(error);

        console.log('Job Details Empty');
      }
    }
  }

  setAccessInfo(accessInfoArr: AccessInfo[]) {
    try {
      let accessInfo = accessInfoArr[0];
      this.store.dispatch(
        new AddAccessInformation({
          isValid: true,
          isCustomerRequired: accessInfo.customerRequiredForAccess,
          isStructureOncriticalRoadway: accessInfo.isStructureOnCriticalRoadway,
          hasSufficientAccess: accessInfo.truckHasSufficientAccess,
          areConeHiveRequired: accessInfo.areConeHivesRequired,
          contactsDataGroup: accessInfo.pointOfContact?.map((x) => {
            return {
              phoneNumber: x.phone,
              name: x.name,
              email: x.email,
            };
          }),
          photos: [],
          areFlaggersRequired: accessInfo.flaggersWorkSafety,
          areParkingRestriction: accessInfo.anyParkingRestrictionsOnLocation,
          parkingRestrictions: [].concat.apply([], accessInfoArr.map((info) => {
            return info.parkingRestrictions?.map((val) => {
              // Map all boolean values and then build out string of days
              const days = Object.keys(val)
                .map((v) => (typeof val[v] === 'boolean' && val[v] ? v.charAt(0).toUpperCase() + v.slice(1) + ' ' : ''))
                .join('')
                .trim();
              return new ParkingRestriction(
                val.assetTag,
                val.restriction,
                days,
                moment(val.parkingRestrictionStartTime, 'HHmm').format('h mm A'),
                moment(val.parkingRestrictionEndTime, 'HHmm').format('h mm A')
              );
            })
          })),
          additionalAccessDetails: accessInfo.additionalAccessDetails,
        })
      );
      if (!!accessInfo.isAccessObtained && !!accessInfo.isOnLocation) {
        this.store.dispatch(new AddEmergencyInformation({
          isAccessObtained: accessInfo.isAccessObtained?.toString(),
          isOnLocation: accessInfo.isOnLocation?.toString()
        }))
      }
    } catch (error) {
      if (error instanceof TypeError) {
        // details is empty, even though our wr is 'In Progress' or 'Completed
        console.log('Access Details Empty');
      }
    }
  }

  setFlushInfo(flushInfoArr: FlushInfo[], options) {
    try {
      const payload = flushInfoArr.map((flushInfo) => {
        const debrisQuantity = options.debrisQuantity;
        const debrisType = options.debrisType;
        const pumpRestrictions = options.pumpingRestrictions;
        const infestations = options.infestations;
        const waterType = options.waterType;
        const waterQuantity = options.waterQuantity;
        const selectedOptionDefaultIndex = new FlushInfoSelect();
        selectedOptionDefaultIndex.noDebrisPumpReason = [];

        // Setup for the first flushInfo
        flushInfo.structureDebrisType?.split(',').forEach((x) => {
          if (x && debrisType) {
            selectedOptionDefaultIndex.noDebrisPumpReason.push(debrisType.indexOf(x));
          }
        });
        selectedOptionDefaultIndex.debrisAmount = debrisQuantity.indexOf(flushInfo.structureDebrisQuantity);
        selectedOptionDefaultIndex.infestationType = infestations.indexOf(flushInfo.structureInfestation);
        selectedOptionDefaultIndex.noPumpReason = pumpRestrictions.indexOf(flushInfo.structurePumpRestriction);
        selectedOptionDefaultIndex.waterAmount = waterQuantity.indexOf(flushInfo.structureWaterQuantity);
        selectedOptionDefaultIndex.waterDesc = waterType.indexOf(flushInfo.structureWaterDescription);
        return {
          canSeeAllEquipement: flushInfo.canSeeAllEquipment,
          isWaterFoundOnStructure: flushInfo.isWaterFoundOnStructure,
          canStructureDewaterUsingPump: flushInfo.canDewaterStructureUsingPump,
          structureDebrisTypeId: flushInfo.structureDebrisType?.split(','),
          structurePumpRestrictionId: flushInfo.structurePumpRestriction,
          isValid: flushInfoArr.length > 0,
          structureWaterId: flushInfo.structureWaterDescription,
          structureWaterQuantityId: flushInfo.structureWaterQuantity,
          isDebrisEnvironmentConditionFound: flushInfo.isDebrisEnvironmentConditionFound,
          selectedOptionDefaultIndex,
          isDiaperOildTesterUser: flushInfo.isOilPresenseTested,
          structureDebrisQuantityId: flushInfo.structureDebrisQuantity,
          structureInfestationId: flushInfo.structureInfestation,
          areTherePearliteBags: flushInfo.arePearliteBagsFound,
          havePearliteBagsCompromized: flushInfo.arePearliteBagsCompromised,
          additionalInformation: flushInfo.additionalDetails,
          structureNumber: flushInfo.structureNumber
        }
      })
      this.store.dispatch(new AddFlushInformation(payload));
    } catch (error) {
      if (error instanceof TypeError) {
        // details is empty, even though our wr is 'In Progress' or 'Completed
        console.log('Flush Details Empty');
      }
    }
  }
  setImages(imgs: FlushPhoto[], workRequestNo) {
    // TODO if we ever want to fetch already saved images from API
    try {
      const images = imgs.filter(p => p.workRequestNumber == workRequestNo);
      const mappedImages = images.map(img => this.photoService.MapFlushPhotoToPhoto(img));
      this.store.dispatch(new AddStartJobPhotos(this.photoService.sortImages(mappedImages)));
    } catch (error) {
      if (error instanceof TypeError) {
        // details is empty, even though our wr is 'In Progress' or 'Completed
        console.log('Images Empty');
      }
    }
  }

  setStructureInformation(structures: StructureInfo[]){
    this.store.dispatch(
      new AddStructureInformation({
        structures: structures.filter((struct) => (struct.submissionStatus?.toLowerCase() !== 'submitted')).map((struct) => {
          return {
            structureId: struct.structureNumber,
            frontAddress: struct.address,
            facilityId: struct.facilityId,
            isCustomerOwned: struct.isCustomerOwned,
            isManual: struct.isManuallyAdded,
            type: struct.facilityType,
            borough: struct.borough,
            id: struct.id,
            longitude: struct?.longitude,
            latitude: struct?.latitude,
            submissionStatus: struct.submissionStatus,
            status:  struct.submissionStatus
          };
        }),
        isValid: (structures.map(s => s).length) > 0,
        isManual: false,
      })
    );
  }

  setFlushReview(comments:string, isCrewConductingRepairs: boolean, canOriginalWorkContinue: boolean, hasStrayVoltage: boolean, hasLeadStabilizerUsed:boolean,
    noOfStabilizers: number ) {
      const payload = { workDescription: comments, isCrewConductingRepairs, canOriginalWorkContinue,  hasStrayVoltage,  hasLeadStabilizerUsed,  noOfStabilizers}
      this.store.dispatch(new AddWorkDescription(payload));
  }

  saveJobDetails(areaOfWork: string, jobType: string, jobPriority: string, jobid = 0, searchwrflag: boolean = false): Observable<JobInfo> {
    return this.GetCCCommonInfo()
      .pipe(
        switchMap(commonInfo => this.baseService.saveCCJobDetails({
          jobType: jobType ?? '',
          jobPriority: jobPriority ?? '',
          selectedIndex: -1,
          subType: "",
          subTypeSelectedIndex: -1,
          jobDescription: areaOfWork ?? '',
          crewCode: commonInfo.crewCode,
          wrNumber: commonInfo.wrNumber,
          workRequestId: commonInfo.wrId,
          workComponentGlobalId: commonInfo.wcNumber,
          createdOn: moment(),
          updatedOn: moment(),
          id: jobid,
          workComponentId: commonInfo.workComponentId
        } as JobInfo
          , commonInfo.wrNumber, searchwrflag)
        )
      );
  }
  getStructureEditHistory(structureNumber: string): Observable<any> {
    return this.GetCCCommonInfo().pipe(
      switchMap(commonInfo =>
        this.baseService.getStructureHistory(commonInfo.wrNumber, structureNumber))
    );
  }

  saveStructureInfo(structures: StructureInfo[]): Observable<StructureInfo[]> {
    return this.GetCCCommonInfo().pipe(
      switchMap(commonInfo => {
        const calls = structures.map((structure, i) =>
          this.baseService.saveCCStructureInfo({ ...structure, crewCode: commonInfo.crewCode, workComponentGlobalId: commonInfo.wcNumber }).pipe(
            catchError((err) => {
              return throwError(err);
            })
          )
        );
        return forkJoin(calls);
      })
    );
  }
  editStructureInfo(structure: StructureInfo): Observable<ArrayBuffer> {
    return this.GetCCCommonInfo().pipe(
      switchMap(commonInfo => this.baseService
        .putCCStructureInfo({ ...structure, crewCode: commonInfo.crewCode, workComponentGlobalId: commonInfo.wcNumber }))
    );
  }
  deleteCCStructureInfo(structureInfo: StructureInfo, wrGlobalId: string): Observable<StructureInfo> {
    return this.GetCCCommonInfo().pipe(
      switchMap(commonInfo => this.baseService.deleteCCStructureInfo(commonInfo.wrNumber, structureInfo.id))
    );
  }
  saveAccessInfo(
    accessInfoFormGroup: FormGroup,
    emergencyRequestFormGroup: FormGroup,
    parkingRestrictions: ParkingRestriction[],
    contacts: PointOfContact[],
    assetTag: string
  ): Observable<AccessInfo> {
    return this.GetCCCommonInfo()
      .pipe(
        map(commonInfo => {
          const accessInfoParkingRestrictions: AccessInfoParkingRestriction[] = this.MapParkingRestrictions(
            parkingRestrictions
          );
          const accessInfo: AccessInfo = {
            crewCode: commonInfo.crewCode,
            workComponentGlobalId: commonInfo.wcNumber,
            wrNumber: commonInfo.wrNumber,
            workRequestId: commonInfo.wrId,
            createdOn: moment(),
            updatedOn: moment(),
            isStructureOnCriticalRoadway: this.stringToBoolean(accessInfoFormGroup.controls.isOnCriticalRoadway.value),
            truckHasSufficientAccess: this.stringToBoolean(accessInfoFormGroup.controls.isSufficientAccess.value),
            areConeHivesRequired: this.stringToBoolean(accessInfoFormGroup.controls.isConeHivesRequired.value),
            anyParkingRestrictionsOnLocation: this.stringToBoolean(accessInfoFormGroup.controls.isParkingRestrictions.value),
            additionalAccessDetails: accessInfoFormGroup.controls.additionalAccessDetails.value,
            parkingRestrictions: accessInfoParkingRestrictions,
            structureNumber: assetTag,
            pointOfContact: contacts.map((x) => {
              return {
                name: x.name,
                phone: x.phoneNumber,
                email: x.email,
              };
            }),
            flaggersWorkSafety: accessInfoFormGroup.controls.isFlagSafetyRequired.value,
            customerRequiredForAccess: accessInfoFormGroup.controls.isCustomerRequired.value,
            isAccessObtained: emergencyRequestFormGroup?.controls?.isAccessObtained?.value ?? null,
            isOnLocation: emergencyRequestFormGroup?.controls?.isOnLocation?.value ?? null,
            workComponentId: commonInfo.workComponentId
          };
          return accessInfo;
        }),
        concatMap(accessInfo => this.baseService.saveCCAccessInfo(accessInfo, accessInfo.wrNumber))
      );
  }
  getCCRequest(checkingAgainstWr: string = null): Observable<CCRequestDetails> {
    return this.getWorkRequestDetail$.pipe(
      filter((wrDetail) => wrDetail != undefined),
      map(wrDetail => {
        console.log('getCCRequest.this.getWorkRequestDetail$.wrDetail :>> ', wrDetail);
        const wcGlobalId = this.store.selectSnapshot(store => store.AppState.workComponentGlobalId) ?? '';
        if (checkingAgainstWr && !(wrDetail.workRequestNo === checkingAgainstWr)) {
          // Mismatch between state and dataItem being passed in. Return me the Request for the incoming WR
          // the other props dont matter here, we are solely looking at wrDetails in
          return { ...wrDetail, workRequestNo: checkingAgainstWr, workComponentGlobalId: wcGlobalId };
        }
        return wrDetail;
      }),
      mergeMap((res) => this.baseService.getCCRequests(res.workRequestNo, res.workComponentGlobalId)),
      catchError((err) => {
        console.log(err);
        return throwError(err);
      }),
      take(1)
    );
  }
  saveFlushInfo(flushInformation: FlushInformation): Observable<FlushInfo> {
    return this.GetCCCommonInfo()
      .pipe(
        map(commonInfo => {
          const flushInfo: FlushInfo = {
            crewCode: commonInfo.crewCode,
            wrNumber: commonInfo.wrNumber,
            workComponentGlobalId: commonInfo.wcNumber,
            structureNumber: flushInformation.structureNumber,
            workRequestId: commonInfo.wrId,
            createdOn: moment(),
            updatedOn: moment(),
            canSeeAllEquipment: flushInformation.canSeeAllEquipement,
            canDewaterStructureUsingPump: flushInformation.canStructureDewaterUsingPump,
            isWaterFoundOnStructure: flushInformation.isWaterFoundOnStructure,
            structurePumpRestriction: flushInformation.structurePumpRestrictionId,
            structureWaterDescription: flushInformation.structureWaterId,
            structureWaterQuantity: flushInformation.structureWaterQuantityId,
            isDebrisEnvironmentConditionFound: flushInformation.isDebrisEnvironmentConditionFound,
            structureDebrisType: flushInformation.structureDebrisTypeId?.toString(),
            structureDebrisQuantity: flushInformation.structureDebrisQuantityId,
            structureInfestation: flushInformation.structureInfestationId,
            isOilPresenseTested: flushInformation.isDiaperOildTesterUser,
            arePearliteBagsFound: flushInformation.areTherePearliteBags,
            arePearliteBagsCompromised: flushInformation.havePearliteBagsCompromized,
            additionalDetails: flushInformation.additionalInformation,
            workComponentId: commonInfo.workComponentId
          };
          return flushInfo;
        }),
        concatMap(flushInfo => this.baseService.saveCCFlushInfo(flushInfo, flushInfo.wrNumber))
      );
  }
  public GetCCCommonInfo(): Observable<{ crewCode: string, wrId: string, wrNumber: string, fac: StructureInfo, wcNumber, crmsAppointmentId: string, workComponentId: string }> {
    // this.getWorkRequestDetail$
    return forkJoin([
      this.getUserInfo$.pipe(map(({ user, workAssignments }) => user?.crewCode), take(1)),
      this.getWorkRequestGlobalId$.pipe(map(res => res), take(1)),
      this.getWorkRequestDetail$
        .pipe(
          map(res => {
            const wrNumber = res?.workRequestNo;
            const defaultItem = {
              frontAddress: null,
              facilityId: '0',
              type: null,
              structureId: null,
              isManual: false,
              borough: null,
              longitude: null,
              latitude: null,
            } as Structure;
            const stored = res.facility ?? defaultItem;

            const fac = {
              address: stored.frontAddress,
              facilityId: stored.facilityId,
              facilityType: stored.type,
              structureNumber: stored.structureId,
              isManuallyAdded: stored.isManual,
              isCustomerOwned: false,
              borough: stored.borough,
              longitude: stored.longitude,
              latitude: stored.latitude,
              workComponentGlobalId: null,
              workComponentId: null,
              createdOn: null,
              updatedOn: null,
              crewCode: undefined,
              id: undefined,
              wrNumber: undefined,
              workRequestId: undefined,
            } as StructureInfo;
            
            return { wrNumber, fac }
          }),
          take(1)
        )
    ]).pipe(
      map((arrayMapping) => {
        const { wrNumber, fac } = arrayMapping[2];
        const wc = this.store.selectSnapshot(store => store.AppState.workComponentGlobalId) ?? '';
        const crmsAppointmentId = this.store.selectSnapshot(store => (store.AppState.workRequestDetail as WorkRequestDetail).appointmentId);
        const wcId = this.store.selectSnapshot(store => store.AppState.workComponentId) ?? '';
        return { crewCode: arrayMapping[0], wrId: arrayMapping[1], wrNumber: wrNumber, fac: fac, wcNumber: wc, crmsAppointmentId, workComponentId: wcId };
      })
    );
  }
  private MapParkingRestrictions(parkingRestrictions): AccessInfoParkingRestriction[] {
    parkingRestrictions = this.atomicAssetTagPipe.transform(parkingRestrictions);
    return parkingRestrictions.map((x) => {
      return {
        monday: x.days.includes('Monday'),
        tuesday: x.days.includes('Tuesday'),
        wednesday: x.days.includes('Wednesday'),
        thursday: x.days.includes('Thursday'),
        friday: x.days.includes('Friday'),
        saturday: x.days.includes('Saturday'),
        sunday: x.days.includes('Sunday'),
        restriction: x.restriction,
        assetTag: x.assetTagFacilities,
        parkingRestrictionStartTime: moment((x.start_time as string).trim(), 'h mm A').format('HHmm'),
        parkingRestrictionEndTime: moment((x.end_time as string).trim(), 'h mm A').format('HHmm'),
      };
    });
  }

  deletePhotos(photos: Photo[]): Observable<any | never> {
    return this.GetCCCommonInfo()
      .pipe(
        map(commonInfo => {
          const ccCrewPhotos = photos.map(
            (x) =>
              new FlushPhoto(
                x.id,
                commonInfo.crewCode,
                x.structureId,
                commonInfo.wrNumber,
                commonInfo.wcNumber,
                commonInfo.wrId,
                x.src?.toString(),
                x.timestamp,
                x.tags,
                x.isUploaded,
                x.status,
                x.additionalComments,
                commonInfo.crmsAppointmentId,
                x.contentSize,
                x.isProcessed,
                x.mediaId,
                x.mediaStatus,
                x.mediaType,
                x.streamingUrls,
                x.thumbnailUrl
              )
          );
          // delete only those images which are uploaded to blob already, Deleting images still in local storage will cause API exceptions
          const ccCrewPhotosToDelete = ccCrewPhotos.filter((x) => x.isUploaded === true);
          return ccCrewPhotosToDelete;
        }),
        concatMap(ccCrewPhotosToDelete => this.baseService.deletePhotos(ccCrewPhotosToDelete)),
        catchError(err => {
          console.log(`Error while deleting photos!`);
          return throwError(err)
        }),
        take(1)
      );
  }

  saveCCReview(
    jobDetails: JobDetail,
    structureInfoData: StructureInfo[],
    accessInfo: AccessInformation,
    flushInfo: FlushInformation[],
    photos: Photo[],
    isSupervisorApproved,
    searchwrflag: boolean
  ): Observable<CCReviewInfo> {
    const wcGlobalId = this.store.selectSnapshot(store => store.AppState.workComponentGlobalId);
    const assignedWRDetails = this.store.selectSnapshot(store => store.AppState?.assignedWRDetails as WorkRequest[])
    const emergencyQuestions = this.store.selectSnapshot(store => store.AppState.emergencyInfo)

    return combineLatest([
      this.GetCCCommonInfo(),
      this.getWorkRequestDetail$
    ]).pipe(
      take(1),
      map(([commonInfo, wrDetails] ) => {
        const wr = assignedWRDetails.filter(req => req.workRequestGlobalID.includes(wrDetails.workRequestGlobalId) &&  req.workComponentGlobalID.includes(wcGlobalId)).reduce(res => res);
        const structures = structureInfoData.map((structure) => {
          return {
            facilityId: structure.facilityId,
            facilityType: structure.facilityType,
            address: structure.address,
            structureNumber: structure.structureNumber,
            isCustomerOwned: structure.isCustomerOwned,
            isManuallyAdded: structure.isManuallyAdded,
            borough: structure.borough,
            id: structure.id,
            crewCode: commonInfo.crewCode,
            workComponentGlobalId: commonInfo.wcNumber,
            wrNumber: commonInfo.wrNumber,
            workRequestId: commonInfo.wrId,
            createdOn: moment(),
            updatedOn: moment(),
          } as StructureInfo;
        });
        const flushPhotos = photos.map(
          (x) =>
            new FlushPhoto(
              x.id,
              commonInfo.crewCode,
              x.structureId,
              commonInfo.wrNumber,
              commonInfo.wcNumber,
              commonInfo.wrId,
              x.src?.toString(),
              x.timestamp,
              x.tags,
              x.isUploaded,
              x.status,
              x.additionalComments,
              commonInfo.crmsAppointmentId,
              x.contentSize,
              x.isProcessed,
              x.mediaId,
              x.mediaStatus,
              x.mediaType,
              x.streamingUrls,
              x.thumbnailUrl
            )
        );
        return {
          jobDetails: {
            jobType: jobDetails.jobPriorityDesc,
            jobPriority: jobDetails.jobPriorityId?.toString(),
            selectedIndex: -1,
            subType: "",
            subTypeSelectedIndex: -1,
            jobDescription: jobDetails.jobDescription,
            wrNumber: commonInfo.wrNumber,
            workComponentGlobalId: commonInfo.wcNumber,
            crewCode: commonInfo.crewCode,
            workRequestId: commonInfo.wrId,
            createdOn: moment(),
            updatedOn: moment(),
            workComponentId: wrDetails.workRequestDesc
          },
          structureInfo: structures.map((s) => s),
          accessInfo: structures?.map((structure) => {
            return {
              isStructureOnCriticalRoadway: accessInfo.isStructureOncriticalRoadway,
              truckHasSufficientAccess: accessInfo.hasSufficientAccess,
              areConeHivesRequired: accessInfo.areConeHiveRequired,
              anyParkingRestrictionsOnLocation: accessInfo.areParkingRestriction,
              additionalAccessDetails: accessInfo.additionalAccessDetails,
              parkingRestrictions: this.MapParkingRestrictions(accessInfo.parkingRestrictions)?.filter((parkingRes: AccessInfoParkingRestriction) => { return parkingRes?.assetTag?.includes(structure.structureNumber + "") }),
              pointOfContact: accessInfo.contactsDataGroup.map((x) => {
                return {
                  name: x.name,
                  phone: x.phoneNumber,
                  email: x.email,
                };
              }),
              flaggersWorkSafety: accessInfo.areFlaggersRequired,
              customerRequiredForAccess: accessInfo.isCustomerRequired,
              crewCode: commonInfo.crewCode,
              wrNumber: commonInfo.wrNumber,
              workComponentGlobalId: commonInfo.wcNumber,
              workRequestId: commonInfo.wrId,
              createdOn: moment(),
              updatedOn: moment(),
              structureNumber: structure.structureNumber,
              isAccessObtained: this.stringToBoolean(emergencyQuestions?.isAccessObtained?.value ?? null),
              isOnLocation: this.stringToBoolean(emergencyQuestions?.isOnLocation?.value ?? null)
            } as AccessInfo
          }),
          flushInfo: flushInfo?.map((flushInfo: FlushInformation) => {
            return {
              canSeeAllEquipment: flushInfo.canSeeAllEquipement,
              canDewaterStructureUsingPump: flushInfo.canStructureDewaterUsingPump,
              isWaterFoundOnStructure: flushInfo.isWaterFoundOnStructure,
              structurePumpRestriction: flushInfo.structurePumpRestrictionId?.toString(),
              structureWaterDescription: flushInfo.structureWaterId?.toString(),
              structureWaterQuantity: flushInfo.structureWaterQuantityId?.toString(),
              isDebrisEnvironmentConditionFound: flushInfo.isDebrisEnvironmentConditionFound,
              structureDebrisType: flushInfo.structureDebrisTypeId?.toString(),
              structureDebrisQuantity: flushInfo.structureDebrisQuantityId?.toString(),
              structureInfestation: flushInfo.structureInfestationId?.toString(),
              isOilPresenseTested: flushInfo.isDiaperOildTesterUser,
              arePearliteBagsFound: flushInfo.areTherePearliteBags,
              arePearliteBagsCompromised: flushInfo.havePearliteBagsCompromized,
              additionalDetails: flushInfo.additionalInformation,
              crewCode: commonInfo.crewCode,
              wrNumber: commonInfo.wrNumber,
              workComponentGlobalId: commonInfo.wcNumber,
              workRequestId: commonInfo.wrId,
              createdOn: moment(),
              updatedOn: moment(),
              structureNumber: flushInfo.structureNumber,
            } as FlushInfo
          }),
          images: flushPhotos,
          isSupervisorApproved: isSupervisorApproved,
          searchwrflag: searchwrflag,
          bTicketNo: wrDetails?.bTicket ?? '',
          polygonCode: wr.schedulingPolygonCode ?? ''
        } as CCReviewInfo;
      }),
      switchMap(ccReviewInfo => this.baseService.saveCCRequest(ccReviewInfo))
    );
  }

  cancelCCRequest(): Observable<null> {
    return this.GetCCCommonInfo().pipe(switchMap(commonInfo => this.baseService.cancelCCRequest(commonInfo.wrNumber)))
  }

  stringToBoolean(string) {
    if (typeof string === 'string') {
      switch (string.toLowerCase().trim()) {
        case "true": return true;
        case "false": return false;
        default: return null;
      }
    } else {
      return null;
    }
  }
  valueToString(val) {
    return val != null ? String(val) : '';
  }
}
