import { AssociatedWorkBody } from 'src/app/interfaces/associated-work-body';
import { WorkComponent } from 'src/app/interfaces/work-component';
import { WorkRequest } from 'src/app/interfaces/work-request';
import { WorkRequestsResponse } from 'src/app/models/work-requests-response';

export namespace Dashboard {
  export class Add {
    static readonly type = '[Dashboard] Add root data';
  
    constructor(public payload: WorkRequestsResponse) {}
  }
  export class Delete {
    static readonly type = '[Dashboard] Delete root data';
  
    constructor() {}
  }

  export class AddDetailedWork {
    static readonly type = '[Dashboard] Add list of details tied to assigned WRs';
  
    constructor(public payload: WorkRequest[], public searchwrflag: boolean) {}
  }

  export class DeleteDetailedWork {
    static readonly type = '[Dashboard] Delete list of details tied to assigned WRs';
  
    constructor() {}
  }

   export class AddAssignedWork {
    static readonly type = '[Dashboard] Add list of assigned work tied to current user';
  
     constructor(public payload: AssociatedWorkBody[]) {}
   }
   
   export class DeleteAssignedWork {
    static readonly type = '[Dashboard] Delete of assigned work tied to current user';
  
    constructor() {}
   }
}
