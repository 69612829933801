<div class="sortable-help-message">
    <ng-container *ngTemplateOutlet="helpMessage || helpMessageDefault;"></ng-container>
</div>
<kendo-sortable
    #sortable
    [kendoSortableBinding]="items"
    [navigable]="isNavigable"
    [animation]="isAnimationOn"
    (dragOver)="onDragOver($event)"
    (dragEnd)="onDragEnd($event)"
    (dragStart)="onDragStart($event)"
    (touchstart)="onTouchOfItem($event)"
    class="sortable"
    itemClass="item"
    activeItemClass="item active"
    [zone]="id"
    [acceptZones]="zones"
    >
    <ng-template let-item="item" (touchstart)="allowDrag = false" (touchend)="allowDrag = false">
        <div class="title-row">
            <ng-container *ngTemplateOutlet="title || contentTitleDefault; context: { $implicit: item }"></ng-container>
            <span class="drag-cta ellipsis"
            (mouseover)="onMouseover($event)" 
            (mouseleave)="onMouseleave($event)"
            (touchstart)="dragTargetOnTouchStart($event)" 
            (touchend)="dragTargetOnTouchEnd($event)"
            ><div class="icon-ellipsis"></div></span>
        </div>
        <div class="main-content" (touchstart)="allowDrag = false" (touchend)="allowDrag = false">
            <ng-container *ngFor="let itemData of item.data; index as i;">
                <ng-container *ngTemplateOutlet="content || contentDefault; context: { $implicit: itemData, dataColIdx: i, itemRow: item }"></ng-container>
            </ng-container>
        </div>
    </ng-template>
</kendo-sortable>

<ng-template #contentTitleDefault let-item>
    <p class="body2">
        {{ item.titleName || item.itemWorkStatus.replace('-', ' ').replace('Pending Scheduled', 'Needs Crew Assignment') }}
        <small>{{ item.titleValue }}</small>
     </p>
</ng-template>

<ng-template #contentDefault let-itemData>
    <div class="col item-header">
        {{itemData}}
    </div>
</ng-template>

<ng-template #helpMessageDefault>
    <small>
        Double tap <span class="ellipsis">&#10247;&#10247;</span> to reorder the jobs
    </small>
</ng-template>