import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class ContractorAuthService {
  currentAccessToken = new BehaviorSubject<string>(localStorage.getItem('okta-access-token'));
  currentIdToken = new BehaviorSubject<string>(localStorage.getItem('okta-id-token'));
  constructor(private httpClient: HttpClient) {}

  public get getAccessTokenValue(): string {
    return this.currentAccessToken.value;
  }
  public get getIdTokenValue(): string {
    return this.currentIdToken.value;
  }

  isAuthenticated(): boolean {
    return this.currentAccessToken.value !== null;
  }

  authenticate() {
    return this.httpClient.get(environment.flushOktaLoginUrl);
  }

  authCallback(code) {
    return this.httpClient
      .post(environment.flushOktaCallbackUrl, { code }, {
        headers: new HttpHeaders().set(
          'Content-Type', 'application/json'
        )
      });
  }
  // just created method if anything changes later
  logout() {
    return this.httpClient.get(environment.flushOktaApiBaseUrl + '/logout');
  }
}
