import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

export interface Banner {
  show: boolean;
  showIcon: boolean;
  type: string;
  details: BannerDetails | HttpErrorBanner | null;
}

interface BannerDetails {
  header: string;
  body: string[];
}
export interface HttpErrorBanner extends BannerDetails {
  httpStatus: number;
  originalServerMessage: string;
  originalUrl: string;
  originalErrorName: string;
  originalError: string;
}

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  banner = new BehaviorSubject<Banner>({
    type: '',
    show: false,
    showIcon: false,
    details: null,
  });
  banner$ = this.banner.asObservable();
  constructor() {}

  createBanner(type, details: BannerDetails | HttpErrorResponse | HttpErrorBanner, showIcon = true) {
    this.banner.next({
      type: type,
      show: true,
      showIcon: showIcon,
      details:
        details instanceof HttpErrorResponse
          ? this.handleHttpErrorResponse(details)
          : this.handleCustomErrorResponse(details),
    });
  }

  handleHttpErrorResponse(err: HttpErrorResponse, customHeader = '', customBody = '') {
    return {
      header: customHeader.length > 0 ? customHeader : err.statusText,
      body: customBody.length > 0 ? [customBody] : this.handleHttpErrorBody(err.error, err.message),
      httpStatus: err.status,
      originalError: err.error,
      originalErrorName: err.name,
      originalUrl: err.url,
      originalServerMessage: err.message,
    } as HttpErrorBanner;
  }
  handleCustomErrorResponse(details) {
    return {
      header: this.formatCaptionHeader(details.header),
      body: this.formatCaptionBody(details.body, details?.originalError, details?.originalServerMessage),
    } as BannerDetails;
  }
  formatCaptionHeader(baseHeader): string {
    return baseHeader;
  }
  formatCaptionBody(baseBody, error = '', server = ''): string[] {
    return error?.length > 0 ? [...baseBody, `Error Details: ${error}`, server.toString()] : [...baseBody];
  }
  handleHttpErrorBody(error, message): string[] {
    return [error, message];
  }
  resetBanner() {
    this.banner.next({
      type: '',
      show: false,
      showIcon: false,
      details: null,
    });
  }
}
