import { SourceWRInformation } from 'src/app/models/sourceWrInformation';
import { Structure } from 'src/app/models/structure.model';
import { WorkRequest } from './work-request';

export class WorkRequestDetail {
  date: string;
  appointmentId: string;
  sourceWrNumber: string;
  workRequestNo: string;
  entryDate: string;
  concatenatedWRAddress: string;
  priorityLevel: string;
  status: string;
  workRequestGlobalId: string;
  workRequestId: string;
  action: string;
  workRequestStatusCode: string;
  bTicket: string;
  workRequestName: string;
  workRequestDesc: string;
  taxDistrict: object;
  facility: Structure;
  sourceWrInfo: SourceWRInformation;
  flushWorkRequestId: string | null;
  job = '';
  prioirtyLevel?: string;
  priorityItem?: string;
  sourceWRInformation?: SourceWRInformation;
  workRequest?: WorkRequestDetail;
  flushPriority?: string;
  wmsComments?: any[];
  images?: any[];
  crmsAppointmentId?: string;
  wR_DESC?: string;
  id: number;
  workComponentGlobalId?: string;
}
