import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-links-directory',
  templateUrl: './links-directory.component.html',
  styleUrls: ['./links-directory.component.scss'],
})
export class LinksDirectoryComponent implements OnInit {
  links: any;
  constructor(private httpClient: HttpClient) {}

  ngOnInit(): void {
    this.httpClient.get<any>('/assets/links.json').subscribe((data) => {
      this.links = data.links;
    });
  }
}
