<div>
  <div class="row justify-content-center align-items-center">
    <div class="col">
      <h3>Flush information</h3>
    </div>
    <div>
      <ce-button size="large" class="flush-info-camera-btn" icon="icon-camera" iconDirection="left" label="Take photo" type="secondary" (clickEvent)="takePhoto()"></ce-button>
    </div>
  </div>
  <p>Please provide details on the inspection of your structure.</p>
  <div class="form-group row">
    <div class="col-auto">
      <app-tile [isChecked]="true" [isDisabled]="true">
        <div class="p-4 pr-5">
          <p class="mb-1">{{ selectedStructure?.type }}</p>
          <h4 class="mb-1">{{ selectedStructure?.structureId }}</h4>
          <p class="mb-1" style="max-width: 300px;">{{ selectedStructure?.frontAddress }}</p>
        </div>
      </app-tile>
    </div>
  </div>
  <form *ngIf="flushInfoFormGroup" [formGroup]="flushInfoFormGroup">
    <div class="form-group row">
      <div class="col-lg-5 ">
        <p><b>Can you see all relevant equipment?</b><a *ngIf="!this.flushInfoFormGroup.controls['isRelevantEquipment'].valid" class="flush-process-error"> *</a></p>
        <div class="row">
          <div class="col-12">
            <ce-radio-group size="large" [isDisabled]="false" formControlName="isRelevantEquipment">
              <ce-radio-button size="large" label="No" id="CanNotSeeAllEquip" value=false [isChecked]="this.flushInfoFormGroup.controls['isRelevantEquipment'].value == 'false'" [isDisabled]="false" [isError]="false"></ce-radio-button>
              <ce-radio-button size="large" label="Yes" id="CanSeeAllEquip" value=true [isChecked]="this.flushInfoFormGroup.controls['isRelevantEquipment'].value == 'true'" [isDisabled]="false" [isError]="false"></ce-radio-button>
            </ce-radio-group>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-lg-5">
        <p><b>Is water found in the structure?</b><a *ngIf="!this.flushInfoFormGroup.controls['isWater'].valid" class="flush-process-error"> *</a></p>
        <div class="row">
          <div class="col-12">
            <ce-radio-group size="large" [isDisabled]="false" formControlName="isWater">
              <ce-radio-button size="large" label="No" value=false id="WaterIsNotFoundInStructure" [isChecked]="this.flushInfoFormGroup.controls['isWater'].value == 'false'" [isDisabled]="false" [isError]="false"></ce-radio-button>
              <ce-radio-button size="large" label="Yes" value=true id="WaterIsFoundInStructure" [isChecked]="this.flushInfoFormGroup.controls['isWater'].value == 'true'" [isDisabled]="false" [isError]="false"></ce-radio-button>
            </ce-radio-group>
          </div>
        </div>
      </div>
    </div>
    <!-- There is water in structure -->
    <div *ngIf="flushInfoFormGroup.controls['isWater'].value == 'true'" class="form-group row">
      <div class="col-lg-5">
        <p><b>Can this structure be dewatered with a pump?</b><a *ngIf="!this.flushInfoFormGroup.controls['canDewater'].valid" class="flush-process-error"> *</a></p>
        <div class="row">
          <div class="col-12">
            <ce-radio-group size="large" [isDisabled]="false" formControlName="canDewater">
              <ce-radio-button size="large" label="No" id="CanNotDewater" value=false [isChecked]="this.flushInfoFormGroup.controls['canDewater'].value == 'false'" [isDisabled]="false" [isError]="false"></ce-radio-button>
              <ce-radio-button size="large" label="Yes" id="CanDewater" value=true [isChecked]="this.flushInfoFormGroup.controls['canDewater'].value == 'true'" [isDisabled]="false" [isError]="false"></ce-radio-button>
            </ce-radio-group>
          </div>
        </div>
      </div>
    </div>
    <!-- 'No' I cant dewater -->
    <div *ngIf="flushInfoFormGroup.controls['canDewater'].value == 'false'" class="form-group row">
      <div class="col-lg-5">
        <p><b>Why can't the structure be pumped?</b><a *ngIf="!this.flushInfoFormGroup.controls['noPumpReason'].valid" class="flush-process-error"> *</a></p>
        <app-selectbox placeholder="Structure not pumped" #noPumpReasonSelectBox formControlName="noPumpReason" [options]="this.selectBoxOptions['pumpingRestrictions']" [defaultIndex]="this.selectOptionDefaultIndex.noPumpReason"></app-selectbox>
      </div>
    </div>
    <!-- the rest of dewater -->
    <div *ngIf="flushInfoFormGroup.controls['isWater'].value == 'true'" class="form-group row">
      <div class="col-lg-5">
        <p><b>How would you describe the water found in the structure?</b><a *ngIf="!this.flushInfoFormGroup.controls['waterDesc'].valid" class="flush-process-error"> *</a></p>
        <app-selectbox placeholder="Water description" #waterDescSelectBox formControlName="waterDesc" [options]="this.selectBoxOptions['waterType']" [defaultIndex]="this.selectOptionDefaultIndex.waterDesc"></app-selectbox>
      </div>
    </div>
    <div *ngIf="flushInfoFormGroup.controls['isWater'].value == 'true'" class="form-group row">
      <div class="col-lg-5">
        <p><b>How much water is in the structure?</b><a *ngIf="!this.flushInfoFormGroup.controls['waterAmount'].valid" class="flush-process-error"> *</a></p>
        <app-selectbox placeholder="Amount of water" #waterAmountSelectBox formControlName="waterAmount" [options]="this.selectBoxOptions['waterQuantity']" [defaultIndex]="this.selectOptionDefaultIndex.waterAmount"></app-selectbox>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-lg-5">
        <p><b>Is debris or an environmental condition found in the structure?</b><a *ngIf="!this.flushInfoFormGroup.controls['isDebris'].valid" class="flush-process-error"> *</a></p>
        <div class="row">
          <div class="col-12">
            <ce-radio-group size="large" [isDisabled]="false" formControlName="isDebris">
              <ce-radio-button size="large" label="No" id="DebrisNotFoundInStructure" value=false [isChecked]="this.flushInfoFormGroup.controls['isDebris'].value == 'false'" [isDisabled]="false" [isError]="false"></ce-radio-button>
              <ce-radio-button size="large" label="Yes" id="DebrisFoundInStructure" value=true [isChecked]="this.flushInfoFormGroup.controls['isDebris'].value == 'true'" [isDisabled]="false" [isError]="false"></ce-radio-button>
            </ce-radio-group>
          </div>
        </div>
      </div>
    </div>

    <!-- why cant the structure be pumped -->
    <div *ngIf="this.flushInfoFormGroup.controls['isDebris'].value == 'true'" class="form-group row">
      <div class="col-lg-5 form-group">
        <p><b> How would you describe the debris  or an environmental condition found in the structure?</b><a *ngIf="!this.flushInfoFormGroup.controls['noDebrisPumpReason'].valid" class="flush-process-error"> *</a></p>
        <app-multi-selectbox [isValid]="!this.flushInfoFormGroup.controls['noDebrisPumpReason'].value?.includes('Oil')" errorMsg="Selecting oil as an environmental hazard will not serve as an official spill notification. Please follow current business practice with spill reporting." placeholder="Debris description/environmental condition" #noDebrisPumpReasonSelectBox formControlName="noDebrisPumpReason" [options]="this.selectBoxOptions['debrisType']" [defaultIndexes]="this.selectOptionDefaultIndex.noDebrisPumpReason"></app-multi-selectbox>
      </div>
    </div>
    <!-- Test for oil -->
    <div *ngIf="debrisReason.includes('Oil') ||
      debrisReason.includes('Grease')" class="form-group row">
      <div class="col-lg-5">
        <p><b>Have you used a diaper/oil tester to confirm the presence of oil in the structure?</b><a *ngIf="!this.flushInfoFormGroup.controls['oilPresenceTest'].valid" class="flush-process-error"> *</a></p>
        <div class="row">
          <div class="col-12">
            <ce-radio-group size="large" [isDisabled]="false" formControlName="oilPresenceTest">
              <ce-radio-button size="large" label="No" id="NotUsedTesterToConfirm" value=false [isChecked]="this.flushInfoFormGroup.controls['oilPresenceTest'].value == 'false'" [isDisabled]="false" [isError]="false"></ce-radio-button>
              <ce-radio-button size="large" label="Yes" id="UsedTesterToConfirm" value=true [isChecked]="this.flushInfoFormGroup.controls['oilPresenceTest'].value == 'true'" [isDisabled]="false" [isError]="false"></ce-radio-button>
            </ce-radio-group>            
          </div>
        </div>
      </div>
    </div>
    <!-- Rest of why cant the structure be pumped? -->
    <div *ngIf="this.flushInfoFormGroup.controls['isDebris'].value == 'true'" class="form-group row">
      <div class="col-lg-5">
        <p><b>How much debris is in the structure?</b><a *ngIf="!this.flushInfoFormGroup.controls['debrisAmount'].valid" class="flush-process-error"> *</a></p>
        <app-selectbox placeholder="Debris amount" #debrisAmountSelectBox formControlName="debrisAmount" [options]="this.selectBoxOptions['debrisQuantity']" [defaultIndex]="this.selectOptionDefaultIndex.debrisAmount"></app-selectbox>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-lg-5">
        <p><b>Is there infestation in the structure?</b><a *ngIf="!this.flushInfoFormGroup.controls['infestationType'].valid" class="flush-process-error"> *</a></p>
        <app-selectbox placeholder="Infestation description" #infestationTypeSelectBox formControlName="infestationType" [options]="this.selectBoxOptions['infestations']" [defaultIndex]="this.selectOptionDefaultIndex.infestationType"></app-selectbox>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-lg-5">
        <p><b>Are there 
          <a (click)="showPerliteModal()">perlite bags</a>
         in the structure?</b><a *ngIf="!this.flushInfoFormGroup.controls['isPearliteBags'].valid" class="flush-process-error"> *</a></p>
        <div class="row">
          <div class="col-12">
            <ce-radio-group size="large" [isDisabled]="false" formControlName="isPearliteBags">
              <ce-radio-button size="large" label="No" id="ThereAreNoPearliteBags" value=false [isChecked]="this.flushInfoFormGroup.controls['isPearliteBags'].value == 'false'" [isDisabled]="false" [isError]="false"></ce-radio-button>
              <ce-radio-button size="large" label="Yes" id="ThereArePearliteBags" value=true [isChecked]="this.flushInfoFormGroup.controls['isPearliteBags'].value == 'true'" [isDisabled]="false" [isError]="false"></ce-radio-button>
            </ce-radio-group>       
          </div>
          <div class="col-6">
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div *ngIf="flushInfoFormGroup.controls['isPearliteBags'].value == 'true'" class="col-lg-5">
        <p><b>Have the perlite bags been comprised?</b><a *ngIf="!this.flushInfoFormGroup.controls['isPearliteBagsCompromised'].valid" class="flush-process-error"> *</a></p>
        <div class="row">
          <div class="col-12">
            <ce-radio-group size="large" [isDisabled]="false" formControlName="isPearliteBagsCompromised">
              <ce-radio-button size="large" label="No" id="BagsHaveNotBeenComprised" value=false [isChecked]="this.flushInfoFormGroup.controls['isPearliteBagsCompromised'].value == 'false'" [isDisabled]="false" [isError]="false"></ce-radio-button>
              <ce-radio-button size="large" label="Yes" id="BagsHaveBeenComprised" value=true [isChecked]="this.flushInfoFormGroup.controls['isPearliteBagsCompromised'].value == 'true'" [isDisabled]="false" [isError]="false"></ce-radio-button>
            </ce-radio-group>   
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-lg-5">
        <p><b>Please add any additional information on your visual inspection?</b><a *ngIf="!this.flushInfoFormGroup.controls['additionalDesc'].valid" class="flush-process-error"> *</a></p>
        <ce-input inputType="textarea" label="Structure description" formControlName="additionalDesc"></ce-input>
      </div>
    </div>
  </form>
</div>

<app-launch-camera-modal #launchCameraModalComponent></app-launch-camera-modal>
<app-modal [showModal]="showPerliteBagModal" [showClose]='true' (closeDialogClick)="showPerliteBagModal = false" [showErrorIcon]="false"  modalHeading='What are Perlite Bags?'>
  <div class="container">
    <div class="form-group row">
      <div class="col">
        <p>Structure Fill Pillows placed in secondary underground structures to limit the amount of 
          accumulated combustible gases. Please refer to EO-10378 for more information.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-auto">
        <ce-button size='large' label="Close" type="primary" (clickEvent)="showPerliteBagModal = false"></ce-button>
      </div>
    </div>
  </div>
</app-modal>