import { Pipe, PipeTransform } from '@angular/core';
/*
 * Splits a string into two and returns either the left or the right
 *
 * Usage:
 *  string  | splitDash:index
 *  index: 0 (left) | 1 (right)
 *
 * Example:
 *   {{ "test-this" | splitDash:0 }} => "test"
 *
 */
@Pipe({ name: 'splitDash' })
export class SplitDashPipe implements PipeTransform {
  transform(splitting: string, index?: number): string {
    if (splitting?.split('-').length > 2 && index === 1) {
      // If we only want the right hand side of the first '-' join the other side of the dash as one string
      return [...splitting?.split('-')].slice(1).join('-') ?? splitting;
    } else {
      return splitting?.split('-')[index] ?? splitting;
    }
  }
}
