<div class="map-wrapper" [ngClass]="{'loading': isLoading}">
    <div #mapViewNode></div>
    <i *ngIf="toggle != 'base' " class="map-btn-1 icon icon-filter" [ngClass]="{'active': toggle === 'legend'}" (click)="toggleLegendWidget()"></i>
    <i class="map-btn-2 icon icon-map" [ngClass]="{'active': toggle !== 'base'}" (click)="toggleConEdData()"></i>
</div>
<div *ngIf="isSupervisor && !isLoading" class="map-legend">
    <div class="row">
        <div class="col-4 col-lg-3 legend-option">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                <circle cx="12" cy="12" r="8" fill="rgba(0, 136, 43, 1)" />
            </svg>
            Scheduled
        </div>
        <div class="col-4 col-lg-3 legend-option">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                <circle cx="12" cy="12" r="8" fill="rgba(255, 142, 44, 1)" />
            </svg>
            Prior Shift
        </div>
        <div class="col-4 col-lg-3 legend-option">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                <circle cx="12" cy="12" r="8" fill="rgba(217, 57, 39, 1)" />
            </svg>
            New Requests
        </div>
    </div>
</div>