import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-launch-camera-modal',
  templateUrl: './launch-camera-modal.component.html',
  styleUrls: ['./launch-camera-modal.component.scss']
})
export class LaunchCameraModalComponent implements OnInit {

  showLaunchCameraModal = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  launchCamera() {
    this.router.navigate(['flush-information', 'photos']);
  }

  // Use this when we want to navigate to photos page, so the user sees modal once per session
  public takePhoto() {
    let sessionVal = sessionStorage.getItem('showLaunchCameraModal');
    if(!sessionVal) {
      this.showLaunchCameraModal = true;
      sessionStorage.setItem('showLaunchCameraModal', 'true');
    } else {
      this.launchCamera();
    }
    
  }
}
