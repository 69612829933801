<div class="view" [ngClass]="{'contain-to-viewport': isUsingViewPort}">
  <ce-video-player
  videoUrl="https://mediadevdfacthermal-usea.streaming.media.azure.net/177c0f4c-73b7-4d4c-929f-4d9f2ba4c899/input-7f212302-94ea-40d5-9c0d-af.ism/manifest(format=mpd-time-csf)"
  type="application/vnd.ms-sstr+xml"></ce-video-player>
    <h1 class="style-rule-header">Flush App Style Guide</h1>
    <h4 class="style-rule-header">Typography and Vertical Rhythm</h4>
    <span class='icon-error modal-heading-icon modal-heading-error'></span>
    <div class="style-rule">
        <p class="hero">Hero</p>
        <h1>Heading h1</h1>
        <h2>Heading h2</h2>
        <h3>Heading h3</h3>
        <h4>Heading h4</h4>
        <h4 class="normal">Heading h4 Normal</h4>
        <p>BODY 1: Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ea, ipsum repudiandae? Velit dolores, quisquam consequuntur odio possimus eligendi culpa! Ipsam ad illo similique inventore, accusantium commodi odio est delectus quasi.</p>
        <p><b>BODY 1 BOLD: Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rem suscipit aliquid animi placeat quasi quod magni blanditiis laudantium odio impedit delectus iusto doloribus in numquam ab provident, vero porro recusandae!</b></p>
        <p class="body2">
            BODY 2: Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit quisquam officia laudantium, iure facere temporibus, similique enim exercitationem laboriosam neque ab aspernatur harum voluptatum velit autem, non ex! Error, voluptates.
        </p>
        <small>Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro modi doloremque deserunt minus illo labore neque, itaque iure possimus ad cupiditate placeat aspernatur eaque aliquam ipsa explicabo laudantium voluptates error?</small>
    </div>
    <h4 class="style-rule-header">Buttons</h4>
    <div class="style-rule">
        <div class="form-group row justify-content-center">
            <div class="col-auto">
              <ce-button label="Primary" type="primary"></ce-button>
            </div>
        </div>
        <div class="form-group row justify-content-center">
          <div class="col-auto">
              <ce-button label="Primary Disabled" type="primary" disabled='true'></ce-button>
            </div>
        </div>
        <div class="form-group row justify-content-center">
          <div class="col-auto">
              <ce-button label="Submit" type="secondary"></ce-button>
            </div>
        </div>
        <div class="form-group row justify-content-center">
          <div class="col-auto">
              <ce-button label="Primary Outline" type="secondary"></ce-button>
            </div>
        </div>
        <div class="form-group row justify-content-center">
          <div class="col-auto">
              <ce-button label="Danger" type="danger"></ce-button>
            </div>
        </div>
        <div class="form-group row justify-content-center">
          <div class="col-auto">
              <ce-button label="Danger Outline" type="danger-outline"></ce-button>
            </div>
        </div>
        <div class="form-group row justify-content-center">
          <div class="col-auto">
            <ce-button label="Link" type="link"></ce-button>
          </div>
        </div>
        <div class="form-group row justify-content-center">
          <div class="col-auto">
            <ce-button label="Danger Link" type="danger-link"></ce-button>
          </div>
        </div>
        <div class="form-group row justify-content-center">
          <div class="col-auto">
            <ce-button label="Text" type="text"></ce-button>
          </div>
        </div>
    </div>
    <form [formGroup]="stylesForm">
      <h4 class="style-rule-header">Text Box</h4>
      <div class="style-rule">
          <div class="row">
            <div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
              <p><b>Default</b></p>
            </div>
          </div>
          <div class="form-group row">
              <div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
                  <ce-input formControlName="textbox"></ce-input>
              </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
              <p><b>Error</b></p>
            </div>
          </div>
          <div class="form-group row">
              <div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
                  <ce-input formControlName="textboxError"  [showError]="true" [errorMessage]="'This is an exampleThis is an exampleThis is an exampleThis is an exampleThis is an exampleThis is an exampleThis is an exampleThis is an exampleThis is an exampleThis is an exampleThis is an exampleThis is an exampleThis is an exampleThis is an exampleThis is an exampleThis is an example'"></ce-input>
              </div>
          </div>
      </div>
      <h4 class="style-rule-header">Select Box</h4>
      <div class="style-rule">
          <div class="form-group row">
            <div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
              <app-selectbox formControlName="selectbox" [options]="[{value: '1', option: 'Option 1'}, {value:'2', option: 'Option 2'}]"></app-selectbox>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
              <p><b>Error</b></p>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
                <app-selectbox formControlName="selectbox"  [isValid]="false" [errorMsg]="'Error Message'" [options]="[{value: '1', option: 'Option 1'}, {value:'2', option: 'Option 2'}]"></app-selectbox>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
              <p><b>Completed</b></p>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
                <app-selectbox formControlName="selectbox"  [isValid]="true" [isCompleted]="true"  [icon]="'icon icon-caret-button'" [options]="[{value: '1', option: 'Option 1'}, {value:'2', option: 'Option 2'}]"></app-selectbox>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
              <p><b>Time Example</b></p>
            </div>
          </div>
          <div class="row">
            <div class="col-4 col-md-2 offset-md-3">
                <app-selectbox formControlName="selectbox" placeholder="Hour"  [isValid]="true" [isCompleted]="true"  [icon]="'icon icon-caret-button'" [options]="[
                {value: '1', option: '1'},
                {value: '2', option: '2'},
                {value: '3', option: '3'},
                {value: '4', option: '4'},
                {value: '5', option: '5'},
                {value: '6', option: '6'},
                {value: '7', option: '7'},
                {value: '8', option: '8'},
                {value: '9', option: '9'},
                {value: '10', option: '10'},
                {value: '11', option: '11'},
                {value: '12', option: '12'}
                ]"></app-selectbox>
            </div>
            <div class="col-4 col-md-2">
                <app-selectbox formControlName="selectbox" placeholder="Minutes"  [isValid]="true" [isCompleted]="true"  [icon]="'icon icon-caret-button'" [options]="[
                {value: '00', option: '00'},
                {value: '15', option: '15'},
                {value: '30', option: '30'},
                {value: '45', option: '45'}]"></app-selectbox>
            </div>
            <div class="col-4 col-md-2">
              <app-selectbox formControlName="selectbox" placeholder="" [isValid]="true" [isCompleted]="true"  [icon]="'icon icon-caret-button'" [options]="[
              {value: 'AM', option: 'AM'},
              {value: 'PM', option: 'PM'}]"></app-selectbox>
            </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
            <p><b>Multiple Select</b></p>
          </div>
          <div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
            <app-multi-selectbox formControlName="multiSelectbox" placeholder="Selections" [options]="[
            {value: '1', option: 'one'},
              {value: '2', option: 'two'},
              {value: '3', option: 'three'},
              {value: '4', option: 'four'},
              {value: '5', option: 'five'},
              {value: '6', option: 'six'},
              {value: '7', option: 'seven'}]"></app-multi-selectbox>
          </div>
        </div>
      </div>
      <h4 class="style-rule-header">Tile</h4>
      <div class="style-rule">
        <div class="form-group row d-flex justify-content-center">
          <div class="col-auto">
            <app-tile formControlName="tile" value="True">
              <div class="p-4 pr-5">
                <p class="mb-1">Service Box</p>
                <h4 class="mb-1">SB34700</h4>
                <p class="mb-1">6 Canal St.</p>
              </div>
            </app-tile>
          </div>
        </div>
      </div>
      <h4 class="style-rule-header">Checkbox</h4>
      <div class="style-rule">
        <div class="form-group row">
          <div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
            <app-checkbox formControlName="checkbox" value="dummyText" text="Lorem, ipsum dolor" formMsg="Form Message"></app-checkbox>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
            <app-checkbox text="Checked + Disabled" [isChecked]="true" [isDisabled]="true" [isError]="false"></app-checkbox>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
            <app-checkbox text="Checked + Enabled" [isChecked]="true" [isDisabled]="false" [isError]="false"></app-checkbox>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
            <app-checkbox text="Unchecked + Disabled" [isChecked]="false" [isDisabled]="true" [isError]="false"></app-checkbox>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
            <app-checkbox text="Unchecked + Enabled" [isChecked]="false" [isDisabled]="false" [isError]="false"></app-checkbox>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
            <app-checkbox text="Error" [isChecked]="false" [isDisabled]="true" [isError]="true" errorMsg="Error Message"></app-checkbox>
          </div>
        </div>
      </div>
      <h4 class="style-rule-header">Radio Button</h4>
      <div class="style-rule">
        <div class="form-group row">
          <div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
            <ce-radio-button formControlName="radiobtn" value="dummyText" text="Lorem, ipsum dolor" formMsg="Form Message"></ce-radio-button>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
            <ce-radio-button label="Checked + Disabled" [isChecked]="true" [isDisabled]="true" [isError]="false"></ce-radio-button>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
            <ce-radio-button label="Checked + Enabled" [isChecked]="true" [isDisabled]="false" [isError]="false"></ce-radio-button>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
            <ce-radio-button label="Unchecked + Disabled" [isChecked]="false" [isDisabled]="true" [isError]="false"></ce-radio-button>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
            <ce-radio-button label="Unchecked + Enabled" [isChecked]="false" [isDisabled]="false" [isError]="false"></ce-radio-button>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
            <ce-radio-button label="Error" [isChecked]="false" [isDisabled]="true" [isError]="true" errorMsg="Error Message"></ce-radio-button>
          </div>
        </div>
      </div>
    </form>
    <h4 class="style-rule-header">Camera</h4>
    <div class="style-rule">
      <div class="form-group row justify-content-center">
        <div class="col-auto">
            <ce-button label="Open Camera" type="primary" (click)="isCameraComponentOn = true; isUsingViewPort = true" ></ce-button>
        </div>
        <app-camera *ngIf="isCameraComponentOn" (emitPhotosAdded)="onPhotosAdded($event)" photoFacing="Test Photos" >
        </app-camera>
      </div>
    </div>
    <h4 class="style-rule-header">Video</h4>
    <div class="style-rule">
      <div class="form-group row justify-content-center">
        <div class="container">
          <ce-media-gallery [videos]="uploadedMedias" [photos]="uploadedPhoto" [hasPlay]="true" [hasVideoEdit]="false"
            [hasPhotoDelete]="true" [hasPhotoEdit]="false" (mediaData)="onMediaSelected($event)"
            (deleteClick)="deleteClick($event)" (mediaUpdate)="mediaUpdate($event)" (viewClick)="onViewClick($event)"
            icon="icon-camera">
            <ng-template ceMediaIconActionTemplate (clickEvent)="clickEvent()">
              <i class="icon-calendar"></i>
            </ng-template>
            <ng-template ceMediaTemplate let-data>
              <strong>{{data?.title}}</strong>
              <div>{{data?.createdOn | date}}</div>
            </ng-template>
            <ng-template cePhotoActionTemplate (clickEvent)="clickEvent()">
              <div class="logo-take-media">
                <i class="icon-camera"></i>
              </div>
              <div class="cap-take-photo">Camera</div>
            </ng-template>
            <ng-template ceVideoActionTemplate (clickEvent)="clickEvent()">
              <div class="logo-take-media">
                <i class="icon-social-share"></i>
              </div>
              <div class="cap-take-photo">Upload</div>
            </ng-template>
          </ce-media-gallery>
        </div>
    
      </div>
      <div class="form-group row justify-content-center">
        <ce-video-player *ngIf="!!testVideo" [videoUrl]="testVideo" [type]="testFileType"></ce-video-player>
      </div>
      <div class="form-group row justify-content-center">
        <ce-video-upload>
        
          <ng-template ceVideoUploadActionTemplate (fileInfo)="showImageInformation($event)" (fileType)="setFileType($event)">
        
            <div class="logo-take-photo">
        
              <i class="icon-social-share"></i>
        
            </div>
        
            <div class="cap-take-photo">Upload</div>
        
          </ng-template>
        
        </ce-video-upload>
      </div>
    </div>
    <h4 class="style-rule-header"> Pop Up Modal </h4>
    <div class="style-rule">
      <div class="form-group row justify-content-center">
        <div class="col-auto">
            <ce-button label="Open Modal" type="primary" (click) = "isModalDisplay = true" ></ce-button>
        </div>
        <app-modal [showModal]="isModalDisplay" modalHeading="Sample Heading" (closeDialogClick)="isModalDisplay=false;" [showSuccessIcon]="true" [showClose]='true'>
          <div class="container">
            <div class="row">
              <div class="col mb-5">
                <p>Example of modal body</p>
              </div>
            </div>
            <div class="row">
              <div class="col-auto style-guide-close-modal-btn">
                <ce-button label='Close' type="secondary" (clickEvent)="isModalDisplay=false;"></ce-button>
              </div>
              <div class="col-auto">
                <ce-button label='Save' (clickEvent)="isModalDisplay=false;"></ce-button>
              </div>
            </div>
          </div>
        </app-modal>
      </div>
    </div>

    <div class="style-rule">
      <div class="form-group no-gutters">
        <div class="col">
          <app-flush-grid [isFlushGridSimple]="true"
          [data]="styleGuideGridData" [columns]="styleGuideGridCols" [displayActionsHeader]="true" [actions]="[{header: 'Customer Owned', caption: '', type: 'checkbox'}, {header: ' ', caption: '', type: 'delete'}]" (actionClicked)="relayAction($event)" [isSelectable]="false"></app-flush-grid>
        </div>
      </div>
    </div>

    <div class="style-rule">
      <div class="form-group no-gutters">
        <div class="col">
          <app-flush-grid [isFlushGridSimple]="true" [allowVerticalDataTable]="true"
          [data]="styleGuideGridData" [columns]="styleGuideGridCols" [isSelectable]="false" [actions]="[]"></app-flush-grid>
        </div>
      </div>
    </div>
    
    <div class="style-rule">
      <div class="form-group no-gutters">
        <div class="col">
          <app-details-box header="This is header">
            <p>I am content</p>
            <p>More content</p>
          </app-details-box>
        </div>
      </div>
    </div>

    <h4>Sortable</h4>
    <div class="style-rule">
      <div class="form-group no-gutters">
        <div class="col">
          <app-sortable [items]="items" id='example' zones="['example']">
            <ng-template #content let-itemData let-i="idx">
              <div class="col item-header" [ngClass]="{'item-cta': itemData.header === '' }">
                <ng-container *ngIf="itemData.header != ''; else icon">
                    <div class="header-item">
                        <small>{{itemData.header}}</small>
                    </div>
                    <div class="item-content">
                        <div class="content-item">
                            <ng-container *ngFor="let row of itemData.value">
                                <ng-container *ngIf="(row | sortableType) === 'text'">
                                    <b>{{row}}</b>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
                <ng-template #icon>
                    <div class="content-item">
                        <ng-container *ngFor="let row of itemData.value; let rowIdx = index;">
                            <ng-container *ngIf="(row | sortableType) === 'icon'; else cta">
                                <span class="line-connect"></span>
                                <i [class]="'icon ' + row.icon" [ngStyle]="{'color': row.iconColor}" *ngIf="!row.isClickable"></i>
                                <button
                                *ngIf="row.isClickable"
                                (click)="iconClicked(row,rowIdx)" 
                                (touchstart)="iconClicked(row, rowIdx)">
                                <i [class]="'icon ' + row.icon + ' clickable'" [ngStyle]="{'color': row.iconColor}" ></i>
                            </button>
                            </ng-container>
                            <ng-template #cta>
                                <div class="arrow-button">
                                    <button type="button" 
                                    (click)="ctaClick(row, rowIdx)"
                                    (touchstart)="ctaClick(row, rowIdx)"
                                    ><i class="material-icons tile-action">chevron_right</i></button>
                                </div>
                            </ng-template>
                        </ng-container>
                    </div>
                </ng-template>
              </div>
            </ng-template>
          </app-sortable>
          <!-- popup-modal -->
          <ce-modal [(visible)]="showModal" [title]="!!modal ? modal.title : ''" (visiblilityChange)="changeVisibility($event)">
            <ce-modal-body *ngIf="showModal">
                <p>{{modal.body}}</p>
                <ng-container *ngIf="modal.createTable">
                    <app-flush-grid [isFlushGridSimple]="true" [allowVerticalDataTable]="true"
                  [data]="modal.tableData" [columns]="modal.tableColumns" [isSelectable]="false" [actions]="[]"></app-flush-grid>
                </ng-container>
            </ce-modal-body>
            <ce-modal-footer style="display: flex;">
                <ce-button type="secondary" label="Close" size="medium" (clickEvent)="showModal = false"></ce-button> 
            </ce-modal-footer>
          </ce-modal>
        </div>
      </div>
    </div>

    <div class="request-card container-fluid card">
      <app-top-tracker [trackerArr]="topTracker"></app-top-tracker>
      <div class="row">
        <div class="col" [ngStyle]="{'min-height': '550px'}">
          <div *ngIf="isOne">
            <p>one works!</p>
          </div>
          <div *ngIf="isTwo">
            <p>two works!</p>
          </div>
          <div *ngIf="isThree">
            <p>three works!</p>
          </div>
        </div>
      </div>
    </div>
</div>
