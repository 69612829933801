import { Component, HostListener, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { Store, Select } from '@ngxs/store';
import { StylerService } from 'src/app/services/styler/styler.service';
import { BaseComponent } from 'src/app/common/base/base.component';
import { BaseService } from 'src/app/services/base/base.service';
import { FormValidationService } from 'src/app/services/forms/form-validation.service';
import { AppState } from 'src/app/app-state/app.state';
import { DetailsBoxComponent } from 'src/app/common/details-box/details-box.component';
import { Photo } from 'src/app/models/photo';
import { combineLatest, Observable, of } from 'rxjs';
import { WorkRequestDetail } from 'src/app/models/work-request-detail';
import { SourceWRInformation } from 'src/app/models/sourceWrInformation';
import { Structure } from 'src/app/models/structure.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StructureHistoryRequest, StructureHistoryResponse } from 'src/app/models/structure-history';
import * as moment from 'moment';
import * as _ from 'lodash';
import { TimestampPipe } from 'src/app/common/pipes/timestamp.pipe';
import { PhotoService } from 'src/app/services/photo/photo.service';
import { StructureInformation } from 'src/app/models/structureInformation';
import { WorkRequest } from 'src/app/interfaces/work-request';
import { CcRequestsService } from 'src/app/services/cc-requests/cc-requests.service';
import { MapperService } from 'src/app/services/mapper/mapper.service';
import { AccessInfo } from 'src/app/models/construction-crew/access-info';
import { FlushInfo } from 'src/app/models/construction-crew/flush-info';
import { ParkingRestriction } from 'src/app/models/parking-restriction.model';
import { MapService } from 'src/app/services/map/map.service';
import { Alert } from '@ce-lib/alert';
export class ConstructionJobDetail {
  sourceWrNumber: string;
  workRequestNo: string;
  workRequestName: string;
  priorityLvl: string;
  job: string;
  workRequestDesc: string;
  entryDate: string;
}
@Component({
  selector: 'app-job-details-request',
  templateUrl: './job-details-request.component.html',
  providers: [TimestampPipe]
})
export class JobDetailsRequestComponent extends BaseComponent implements OnInit {
  constructionJobDetailDataGroup = new ConstructionJobDetail();
  constructionJobDetailCols = [];
  @ViewChildren(DetailsBoxComponent) children: QueryList<DetailsBoxComponent>;
  photos: Photo[] = [];
  selectedPhoto: Photo = null;
  showReview: boolean = false;
  sourceWrInfo: SourceWRInformation;
  structureInfoData: Structure[]  = [];
  structureInfoCols = [];
  structureInfoHistory: StructureHistoryResponse[] = [];
  editStructureForm: FormGroup;
  addressHistoryCols = [
    { field: 'dateSaved', title: 'Date Saved' },
    { field: 'crewName', title: 'Crew Member' },
    { field: 'address', title: 'Front Address' },
  ]
  accessInfoData: ParkingRestriction[] = [];
  accessInfoCols = [];

  flushInfoData = [];
  flushInfoCols = [];
  armWrNo: string;
  workComponentId: string;
  facilityGlobalId: string;
  flushWorkRequestId: string | null;
  workRequestNo: string;
  workRequestName: string;
  entryDate: string;
  priorityLvl: string;
  workRequestDesc: string;
  isLoading: boolean;
  showNavItems = false;
  wmsComments = [];
  wmsCommentsCols = [
    { field: 'createdOn', title: 'Date Saved' },
    { field: 'userName', title: 'Crew Member' },
    { field: 'description', title: 'Description of Work' }
  ];
  isMobile: boolean;
  showMapModal = false; //Flag to show/hide the modal popup for map
  assetTag: string; 
  mapLoaded = false; //Variable used to check whether the map is loaded or not before triggering any future events.
  flushUserRole: string = ''; 

  @Select(AppState.getWorkRequestGlobalId)
  getWorkRequestGlobalId$: Observable<string>;
  @Select(AppState.getFacilityGlobalId)
  getFacilityGlobalId$: Observable<string>;
  @Select(AppState.getFlushWorkRequestId)
  getFlushWorkRequestId$: Observable<string>;
  @Select(AppState.getWorkRequestDetail)
  getWorkRequestDetail$: Observable<WorkRequestDetail>;
  @Select(AppState.getFlushUserRole) 
  getFlushUserRole$: Observable<string>;
  isEditBtnClicked: boolean;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private styler: StylerService,
    private baseService: BaseService,
    private formValidationService: FormValidationService,
    private timestampPipe: TimestampPipe,
    private photoService: PhotoService,
    private ccService: CcRequestsService,
    private mapperService: MapperService,
    private mapService: MapService,
    private alert: Alert
  ) {
    super(styler);
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    this.adjustToScreenSize(window.innerWidth);
  }

  selectPhotoReview(photo) {
    this.selectedPhoto = photo; 
    this.showReview = true; 
    this.styler.isPhotoReview.next(true);
  }
  closeReview() {
    this.selectedPhoto = null;
    this.showReview = false;
    this.styler.isPhotoReview.next(false);
  }

  getMostRecentObjectFromArray(objArr: Array<any>, dateProperty: string) : any {
    if(!objArr || objArr?.length <= 0){
      // Falsy
      return null;
    }
    return objArr?.reduce((mostRecentObj, currentObj) => (new Date(currentObj[dateProperty]) > new Date(mostRecentObj[dateProperty])) ? currentObj : mostRecentObj);
  }
  nextClick() {
    this.router.navigate(['start-job', 'photos']);
  }
  handleMobileNav() {
    this.showNavItems = !this.showNavItems;
  }
  headerClicked(event) {
    this.children.forEach((element) => {
      if (element.header !== event && element.showItems) {
        element.showItems = false;
        element.animationType = 'closed';
      }
    });
  }
  handleNavigation(route) {
    window.scrollTo(0, 0);
    this.router.navigate(route);
    this.showNavItems = false;
  }
  ngOnInit(): void {
    this.adjustToScreenSize(window.innerWidth);
    this.getFlushUserRole$.subscribe((res) => {
      this.flushUserRole = res;
    });

    combineLatest([
      this.activatedRoute.paramMap,
      this.getWorkRequestGlobalId$,
      this.getFacilityGlobalId$,
      this.getFlushWorkRequestId$,
      this.store.select(store => store.AppState.workComponentGlobalId),
      this.getWorkRequestDetail$
    ])
    .pipe(
      tap(([params, wrGlobalId, facilityGlobalId, flushWorkRequestId, wcId,res]) => {
        this.armWrNo = params.get('workRequestGlobalId') ?? wrGlobalId;
        this.facilityGlobalId = params.get('facilityGlobalId') ?? facilityGlobalId;
        this.flushWorkRequestId = params.get('flushWorkRequestId') ?? flushWorkRequestId;
        this.workComponentId = wcId;
        this.constructionJobDetailDataGroup = {
          sourceWrNumber: res.sourceWrNumber,
          workRequestNo: res.workRequestNo,
          workRequestName: res.workRequestName,
          priorityLvl: res.priorityLevel,
          job: res.job,
          workRequestDesc: res.workRequestName,
          entryDate: res.entryDate,
        };
        this.isLoading = true;
      }),
      switchMap(() => this.store.select(store => store.AppState.assignedWRDetails as WorkRequest[])),
      map(reqs => reqs.filter(req => req.workRequestGlobalID.includes(this.armWrNo) &&  req.workComponentGlobalID.includes(this.workComponentId)).reduce(res => res)),     
      catchError(err => of(null)),
      take(1)
    ).subscribe((res: WorkRequest | null) => {
      if (res) {
        const structures = this.store.selectSnapshot(store => store.AppState?.structureInfo as StructureInformation)?.structures;
        this.structureInfoData = structures.map(i => i);
        this.sourceWrInfo = {
          accessInfoModel: (typeof res.accessInfo === 'object') ? res.accessInfo as AccessInfo : null,
          flushInfoModel: (typeof res.flushInfo === 'object') ? res.flushInfo as FlushInfo : null,
          structureInformation: null,
          flushJobDetails: null,
          isSupervisorApproved: false,
          accessInfo: null,
          flushInfo: null,
          structureInfo: null,
          crmsAppointmentId: res.crmsAppointmentId
        }
        this.wmsComments = res.wmsComments.map(c => c);
        this.photos = (res.images ?? []).filter(p => p.workRequestNumber != res.workRequestNo).map(p => this.photoService.MapFlushPhotoToPhoto(p));
        this.entryDate = res.entryDate;
        this.setColsAndData();
        this.formValidationService.$flushInformationValid.next(true);
      }
      this.isLoading = false;
    });
    this.editStructureForm = new FormGroup({
      currentStructure: new FormControl(''),
      address: new FormControl('', Validators.required),
    });
  }

  private adjustToScreenSize(innerWidth) {
    this.isMobile = innerWidth <= 768 ? true : false;
  }

  setColsAndData() {
    this.structureInfoCols = [
      // { field: 'facilityID', title: 'Facility ID' },
      { field: 'type', title: 'Type' },
      { field: 'structureId', title: 'Asset Tag', actionType: this.flushUserRole.toLowerCase() !== "cn" ? 'anchor' : '', action:  this.flushUserRole.toLowerCase() !== "cn" ? 'assetTag': '' },
      { field: 'borough', title: 'Borough' },
      { field: 'frontAddress', title: 'Front Address' },
      { field: 'isCustomerOwned', title: 'Customer Owned' },
    ];

    this.accessInfoData = [].concat.apply([], this.sourceWrInfo?.accessInfoModel?.parkingRestrictions?.map((val) => {
      // Map all boolean values and then build out string of days
      const days = Object.keys(val)
        .map((v) => (typeof val[v] === 'boolean' && val[v] ? v.charAt(0).toUpperCase() + v.slice(1) + ' ' : ''))
        .join('')
        .trim();
      return new ParkingRestriction(
        val.assetTag,
        val.restriction,
        days,
        moment(val.parkingRestrictionStartTime, 'HHmm').format('h mm A'),
        moment(val.parkingRestrictionEndTime, 'HHmm').format('h mm A')
      );
    }));
    this.accessInfoCols = [
      { field: 'restriction', title: 'Restriction' },
      { field: 'days', title: 'Days' },
      { field: 'start_time', title: 'Start time' },
      { field: 'end_time', title: 'End time' },
    ];

    this.flushInfoCols = [{ field: 'comments', title: 'Comments' }];
  }

  mapLoadedEvent(status: boolean) {
    document.querySelector('.map-wrapper').addEventListener('touchmove', function(event) {
      // Prevent page scrolling if touch device is on
      event.preventDefault();
    })
    this.mapLoaded = true;
  }

  showMapCoordinates(dataItem) {
    this.showMapModal = true;
    if (this.mapLoaded) {
      this.assetTag = dataItem?.structureId;

      const longitude = dataItem?.longitude;
      const latitude = dataItem?.latitude;

      const undefinedCoords = !longitude || !latitude;
      const invalidCoords = (lat, lng) => { return Math.abs(lat) > 85 || Math.abs(lng) > 180 }
      
      console.log('Longtitute: ', longitude, ' Latitute: ', latitude );
      if (!undefinedCoords && !invalidCoords(latitude, longitude)) {
        document.getElementById('main-navigation').scrollIntoView();
        
        this.mapService.queueNewPan(parseFloat(longitude), parseFloat(latitude));
      } else {
        this.alert.urgent('Map coordinates not found: This request is missing coordinates to show on the map. If no address is provided, reference the Structure Number.', 'Dismiss')
      }
    }else{
      setTimeout(this.showMapCoordinates.bind(this, dataItem), 2000);
    }
  }

  relayAction(item) {
    const ind = this.structureInfoData.findIndex((el) => el.facilityId === item.value.facilityId);

    switch (item.action) {
      case 'assetTag':
        this.showMapCoordinates(item.value);
        break;
      case 'edit':
        let currentStructure: Structure = this.structureInfoData[ind];

        //Update editStructureForm to current structure
        this.editStructureForm.controls.currentStructure.setValue(currentStructure);
        this.editStructureForm.controls.address.setValue(currentStructure.frontAddress);

        // Before showinhg edit modal, show history of changes
        this.baseService.getStructureHistory(
          this.constructionJobDetailDataGroup.sourceWrNumber,
          currentStructure.structureId
        ).pipe(
          tap(s => {
            this.structureInfoHistory = s.map(struct => {
              return {
                ...struct,
                dateSaved: moment(struct.dateSaved).format('MM/DD/YYYY')
              }
            }).slice(-3);
          })
        ).subscribe(res => {
          this.editStructure();
        });
        break;
    }
  }
  editStructure() {
    this.isEditBtnClicked = true;
  }
  closeEditStructure() {
    this.editStructureForm.get('address').reset();
    this.isEditBtnClicked = false;
  }
  saveEditStructure() {
    // Set new address to selected structure
    let currentStructure: Structure = Object.assign({}, this.editStructureForm.controls.currentStructure.value);
    currentStructure = {
      ...currentStructure,
      frontAddress: this.editStructureForm.controls.address.value,
      isManual: true
    };

    // PUT currentStructure
    this.ccService.editStructureInfo(this.mapperService.mapStructureToStructureInfo(currentStructure, this.constructionJobDetailDataGroup.sourceWrNumber, this.constructionJobDetailDataGroup.workRequestNo, this.workComponentId))
    .subscribe(res => {
      console.log('SUCCESSFULLY PUT STRUCTURE');
      // Update structure global reference
      const ind = this.structureInfoData.findIndex((el) => el.facilityId === currentStructure.facilityId);
      this.structureInfoData[ind] = currentStructure;
      
      // Close Modal
      this.closeEditStructure();
    })
  }
}
