export class JobDetail {
  jobPriorityId: string;
  jobPriorityDesc: string;
  jobPrioritySelectBoxIndex: number;
  jobSubtype: string; // acutal ID value is being passed
  jobSubtypeName: string;
  jobSubtypeSelectBoxIndex: number;
  jobDescription: string;
  isValid: boolean;
  id: number;
  constructor(
    jobPriorityId: string,
    jobPriorityDesc: string,
    jobPrioritySelectBoxIndex: number,
    jobSubtype: string,
    jobSubtypeName: string,
    jobSubtypeSelectBoxIndex: number,
    jobDescription: string,
    isValid: boolean,
    id: number
  ) {
    this.jobPriorityId = jobPriorityId;
    this.jobPriorityDesc = jobPriorityDesc;
    this.jobPrioritySelectBoxIndex = jobPrioritySelectBoxIndex;
    this.jobSubtype = jobSubtype;
    this.jobSubtypeSelectBoxIndex = jobSubtypeSelectBoxIndex;
    this.jobSubtypeName = jobSubtypeName;
    this.isValid = isValid;
    this.jobDescription = jobDescription;
    this.id = id
  }
}
