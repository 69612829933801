import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, ChangeDetectorRef, ApplicationRef, Renderer2, HostListener } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { AuthService } from './services/auth/auth.service';
import { BaseService } from './services/base/base.service';
import { StylerService } from './services/styler/styler.service';
import { InternetCheckerService } from './services/internet-checker/internet-checker.service';
import { Select, Store } from '@ngxs/store';
import { AddUserInfo, DeleteUserInfo } from 'src/app/app-state/actions/user-info.actions';
import { catchError, concatMap, filter, first, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AddCrewCode, AddFlushUserRole } from './app-state/actions/work-request.actions';
import { combineLatest, concat, fromEvent, interval, Observable, of, Subject, throwError } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { AppState } from './app-state/app.state';
import { ContractorAuthService } from './services/auth/contractor-auth.service';
import { BannerService } from './services/banner/banner.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { MasterDataService } from './services/master-data/master-data.service';
import { Dashboard } from './app-state/actions/dashboard-work-requests.actions';
import { AssociatedWorkBody } from './interfaces/associated-work-body';
import { UserInfo } from './interfaces/user-info';
import { LoggingService } from './services/logging/logging.service';
import { environment } from 'src/environments/environment';
import { Alert } from '@ce-lib/alert';
import { CcRequestsService } from './services/cc-requests/cc-requests.service';
import { DeleteJobDetails } from './app-state/actions/job-details.actions';
import { DeleteAccessInformation } from './app-state/actions/access-info.actions';
import { DeleteFlushInformation } from './app-state/actions/flush-process-info.actions';
import { DeleteStructureInformation } from './app-state/actions/structure-info.actions';
import { DeleteStartJobPhoto, DeleteWorkDescription, DeleteWorkRequestDetail } from './app-state/actions/start-job.actions';
import { Photo } from './models/photo';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'df-flush-app';
  isLoggedIn = false;
  openModal = false;
  openInstallPromote = false;
  doNotInstall = false;
  deferredPrompt;
  isOnline = true;
  observer = new Subject<boolean>();
  sessionStream: Observable<string>;
  sessionTimeout: boolean = false;
  tick = 120;
  interval = 60;
  @ViewChild('installButton') installButton: ElementRef;
  sessionCloseToTimeout: boolean = false;
  @Select(AppState.getUserInfo) getUserInfo$: Observable<UserInfo>;
  showGlobalIdModal = false;
  offlineType = '';
  showToaster = false;
  listener;
  cacheName = '1.17';
  toasterMessage = "App Update Successful!. If you have any issues with the updates let us know.";
  toasterAction = "Let us know";
  toasterAlertType = "success";
  showReleaseNotes = false;
  surveyQuestions;
  showSurvey;
  isSurveySubmitted = false;
  surveyTitle = 'We’d love your feedback!';
  surveySubTitle = 'Tell us what you love/don’t love about this product.';
  releaseNotes = ``;
  surveyId;
  showFeedBackButton = false;
  isCancel = false;
  photos: Photo[];
  enableSurvey = environment.showSurvey;
  @Select(AppState.getStartJobPhotos) getStartJobPhotos$: Observable<Photo[]>;
  
  constructor(
    private ccRequestsService: CcRequestsService,
    private authService: AuthService,
    private msal: MsalService,
    private contractorService: ContractorAuthService,
    private baseService: BaseService,
    private swUpdate: SwUpdate,
    private store: Store,
    private styler: StylerService,
    private internetCheckerService: InternetCheckerService,
    private cdr: ChangeDetectorRef,
    private masterData: MasterDataService,
    private router: Router,
    private bannerService: BannerService,
    private msalBroadcastService: MsalBroadcastService,
    private logger: LoggingService,
    private appRef: ApplicationRef,
    private alert: Alert
  ) {    
    // Must be updated when moving to ng 13
    this.swUpdate.available.subscribe(evt => {
      console.log('%cUpdate Available', 'color: slateblue; font-weight: bold; font-size: 20px');
      localStorage.setItem('update-activated', 'false');
      this.alert.notUrgent('App Update Needed! Save Where you are submit this current page and refresh', 'Close', { timeOut: 10000000 , closeButton : true});
      this.swUpdate.activateUpdate().then(() => {
        localStorage.setItem('update-activated', 'true');
        console.log('%cRunning Activate Update', 'color: purple; font-weight: bold; font-size: 20px');
        //this.alert.success('App Update Successful! If you have any issues with the updates let us know', 'Let us know', { timeOut: 10000000 , closeButton : true});
        this.logger.logEvent(
          { name: 'SERVICE WORKER UPDATE' },
          {
            action: 'Service Worker - Update',
            eventDate: new Date()
          })
        this.clearCache();
        document.location.reload();
      }).catch(err => {
        this.logger.logEvent(
          { name: 'SERVICE WORKER UPDATE FAILED' },
          {
            action: 'Service Worker - Update Failed',
            eventDate: new Date()
          })
      });
    });
    this.swUpdate.checkForUpdate().then((val) => console.log('this.swUpdate.checkForUpdate :>> ', val)).catch(err => {console.log(err);});
    //
    this.isOnline = navigator.onLine;
    this.internetCheckerService.addOnlineEventListeners();
  }
  
  ngOnInit(): void {
    // Fetch all pre-cacheable data and do pwa logic before saving features to store
    // On page load, we need to check if we need to show sign in modal (employees whose session has timed out)
    this.store.select(store => store.AppState.userInfo as UserInfo).subscribe(userInfo => {
      if (!userInfo) {
        this.isLoggedIn = false;
      } else if (!userInfo.user.isLoggedin && !this.router.url.includes('sign-in')) {
        this.isLoggedIn = false;
        this.sessionTimeout = true;
      } else {
        this.isLoggedIn = true;
        this.sessionTimeout = false;
      }
    });
    this.msalBroadcastService.inProgress$
      .pipe(
        tap(status => {
          if (status === InteractionStatus.HandleRedirect) {
            this.sessionTimeout = false;
          }
        }),
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        take(1)
      )
      .subscribe(() => {
        // Do user account/UI functions here
        // For Employees, isAuthenticated() will contain token in time, for OKTA users isAuthenticated wont be available until crew-code loads     
        const accounts = this.msal.instance.getAllAccounts();
        const viewingEmailUrl = this.router.url.includes('flush-request') || this.router.url.includes('flush-job');
        if (accounts.length > 0) {
          this.msal.instance.setActiveAccount(accounts[0]);
          if (!(this.authService.getAccessTokenStatus() == 'EXPIRED')) {
            this.isLoggedIn = true;
            this.doUIWork();
          } else {
            this.msal.acquireTokenSilent({ scopes: [environment.aadScopes] }).pipe(
              catchError(err => {
                return throwError(err) //Should hit error if no active account is set, in which case present login prompt
              })
            )
              .subscribe(data => {
                this.isLoggedIn = true;
                this.doUIWork();
              }, err => {
                if(!this.router.url.includes('sign-in')){
                  this.sessionTimeout = true;
                }
              })
          }
        } else {
          if (viewingEmailUrl) {
            this.msal.loginRedirect({ scopes: [environment.aadScopes] }).subscribe(data => {
              this.isLoggedIn = true;
              this.doUIWork();
            })
          } else if (accounts.length === 0 && !this.router.url.includes('sign-in')) {
            // If on a page and no MSAL info cached, show session timeout
            this.sessionTimeout = true;
          }
        }
      });
    // Pipe currentAccessToken Obs and only omit value if token has a value, then take that single emit and unsubscribe
    this.contractorService.currentAccessToken
      .pipe(
        filter((token) => token && token.length > 0),
        take(1)
      )
      .subscribe((token) => {
        // At this point token should have a value and should unsubscribe after callback block is done
        // Check if already ran isAuthenticated, to avoid duplicate calls
        if (this.isLoggedIn) {
          this.isLoggedIn = this.authService.isAuthenticated();
          this.checkForTimeout(false);
        }
      });
    // Whenever we switch to a new route make sure that we land on the top of the page. (Hard for mobile users)
    this.router.events.subscribe((evt) => {           
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      console.log('this.router.events.evt :>> ', evt);
      if(!evt.url.includes('sign-in')) {        
        const userInfo: UserInfo = this.store.selectSnapshot(store => (store.AppState.userInfo) as UserInfo)
        this.logger.setUserId(userInfo.user.email);
        if(!this.surveyQuestions && userInfo.user && !localStorage.getItem('showSurvey') && this.enableSurvey) {                    
          this.baseService.getSurveyQuestions(userInfo.user.email)?.subscribe({
            next: (resp: any) => {
              console.log('getSurveyQuestions.resp :>> ', resp);
              if(resp.length > 0 && resp[0].code !== 'USER-RESPONDED') {
                this.surveyQuestions = resp[0].surveyQuestions;
                this.surveyId = resp[0].surveyId;      
                this.showFeedBackButton = true;
              } else {
                localStorage.setItem('showSurvey', 'false');
              }
            },
            error: (err) => {
              console.log('getSurveyQuestions.err :>> ', err);
            }
          });
        }        
      }  
      document.getElementById('main-navigation').scrollIntoView();
    });
    this.observer.subscribe(restartTimer => {
      const userInfo: UserInfo = this.store.selectSnapshot(store => (store.AppState.userInfo) as UserInfo)
      if (restartTimer) {
        this.checkForTimeout(userInfo.user.flushRoleType != 'CN');
      } else {
        this.store.dispatch(new AddUserInfo({ user: { ...userInfo.user, isLoggedin: false }, workAssignments: userInfo.workAssignments.map(i => i) }))
      }
    });   
    interval(60000).pipe(take(1)).subscribe(() => {     
      if(localStorage.getItem('update-activated') === 'true') {    
        this.showToaster = true;
      };
      localStorage.setItem('update-activated', 'false');
      this.showToaster = false;
    });
    this.baseService.signin$.subscribe((isSigin) => this.sessionTimeout = !isSigin);
    interval(60*1000).subscribe(() => { this.swUpdate.checkForUpdate().catch((e) => {console.log('Service Worker not working')}); })  
    
    this.getStartJobPhotos$.subscribe(photos => {
      this.photos = photos.filter(photo => photo.mediaType != 'Video');
    }).unsubscribe();

  }

  ngAfterViewInit() {
    this.promptInstallApplication();
    const isSafari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
    const url =location.href;
    if (isSafari) {
      history.pushState(null, null, url);
      window.onpopstate = function(event) {
        if ((url.indexOf('era') > -1 || url.indexOf('requests') > -1)) {
          // Prevent backward nav
          history.go(1);
        }
      };
    }
    
    this.internetCheckerService.getStatus.subscribe((status) => {
      this.isOnline = status;
      this.offlineType = (this.store.selectSnapshot(store => store.AppState?.userInfo?.user?.role) ?? '');
      this.cdr.detectChanges();
    });

    this.baseService.showCancelConfirmation$.subscribe((val) => {
      this.isCancel = val;
    })

  }

  closeRequestInProgress(){
    this.ccRequestsService
    .cancelCCRequest()
    .pipe(
      tap((done) => {
        // Clear our store if we cancel a request
        this.store.dispatch(DeleteJobDetails);
        this.store.dispatch(DeleteAccessInformation);
        this.store.dispatch(DeleteFlushInformation);
        this.store.dispatch(DeleteStructureInformation);
        this.ccRequestsService.deletePhotos(this.photos);
        this.store.dispatch(DeleteStartJobPhoto);
        this.store.dispatch(DeleteWorkRequestDetail);
        this.store.dispatch(DeleteWorkDescription);
      })
    )
    .subscribe(() => {
      this.router.navigate(['/requests']);
    });

    this.baseService.showCancelConfirmation$.next(false);   
  }

  /**
   * Clear Stale Cache and let SW fetch the updated information from Server
   */
  private clearCache() {
    if (window.caches) {
      caches.keys().then((cacheKeys) => {
        return Promise.all(
          cacheKeys.map((key) => {
            return caches.delete(key);
          })
        );
      });
    }
  }

  clickFeedbackButton() {
    console.log('clickFeedbackButton :>> ');
    this.showSurvey = true;
  }

  submitSurveyResults(response){
    console.log('submitSurveyResults.response :>> ', response);
    const userInfo: UserInfo = this.store.selectSnapshot(store => (store.AppState.userInfo) as UserInfo)
    const answer = {
      surveyId: this.surveyId,
      userId: userInfo.user.email,
      groupserviceName: 'flush',
      surveyAnswers: response
    };
    this.baseService.submitSurveyResults(answer).subscribe({
      next: (res) => {
        this.isSurveySubmitted = true;
        console.log(answer);
        localStorage.removeItem('showSurvey');
      },
      error: (err) => {
        this.isSurveySubmitted = false;
        console.error(err);
      }
    });
  }

  promptInstallApplication() {
    window.addEventListener('beforeinstallprompt', (e) => {
      console.log('No Install');
      e.preventDefault();
      const installFlag = JSON.parse(localStorage.getItem('showInstallPromt'));     
      console.log('installFlag :>> ', installFlag);
      if (installFlag !== null) {
        this.openInstallPromote = installFlag;
      }
      else {
        this.openInstallPromote = true;
      }
      console.log('openInstallPromote :>> ', this.openInstallPromote);
      this.deferredPrompt = e;
      this.installButton.nativeElement.click();
    });
  }

  showPrompt() {
    this.deferredPrompt
      .prompt()
      .then((res) => { })
      .catch((error) => { }); // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice.then((choiceResult) => {      
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
        this.closeInstallDialog();
      } else {
        console.log('User dismissed the A2HS prompt');
        this.closeInstallDialog();
        this.doNotInstall = true;       
      }
      this.deferredPrompt = null;
    });
  }

  closeReleaseNotes() {
    this.showReleaseNotes = false;
    this.baseService.putReleaseFlag().subscribe((resp) => console.log('putReleaseFlag.resp :>> ', resp));
  }

  closeModal() {
    this.openModal = false;
  }

  closeInstallDialog() {
    localStorage.setItem('showInstallPromt', 'false');
    this.openInstallPromote = false;
  }

  closeGlobalIdModal() {
    this.showGlobalIdModal = false;
    window.localStorage.setItem('refreshDashboard', 'false');
  }

  goSignIn() {
    const userInfo: UserInfo = this.store.selectSnapshot(store => (store.AppState.userInfo) as UserInfo)
    if (!!userInfo) {
      if (userInfo.user?.name?.includes('Contractor')) {
        // localStorage.clear();
        // sessionStorage.clear();
        this.router.navigate(['', 'contractor-sign-in']);
      } else {
        this.msal.loginRedirect({
          redirectUri: environment.redirectUri,
          scopes: [environment.aadScopes]
        }).subscribe(data => { }).unsubscribe();
      }
    } else {
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigate(['sign-in']);
    }
  }

  goSignOut() {
    localStorage.removeItem('showSurvey');
    this.authService.logout();
  }
  checkForTimeout = (isEmployee: boolean) => {
    let stopSession = new Subject<boolean>();
    this.sessionStream = interval(1000).pipe(
      startWith(0),
      map(val => this.authService.getAccessTokenStatus()),
      takeUntil(stopSession)
    );
    let expiringStream = this.sessionStream.pipe(
      filter(status => status != 'VALID'),
      tap(status => {
        console.log("Session Stream - Status:", status);
        if (status.startsWith('EXPIRING')) {
          this.tick = Number(status.split(';').slice(1).join('')) // in secs
          this.sessionCloseToTimeout = !isEmployee;
        }
      })
    )
    expiringStream.pipe(
      filter(status => status === "EXPIRED"),
      take(1)
    ).subscribe(expired => {
      stopSession.next(isEmployee);
      stopSession.complete();
      if (!isEmployee) {
        if(!this.router.url.includes('sign-in')) {
          this.sessionTimeout = true;
        }        
        this.sessionCloseToTimeout = false;
        this.observer.next(false);
      } else {
        const accounts = this.msal.instance.getAllAccounts();
        this.msal.instance.setActiveAccount(accounts[0]);
        this.msal.acquireTokenSilent({
          scopes: [environment.aadScopes]
        }).subscribe(res => {
          if (!(this.authService.getAccessTokenStatus() === "EXPIRED")) {
            this.observer.next(true);
          }
        }, err => {
          console.log(err);
          const userInfo = this.store.selectSnapshot(store => store.AppState.userInfo as UserInfo);
          this.store.dispatch(new AddUserInfo({ ...userInfo, user: { ...userInfo.user, isLoggedin: false } }));
          if(!this.router.url.includes('sign-in')) {
            this.sessionTimeout = true;
          }  
          this.sessionCloseToTimeout = false;
        })
      }
    })
  };
  doUIWork(isEmployee = true) {
    // Listen to globalId, if it doesnt match, refresh to dashboard
    this.checkForTimeout(isEmployee);
    fromEvent<StorageEvent>(window, "storage").pipe(
      filter((event) => event.key === 'workRequestGlobalId' || event.key === 'refreshDashboard')
    ).subscribe(event => {
      let sessionStorageGlobalId = window.sessionStorage.getItem('workRequestGlobalId');

      if (sessionStorageGlobalId) {
        let tabName = window.sessionStorage.getItem('tabName');
        let localStorageGlobalId = window.localStorage.getItem('workRequestGlobalId');
        if (localStorageGlobalId !== sessionStorageGlobalId) {
          window.localStorage.setItem('refreshDashboard', 'false');
          window.localStorage.setItem('refreshDashboard', 'true');
          this.showGlobalIdModal = true;
          this.router.navigate(['/requests']);
        }
      }
      if (window.localStorage.getItem('refreshDashboard') == 'true') {
        this.showGlobalIdModal = true;
      }
    })
    this.masterData.saveMasterData(this.swUpdate.isEnabled).pipe(take(1)).subscribe();
    this.baseService.getUserInfo().pipe(
      tap(info => {
        console.log('this.baseService.getUserInfo().info :>> ', info);
        const user = info.user;
        this.store.dispatch([new AddUserInfo(info)]);
        if (user.flushRoleType == 'cc' || !user.crewCode.includes('User')) {
          // If a flush user, must have crew code assigned in order to enter system
          this.store.dispatch([new AddCrewCode(user.crewCode), new AddFlushUserRole(user.flushRoleType)]);
        }        
      }),
      map((info) => ((info.workAssignments ?? [])
        .map(assignment => ({
          role: info.user.flushRoleType,
          email: info.user.email,
          crewCode: info.user.crewCode,
          workRequestGlobalID: assignment.workRequestGlobalID,
          workComponentGlobalID: assignment.workComponentGlobalID,
          excludeCache: true
        } as AssociatedWorkBody)
        ))
      ),
      take(1),
      catchError((err) => throwError(err))
    )
      .subscribe(
        (assignedWork) => {
          console.log('doUIWork.assignedWork', assignedWork);
          this.baseService.getReleaseNotes().subscribe((notes) => {
            if(notes && notes !== '' && !this.router.url.includes('sign-in')) {
              this.showReleaseNotes = true;
            } 
            this.releaseNotes = notes;
          });   
          this.store.dispatch(new Dashboard.AddAssignedWork(assignedWork))
        },
        (error: HttpErrorResponse) => {
          if (this.router.routerState.snapshot.url.includes('_crew_')) {
            this.isLoggedIn = false;
            this.store.dispatch(DeleteUserInfo);
          } else {
            this.router.navigate(['sign-in']);
            this.logger.logEvent({
              name: 'User redirected to sign-in'
            }, {
              action: 'POST /users result',
              eventDate: new Date(),
              originalUrl: error?.url,
              httpStatus: error?.status,
              originalErrorName: error?.name,
              originalError: error?.error,
              originalServerMessage: error?.message,
              userInfo: this.store.selectSnapshot(store => store.AppState?.userInfo) ?? 'EMPTY',
              refUri: document.URL
            })
            this.bannerService.createBanner('error', {
              header: 'Could not connect to WMS',
              body: [
                'Having trouble retrieving detailed employee information from WMS. Try again later or reach out to ERA team so we can notify our IT partner.',
              ],
              originalUrl: error?.url,
              httpStatus: error?.status,
              originalErrorName: error?.name,
              originalError: error?.error,
              originalServerMessage: error?.message,
            });
          }
        }
      );
  }
}
