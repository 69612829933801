<div class="container">
  <div class="row">
    <div class="col-12">
      <!-- <div class="hazard-notification form-group no-gutters row" *ngIf="dFaultElement">
        <div class="col-auto" >
          <img class="icon" src="assets/icons/alert.svg" alt="Con ed">
        </div>
        <div class="col-10 col-sm-11 col-md-11 col-lg-11 col-xl-11">
          <h4> Do not enter the structure.</h4>
          <p> A D-Fault tag is found for this request. Please provide your assessment from above the structure.</p>
        </div>
      </div> -->
    <div>
        <h3>Flush Prerequisite Information</h3>
        <p>To request a flush, start with either Media Capture or Flush Details.</p>
      </div>
      <div class="form-group row">
        <div *ngIf="showNavigateToPhotos" class="col-sm-5 col-md-6 col-lg-6 col-xl-5">
          <div class="nav-box">
            <div class="nav-box-heading">
              <h4>Structure Media</h4>
            </div>
            <div class="nav-box-body">
              <p>Take photos or upload photos of the structure to capture the flush scope of work.</p>
              <ce-button size="large" label="Start with media" type="primary" icon="icon-camera-photo" (clickEvent)="navigateToPhotos()"></ce-button>
            </div>
          </div>
        </div>
        <div class="col-sm-5 col-md-6 col-lg-6 col-xl-5">
          <div class="nav-box">
            <div class="nav-box-heading">
              <h4>Flush Details</h4>
            </div>
            <div class="nav-box-body">
              <p>Provide details on job priority, scope and access information.</p>
              <ce-button size="large" styleClass="buttonStart" label="Start with details" type="primary" icon="icon-doc" (clickEvent)="navigateToFlushQuestions()"></ce-button>
            </div>

          </div>
        </div>
      </div>
      <small>* Photos and details are both required before submitting your request.</small>
    </div>
  </div>
</div>
<router-outlet></router-outlet>

<app-launch-camera-modal #launchCameraModalComponent></app-launch-camera-modal>
