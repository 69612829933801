import { StructureInformation } from '../../models/structureInformation';

export class AddStructureInformation {
  static readonly type = '[StructureInformation] Add';

  constructor(public payload: StructureInformation) {}
}

export class DeleteStructureInformation {
  static readonly type = '[StructureInformation] Delete';

  constructor() {}
}
