<!-- Photo popup-modal -->
<ce-modal [visible]="showLaunchCameraModal">
    <ce-modal-body style="text-align: center;">
        <h3>We value your clear perspective</h3>
        <p>Documentation of structures before and after they are flushed are paramount to the future of Con Edison&#8217;s digital transformation. They help us minimize the need for manual data entry and provide a clear record of our assets.</p>
        <img src="assets/icons/photo_icon.svg" class="center-modal-photo mb-4">
        <p>The integrity and quality of your media is important. Thank you!</p>
        <ce-button label="Launch Camera" type='primary' (clickEvent)="launchCamera()" size='large' icon="icon-camera"
            iconDirection="left"></ce-button>
    </ce-modal-body>
</ce-modal>