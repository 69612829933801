<div class="camera">
  <div class="camera-overlay">
    <div class="camera-loading" [hidden]='!buffering || showCanvas'>
      <div class="loading-container">
        <div class="row">
          <div [ngClass]="{'col-12': !errorBuffering,'col-8 offset-2': errorBuffering}">
            <h2 class="text" [ngClass]="{'loading-text': !errorBuffering}">{{ !errorBuffering ? 'Camera Loading' : 'Oops! Something went wrong. 🙁'}}</h2>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-8 offset-2">
            <p class="text" *ngIf="errorBuffering">We are unable to access your camera. Your camera may be disabled or in use by another application. Check your camera settings to enable your camera or try again later.</p>
          </div>
        </div>
        <div class="form-group row" *ngIf="capacityReached">
          <div class="col-8 offset-2">
            <p class="text">You've uploaded a max of <b>ten</b> photos. Go back to review photos if you wish to retake them.</p>
          </div>
        </div>
        <div class="gallery-error" [@photoSaved] *ngIf="errorBuffering && photos.length > 0" (click)="checkUnsavedPhotoBeforeClosing();">
          <ng-container *ngIf="photos[photos.length - 1]?.mediaType === mediaTypes.VIDEO; else imageGallery">
            <img src="./assets/images/blankVideoIcon.svg" class="blankVideoassetsClass" alt="image">
          </ng-container>
          <ng-template #imageGallery>
            <img src="{{photos[photos.length - 1]?.src}}" alt="image">
          </ng-template>
          <div class="gallery-caption">
              {{photos.length}}
          </div>
        </div>
        <div class="multi-photo-info error" *ngIf="isShowMultiPhotoInfo">
          <div class="modal-close">
            <span class="icon icon-close modal-close" (click)="isShowMultiPhotoInfo=false"></span>
          </div>
          <span class="description mb-0">Want to upload multiple photos at once?</span>
          <span class="description">Hold down the control key as you click on each photo.</span>
          <div class="bubble-arrow"></div>
        </div>
        <div class="file-input-wrapper row">
          <div class="file-input-error col-6" *ngIf="errorBuffering">
            <input type="file" multiple class="input" (change)="onFileChange($event)" accept="image/*"
              [disabled]="capacityReached" />
            <img class="icon" src="assets/icons/photos.svg" alt="con ed">
            <div class="row no-gutters">
              <div class="col-auto">
                <i class="icon icon-question-circle" (mouseover)="isShowMultiPhotoInfo=true"></i>
              </div>
              <div class="col">
                <span class="file-input-solo-caption">Upload Photo</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="camera-component" [hidden]="buffering">
      <video
      [hidden]="showCanvas || isCameraDisabled"
      class="camera-lens"
      height="100%"
      width="100%"
      #video
      autoplay
      (loadedmetadata)="onCameraMetadataLoaded($event)"
      ></video>
      <canvas
      #canvas
      [height]="cameraHeight"
      [width]="cameraWidth"
      [hidden]="true"
      ></canvas>
      <div class="camera-shutter-pane"
      [ngClass]="{'landscape': capturedPhoto}"
      *ngIf="!showCanvas && !isCameraDisabled">
        <div class="gallery" [@photoSaved] *ngIf="photos.length > 0 && !photoSaving" (click)="checkUnsavedPhotoBeforeClosing();">
            <ng-container *ngIf="photos[photos.length - 1]?.mediaType === mediaTypes.VIDEO; else imageGallery">
              <img src="./assets/images/blankVideoIcon.svg" class="blankVideoassetsClass" alt="image">
            </ng-container>
            <ng-template #imageGallery>
              <img src="{{photos[photos.length - 1]?.src}}" alt="image">
            </ng-template>
            <div class="gallery-caption">
                {{photos.length}}
            </div>
        </div>
        <div class="swap-camera icon-camera-switch" (click)="swapCamera()" *ngIf="!isCameraDisabled"></div>
        <div class="multi-photo-info" *ngIf="isShowMultiPhotoInfo">
          <div class="modal-close">
            <span class="icon icon-close modal-close" (click)="isShowMultiPhotoInfo=false"></span>
          </div>
          <span class="description mb-0">Want to upload multiple photos at once?</span>
          <span class="description">Hold down the control key as you click on each photo.</span>
          <div class="bubble-arrow"></div>
        </div>
        <div class="file-input-solo row pl-lg-0 pl-2">
          <div class="col-6">
            <div class="logo-take-photo">
              <input type="file" multiple class="input" (change)="onFileChange($event)" accept="image/*" [disabled]="capacityReached" />
              <img class="icon" src="assets/icons/photos.svg" alt="con ed">
              <div class="row px-0 justify-center">
                <div class="col-auto">
                  <i class="icon icon-question-circle" (mouseover)="isShowMultiPhotoInfo=true"></i>
                </div>
                <div class="col-auto">
                  <span class="file-input-solo-caption">Upload Photo</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="camera-shutter"
        *ngIf="!isCameraDisabled"
        [ngClass]="{'disabled': capacityReached}"
        (click)="clickPhoto()"
        >
        </div>
      </div>

    </div>
    <div class="camera-captured-pane"
      [ngClass]="{
        'landscape': isCameraLandscape,
        'mobile': isCameraDisabled
      }"
      *ngIf="showCanvas"
    >
      <div class="captured-photo">
        <!-- One pane, handle showing one photo at a time -->
        <ng-container *ngIf="videoUploaded; else photoUploaded">
          <video class="captured-video" #videoUpload controls autobuffer></video>
        </ng-container>
        <ng-template #photoUploaded>
            <app-photo
            [image]="capturedPhoto[showingIndex]"
            [inputPhoto]="capturedPhoto[showingIndex] != undefined ? capturedPhoto[showingIndex] : {}"
            [photoAdded]="photoAdded"
            (labelsAdded)="addLabels($event)"
            [ngStyle]="{position: 'absolute', top: '0', bottom: '0', left: '0', right: '0'}"
            >
          </app-photo>
        </ng-template>
        <div class="carousel-left-btn" *ngIf="capturedPhoto?.length > 1 && (showingIndex - 1 > -1)" (click)="selectPhoto(showingIndex - 1)"><span>&#8249;</span></div>
        <div class="carousel-right-btn" *ngIf="capturedPhoto?.length > 1 && (showingIndex + 1 < capturedPhoto?.length)" (click)="selectPhoto(showingIndex + 1)"><span>&#8250;</span></div>
      </div>
      <div class="comments-section">
        <div class="container section">
          <app-photo-review [photo]="selectedPhoto" (changes)="handlePhotoDetails($event)"></app-photo-review>
        </div>
      </div>
    </div>
    <div class="camera-options-pane"
    [ngClass]="{
      'landscape': isCameraLandscape,
      'mobile': isCameraDisabled
      }"  *ngIf="showCanvas">
      <div class="gallery" [@photoSaved] *ngIf="(photos.length > 0 && !photoSaving && !isCameraDisabled)" (click)="checkUnsavedPhotoBeforeClosing();">
        <ng-container *ngIf="photos[photos.length - 1]?.mediaType === mediaTypes.VIDEO; else imageGallery">
          <img src="./assets/images/blankVideoIcon.svg" class="blankVideoassetsClass" alt="image">
        </ng-container>
        <ng-template #imageGallery>
          <img src="{{photos[photos.length - 1]?.src}}" alt="image">
        </ng-template>
        <div class="gallery-caption">{{photos.length}}</div>
      </div>
      <div class="camera-options-buttons" *ngIf="!isCameraDisabled">
        <ng-container *ngIf="!videoUploaded">
            <ce-button label="Save Photo" [@photoSaved] type='primary' (clickEvent)="addPhoto()" size='medium'
              *ngIf="!photoSaving && !photoError" [disabled]="!isPhotoValid"></ce-button>
        </ng-container>
        <div class="banner banner-white" *ngIf="photoSaving" @photoSavedConfirmed>
          <span class="icon icon-check"></span>
          Media Saved
        </div>
        <ce-button [label]="photoError ? 'Capture Different Media' : 'Retake Media'" [@photoSaved] type="secondary" (clickEvent)="retakePhoto()" size='medium' *ngIf="!photoSaving"></ce-button>
        <div class="button-left" [@photoSaved] *ngIf="!photoSaving && !photoError">
          <ce-button label="Comments" type='primary' (clickEvent)="addComments()" size='medium' icon="icon-caret-button" iconDirection="left"></ce-button>
        </div>
      </div>
      <div class="camera-options-buttons" *ngIf="isCameraDisabled">
        <ce-button label="Retake" type="secondary" (clickEvent)="retakePhoto()" *ngIf="!photoSaving"></ce-button>
        <ng-container *ngIf="!videoUploaded">
            <ce-button [label]="photoError ? 'Retry': 'Save'" [@photoSaved] type='primary' (clickEvent)="addPhoto()" size='medium'
              *ngIf="!photoSaving" [disabled]="!isPhotoValid"></ce-button>
        </ng-container>
        <div class="banner banner-white" *ngIf="photoSaved" @photoSavedConfirmed>
          <span class="icon icon-check"></span>
          Media Saved
        </div>
        <div class="button-left" [@photoSaved] *ngIf="isCameraLandscape && (!photoSaving && !photoError)">
          <ce-button label="Comments" type='primary' (clickEvent)="addComments()" size='medium' icon="icon-caret-button" iconDirection="left"></ce-button>
        </div>
      </div>

    </div>
    <div class="camera-mobile">
      <div class="container use-file" *ngIf="isCameraDisabled ">
        <h2>Add a Media</h2>
        <p>Take a new photo or upload a photo from your device.</p>
        <div class="mb-1 row">
          <div class="col-12" *ngIf="!capacityReached">
            <div class="btn btn-primary file" tabindex="0" role="button">
              <input placeholder="Take Photo" type="file" multiple class="file-input" (change)="onFileChange($event)" accept="image/*" capture="camera" [disabled]="photos.length > 9" />
              <span class="icon icon-camera-photo"></span>
              Launch Camera
            </div>
          </div>
          <div class="col-12" *ngIf="capacityReached">
            <p class="text">You've uploaded a max of <b>ten</b> photos. Go back to review photos if you wish to retake them.</p>
          </div>
        </div>
        <div class="mb-1 row">
          <div class="col-12">
            <div class="btn btn-primary file" tabindex="0" role="button">
              <input placeholder="Upload Photo" type="file" multiple class="file-input" (change)="onFileChange($event)" accept="image/*" [disabled]="capacityReached" />
              <img class="icon" src="assets/icons/photos.svg" alt="con ed">
              Upload Photo
            </div>
          </div>
        </div>
      </div>
      <div class="gallery" [@photoSaved] *ngIf="!showCanvas && photos.length > 0" (click)="checkUnsavedPhotoBeforeClosing();">
        <ng-container *ngIf="photos[photos.length - 1]?.mediaType === mediaTypes.VIDEO; else imageGallery">
          <img src="./assets/images/blankVideoIcon.svg" class="blankVideoassetsClass" alt="image">
        </ng-container>
        <ng-template #imageGallery>
          <img src="{{photos[photos.length - 1]?.src}}" alt="image">
        </ng-template>
        <div class="gallery-caption">
            {{photos.length}}
        </div>
      </div>
    </div>
    <div class="cancel-link" [@photoSaved] *ngIf="(!photoSaved && !showCanvas) || (isCameraDisabled && !showCanvas)">  
    <ce-button [label]="'Close'" type="footer-link" (clickEvent)="exitCamera()" [hidden]='(buffering && !errorBuffering)' icon="icon-caret-button"></ce-button>
    </div>
  </div>
</div>
<app-modal class="camera-error-msg" [showModal]="errorDialog.isShowing" [showClose]='true' (closeDialogClick)="closeErrorDialog();" [showErrorIcon]="true" [modalHeading]="errorDialog?.title">
  <div class="container">
    <div class="row">
      <div class="col mb-5">
        <div [innerHTML]="errorDialog?.msg"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-auto style-guide-close-modal-btn">
        <ce-button label='Close' type="secondary" (clickEvent)="closeErrorDialog();"></ce-button>
      </div>
    </div>
  </div>
</app-modal>
