import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Store } from '@ngxs/store';
import { DeleteUserInfo } from 'src/app/app-state/actions/user-info.actions';
import { DeleteFlushUserRole } from 'src/app/app-state/actions/work-request.actions';
@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
})
export class LogoutComponent implements OnInit {
  constructor(private authService: AuthService, private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(new DeleteUserInfo());
    this.store.dispatch(new DeleteFlushUserRole());
    this.authService.logout();
  }
}
