import { State, Action, StateContext, Selector } from '@ngxs/store';
import { patch, append } from '@ngxs/store/operators';
import { AddJobDetails, DeleteJobDetails } from './actions/job-details.actions';
import { JobDetail } from '../models/jobDetail';
import { StructureInformation } from '../models/structureInformation';
import { AccessInformation } from '../models/accessInformation';
import { FlushInformation } from '../models/flushInformation';
import { User } from '../models/user.model';
import { AddAccessInformation, AddEmergencyInformation, DeleteAccessInformation } from './actions/access-info.actions';
import { AddFlushInformation, DeleteFlushInformation } from './actions/flush-process-info.actions';
import { AddStructureInformation, DeleteStructureInformation } from './actions/structure-info.actions';
import { Photo } from '../models/photo';
import {
  AddFlushWorkRequestId,
  DeleteFlushWorkRequestId,
  AddFlushUserRole,
  DeleteFlushUserRole,
  AddWorkRequestGlobalId,
  DeleteWorkRequestGlobalId,
  DeleteFacilityGlobalId,
  AddFacilityGlobalId,
  AddCrewCode,
  DeleteCrewCode,
  AddFlushWRNumber,
  DeleteFlushWRNumber,
  AddWorkComponentGlobalId,
  DeleteWorkComponentGlobalId,
  AddWorkComponentId,
} from './actions/work-request.actions';
import { WorkRequestDetail } from '../models/work-request-detail';
import {
  AddWorkRequestDetail,
  DeleteWorkRequestDetail,
  AddStartJobPhotos,
  DeleteStartJobPhoto,
  DeleteWorkDescription,
  AddWorkDescription,
  AddPausedJob,
  DeletePausedJob,
} from './actions/start-job.actions';
import { WorkRequestsResponse } from '../models/work-requests-response';
import { Dashboard } from './actions/dashboard-work-requests.actions';
import { AddUserInfo, DeleteUserInfo, ResetAppStateToDefault } from 'src/app/app-state/actions/user-info.actions';
import { MasterData } from '../interfaces/master-data';
import { AddMasterData } from './actions/master-data.action';
import { WorkRequest } from '../interfaces/work-request';
import { WorkComponent } from '../interfaces/work-component';
import { UserInfo } from '../interfaces/user-info';
import { AssociatedWorkBody } from '../interfaces/associated-work-body';
import { FlushReview } from '../models/flush-review';
import { PublishedEsriServer } from '../services/map/map.service';
import { CONFIG } from '../global/config';
import { MapLayers } from './actions/map.actions';

export interface AppStateModel {
  //Construction Crew
  jobDetails: JobDetail;
  structureInfo: StructureInformation;
  accessInfo: AccessInformation;
  emergencyInfo: { isOnLocation: string, isAccessObtained: string } | null;
  flushProcess: FlushInformation[];
  searchwrflag: Boolean;

  //Global
  userInfo: UserInfo;
  masterData: MasterData;
  startJobPhotos: Photo[];
  mapLayers: PublishedEsriServer[];
  //Flush Mechanic + Contractors (start-job)
  workRequestDetail: WorkRequestDetail;
  workDescription: string;
  pausedJob: boolean;
  //Dashboard
  dashboardWorkRequests: WorkRequestsResponse;
  assignedWork: AssociatedWorkBody[]
  assignedWRDetails: WorkRequest[];

  // Flush variables
  flushWorkRequestId: string;
  facilityGlobalId: string;
  flushUserRole: string;
  workComponentGlobalId: string;
  workRequestGlobalId: string;
  workComponentId: string;
  flushWRNumber: string;
  crewCode: string;
  isCrewConductingRepairs: boolean;
  canOriginalWorkContinue: boolean;
  hasStrayVoltage: boolean;
  hasLeadStabilizerUsed: boolean;
  noOfStabilizers: number;
  flushReviewInfo: FlushReview
}

const defaults = {
  jobDetails: new JobDetail(null, null, null, null, null, null, null, null, 0),
  structureInfo: new StructureInformation(null, false),
  accessInfo: new AccessInformation(null, null, null, null, null, null, null, null, null, null),
  emergencyInfo: null,
  flushProcess: [],
  searchwrflag: false,
  userInfo: null,
  assignedWork: [],
  masterData: null,
  startJobPhotos: new Array<Photo>(),
  dashboardWorkRequests: new WorkRequestsResponse(),
  assignedWRDetails: [],
  workRequestDetail: new WorkRequestDetail(),
  workDescription: '',
  pausedJob: false,
  flushWorkRequestId: '',
  facilityGlobalId: '',
  flushUserRole: '',
  workComponentGlobalId: '',
  workRequestGlobalId: '',
  flushWRNumber: '',
  crewCode: '',
  isCrewConductingRepairs: false,
  canOriginalWorkContinue: false,
  hasStrayVoltage: false,
  hasLeadStabilizerUsed: false,
  noOfStabilizers: 0,
  flushReviewInfo: null,
  workComponentId: '',
  mapLayers: []
};
@State<AppStateModel>({
  name: 'AppState',
  defaults,
})
export class AppState {
  @Selector()
  static getFlushReviewInfo(state: AppStateModel) {
    return state.flushReviewInfo;
  }
  @Selector()
  static getWorkDescription(state: AppStateModel) {
    return state.workDescription;
  }
  @Selector()
  static getDashboardWorkRequests(state: AppStateModel) {
    return state.dashboardWorkRequests;
  }
  @Selector()
  static getWorkRequestDetail(state: AppStateModel) {
    return state.workRequestDetail;
  }
  @Selector()
  static getFlushWorkRequestId(state: AppStateModel) {
    return state.flushWorkRequestId;
  }
  @Selector()
  static getFacilityGlobalId(state: AppStateModel) {
    return state.facilityGlobalId;
  }
  @Selector()
  static getFlushUserRole(state: AppStateModel) {
    return state.flushUserRole;
  }
  @Selector()
  static getWorkRequestGlobalId(state: AppStateModel) {
    return state.workRequestGlobalId;
  }
  @Selector()
  static getWorkComponentGlobalId(state: AppStateModel) {
    return state.workComponentGlobalId;
  }
  @Selector()
  static getWorkComponentId(state: AppStateModel) {
    return state.workComponentId;
  }
  @Selector()
  static getFlushWRNumber(state: AppStateModel) {
    return state.flushWRNumber;
  }
  @Selector()
  static getCrewCode(state: AppStateModel) {
    return state.crewCode;
  }

  @Selector()
  static getStartJobPhotos(state: AppStateModel) {
    return state?.startJobPhotos;
  }

  @Selector()
  static getPausedJob(state: AppStateModel) {
    return state?.pausedJob;
  }

  @Selector()
  static getJobDetails(state: AppStateModel) {
    return state.jobDetails;
  }

  @Selector()
  static getStructureInformation(state: AppStateModel) {
    return state.structureInfo;
  }

  @Selector()
  static getAccessInformation(state: AppStateModel) {
    return state.accessInfo;
  }

  @Selector()
  static getFlushInformation(state: AppStateModel) {
    return state.flushProcess;
  }

  @Selector()
  static getUserInfo(state: AppStateModel) {
    return state.userInfo;
  }

  @Selector()
  static getSearchwrflag(state: AppStateModel) {
    return state.searchwrflag;
  }

  @Action(ResetAppStateToDefault)
  resetStateToDefault(ctx: StateContext<AppStateModel>) {
    const state = ctx.getState();
    const defaultState = {
      ...defaults,
      onLine: window.navigator.onLine,
      flushUserRole: state.flushUserRole,
      crewCode: state.crewCode,
      userInfo: state.userInfo,
      masterData: state.masterData
    };
    ctx.patchState(defaultState);
  }

  @Action(AddUserInfo)
  addUserInfo(ctx: StateContext<AppStateModel>, action: AddUserInfo) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      userInfo: action?.payload,
    });
  }

  @Action(DeleteUserInfo)
  deleteUserInfo(ctx: StateContext<AppStateModel>, action: DeleteUserInfo) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      userInfo: null,
    });
  }

  @Action(AddWorkDescription)
  addWorkDescription(ctx: StateContext<AppStateModel>, action: AddWorkDescription) {
    const state = ctx.getState();
    const flushInfo = new FlushReview(null, null, action?.payload.workDescription, null, null, action?.payload.isCrewConductingRepairs, action?.payload.canOriginalWorkContinue, action?.payload.hasStrayVoltage, action?.payload.hasLeadStabilizerUsed, action?.payload.noOfStabilizers);
    ctx.setState({
      ...state,
      workDescription: action?.payload.workDescription,
      flushReviewInfo: flushInfo,
      isCrewConductingRepairs: action?.payload.isCrewConductingRepairs,
      canOriginalWorkContinue: action?.payload.canOriginalWorkContinue,
      hasStrayVoltage: action?.payload.hasStrayVoltage
    });
  }
  @Action(DeleteWorkDescription)
  deleteWorkDescription(ctx: StateContext<AppStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      workDescription: '',
    });
  }

  @Action(Dashboard.Add)
  addDashboardWorkRequests(ctx: StateContext<AppStateModel>, action: Dashboard.Add) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      dashboardWorkRequests: action?.payload,
    });
  }
  @Action(Dashboard.Delete)
  deleteDashboardWorkRequests(ctx: StateContext<AppStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      dashboardWorkRequests: new WorkRequestsResponse(),
    });
  }

  @Action(Dashboard.AddAssignedWork)
  addAssignedWork(ctx: StateContext<AppStateModel>, action: Dashboard.AddAssignedWork) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      assignedWork: action?.payload,
    });
  }
  @Action(Dashboard.DeleteAssignedWork)
  deleteAssignedWork(ctx: StateContext<AppStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      assignedWork: [],
    });
  }

  @Action(Dashboard.AddDetailedWork)
  addDashboardDetails(ctx: StateContext<AppStateModel>, action: Dashboard.AddDetailedWork) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      assignedWRDetails: action?.payload,
      searchwrflag: action?.searchwrflag ?? false,
    });
  }
  @Action(Dashboard.DeleteDetailedWork)
  deleteDashboardDetails(ctx: StateContext<AppStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      assignedWRDetails: [],
    });
  }

  @Action(AddWorkRequestDetail)
  addWorkRequestDetail(ctx: StateContext<AppStateModel>, action: AddWorkRequestDetail) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      workRequestDetail: action?.payload,
    });
  }
  @Action(DeleteWorkRequestDetail)
  deleteWorkRequestDetail(ctx: StateContext<AppStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      workRequestDetail: new WorkRequestDetail(),
    });
  }
  @Action(AddFlushWRNumber)
  addFlushWRNumber(ctx: StateContext<AppStateModel>, action: AddFlushWRNumber) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      flushWRNumber: action?.payload,
    });
  }
  @Action(DeleteFlushWRNumber)
  deleteFlushWRNumber(ctx: StateContext<AppStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      flushWRNumber: '',
    });
  }

  @Action(AddCrewCode)
  addCrewCode(ctx: StateContext<AppStateModel>, action: AddCrewCode) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      crewCode: action?.payload,
    });
  }
  @Action(DeleteCrewCode)
  deleteCrewCode(ctx: StateContext<AppStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      crewCode: '',
    });
  }

  @Action(AddFlushWorkRequestId)
  addFlushWorkRequestId(ctx: StateContext<AppStateModel>, action: AddFlushWorkRequestId) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      flushWorkRequestId: action?.payload,
    });
  }
  @Action(DeleteFlushWorkRequestId)
  deleteFlushWorkRequestId(ctx: StateContext<AppStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      flushWorkRequestId: '',
    });
  }

  @Action(AddFlushUserRole)
  addFlushUserRole(ctx: StateContext<AppStateModel>, action: AddFlushUserRole) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      flushUserRole: action?.payload,
    });
  }
  @Action(DeleteFlushUserRole)
  deleteFlushUserRole(ctx: StateContext<AppStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      flushUserRole: '',
    });
  }

  @Action(AddWorkRequestGlobalId)
  addWorkRequestGlobalId(ctx: StateContext<AppStateModel>, action: AddWorkRequestGlobalId) {
    const state = ctx.getState();
    sessionStorage.setItem('workRequestGlobalId', action?.payload);
    localStorage.setItem('workRequestGlobalId', action?.payload);
    ctx.setState({
      ...state,
      workRequestGlobalId: action?.payload,
    });

  }
  @Action(DeleteWorkRequestGlobalId)
  deleteWorkRequestGlobalId(ctx: StateContext<AppStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      workRequestGlobalId: '',
    });
  }

  @Action(AddWorkComponentGlobalId)
  addWorkComponentGlobalId(ctx: StateContext<AppStateModel>, action: AddWorkComponentGlobalId) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      workComponentGlobalId: action?.payload,
    });

  }
  @Action(AddWorkComponentId)
  addWorkComponentId(ctx: StateContext<AppStateModel>, action: AddWorkComponentId) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      workComponentId: action?.payload,
      workDescription: action?.payload,
    });

  }
  @Action(DeleteWorkComponentGlobalId)
  deleteWorkComponentGlobalId(ctx: StateContext<AppStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      workComponentGlobalId: '',
    });
  }

  @Action(AddFacilityGlobalId)
  addFacilityGlobalId(ctx: StateContext<AppStateModel>, action: AddFacilityGlobalId) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      facilityGlobalId: action?.payload,
    });
  }
  @Action(DeleteFacilityGlobalId)
  deleteFacilityGlobalId(ctx: StateContext<AppStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      facilityGlobalId: '',
    });
  }
  @Action(AddPausedJob)
  addPausedJob(ctx: StateContext<AppStateModel>, payload: boolean) {
    const state = ctx.getState();
    ctx.setState(
      {
        ...state,
        pausedJob: true
      }
    );
  }
  @Action(DeletePausedJob)
  deletePausedJob(ctx: StateContext<AppStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      pausedJob: false,
    });
  }
  @Action(AddStartJobPhotos)
  addStartJobPhotos(ctx: StateContext<AppStateModel>, { payload }: AddStartJobPhotos) {
    const state = ctx.getState();
    ctx.setState(
      patch({
        ...state,
        startJobPhotos: [...payload],
      })
    );
  }
  @Action(DeleteStartJobPhoto)
  deleteStartJobPhotos(ctx: StateContext<AppStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      startJobPhotos: new Array<Photo>(),
    });
  }

  // Fetch all static config data
  @Action(AddMasterData)
  addFeatureToggle(ctx: StateContext<AppStateModel>, action: AddMasterData) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      masterData: action?.payload,
    });
  }
  // Job Detail
  @Action(AddJobDetails)
  addJobDetails(ctx: StateContext<AppStateModel>, action: AddJobDetails) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      jobDetails: action?.payload,
    });
  }
  @Action(DeleteJobDetails)
  deleteJobDetails(ctx: StateContext<AppStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      jobDetails: new JobDetail(null, null, null, null, null, null, null, null, 0),
    });
  }

  // StructureInfo
  @Action(AddStructureInformation)
  addStructureInformation(ctx: StateContext<AppStateModel>, action: AddStructureInformation) {  
    const state = ctx.getState();
    ctx.setState({
      ...state,
      structureInfo: action?.payload,
    });
  }
  @Action(DeleteStructureInformation)
  deleteStructureInformation(ctx: StateContext<AppStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      structureInfo: new StructureInformation(null, false),
    });
  }

  // FlushProcessInfo
  @Action(AddFlushInformation)
  addFlushInformation(ctx: StateContext<AppStateModel>, action: AddFlushInformation) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      flushProcess: action?.payload,
    });
  }
  @Action(DeleteFlushInformation)
  deleteFlushInformation(ctx: StateContext<AppStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      flushProcess: new Array<FlushInformation>(),
    });
  }

  // AccessInfo
  @Action(AddAccessInformation)
  addAccessInformation(ctx: StateContext<AppStateModel>, action: AddAccessInformation) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      accessInfo: action?.payload,
    });
  }
  @Action(DeleteAccessInformation)
  deleteAccessInformation(ctx: StateContext<AppStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      accessInfo: new AccessInformation(null, null, null, null, null, null, null, null, null, null),
    });
  }

  //Emergency Info
  @Action(AddEmergencyInformation)
  addEmergencyInformation(ctx: StateContext<AppStateModel>, action: AddEmergencyInformation) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      emergencyInfo: action?.payload,
    });
  }
  @Action(DeleteAccessInformation)
  deleteEmergencyInformation(ctx: StateContext<AppStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      emergencyInfo: null,
    });
  }

  @Action(MapLayers.Set)
  setMapLayers(ctx: StateContext<AppStateModel>, action: MapLayers.Set) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      mapLayers: action?.payload.map(l => l),
    });
  }
}
