<app-banner [(banner)]="errorBanner$">
  <ce-button class="p-2" type="secondary" label="Add Structure" (clickEvent)="goToLink('/flush-information/structure-information')"></ce-button>
</app-banner>
<div *ngIf="!(errorBanner$ | async).show">
  <div class="row justify-content-center align-items-center">
    <div class="col">
      <h3>Access Information</h3>
    </div>
    <div class="align-middle">
      <ce-button styleClass="align-middle flush-info-camera-btn" icon="icon-camera" iconDirection="left" label="Take photo" type="secondary" (clickEvent)="takePhoto()"></ce-button>
    </div>
  </div>
  <p>Please confirm or provide all access details on the location</p>
  <form *ngIf="showEmergencyQuestions" [formGroup]="emergencyFormGroup">
    <div class="form-group row">
      <div class="col-lg-5">
        <p><b>Are you on location?</b><a *ngIf="!this.emergencyFormGroup.controls['isOnLocation'].valid" class="flush-process-error"> *</a></p>
        <div class="row">
          <div class="col-12">
            <ce-radio-group size="large"formControlName="isOnLocation" [isDisabled]='false'>
              <ce-radio-button size="large" label="No" id="TheEmployeeIsNotOnLocation" value=false [isChecked]="this.emergencyFormGroup.controls['isOnLocation'].value == 'false'" [isDisabled]="false" [isError]="false"></ce-radio-button>
              <ce-radio-button size="large" label="Yes" id="TheEmployeeIsOnLocation" value=true [isChecked]="this.emergencyFormGroup.controls['isOnLocation'].value == 'true'" [isDisabled]="false" [isError]="false" ></ce-radio-button>
            </ce-radio-group>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row" *ngIf="this.emergencyFormGroup.controls['isOnLocation'].value != ''">
      <div class="col-lg-5 ">
        <p><b>Do you currently have access?</b><a *ngIf="!this.emergencyFormGroup.controls['isAccessObtained'].valid" class="flush-process-error"> *</a></p>
        <div class="row">
          <div class="col-12">
            <ce-radio-group size="large"formControlName="isAccessObtained" [isDisabled]='false'>
              <ce-radio-button size="large" label="No" id="TheEmployeeDoesNotHaveAccess" value=false [isChecked]="this.emergencyFormGroup.controls['isAccessObtained'].value == 'false'" [isDisabled]="false" [isError]="false"></ce-radio-button>
              <ce-radio-button size="large" label="Yes" id="TheEmployeeDoesHaveAccess" value=true [isChecked]="this.emergencyFormGroup.controls['isAccessObtained'].value == 'true'" [isDisabled]="false" [isError]="false"></ce-radio-button>
            </ce-radio-group>
          </div>
        </div>
      </div>
    </div>
  </form>
  <form *ngIf="showAccessQuestions && accessInfoFormGroup" [formGroup]="accessInfoFormGroup">
    <div class="form-group row">
      <div class="col-lg-5">
        <p><b>Will the vactor truck have sufficient access?</b><a *ngIf="!this.accessInfoFormGroup.controls['isSufficientAccess'].valid" class="flush-process-error"> *</a></p>
        <p>The vactor truck is approximately 4 car lengths.</p>
        <div class="row">
          <div class="col-12">
            <ce-radio-group size="large"formControlName="isSufficientAccess" [isDisabled]='false'>
              <ce-radio-button size="large" label="No" id="TheVactorNotHaveSuffiecientAccess" value=false [isChecked]="this.accessInfoFormGroup.controls['isSufficientAccess'].value == 'false'" [isDisabled]="false" [isError]="false"></ce-radio-button>
              <ce-radio-button size="large" label="Yes" id="TheVactorHaveSuffiecientAccess" value=true [isChecked]="this.accessInfoFormGroup.controls['isSufficientAccess'].value == 'true'" [isDisabled]="false" [isError]="false" ></ce-radio-button>
            </ce-radio-group>
          </div>
        </div>
      </div>
    </div>
    <!-- If vactor truck DOES NOT have suffiecient access -->
    <div class="form-group row">
      <div class="col-lg-5 ">
        <p><b>Is the structure on a critical roadway?</b><a *ngIf="!this.accessInfoFormGroup.controls['isOnCriticalRoadway'].valid" class="flush-process-error"> *</a></p>
        <div class="row">
          <div class="col-12">
            <ce-radio-group size="large"formControlName="isOnCriticalRoadway" [isDisabled]='false'>
              <ce-radio-button size="large" label="No" id="StructureNotOnACriticalRoadWay" value=false [isChecked]="this.accessInfoFormGroup.controls['isOnCriticalRoadway'].value == 'false'" [isDisabled]="false" [isError]="false"></ce-radio-button>
              <ce-radio-button size="large" label="Yes" id="StructureIsOnACriticalRoadWay" value=true [isChecked]="this.accessInfoFormGroup.controls['isOnCriticalRoadway'].value == 'true'" [isDisabled]="false" [isError]="false"></ce-radio-button>
            </ce-radio-group>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-lg-5 ">
        <p><b>Are cone hives required?</b><a *ngIf="!this.accessInfoFormGroup.controls['isConeHivesRequired'].valid" class="flush-process-error"> *</a></p>
        <div class="row">
          <div class="col-12">
            <ce-radio-group size="large"formControlName="isConeHivesRequired" [isDisabled]='false'>
              <ce-radio-button size="large" label="No" id="ConeHivesNotRequired" value=false [isChecked]="this.accessInfoFormGroup.controls['isConeHivesRequired'].value == 'false'" [isDisabled]="false" [isError]="false"></ce-radio-button>
              <ce-radio-button size="large" label="Yes" id="ConeHivesRequired" value=true [isChecked]="this.accessInfoFormGroup.controls['isConeHivesRequired'].value == 'true'" [isDisabled]="false" [isError]="false"></ce-radio-button>  
            </ce-radio-group>
          </div>
        </div>
      </div>
    </div>
    <div  class="form-group row">
      <div class="col-lg-5 ">
        <p><b>Are flaggers required to work safely?</b><a *ngIf="!this.accessInfoFormGroup.controls['isFlagSafetyRequired'].valid" class="flush-process-error"> *</a></p>
        <div class="row">
          <div class="col-12">
            <ce-radio-group size="large"formControlName="isFlagSafetyRequired" [isDisabled]='false'>
              <ce-radio-button size="large" label="No" id="FlaggersNotRequiredToWorkSafely" value=false [isChecked]="this.accessInfoFormGroup.controls['isFlagSafetyRequired'].value == 'false'" [isDisabled]="false" [isError]="false"></ce-radio-button>
              <ce-radio-button size="large" label="Yes" id="FlaggersRequiredToWorkSafely" value=true [isChecked]="this.accessInfoFormGroup.controls['isFlagSafetyRequired'].value == 'true'" [isDisabled]="false" [isError]="false"></ce-radio-button>
            </ce-radio-group>
          </div>
        </div>
      </div>
    </div>
    <div  class="form-group row">
      <div class="col-lg-5 ">
        <p><b>Is the customer required for access?</b><a *ngIf="!this.accessInfoFormGroup.controls['isCustomerRequired'].valid" class="flush-process-error"> *</a></p>
        <div class="row">
          <div class="col-12">
            <ce-radio-group size="large"formControlName="isCustomerRequired" [isDisabled]='false'>
              <ce-radio-button size="large" label="No" id="CustomerNotRequiredForAccess" value=false [isChecked]="this.accessInfoFormGroup.controls['isCustomerRequired'].value == 'false'" [isDisabled]="false" [isError]="false"></ce-radio-button>
              <ce-radio-button size="large" label="Yes" id="CustomerRequiredForAccess" value=true [isChecked]="this.accessInfoFormGroup.controls['isCustomerRequired'].value == 'true'" [isDisabled]="false" [isError]="false"></ce-radio-button>
            </ce-radio-group>
          </div>
        </div>
      </div>
    </div>

    <div  class="form-group row">
      <div class="col-lg-5 ">
        <p><b>Are there parking restrictions at the location?</b><a *ngIf="!this.accessInfoFormGroup.controls['isParkingRestrictions'].valid" class="flush-process-error"> *</a></p>
        <div class="row">
          <div class="col-12">
            <ce-radio-group size="large"formControlName="isParkingRestrictions" [isDisabled]='false'>
              <ce-radio-button size="large" label="No" id="ParkingRestrictionsNotAtTheLocation" value=false [isChecked]="this.accessInfoFormGroup.controls['isParkingRestrictions'].value == 'false'" [isDisabled]="false" [isError]="false"></ce-radio-button>
              <ce-radio-button size="large" label="Yes" id="ParkingRestrictionsAtTheLocation"value=true [isChecked]="this.accessInfoFormGroup.controls['isParkingRestrictions'].value == 'true'" [isDisabled]="false" [isError]="false"></ce-radio-button>
            </ce-radio-group>
          </div>
        </div>
      </div>
    </div>
    <!-- Parking restrictions -->
    <div >
      <div>
        <p><b>Please provide detail on the parking restrictions</b><a *ngIf="!this.accessInfoFormGroup.controls['parkingRestrictions'].valid" class="flush-process-error"> *</a></p>
      </div>
      <div class="row">
        <div class="col-12 ">
          <app-flush-grid [isFlushGridSimple]="true" *ngIf="parkingResDataGroup.length > 0 && !isMobile" [data]="parkingResDataGroup" [displayActionsHeader]="true" [columns]="parkingHeader" [actions]="[{header: ' ', caption: '', type: 'delete'}]" (actionClicked)="relayAction($event)"></app-flush-grid>
        </div>
      </div>
      <div *ngIf="isMobile">
        <div *ngFor="let data of parkingResDataGroup">
          <div class="row">
            <div class="col-3">
              <small>Asset Tag(s)</small>
            </div>
            <div class="col-7">
              <label class="mobilefont">{{data.assetTagFacilities}}</label>
            </div>
            <div class="col-2 ">
              <span class="icon icon-trash mobiledelete"  (click)="relayAction({action: 'delete', value: data})"></span>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <small>Restriction </small>
            </div>
            <div class="col-7">
              <label class="mobilefont">{{data.restriction}}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <small>Days </small>
            </div>
            <div class="col-9">
              <label class="mobilefont">{{data.days}}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <small>Start Time </small>
            </div>
            <div class="col-9">
              <label class="mobilefont">{{data.start_time}}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <small>End Time </small>
            </div>
            <div class="col-9">
              <label class="mobilefont">{{data.end_time}}</label>
            </div>
          </div>
          <hr/>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5 col-12">
          <ce-button size="large" type="secondary" label="Add parking restrictions" icon='icon-plus-line' iconDirection='left' (clickEvent)="addParkingRestrictionsBtn()"></ce-button>
        </div>
      </div>
    </div>

    <!-- Additional access details textbox -->
    <div class="form-group row">
      <div class="col-lg-5 ">
        <p><b>Additional access details</b></p>
        <p>Is the location a heavy traffic area, street entry against traffic, is the street too narrow for the Vactor truck, is there scaffolding or construction, is the structure paved over?</p>
        <ce-input inputType="textarea" label="Additional Access Details" name="additionalAccessDetails" id="additionalAccessDetails" formControlName="additionalAccessDetails"></ce-input>
      </div>
    </div>

    <!-- Add contacts -->
    <div>
      <!-- <div class="col-lg-12 col-sm-12"> -->
        <p><b>Point of contact for this location</b><a *ngIf="this.accessInfoFormGroup.controls['isCustomerRequired'].value == 'true' && !this.accessInfoFormGroup.controls['contacts'].valid" class="flush-process-error"> *</a></p>
      <!-- </div> -->
      <div class="row">
      <div class="col-12 ">
        <app-flush-grid [isFlushGridSimple]="true" *ngIf="contactsDataGroup.length > 0 && !isMobile" [data]="contactsDataGroup" [displayActionsHeader]="true" [columns]="contactsDataColumns" [actions]="[{header: 'Action', caption: 'Caption', type: 'delete'}]" (actionClicked)="deleteContactBtn($event)" [isSelectable]="false"></app-flush-grid>
      </div>
    </div>
      <div *ngIf="isMobile" >
      
          <div  *ngFor="let data of contactsDataGroup" >
        
           <div class="row">
            <div class="col-3">
              <small>Name </small>
            </div>
            <div class="col-7 mobilefont">
              <label class="mobilefont">{{data.name}}</label>
            </div>
            <div class="col-2 ">
              <span class="icon icon-trash mobiledelete"  (click)="deleteContactBtn({action: 'delete', value: data})"></span>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <small>Email </small>
            </div>
            <div class="col-9 mobilefont">
              <label class="mobilefont">{{data.email}}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <small>Phone  </small>
            </div>
            <div class="col-9 mobilefont">
              <label class="mobilefont">{{data.phoneNumber}}</label>
            </div>
          </div> 
          <hr/>
        </div>
      </div>
      <div class="col-lg-5 ">
        <ce-button size="large" type="secondary" label="Add contact" icon='icon-setup-service' iconDirection='left' (clickEvent)="addContactsBtn()"></ce-button>
      </div>
      <br />
    </div>

    <div class="row">
      <div *ngIf="!isEdit" class="col-auto style-guide-close-modal-btn">
        <ce-button size='large' (clickEvent)="previousClick()" label="Previous" type="secondary"></ce-button>
      </div>
      <div class="col-auto">
        <ce-button size='large' *ngIf="!isEdit" data-custom-id="CCAccessInfoPage" data-custom-bhvr="PROCESSCHECKPOINT" (clickEvent)="nextClick()" label="Next" type="primary" [disabled]="!this.accessInfoFormGroup.valid"></ce-button>
        <ce-button size='large' *ngIf="isEdit" (clickEvent)="saveClick()" label="Save" type="primary" [disabled]="!this.accessInfoFormGroup.valid"></ce-button>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-12 col-lg-4">
        <a class='btn-danger-link' (click)="cancelRequestInProgres()">Cancel Request</a>
      </div>
    </div>
  </form>
</div>

<!-- modal dialog for getting parking restrictions -->
<app-modal [showModal]="openParkingDialog" modalHeading="Add parking restriction" (closeDialogClick)="closeParkingDialog()" [showClose]='true' style="margin-bottom: 0px;">

  <div class="container" *ngIf="openParkingDialog">
    <div class="row">
      <div class="col ">
        <form [formGroup]="accessInfoFormGroup">
          <div formArrayName="parkingRestrictions">
            <div [formGroupName]="getCurrentParkingRestrictionIndex()"> 
              <p class="mb-4">Enter details for the parking restriction for this location.</p>
              <div class="style-rule">
                <div class="row">
                  <div class="col">
                    <p *ngIf="assetTagOpts.length > 1" class="body2">Please click on the structure(s)  you want to specify a parking restriction for:</p>
                  </div>
                </div>
                <div class="form-group row row-eq-height">
                  <div *ngFor="let assetTagFC of assetTagOpts; let i = index" class="col-md-6 col-sm-12 form-group eq-item" >
                    <div formArrayName="assetTagFacilities" class="eq-item" (change)="assetTagTileClicked($event)">
                      <app-tile value="{{assetTagFC?.structureId}}" formControlName="{{i}}" [isChecked]="assetTagOpts.length === 1" [isDisabled]="assetTagOpts.length === 1" class="eq-item">
                        <div class="p-4 pr-5" style="height: 100%;">
                          <p class="mb-1">{{assetTagFC?.type}}</p>
                          <h4 class="mb-1">{{assetTagFC?.structureId}}</h4>
                          <p class="mb-1">{{assetTagFC?.frontAddress}}</p>
                        </div>
                      </app-tile>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="isAssetTag">
                <div class="form-group row">
                  <div class="col-md-8">
                    <app-selectbox formControlName="restriction" placeholder="Parking Restrictions" [options]="parkingResOpts | async"></app-selectbox>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p>Days of the week</p>
                  </div>
                </div>
                <div formGroupName="days" class="row">
                  <div class="col parking-rest-days-col">
                    <div class="access-info-center-text">
                      <app-checkbox (clickEvent)="toggleAll()"></app-checkbox>
                      <p style="width: 2.5rem;">All</p>
                    </div>
                    <div class="access-info-center-text">
                      <app-checkbox formControlName="sun" [isChecked]="allCheckbox" value="Sunday"></app-checkbox>
                      <p style="width: 2.5rem;">Sun</p>
                    </div>
                    <div class="access-info-center-text">
                      <app-checkbox formControlName="mon" [isChecked]="allCheckbox" value="Monday"></app-checkbox>
                      <p style="width: 2.5rem;">Mon</p>
                    </div>
                    <div class="access-info-center-text">
                      <app-checkbox formControlName="tue" [isChecked]="allCheckbox" value="Tuesday"></app-checkbox>
                      <p style="width: 2.5rem;">Tue</p>
                    </div>
                    <div class="access-info-center-text">
                      <app-checkbox formControlName="wed" [isChecked]="allCheckbox" value="Wednesday"></app-checkbox>
                      <p style="width: 2.5rem;">Wed</p>
                    </div>
                    <div class="access-info-center-text">
                      <app-checkbox formControlName="thur" [isChecked]="allCheckbox" value="Thursday"></app-checkbox>
                      <p style="width: 2.5rem;">Thur</p>
                    </div>
                    <div class="access-info-center-text">
                      <app-checkbox formControlName="fri" [isChecked]="allCheckbox" value="Friday"></app-checkbox>
                      <p style="width: 2.5rem;">Fri</p>
                    </div>
                    <div class="access-info-center-text">
                      <app-checkbox formControlName="sat" [isChecked]="allCheckbox" value="Saturday"></app-checkbox>
                      <p style="width: 2.5rem;">Sat</p>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-12 col-lg-12 col-md-12">
                    <p>Start Time</p>
                    <div id="startTimeSelector" formGroupName="start_time" class="row" [ngClass]="{'no-gutters': isMobile}">
                        <div class="col-4">
                          <app-selectbox formControlName="hour" placeholder="Hour" [icon]="'icon icon-caret-button'" [options] ="[ {value: '1', option: '01'},
                          {value: '2', option: '02'},
                          {value: '3', option: '03'},
                          {value: '4', option: '04'},
                          {value: '5', option: '05'},
                          {value: '6', option: '06'},
                          {value: '7', option: '07'},
                          {value: '8', option: '08'},
                          {value: '9', option: '09'},
                          {value: '10', option: '10'},
                          {value: '11', option: '11'},
                          {value: '12', option: '12'}]" ></app-selectbox>                        
                        </div>
                        <div class="col-4">
                          <app-selectbox formControlName="minute" placeholder="Minute" [defaultIndex]="defaultStartMinute"  [icon]="'icon icon-caret-button'" [options]="[
                          {value: '00', option: '00'},
                          {value: '15', option: '15'},
                          {value: '30', option: '30'},
                          {value: '45', option: '45'}]"></app-selectbox>
                        </div>
                        <div class="col-4">
                          <app-selectbox formControlName="ampm" placeholder="AM/PM" [options]="[
                          {option: 'AM', value: 'AM'},
                          {option: 'PM', value: 'PM'}
                          ]"></app-selectbox>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-12 col-md-12 col-lg-12">
                    <p>End Time</p>
                    <div id="endTimeSelector" formGroupName="end_time" class="row" [ngClass]="{'no-gutters': isMobile}">
                      <div class="col-4">
                        <app-selectbox formControlName="hour" placeholder="Hour" [icon]="'icon icon-caret-button'" [options] ="[ {value: '1', option: '01'},
                        {value: '2', option: '02'},
                        {value: '3', option: '03'},
                        {value: '4', option: '04'},
                        {value: '5', option: '05'},
                        {value: '6', option: '06'},
                        {value: '7', option: '07'},
                        {value: '8', option: '08'},
                        {value: '9', option: '09'},
                        {value: '10', option: '10'},
                        {value: '11', option: '11'},
                        {value: '12', option: '12'}]" ></app-selectbox>
                      </div>
                      <div class="col-4">
                        <app-selectbox formControlName="minute" placeholder="Minute" [defaultIndex]="defaultEndMinute" [icon]="'icon icon-caret-button'" [options]="[
                        {value: '00', option: '00'},
                        {value: '15', option: '15'},
                        {value: '30', option: '30'},
                        {value: '45', option: '45'}]"></app-selectbox>
                      </div>
                      <div class="col-4">
                        <app-selectbox formControlName="ampm" placeholder="AM/PM" [options]="[
                        {option: 'AM', value: 'AM'},
                        {option: 'PM', value: 'PM'}
                        ]"></app-selectbox>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" style="margin-bottom: 4.5rem;">
                  <div class="col-12 col-md-auto style-guide-close-modal-btn">
                    <ce-button size="large" (clickEvent)="closeParkingDialog()" label="Cancel" type="secondary"></ce-button>
                  </div>
                  <div class="col-12 col-md-auto order-first order-md-last">
                    <ce-button size="large" (clickEvent)="saveParkingDialog()" label="Save" type="primary" [disabled]="!parkingRestrictionsIsValid || !isAssetTag"></ce-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</app-modal>


<!-- model dialog for adding contacts -->
<app-modal [showModal]="openContactDialog" modalHeading="Add point of contact" (closeDialogClick)="closeContactDialog()" [showClose]='true'>
    <div class="container" *ngIf="openContactDialog">
      <div class="row">
        <div class="col-12 mb-5">
          <form [formGroup]="accessInfoFormGroup">
            <div formArrayName="contacts">
              <div [formGroupName]="getCurrentContactsIndex()">
                <p>Enter details for the point of contact for this location.</p>
                <ce-input 
                  formControlName="name" 
                  label="Name" 
                  [showError]="accessInfoFormGroup?.get('contacts')?.at(getCurrentContactsIndex())?.get('name')?.invalid && accessInfoFormGroup?.get('contacts')?.at(getCurrentContactsIndex())?.get('name')?.dirty"
                  [errorMessage]="'Name is required.'">
                </ce-input>
                <ce-input 
                  formControlName="email" 
                  label="Email"
                  [showError]="accessInfoFormGroup?.get('contacts')?.at(getCurrentContactsIndex())?.get('email')?.invalid"
                  [errorMessage]="'Please enter a valid email address.'">
                </ce-input>
                <ce-input 
                  formControlName="phoneNum" 
                  label="Phone Number" 
                  [showError]="accessInfoFormGroup?.get('contacts')?.at(getCurrentContactsIndex())?.get('phoneNum')?.invalid" 
                  [errorMessage]="'Invalid phone number format. Acceptable formats: 1234567890, 123-456-7890, (123) 456-7890'"
                  style="max-width: 0;">
                </ce-input>
                <div class="row">
                  <div class="col-auto style-guide-close-modal-btn">
                    <ce-button size="large" (clickEvent)="closeContactDialog()" label="Cancel" type="secondary"></ce-button>
                  </div>
                  <div class="col-auto">
                    <ce-button size="large" (clickEvent)="saveContactDialog()" label="Save" type="primary" [disabled]="accessInfoFormGroup?.get('contacts')?.at(getCurrentContactsIndex())?.invalid"></ce-button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>   
  </app-modal>

<app-modal [showModal]="openEmgRequestDialog" modalHeading="Emergency Flush Request with Access" (closeDialogClick)="closeEmgRequestDialog()" [showClose]='true'>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <p>To submit a request with access, all of the following must be true:</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ul class="formatted-list">
          <li><b>There is sufficient space for Vactor Truck Parking</b></li>
          <li><b>The structure isn't on a critical roadway</b></li>
          <li><b>Cone Hives are not needed or already present</b></li>
          <li><b>Flaggers not needed or already present</b></li>
          <li><b>Customer Access is not needed or already attained</b></li>
          <li><b>There arent Perlite bags in the structure</b></li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p>If any one of the above confirmations aren't true about the job, please press Modify to fill out the Access Information.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-auto">
        <ce-button size="large" (clickEvent)="modifyAccessInfoForm()" label="Modify" type="secondary"></ce-button>
      </div>
      <div class="col-auto">
        <ce-button size="large" (clickEvent)="confirmEmergencyForm()" label="Confirm" type="primary"></ce-button>
      </div>
    </div>
  </div>
</app-modal>

<app-launch-camera-modal #launchCameraModalComponent></app-launch-camera-modal>