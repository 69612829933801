export class Structure {
  facilityId: string;
  type: string;
  structureId: string;
  borough: string;
  frontAddress: string;
  isCustomerOwned: boolean;
  isManual: boolean;
  id?: number;  
  longitude?:string;
  latitude?:string;
  submissionStatus?: string;
  status?:string;
  constructor(
    facilityId: string,
    type: string,
    // structureId: string,
    borough: string,
    frontAddress: string,
    isCustomerOwned: boolean,
    isManual: boolean,
    structureId: string,
    id?: number,
    longitude?:string,
    latitude?:string,
    submissionStatus?: string
  ) {
    this.facilityId = facilityId;
    this.type = type;
    // this.structureId = structureId;
    this.borough = borough;
    this.frontAddress = frontAddress;
    this.isCustomerOwned = isCustomerOwned;
    this.isManual = isManual;
    this.structureId = structureId;
    this.id = id;
    this.latitude = latitude;
    this.longitude = longitude;
    this.submissionStatus = submissionStatus;
    this.status = submissionStatus;
  }
}
