import { Pipe, PipeTransform } from '@angular/core';
import { Photo } from '../../models/photo';
/*
 * Identify the type of the array of SortableItem.data[i].value
 *
 * Usage:
 *   item | sortableType
 *  
 * can return 'icon', 'text', 'cta'
 */
@Pipe({
  name: 'sortableType',
  pure: false,
})
export class SortableItemTypePipe implements PipeTransform {
  transform(item: any): string {
    if (typeof item === 'string') {
        return 'text';
    } 
    else if (typeof item === 'object') {
        // CTA
        return item.type;
    }
    return '';    
  }
}