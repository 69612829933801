import { Injectable } from '@angular/core';
import { WorkComponent } from 'src/app/interfaces/work-component';
import { StructureInfo } from 'src/app/models/construction-crew/structure-info';
import { Facility } from 'src/app/models/ords/facility';
import { Structure } from 'src/app/models/structure.model';
import { WorkRequest } from 'src/app/interfaces/work-request';

@Injectable({
  providedIn: 'root'
})
export class MapperService {

  constructor() { }

  mapStructureToStructureInfo(structure: Structure, wrNumber, workRequestId, workcomponentId, searchWr: boolean = false): StructureInfo {
    return {
      facilityId: structure?.facilityId,
      facilityType: structure?.type,
      structureNumber: structure?.structureId,
      address: structure?.frontAddress,
      isCustomerOwned: structure?.isCustomerOwned,
      isManuallyAdded: structure?.isManual,
      borough: structure?.borough,
      wrNumber: wrNumber,
      id: structure?.id > -1 ? structure?.id : undefined,
      crewCode: undefined,
      workRequestId: workRequestId,
      workComponentId: workcomponentId
    } as StructureInfo;
  }
  mapStructureInfoToStructure(structureInfo: StructureInfo): Structure {
    return new Structure(structureInfo.facilityId, structureInfo.facilityType, structureInfo.borough, structureInfo.address, structureInfo.isCustomerOwned, structureInfo.isManuallyAdded, structureInfo.structureNumber, structureInfo?.id, structureInfo.longitude, structureInfo.latitude, structureInfo.submissionStatus)
  }
  
  mapFacilityToStructure(facility: Facility): Structure {
    return new Structure(facility.facilityID.toString(), facility.facilityType, facility.borough, facility.address, false, false, facility.assetTag, null, facility.longitude, facility.latitude);
  }

  mapFacilityToStructureInfo(facility: Facility, wrNumber: string, workRequestId: string): StructureInfo {
    return {
      facilityId: facility?.facilityID.toString(),
      facilityType: facility?.facilityType,
      structureNumber: facility?.assetTag,
      address: facility?.address,
      isCustomerOwned: undefined,
      isManuallyAdded: facility?.isManualAdd,
      borough: facility?.borough,
      wrNumber: wrNumber,
      id: undefined,
      crewCode: undefined,
      workRequestId: workRequestId,
      longitude: facility?.longitude,
      latitude: facility?.latitude,
      workComponentGlobalId: null,
      workComponentId: null,
      createdOn: null,
      updatedOn: null
    } as StructureInfo;
  }

  flattenWcListToWrList (wcList: WorkComponent[], roleType: string = 'cc'): WorkRequest[] {
    return wcList.map((curVal) => {
      const forms = { ...curVal.sourceWRInformation }
      let wrList = {
        ...curVal.workRequest,
        images: forms.ccImages,
        jobDetail: forms.flushJobDetails,
      }
      if (roleType === 'cc') {
        return ({
          ...wrList,
          accessInfo: forms.accessInfo,
          flushInfo: forms.flushInfo,
          structureInfo: forms.structureInfo
        })
      } else {
        return ({
          ...wrList,
          accessInfo: forms.accessInfoModel,
          flushInfo: forms.flushInfoModel,
          structureInfo: forms.structureInformation
        })
      }
    })
  }
}
