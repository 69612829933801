<div class="row">
  <div class="col">
    <h3 class="mb-3">Request Summary</h3>
  </div>
</div>
<div class="row">
  <div class="col">
    <p class="body2">{{today | dash}}</p>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-auto align-text">
      <h4>Job Details</h4>
      <ce-button size="large" *ngIf="!isSummary" type="secondary" label="Edit" icon="icon-pencil" iconDirection="left" (clickEvent)="navigateToDetails()"></ce-button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3">
      <b>Source WR#</b>
      <p [ngClass]="{'dash-text': !wrDetails?.workRequestNo}">{{wrDetails?.workRequestNo | dash}}</p>
    </div>
    <div class="col-md-3">
      <b>WC</b>
      <p [ngClass]="{'dash-text': !(dashboardDetailsEntity?.workComponentDescription || wrDetails?.wR_DESC)}">{{dashboardDetailsEntity?.workComponentDescription || wrDetails?.wR_DESC | dash}}</p>
    </div>
  </div>
  <div class="form-group row">
    <div class="col-md-3">
      <b>Job</b>
      <p [ngClass]="{'dash-text': !jobDetails?.jobPriorityDesc}">{{jobDetails?.jobPriorityDesc | dash}}</p>
    </div>
    <!-- <div class="col-md-3">
      <b>Subtype</b>
      <p [ngClass]="{'dash-text': !jobDetails?.jobSubtypeName}">{{jobDetails?.jobSubtypeName | dash}}</p>
    </div> -->
    <div class="col-md-2">
      <b>Priority</b>
      <p [ngClass]="{'dash-text': !jobDetails?.jobPriorityId}">{{jobDetails?.jobPriorityId | dash}}</p>
    </div>
    <div class="col-md-2">
      <b>Description</b>
      <p [ngClass]="{'dash-text': !jobDetails?.jobDescription}">{{jobDetails?.jobDescription | dash}}</p>
    </div>
  </div>
</div>
<div class="mb-5 row">
  <div class="col-12" *ngIf="!isMobile">
    <app-details-box header="Structure Information" [hideLink] = "isSummary" [link]="{url: '/flush-information/structure-information', text:'Edit', params: {isEdit: true}, icon:'icon-edit'}">
      <div class="form-group" *ngIf="structureInfoData?.length > 0 ">
        <app-flush-grid [isSelectable]="false" [isFlushGridSimple]="true" [ngClass] ="{'no-highlight' : true}" [allowVerticalDataTable]="true"
        *ngIf="structureInfoData?.length > 0" [data]="structureInfoData | dash" [columns]="structureInfoCols" (actionClicked)="onStructureClicked($event)"
        [actions]="[]">
      </app-flush-grid>
      <p *ngIf="structureInfoData?.length === 0 || structureInfoData == undefined">There is no structure information available for this job.</p>
      </div>
    </app-details-box>
      </div>
      <div class="col-12" *ngIf="isMobile" >
        <app-details-box header="Structure Information" [hideLink] = "isSummary" [link]="{url: '/flush-information/structure-information', text:'Edit', params: {isEdit: true}, icon:'icon-edit'}">
          <div  *ngIf="structureInfoData?.length > 0 " class="mobileStructure">
        <hr/>
        <div  *ngFor="let data of structureInfoData" >

            <div class="row">
            <div class="col-4">
              <small>Type </small>
            </div>
            <div class="col-8">
              <label >{{data.type}}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <small>Structure No </small>
            </div>
            <div class="col-8">
              <label> <a (click) = "showMapCoordinates(data)">{{data.structureId}} </a></label>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <small>Borough </small>
            </div>
            <div class="col-8">
              <label >{{data.borough}}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <small> Address </small>
            </div>
            <div class="col-8">
              <label >{{data.frontAddress}}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <small>Customer Owned </small>
            </div>
            <div class="col-8">
              <label >{{data.isCustomerOwned |dash}}</label>
            </div>
          </div>
        </div>
        <hr/>
        <br/>
      </div> 

      <p *ngIf="structureInfoData?.length === 0 || structureInfoData == undefined">There is no structure information available for this job.</p>
    </app-details-box>
  </div>
</div>
<div class="mb-5 row">
  <div class="col-12">
    <app-details-box header="Access Information" [hideLink] = "isSummary" [link]="{url: '/flush-information/access-information', text:'Edit', params: {isEdit: true}, icon:'icon-edit'}">
      <div class="access-info row">
        <div class="col-12 col-lg-1">
          <i class="icon icon-user"></i>
        </div>
        <div class="col-12 col-lg-5">
          <p><b>Point of contact for this location.</b></p>
          <div class="customer-contact" *ngFor="let contact of accessInfo?.contactsDataGroup">
            <p>{{contact?.name}}</p>
            <p>{{contact?.email}}</p>
            <p>{{contact?.phoneNumber}}</p>
          </div>
          <p *ngIf="accessInfo?.contactsDataGroup == undefined || accessInfo?.contactsDataGroup?.length < 1">Point of contact is unavailable for this job.</p>
        </div>
      </div>
      <div class="access-info row">
        <div class="col-12 col-lg-1">
          <i class="icon icon-cone"></i>
        </div>
        <div class="col-12 col-lg-4">
          <p><b>Critical roadway</b></p>
          <p>{{accessInfo?.isStructureOncriticalRoadway === true ? 'Yes' : 'No' }}</p>
          <p><b>Cone hives</b></p>
          <p>{{ accessInfo?.areConeHiveRequired === true ? 'Yes' : 'No' }}</p>
          <p><b>Flaggers</b></p>
          <p>{{ accessInfo?.areFlaggersRequired === true ? 'Yes' : 'No' }}</p>
          <p><b>Customer access</b></p>
          <p>{{ accessInfo?.isCustomerRequired === true ? 'Required' : 'Not Required' }}</p>
        </div>
        <div class="col-12 col-lg-1 offset-lg-1">
          <i class="icon icon-truck"></i>
        </div>
        <div class="col-12 col-lg-4">
          <p><b>Vactor truck access</b></p>
          <p>{{accessInfo?.hasSufficientAccess === true ? 'Yes': 'No'}}</p>
        </div>
      </div>
      <div class="access-info row">
        <div class="col-12 col-lg-1">
          <i class="icon icon-car"></i>
        </div>
        <div class="col-12 col-lg-11">
          <p><b>Parking restrictions</b></p>
            <app-flush-grid *ngIf="accessInfo?.parkingRestrictions?.length > 0" [isSelectable]="false" [isFlushGridSimple]="true" [allowVerticalDataTable]="true" [data]="accessInfo?.parkingRestrictions | atomicAssetTagsForParkingRes" [columns]="accessInfoCols" [actions]="[]">
            </app-flush-grid>
          <p *ngIf="accessInfo?.parkingRestrictions?.length==0">There is no access information available for this job.</p>
        </div>
      </div>
      <div class="last-row row">
        <div class="col-12 col-lg-4">
          <p><b>Additional Details</b></p>
          <p  [ngClass]="{'dash-text': !accessInfo?.additionalAccessDetails}">{{accessInfo?.additionalAccessDetails | dash}}</p>
        </div>
      </div>
    </app-details-box>
  </div>
</div>
<div *ngFor="let flushInfo of flushInfoArr">
  <div class="mb-5 row">
    <div class="col-12">
      <app-details-box header="Flush Information: {{ getStructureType(flushInfo?.structureNumber) }} - {{ flushInfo?.structureNumber }}" [hideLink] = "isSummary" [link]="{url: '/flush-information/flush-process-information', text:'Edit', params: {isEdit: true}, icon:'icon-edit'}">
        <div class="flush-info row">
          <div class="col-12 col-lg-1">
            <i class="icon icon-eye"></i>
          </div>
          <div class="col-12 col-lg-4">
            <p><b>Equipment visibility</b></p>
            {{flushInfo?.canSeeAllEquipement === true ? 'Visible' : 'Not visible' }}
          </div>
        </div>
        <div class="flush-info row">
          <div class="col-12 col-lg-1">
            <i class="icon icon-water"></i>
          </div>
          <div class="col-12 col-lg-4">
            <p><b>Water</b></p>
            <p>{{flushInfo?.isWaterFoundOnStructure === true ? 'Yes' : 'None' }}</p>
            <p *ngIf="flushInfo?.isWaterFoundOnStructure === true && flushInfo.structurePumpRestrictionId"> {{flushInfo?.structurePumpRestrictionId}}</p>
            <p *ngIf="flushInfo?.isWaterFoundOnStructure === true"> {{flushInfo?.structureWaterId | dash}}</p>
            <p *ngIf="flushInfo?.isWaterFoundOnStructure === true"> {{flushInfo?.structureWaterQuantityId | dash}}</p>
          </div>
          <div class="col-12 col-lg-1 offset-lg-1">
            <i class="icon icon-shovel"></i>
          </div>
          <div class="col-12 col-lg-4">
            <p><b>Debris</b></p>
            <p>{{flushInfo?.isDebrisEnvironmentConditionFound === true ? 'Yes': 'None'}}</p>
            <p *ngIf="flushInfo?.isDebrisEnvironmentConditionFound"><span *ngFor="let debrisType of flushInfo?.structureDebrisTypeId; let i=index">{{debrisType}}{{i === flushInfo?.structureDebrisTypeId.length - 1 ? '' : ', '}}</span></p>
            <p><b>Amount</b></p>
            <p *ngIf="flushInfo?.isDebrisEnvironmentConditionFound" [ngClass]="{'dash-text': !flushInfo?.structureDebrisQuantityId}">{{flushInfo?.structureDebrisQuantityId | dash}}</p>
          </div>
        </div>
        <div class="flush-info row">
          <div class="col-12 col-lg-1">
            <i class="icon icon-insect"></i>
          </div>
          <div class="col-12 col-lg-4">
            <p><b>Infestation</b></p>
            <p [ngClass]="{'dash-text': !flushInfo?.structureInfestationId}" >{{ flushInfo?.structureInfestationId }}</p>
          </div>
          <div class="col-12 col-lg-1 offset-lg-1">
            <i class="icon icon-explosion"></i>
          </div>
          <div class="col-12 col-lg-4">
            <p><b>Perlite bags</b></p>
            <p>{{flushInfo?.areTherePearliteBags === true ? 'Yes': 'None'}}</p>
            <p *ngIf="flushInfo?.areTherePearliteBags === true"> {{flushInfo?.havePearliteBagsCompromized === true ? 'Compromised' : 'Not compromised'}}</p>
          </div>
        </div>
        <div class="last-row row">
          <div class="col-12 col-lg-4">
            <p><b>Additional Details</b></p>
            <p [ngClass]="{'dash-text': !flushInfo?.additionalInformation}" >{{flushInfo?.additionalInformation | dash}}</p>
          </div>
        </div>
      </app-details-box>
    </div>
  </div>
</div>
<div class="mb-5 row">
  <div class="col-12">
    <app-details-box [header]="'Request Photos (' + photos?.length + ')'" [hideLink] = "isSummary"  [link]="{url: '/flush-information/photos', text:'Edit', icon:'icon-edit'}" #flushCrewSummary>
      <app-photos *ngIf="photos?.length > 0" [cameraEnabled]="false" [isSummary]="isSummary"></app-photos>
      <p *ngIf="photos?.length == 0">There are no photos available for this job.</p>
    </app-details-box>
  </div>
</div>
<div *ngIf="!isSummary"  class="mb-5 row align-items-center">
  <div class="col-auto">
    <app-checkbox (clickEvent)="toggleCheckBox()"></app-checkbox>
  </div>
  <div class="col">
    <div [ngStyle]="{'color':isSupervisorApproved === true ? '#43A460' : '#000' }"> I have already informed my supervisor that I am submitting a request for flush</div>
  </div>   
  </div>
  <div *ngIf="isSummary"  class="mb-5 row align-items-center">
    <div class="col-auto">
      <app-checkbox [isChecked]="isOnline" value=true [isDisabled]="true"></app-checkbox>
    </div>
    <div class="col">
      <div> I have already informed my supervisor that I am submitting a request for flush</div>
    </div>   
  </div>
<div *ngIf="!isSummary" class="mb-3 row">
  <div class="col-auto style-guide-close-modal-btn">
    <ce-button size='large' label="Cancel" type="secondary" (clickEvent)="toggleCancel(); showModal = true"></ce-button>
  </div>
  <div class="col-auto">
    <ce-button size='large' label="Submit" type="primary" (clickEvent)="toggleSubmit(); showModal = true" [disabled]="!isOnline || !isSupervisorApproved || !isReviewValid || !isPhotosValid"></ce-button>
  </div>
</div>
<div *ngIf="isSummary" class="mb-3 row ">
  <div class="col-auto">
    <ce-button size='large' label="Close" (clickEvent)="closeRequest()" type="secondary"></ce-button>
  </div>
</div>
<app-modal [showModal]="showModal" [showClose]='true' (closeDialogClick)="showModal = false" [showErrorIcon]="false" [modalHeading]="showCancel ? 'Are you sure you want to cancel this request?' : 'Are you ready to submit this request?'">
  <div class="container">
    <div class="form-group row" *ngIf="showCancel">
      <div class="col">
        <p>Once you cancel this request, this action can not be undone.</p>
      </div>
    </div>
    <div class="row" *ngIf="showCancel">
      <div class="col-auto style-guide-close-modal-btn">
        <ce-button size='large' label="Yes" type="secondary" (clickEvent)="handleModalClose()" icon="icon-trash" styleClass="btn-danger-outline"></ce-button>
      </div>
      <div class="col-auto">
        <ce-button size='large' label="No" type="primary" (clickEvent)="showModal = false"></ce-button>
      </div>
    </div>
    <div class="form-group row" *ngIf="showSubmit">
      <div class="col">
        <p>Confirm all the information is correct before submitting your request.</p>
      </div>
    </div>
    <div class="row" *ngIf="showSubmit">
      <div class="col-auto style-guide-close-modal-btn">
        <ce-button size='large' label="Cancel" type="secondary" (clickEvent)="showModal = false"></ce-button>
      </div>
      <div class="col-auto">
        <ce-button size='large' label="Submit" type="primary" data-custom-id="CCReviewPage" data-custom-bhvr="COMPLETEPROCESS" (clickEvent)="handleModalClose()"></ce-button>
      </div>
    </div>
  </div>
</app-modal>
<app-modal [showSuccessIcon]="true" [showModal]="showSubmitSuccess" modalHeading="Request Successfully Submitted" [showClose]="false">
  <div class="container">
    <div class="mb-5 row">
      <div class="col">
        <span>Your request was successfully submitted to Environmental Operations for review.</span><br>
        <span>If this is not a same day request, you will recieve further instructions from your supervisor.</span>
      </div>
    </div>
    <div class="mb-4 row">
      <div class="col-auto style-guide-close-modal-btn">
        <ce-button size='large' label="Close" (clickEvent)="closeRequest()"></ce-button>
      </div>
    </div>
  </div>
</app-modal>
<app-modal [showErrorIcon]="true" [showModal]="showSubmitError" modalHeading="Request Not Submitted" [showClose]="true">
  <div class="container">
    <div class="mb-5 row">
      <div class="col">
        <span>Oops! An error occured while trying to submit this request</span><br>
        <span>{{submitFeedback}}</span>
      </div>
    </div>
    <div class="mb-4 row">
      <div class="col-auto style-guide-close-modal-btn">
        <ce-button size='large' label="Close" (clickEvent)="showSubmitError = false"></ce-button>
      </div>
    </div>
  </div>
</app-modal>
<app-modal [showBellIcon]="!isMobile" [showModal]="showSubmitTimeOut" modalHeading="Submission Timed Out" [showClose]="false">
  <div class="container">
    <div class="mb-5 row">
      <div class="col">
        <span>We're sorry. Your request took longer than normal. There is a chance it wasn't successfully submitted. </span><br/><br/>
        <span>Check the status of this request on your dashboard.</span>
      </div>
    </div>
    <div class="mb-4 row">
      <div class="col-auto style-guide-close-modal-btn">
        <ce-button size='large' label="View Dashboard" (clickEvent)="navigateToDashboard()"></ce-button>
      </div>
    </div>
  </div>
</app-modal>


<app-modal [showModal]="showMapModal" modalHeading="" (closeDialogClick)="showMapModal = false" [showClose]='true'>
  <div class="map-container" style="width: 80vw;"  [ngStyle]="{'padding-top': isMobile ? '40px' : '20px' }">
    <app-esri-map  [isSupervisor]="false" (mapLoaded)="mapLoadedEvent($event)">
    </app-esri-map>
  </div>
  <div class="row" style="padding-top: 20px;">
    <div class="col">
      <p><strong>Asset Tag - <span style="color:#0078cf;">{{this.assetTag}}</span></strong></p>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <ce-button type="secondary" label="Close" size="large" (clickEvent)="showMapModal = false">
      </ce-button>
    </div>
  </div>
</app-modal>
