import { Structure } from './structure.model';

export class StructureInformation {
  structures: Structure[];
  isValid: boolean;
  isManual: boolean;
  constructor(structures: Structure[], isManual: boolean) {
    this.structures = structures;
    this.isManual = isManual;
  }
}
