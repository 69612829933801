import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { Alert } from '@ce-lib/alert';
import { BannerService } from '../services/banner/banner.service';
import { LoggingService } from '../services/logging/logging.service';
import { catchError, tap } from 'rxjs/operators';
import { BaseService } from '../services/base/base.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private baseService: BaseService,
    private logger: LoggingService,
    private alert: Alert
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    const isOktaLoginEndpoints = request.url.endsWith('login') || request.url.endsWith('callback')
    if (request.url.includes('arcgis')) {
      // Do not modify body
      console.log('%cREQUEST INTERCEPTED' + request.url, 'color: blue; font-size: 40px');
    }
    else {
      if (request.url.includes('ce-common-video-service') || request.url.includes('ce-common-admin-utility')) {
        request = request.clone({
          setHeaders: {
            'Authorization': this.authService.accesstoken,
            APIEnvironment: environment.apimEnvironment,
            'Ocp-Apim-Subscription-Key': environment.commonMediaApiSubKey,
            'X-API-Version': '1.0'
          },
          body: request.method === 'GET' ? null : (request.url.includes('ce-common-video-service') ? request.body.append('groupService', "flush") : request.body),
        });
      } else {
        request = isOktaLoginEndpoints ? request.clone() : request.clone({
          setHeaders: {
            'Authorization': 'Bearer ' + this.authService.accesstoken,
            APIEnvironment: environment.apimEnvironment,
            'Ocp-Apim-Subscription-Key': environment.apiSubKey,
          }
        });
      }
    }

    return next.handle(request).pipe(
      catchError((resp: HttpErrorResponse) => {
        console.log('request :>> ', request);
        const isRetry = request.headers.get("RetryResponse");

        let status = resp.status;
        if(status === 502 || status === 504) {
          this.baseService.submissionTimeoutModal.next(true);
        }
        if(resp.url.indexOf('/surveys/questions?') > 0 && resp.status === 400 && resp.error.code === 'USER-RESPONDED-409') {
          return;
        }

        if (resp.error instanceof ErrorEvent) {
          // client-side error or network error
          console.log('code_error', resp.error);
        } else {
          const errorDetails = {
            500: {
              header: 'We have a little problem.',
              body: "It appears there is an issue on our end. Wait a minute and try again. If it persists log the issue.",
              action: "Let us know what happened"
            },
            404: {
              header: 'We have a little problem.',
              body: "This page is down or not working. If this error continues please help us by logging the issue.",
              action: "Let us know what happened"
            },
            403: {
              header: 'Permission Needed',
              body: "You don't have permission to access this page. If this is an error, log the issue.",
              action: "Let us know what happened"
            },
            400: {
              header: 'We have a little problem.',
              body: "It appears we've made a mistake somewhere. Try again, if it persists, log the issue.",
              action: "Let us know what happened"
            },
            default: {
              header: 'We have a little problem.',
              body: "It appears there is an issue on our end. Wait a minute and try again. If it persists log the issue.",
              action: "Let us know what happened"
            }
          };

          const error = errorDetails[status];

          if (!isRetry || isRetry === "false" || (status !== 502 && status !== 504)) {
            //Override the default timeout as 10 minutes. 
            this.alert.urgent(error.header + ' ' + error.body + ' ', error.action, { timeOut: 10000000,closeButton : true});
            //TODO - Need CCL version 14 of alert with close button
            //  this.alert.urgent(error.header + ' ' + error.body + ' ', error.action, { timeOut: 10000000, closeButton: true });
          }

          this.logger.logEvent({
            name: 'Handling API request failures'
          }, {
            eventDate: new Date(),
            statusText: resp.statusText,
            status: resp.status,
            url: resp.url,
            refUri: document.URL
          });
        }
        return throwError(resp);
      })
    );
  }
}