import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PageChangeEvent } from '@progress/kendo-angular-pager';
import { filter, map, catchError, tap, switchMap, take } from 'rxjs/operators';
import { Store, Select } from '@ngxs/store';
import { StylerService } from 'src/app/services/styler/styler.service';
import { BaseComponent } from 'src/app/common/base/base.component';
import { BaseService } from 'src/app/services/base/base.service';
import { WorkRequestsResponse } from 'src/app/models/work-requests-response';
import { combineLatest, forkJoin, Observable, of, throwError } from 'rxjs';
import { AppState, AppStateModel } from 'src/app/app-state/app.state';
import { tracker } from 'src/app/common/top-tracker/top-tracker.component';
import { FormValidationService } from 'src/app/services/forms/form-validation.service';
import { LoggingService } from 'src/app/services/logging/logging.service';
import { InternetCheckerService } from 'src/app/services/internet-checker/internet-checker.service';
import { Dashboard } from 'src/app/app-state/actions/dashboard-work-requests.actions';
import { CcRequestsService } from 'src/app/services/cc-requests/cc-requests.service';
import { Banner, BannerService } from 'src/app/services/banner/banner.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserInfo } from 'src/app/interfaces/user-info';
import { AssociatedWorkBody } from 'src/app/interfaces/associated-work-body';
import { WorkRequest } from 'src/app/interfaces/work-request';
import { RequestsService } from 'src/app/services/requests/requests.service';
import { CONFIG } from 'src/app/global/config';
import { User } from 'src/app/models/user.model';
import { WorkRequestRow } from 'src/app/interfaces/work-request-row';
import { AddWorkDescription } from 'src/app/app-state/actions/start-job.actions';
import { WorkComponent } from 'src/app/interfaces/work-component';
import { WorkRequest as WRModel } from 'src/app/models/work-request';
import { WorkRequestsHistoryResponse } from 'src/app/models/work-requests-history-response';
import { PreCacheService } from 'src/app/services/pre-cache/pre-cache.service';
import { MapService } from 'src/app/services/map/map.service';
import { Alert } from '@ce-lib/alert';
import { ResetAppStateToDefault } from 'src/app/app-state/actions/user-info.actions';
@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
})
export class RequestsComponent extends BaseComponent implements OnInit {
  // Config for HTML
  config = CONFIG;

  noFlushRequests = false;
  roleType: string;
  userRoleType: string; //To identify where the logged in user is an employee or contractor
  searchWRErrorMessage: string = CONFIG.SEARCH_WR_ERROR_MESSAGES.DOES_NOT_EXIST;
  isEnvironmentalOperations = false;
  isConstructionCrew: boolean;
  crewCode: string;
  flushEoColumns: any[];
  flushConstructionCrewColumns: any[];
  requestHistoryColumns: any[];
  flushData: Observable<WorkRequestsResponse>;
  requestHistoryData: Observable<WorkRequestsHistoryResponse>;
  stateFlushData: WorkRequestsResponse = new WorkRequestsResponse();
  newRequest: boolean;
  isTableHasData = true;
  topTracker: Array<tracker>;
  topCrewTracker: Array<tracker>;
  banner$: Observable<Banner>;
  isClearIcon = false;
  showModal = false; //Flag to show/hide the modal popup
  assetTag: string;
  isMobile = false; //Flag to track the size of the current viewport.
  mapLoaded = false; //Variable used to check whether the map is loaded or not before triggering any future events.
  dashboardFormGroup = new FormGroup({
    createWR: new FormControl(null, [
      Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(7),
    ])
  });
  showCreateTicketInput: boolean = false;
  crewTrackerReqUrl: string = '/requests?topCrewTracker=my-requests';
  crewTrackerReqHistoryUrl: string = '/requests?topCrewTracker=request-history';
  eoJobsUrl: string = '/requests?topTracker=my-jobs';
  eoRequestHistoryUrl: string = '/requests?topTracker=my-history';

  @Select(AppState.getWorkRequestGlobalId)
  getWorkRequestGlobalId$: Observable<string>;
  @Select(AppState.getDashboardWorkRequests)
  getDashboardWorkRequests$: Observable<WorkRequestsResponse>;
  @Select(AppState.getUserInfo) getUserInfo$: Observable<UserInfo>;
  @Input() skip = 0;
  @Input() selectedIndex = 1;
  @Input() pageSize = 5;
  @Input() buttonCount = 0;
  @Input() pageSizeValues = false;
  @Input() pageSizeArray = null;
  @Input() prevNext = true;
  @Input() info = false;
  @Input() totalRows = 0;

  constructor(
    private logger: LoggingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private ccRequestsService: CcRequestsService,
    private styler: StylerService,
    private baseService: BaseService,
    private formValidationService: FormValidationService,
    private internetCheckerService: InternetCheckerService,
    private banner: BannerService,
    private requestsService: RequestsService,
    private preCacheService: PreCacheService,
    private mapService: MapService,
    private alert: Alert
  ) {
    super(styler);
    this.banner.resetBanner();
    this.banner$ = this.banner.banner$;
    this.formValidationService.$flushInformationValid.next(false);
    this.formValidationService.$flushPhotos.next(false);
    this.formValidationService.$flushRequestValid.next(false);
    this.formValidationService.$flushSummary.next(false);
    this.activatedRoute.queryParams.subscribe((params) => {
      // If top-tracker is set to my history, set active
      if (params?.topTracker === 'my-history' && this.topTracker?.length > 1) {
        this.topTracker[0].isActive = false;
        this.topTracker[1].isActive = true;
      }

      if (params?.topCrewTracker === 'my-requests' && this.topTracker?.length > 1) {
        this.topTracker[0].isActive = false;
        this.topTracker[1].isActive = true;
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  private onResize() {
    // Check dimensions of the screen if the user has changed view between landscape/portrait
    this.isMobile = window.innerWidth < 768;
  }

  ngOnInit(): void {
    // Clear globalId on dashboard to pre vent refresh
    sessionStorage.removeItem('workRequestGlobalId');
    combineLatest([
      this.store.select(store => store.AppState as AppStateModel),
      this.getDashboardWorkRequests$
    ])
      .pipe(
        map(res => res),
        filter(([state, request]) => !!state.userInfo && state.userInfo.user.isLoggedin),
        tap(([state, request]) => {
          this.banner.resetBanner();
          this.stateFlushData = request;
          const user: User = state.userInfo.user;
          this.roleType = this.baseService.confirmUserRoleByType(user.flushRoleType);
          this.userRoleType = user.flushRoleType;
          this.crewCode = user.crewCode;
        }),
        take(1)
      )
      .subscribe(([state, request]) => {
        //this.loadDashboardWorkRequests(this.roleType);
        this.refreshDashboard();
        const user: User = state.userInfo.user;
        // Set provisions warning based on userInfo boolean return, (loadDashboardWorkRequests resets banner; call after)
        if (user?.isProvisioned === false && user?.flushRoleType != 'CN') {
          this.createBanner(null, CONFIG.DASHBOARD_ERROR_MESSAGES.NOT_PROVISIONED.HEADER, CONFIG.DASHBOARD_ERROR_MESSAGES.NOT_PROVISIONED.BODY);
        }
      });

      this.getUserInfo$.subscribe((UserInfo: UserInfo) => {
        this.banner.resetBanner();
        const dashboardWorkRequests: WorkRequestsResponse = this.store.selectSnapshot(store => (store.AppState.dashboardWorkRequests) as WorkRequestsResponse);
        this.stateFlushData = dashboardWorkRequests;
        const user: User = UserInfo.user;
        this.roleType = this.baseService.confirmUserRoleByType(user.flushRoleType);
        this.userRoleType = user.flushRoleType;
        this.crewCode = user.crewCode;     
        this.refreshDashboard();
        // Set provisions warning based on userInfo boolean return, (loadDashboardWorkRequests resets banner; call after)
        if (user?.isProvisioned === false && user?.flushRoleType != 'CN') {
          this.createBanner(null, CONFIG.DASHBOARD_ERROR_MESSAGES.NOT_PROVISIONED.HEADER, CONFIG.DASHBOARD_ERROR_MESSAGES.NOT_PROVISIONED.BODY);
        }
      })
    this.wrSearchIconFunctionality();

  }

  onTrackerClicked(url: string) {
    this.clearWRSearchForm();
    if (this.isConstructionCrew && url === this.crewTrackerReqHistoryUrl) {
      this.showCreateTicketInput = true;
      this.loadDashboardCCRequestHistory();
    } else if (this.isEnvironmentalOperations && url === this.eoRequestHistoryUrl) {
      this.showCreateTicketInput = true;
      this.loadDashboardEORequestHistory();
    } else {
      this.showCreateTicketInput = false;
      this.loadDashboardWorkRequests(this.roleType);
    }
  }

  pageChange(e: PageChangeEvent): void {
    this.skip = e.skip;
    this.pageSize = e.take;
    //TODO: Add the Wr number 
    if (this.isConstructionCrew) this.loadDashboardCCRequestHistory();
    else if (this.isEnvironmentalOperations) this.loadDashboardEORequestHistory();
  }

  loadDashboardCCRequestHistory() {
    let wrNumber = this.dashboardFormGroup.get('createWR').value;
    this.baseService.getCCRequestHistory(this.skip.toString(), this.pageSize.toString(), wrNumber).subscribe(
      (data) => {
        this.updateRequestHistoryResponse(data);
      },
      (error) => {
        this.logger.logException(error);
      },
      () => {
        this.isClearIcon = true;
      }
    );
  }

  loadDashboardEORequestHistory() {
    let flushWRNumber = this.dashboardFormGroup.get('createWR').value;
    this.baseService.getEORequestHistory(this.skip.toString(), this.pageSize.toString(), this.userRoleType?.toUpperCase() == "CN" ? this.crewCode : '', flushWRNumber).subscribe(
      (data) => {
        this.updateRequestHistoryResponse(data);
      },
      (error) => {
        this.logger.logException(error);
      },
      () => {
        this.isClearIcon = true;
      }
    );
  }

  updateRequestHistoryResponse(data: any) {
    this.totalRows = data.totalRows ? +data.totalRows : 0;
    let requestHistoryObj: WorkRequestsHistoryResponse = { entities: data.results, currentPage: data.currentPage, totalPages: data.totalPages, pageSize: data.pageSize, totalRows: data.totalRows } as WorkRequestsHistoryResponse;
    this.requestHistoryData = of(requestHistoryObj);
  }


  refreshDashboard($event = null) {
    // Handle animation
    this.clearWRSearchForm();
    $event?.srcElement.classList?.remove("refresh-animation");
    setTimeout(() => {
      $event?.srcElement.classList?.add("refresh-animation");
    }, 0);
    this.baseService.getUserInfo().pipe(
      tap(info => {
        this.store.dispatch(new ResetAppStateToDefault);
        let assignedWork = info?.workAssignments
          ?.map(assignment => ({
            role: info.user.flushRoleType,
            email: info.user.email,
            crewCode: info.user.crewCode,
            workRequestGlobalID: assignment.workRequestGlobalID,
            workComponentGlobalID: assignment.workComponentGlobalID,
            excludeCache: true
          } as AssociatedWorkBody)
          )?.map(i => i);

        // This handles no assignedWork in userInfo. For example, if user is a contractor
        if (!!assignedWork) {
          this.store.dispatch(new Dashboard.AddAssignedWork(assignedWork))
        } else {
          console.log('ERROR: Unable to load userInfo.workAssignments, use current state');
        }
      }),
      take(1),
      switchMap(() => this.store.selectOnce(store => store.AppState.userInfo as UserInfo))
    )
      .subscribe(info => {
        this.loadDashboardWorkRequests(this.roleType);
      })
  }

  startNewFlushRequest() {
    this.noFlushRequests = false;
    this.newRequest = true;
  }

  loadDashboardWorkRequests(role: string) {
    this.banner.resetBanner();
    this.prepareDashboard(role);
    this.flushData = this.getWorkRequestsFromAssignedWork();
  }

  getWorkRequestsFromAssignedWork(): Observable<WorkRequestsResponse> {   
    // use async pipe to subscribe and unsubscibe to result
    return this.store.selectOnce(store => store.AppState.assignedWork as AssociatedWorkBody[])
      .pipe(
        tap(assignedWork => {
          if (assignedWork?.length === 0) {
            throw { header: CONFIG.DASHBOARD_ERROR_MESSAGES.NO_WORKREQUESTS_ERROR.HEADER };
          }
        }),
        switchMap(assignedWork => forkJoin(assignedWork.map(body => this.baseService.getAssignedWork(body))).pipe(map(d => d))
        ),
        tap(data => {
          // Sort, might be used by state
          const entities = this.sortEntitiesByDate(data.map(item => item.dashBoard.entities).filter(item => item != undefined && !item.errorText)) as WorkRequestRow[];
          const submittedEntities = this.sortEntitiesByDate(data[0].dashBoard.flushSubmittedEntities?.map(i => i)) as WRModel[];
          // Filter out undefined/null workrequests
          const wrArray = data.map(response => this.flattenWcListToWrList(response.workRequestWithComponentList, this.roleType)).map(wr => wr[0])?.filter(i => i);
          this.store.dispatch(new Dashboard.AddDetailedWork(wrArray, false));
          this.store.dispatch(new Dashboard.Add({ status: '200', message: '', crewCode: '', exception: '', entities: entities, flushSubmittedEntities: submittedEntities as any }));
        }),
        map(data => {
          // Sort on success, return object mapped for ui
          const entities = this.sortEntitiesByDate(data.map(item => (item.dashBoard as any).entities).filter(item => item != undefined && !item.errorText)) as WorkRequestRow[];
          const submittedEntities = this.sortEntitiesByDate(data[0].dashBoard.flushSubmittedEntities?.map(i => i)) as WRModel[];

          return { status: data[0].dashBoard.status, message: data[0].dashBoard.message, entities: entities, flushSubmittedEntities: submittedEntities as any } as WorkRequestsResponse;
        }),
        catchError((error) => {
          this.logger.logException(error);

          if (error?.header === CONFIG.DASHBOARD_ERROR_MESSAGES.NO_WORKREQUESTS_ERROR.HEADER) {
            return of({} as WorkRequestsResponse);
          } else {
            return this.getWorkRequestHttpErrorHandler(error);
          }
        })
      )
  }
  flattenWcListToWrList = (wcList: WorkComponent[], roleType: string): WorkRequest[] => {
    return wcList.map((curVal) => {
      const forms = { ...curVal.sourceWRInformation }
      let wrList = {
        ...curVal.workRequest,
        images: forms?.ccImages ?? curVal?.workRequest['images'] ?? [],
        jobDetail: forms.flushJobDetails ?? curVal?.workRequest['jobDetail'],
      }

      wrList?.images?.filter((image) => image?.mediaType === 'Image')?.forEach((image) => {
        this.preCacheService.preloadImage(image["imageData"])
      });

      if (roleType === 'cc') {
        return ({
          ...wrList,
          accessInfo: forms.accessInfo,
          flushInfo: forms.flushInfo,
          structureInfo: forms.structureInfo
        })
      } else {
        return ({
          ...wrList,
          accessInfo: forms.accessInfoModel,
          flushInfo: forms.flushInfoModel,
          structureInfo: forms.structureInformation
        })
      }
    })
  }
  createBanner(httpError, header, body, type = 'error') {
    this.banner.createBanner(type, {
      header: header,
      body: [body],
      httpStatus: httpError?.status ?? 0,
      originalError: httpError?.error ?? '',
      originalErrorName: '',
      originalUrl: '',
      originalServerMessage: httpError?.message ?? '',
    });
  }

  getWorkRequestHttpErrorHandler(error): Observable<WorkRequestsResponse> {
    if (this.stateFlushData?.entities?.length > 0) {
      this.createBanner(error, window?.navigator?.onLine ? CONFIG.DASHBOARD_ERROR_MESSAGES.WMS_DOWN : CONFIG.DASHBOARD_ERROR_MESSAGES.USER_OFFLINE, CONFIG.DASHBOARD_ERROR_MESSAGES.LOADED_FROM_STATE);
      // WARNING: This could be the result of a network or unhandled error. The state will either be empty or have previous values that are not up to date
      // TODO: Find a way to handle multiple possible errors and updating the correct workrequest response
      return of(this.stateFlushData);
    }

    // Default
    return of({} as WorkRequestsResponse);
  }

  prepareDashboard(userRole) {
    //Declared on the top to avoid redundancy and to resuse for construction crew columns.
    if (userRole === 'cc') {
      this.topCrewTracker = [
        {
          text: 'My Requests',
          isActive: true,
          url: this.crewTrackerReqUrl,
        },
        {
          text: 'Request History',
          isActive: false,
          url: this.crewTrackerReqHistoryUrl,
        },
      ];

      this.isConstructionCrew = true;
      this.isEnvironmentalOperations = false;
      this.flushConstructionCrewColumns = [
        { field: 'wrNo', title: 'Source WR#' },
        { field: 'workComponentDescription', title: 'Work Component' },
        { field: 'structureId', title: 'Asset Tag', actionType: 'anchor', action: 'assetTag' },
        { field: 'address', title: 'Address' },
        { field: 'priorityLevel', title: 'Priority Level', hidden: 'true' },
        { field: 'date', title: 'Date' },
        {
          field: 'workRequestGlobalId',
          title: 'Work Request Global Id',
          hidden: 'true',
        },
        {
          field: 'facilityId',
          title: 'F Global Id',
          hidden: 'true',
        },
        { field: 'status', title: 'Flush Status' },
      ];
    } else {
      this.topTracker = [
        {
          text: 'My Jobs',
          isActive: true,
          url: this.eoJobsUrl,
        },
        {
          text: 'Job History',
          isActive: false,
          url: this.eoRequestHistoryUrl,
        },
      ];
      this.isConstructionCrew = false;
      this.isEnvironmentalOperations = true;
      this.flushEoColumns = [
        { field: 'appointmentId', title: 'Appointment' },
        { field: 'wrNo', title: 'Work Request#' },
        { field: 'date', title: 'Date' },
        { field: 'address', title: 'Address' },
        { field: 'structureId', title: 'Structure', actionType: this.userRoleType.toLowerCase() !== "cn" ? 'anchor' : '', action: this.userRoleType.toLowerCase() !== "cn" ? 'assetTag' : '' },
        { field: 'priorityLevel', title: 'Priority Level' },
        { field: 'status', title: 'Status' },
        {
          field: 'workRequestGlobalId',
          title: 'Work Request Global Id',
          hidden: 'true',
        },
        {
          field: 'facility.facilityGlobalId',
          title: 'F Global Id',
          hidden: 'true',
        },
      ];
    }
  }

  navigate(t) {
    this.router.navigate(t);
  }

  openLinkInNewTab(link) {
    window.open(link);
  }

  mapLoadedEvent(status: boolean) {
    document.querySelector('.map-wrapper').addEventListener('touchmove', function (event) {
      // Prevent page scrolling if touch device is on
      event.preventDefault();
    })
    this.mapLoaded = true;
  }

  showMapCoordinates(dataItem) {
    this.showModal = true;

    if (this.mapLoaded) {
      this.assetTag = dataItem?.structureId;

      const longitude = dataItem?.facility?.[0]?.longitude;
      const latitude = dataItem?.facility?.[0]?.latitude;

      const undefinedCoords = !longitude || !latitude;
      const invalidCoords = (lat, lng) => { return Math.abs(lat) > 85 || Math.abs(lng) > 180 }
      if (!undefinedCoords && !invalidCoords(latitude, longitude)) {
        document.getElementById('main-navigation').scrollIntoView();
        this.mapService.queueNewPan(parseFloat(longitude), parseFloat(latitude));
      } else {
        this.alert.urgent('Map coordinates not found: This request is missing coordinates to show on the map. If no address is provided, reference the Structure Number.', 'Dismiss')
      }
    } else {
      setTimeout(this.showMapCoordinates.bind(this, dataItem), 2000);
    }
  }

  onStructureClicked(event: { action: any; value: any }) {
    console.log('Action: ', event.action, ' Value: ', event.value);
    if (event.action === 'assetTag') {
      this.showMapCoordinates(event.value);
    }
  }

  onOpen(event: { action: any; value: any }) {
    if (event.action === 'assetTag') {
      this.showMapCoordinates(event.value);
    } else {
      this.banner.resetBanner();
      // const action = event.action;
      const dataItem = event.value;
      const payload = { workDescription: null, isCrewConductingRepairs: null, canOriginalWorkContinue: null, hasStrayVoltage: null, hasLeadStabilizerUsed: null, noOfStabilizers: null };
      this.store.dispatch(new AddWorkDescription(payload));
      this.requestsService.onOpenHandler(event)
        .subscribe((res: WorkRequest) => {
          this.ccRequestsService.handleNavigationFromDashboard(dataItem, this.roleType);
        })
    }
  }

  sortEntitiesByDate(workRequestRows: WRModel[] | WorkRequestRow[]): WRModel[] | WorkRequestRow[] {
    if (!workRequestRows || workRequestRows?.length < 1) return [];

    // Most recent date - Not most recent date
    return workRequestRows.sort((a: WRModel | WorkRequestRow, b: WRModel | WorkRequestRow) => {
      return new Date(b.date).getTime() - new Date(a.date).getTime();
    });
  }

  startWRSearch(event) {
    if (this.dashboardFormGroup.get('createWR').valid) {
      let wrNumber = this.dashboardFormGroup.get('createWR').value;
      if ((this.roleType === "cc" && this.topCrewTracker[0].isActive) || (this.roleType === "eo" && this.topTracker[0].isActive)) {
        this.baseService.getSearchWorkrequest(wrNumber, this.isConstructionCrew ? 'cc' : 'eo').subscribe(
          (data) => {
            let searchWRErrorMessage = '';
            let refreshDashboard = true;
            if (data?.warningMessage) {
              searchWRErrorMessage = data.warningMessage;
              if (searchWRErrorMessage.indexOf("Construction Management") === -1) {
                refreshDashboard = false;
              }
              
            }else if (!data?.dashBoard) {
              searchWRErrorMessage = this.isEnvironmentalOperations ? CONFIG.SEARCH_WR_ERROR_MESSAGES.NOT_FLUSH_WR : CONFIG.SEARCH_WR_ERROR_MESSAGES.COULD_NOT_BE_FOUND;
            }

            if (searchWRErrorMessage) {
              this.searchWRErrorMessage = searchWRErrorMessage;
              this.dashboardFormGroup.get('createWR').setErrors({ 'incorrect': true });
              this.isClearIcon = true;
            } 
            
            if (data?.dashBoard && refreshDashboard) {
              const searchedEntities = this.sortEntitiesByDate(data.dashBoard.searchedEntities?.filter(item => !!item && !item.errorText)) as WorkRequestRow[];
              const submittedEntities = this.sortEntitiesByDate(data.dashBoard.flushSubmittedEntities?.map(i => i));
              const wrArray = this.flattenWcListToWrList(data.workRequestWithComponentList, this.roleType)?.filter(i => i);

              this.store.dispatch(new Dashboard.AddDetailedWork(wrArray, true));

              const dashBoardObj: WorkRequestsResponse = {
                status: '200',
                message: data.dashBoard.message,
                crewCode: data.dashBoard.crewCode,
                exception: data.dashBoard.exception,
                entities: searchedEntities,
                flushSubmittedEntities: submittedEntities as any
              };

              this.store.dispatch(new Dashboard.Add(dashBoardObj));
              this.flushData = of(dashBoardObj);
            } 

          },
          (error) => {
            this.searchWRErrorMessage = this.isEnvironmentalOperations ? CONFIG.SEARCH_WR_ERROR_MESSAGES.NOT_FLUSH_WR : CONFIG.SEARCH_WR_ERROR_MESSAGES.COULD_NOT_BE_FOUND;
            this.dashboardFormGroup.get('createWR').setErrors({ 'incorrect': true });
            this.isClearIcon = true;

            this.logger.logException(error);

            this.getWorkRequestHttpErrorHandler(error);
          },
          () => {
            this.isClearIcon = true;
          }
        );
      } else if (this.roleType === "cc" && this.topCrewTracker[1].isActive) {
        this.loadDashboardCCRequestHistory();
      } else if (this.roleType === "eo" && this.topTracker[1].isActive) {
        this.loadDashboardEORequestHistory();
      }
    }
  }

  resetWRSearch() {
    this.clearWRSearchForm();
    if (this.roleType === "cc") {
      if (this.topCrewTracker[0].isActive) {
        this.loadDashboardWorkRequests(this.roleType);
      } else if (this.topCrewTracker[1].isActive) {
        this.loadDashboardCCRequestHistory();
      }
    } else if (this.roleType === "eo") {
      if (this.topTracker[0].isActive) {
        this.loadDashboardWorkRequests(this.roleType);
      } else if (this.topTracker[1].isActive) {
        this.loadDashboardEORequestHistory();
      }
    }
  }

  clearWRSearchForm() {
    if (this.dashboardFormGroup.get('createWR')) {
      this.dashboardFormGroup.get('createWR').patchValue('');
      this.dashboardFormGroup.get('createWR').markAsPristine();
      this.searchWRErrorMessage = CONFIG.SEARCH_WR_ERROR_MESSAGES.DOES_NOT_EXIST;
      this.isClearIcon = false;
    }
  }

  // Listener so that we can show correct icon regardless of input and clear once after search
  wrSearchIconFunctionality() {
    this.dashboardFormGroup.get('createWR').valueChanges.subscribe(() => {
      if (this.dashboardFormGroup.get('createWR').invalid && this.dashboardFormGroup.get('createWR').dirty) {
        this.isClearIcon = true;
      } else {
        this.isClearIcon = false;
      }
    })
  }

}
