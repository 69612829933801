import { Component, OnInit } from '@angular/core';
import { ContractorAuthService } from '../../services/auth/contractor-auth.service';
@Component({
  selector: 'app-okta-login',
  templateUrl: './okta-login.component.html',
})
export class OktaLoginComponent implements OnInit {
  constructor(private contractorAuthService: ContractorAuthService) {}

  ngOnInit(): void {
    // Call api login endpoint
    this.contractorAuthService.authenticate().subscribe((res) => {
      console.log(res);
      
      window.location.replace(res.toString());
    }, 
    err => {
      console.log(err);
      
    });
  }
}
