import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { Observable, throwError } from 'rxjs';
import { catchError, map, startWith, switchMap, take } from 'rxjs/operators';
import { Photo } from 'src/app/models/photo';
import { WorkRequestDetail } from 'src/app/models/work-request-detail';
import { BaseService } from 'src/app/services/base/base.service';
import * as _ from 'lodash';
import { MasterDataService } from 'src/app/services/master-data/master-data.service';

@Component({
  selector: 'app-edit-close-wr',
  templateUrl: './edit-close-wr.component.html',
  styleUrls: ['./edit-close-wr.component.scss']
})
export class EditCloseWrComponent implements OnInit {
  today = moment().format('MM/DD/YYYY');
  workRequestDetails$: Observable<WorkRequestDetail>;
  photosLength$: Observable<number>;
  flushReviewForm: FormGroup = new FormGroup({
    comments: new FormControl('', Validators.required),
  });
  flushCommentsCols = [
    { field: 'createdOn', title: 'Date Saved' },
    { field: 'userName', title: 'Crew Member' },
    { field: 'description', title: 'Description of Work' }
  ];
  isUserScrolling = false;
  @HostListener('window:scroll', ['$event'])
  onScroll(e) { 
    if(!this.isUserScrolling) {
      this.saveScroll();
    }
  }
  constructor(
    private store: Store,
    private baseService: BaseService,
    private router: Router,
    private masterData: MasterDataService
  ) { }

  ngOnInit(): void {
    document.addEventListener('wheel', (e) => {
      this.saveScroll();
    }); 
    document.addEventListener('touchmove', (e) => {
      this.saveScroll();
    });
    document.addEventListener('touchstart', (e) => {
      this.saveScroll();
    });
    // Populate workrequest from store
    this.workRequestDetails$ = this.store.select(store => store.AppState.workRequestDetail as WorkRequestDetail).pipe(
      map(workRequestDetail => {
        if (workRequestDetail.wmsComments?.length > 0) {
          const reqDetails = _.cloneDeep(workRequestDetail);
          const comments = reqDetails.wmsComments?.map(obj => {
            const oldObj = _.cloneDeep(obj);
            const formattedDate = moment(obj.createdOn).format('MMM D, YYYY HH:mm');
            oldObj.createdOn = formattedDate;

            obj = oldObj;
            return obj;
          });
          //Object.assign({}, workRequestDetail.wmsComments, comments);
          reqDetails.wmsComments = comments;
          workRequestDetail = reqDetails;
        }
        return workRequestDetail;
      })
    );
    this.photosLength$ = this.store.select(store => store.AppState.startJobPhotos as Photo[]).pipe(map(photos => photos?.length ?? 0), startWith(0))
  }

  backClick() {
    //this.router.navigateByUrl('/era-axis');
    location.href='/era-axis';
  }
  getYPosition(): number {
    return window.scrollY;
  }

  saveScroll(){
    const pos = this.getYPosition();
    this.masterData.setScrollYPosition(pos);
  }

  saveClick() {
    this.store.selectOnce(store => store.AppState.workRequestDetail as WorkRequestDetail)
    .pipe(
      map(workRequest => ({
        flushWrNumber: workRequest.workRequestNo,
        comments: this.flushReviewForm?.controls?.comments?.value,
        status: workRequest.status,
        appointmentId: workRequest.crmsAppointmentId
      })),
      catchError(errorWithBody => throwError(errorWithBody)),
      switchMap(updateComments => this.baseService.updateFlushRequestComments(updateComments)),
      catchError(errorWithReq => throwError(errorWithReq)),
    )
    .subscribe({
      complete: () => {
        console.log('Completed comment update!');
        location.href='/era-axis';
      },
      error: (error) => {
        console.error(error);
      }
    });
  }
}
