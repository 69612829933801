<app-sidebar-mobile *ngIf="isMobile" 
  [wrNum]="isSummary ? this.dashboardSideBarDetails?.wrNo : dashboardDetailsEntity?.wrNo" 
  [wcDesc]="isSummary ? this.dashboardSideBarDetails?.workComponentDescription : dashboardDetailsEntity?.workComponentDescription" 
  [sourceWrNo]="''" 
  isFlushRequest="true" [showFirst]="showing"></app-sidebar-mobile>
<div class="request-wrapper">
  <div class="request-card container-fluid card">
    <app-top-tracker [trackerArr]="topTracker" *ngIf="isShowingFlushInfo && !isEdit"></app-top-tracker>
    <div class="row no-gutters">
      <div class="col" [ngStyle]="!isPhotoReview && {'min-height': '550px'}">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <app-sidebar *ngIf="!isMobile" [wrNum]="isSummary ? this.dashboardSideBarDetails?.wrNo : dashboardDetailsEntity?.wrNo" 
    [wcDesc]="isSummary ? this.dashboardSideBarDetails?.workComponentDescription : dashboardDetailsEntity?.workComponentDescription" 
    [bTicket]="isSummary ? this.dashboardSideBarDetails?.bTicket : dashboardDetailsEntity?.bTicket ?? ''" isFlushRequest="true" [showFirst]="showing"></app-sidebar>
</div>
