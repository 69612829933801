<!-- Standard box -->
<div class="details-wrapper container service-box-card">
  <div class="details-nav-button" [ngClass]="{'show': showItems}"  (click)="handleMobileNav()">
    <div class="row">
      <div class="col">
        <div class="details-box-header-wrapper">
          <h4 class="details-box-header">{{header}}
            <span *ngIf="iconInfo=='false'" class="icon icon-error iconStyle"  ></span> 
          </h4>
          <a class="details-box-header details-link-btn" [ngClass]="showItems ? 'visible' : 'invisible'" *ngIf="link && !hideLink" (click)="linkClick()">
            <i [ngClass]="{'icon icon-pencil': !!link }"></i> 
            {{link.text}}
          </a>
        </div>
      </div>
      <div class="col-sm-auto" class="details-box-caret-col">
        <div class="close-wrapper">
          <i class="icon icon-caret-button"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="details-nav-items-wrapper">
    <div [@openClose]="animationType" class="details-content-area">
      <div>
        <ng-content></ng-content>
        <!-- Below is workaround for an animation conflict -->
        <div style="height: 0px; font-size: 0px;">d</div>
      </div>
    </div>
  </div>
</div>
