<h3>Job Details</h3>
<p>Please provide a visual and details on the scope of work that is to be performed in the structure.</p>
<form *ngIf="jobDetailsForm" [formGroup]="jobDetailsForm">
    <!-- Environment Photo -->
    <div class="form-group row">
        <div class="col-lg-5">
            <div class="env-card">
                <h4>Capture Environment</h4>
                <p class="body"><small><b>(Not Required)</b></small></p>
                <p class="text-center"><b>Take media of the area around the structure to capture the flush scope of work.</b></p>
                <ce-button label="Launch Camera" type='primary' (clickEvent)="takePhoto()" size='large' icon="icon-camera" iconDirection="left"></ce-button>
            </div>
        </div>
    </div>
    <!-- Job Selection -->
    <div class="form-group row">
        <div class="col-lg-5 ">
            <p><b>Please select the job to be performed.</b><a *ngIf="!this.jobDetailsForm.controls['electricConst'].valid" class="flush-process-error"> *</a></p>
            <ce-select formControlName="electricConst" errorMessage='*'
              placeholder="Electric construction job">
              <ce-option *ngFor="let option of electricJobs | async" [value]="option">
                {{ option.option }}
              </ce-option>
            </ce-select>
        </div>
    </div>
    <div class="row">
        <div class="col-auto">
            <ce-button (clickEvent)="prevClick()" label="Previous" type="secondary" size="large"></ce-button>
        </div>
        <div class="col-auto">
            <ce-button (clickEvent)="nextClick()" label="Next" type="primary" size="large" [disabled]="!this.jobDetailsForm.valid"></ce-button>
        </div>
    </div>
    <div class="form-group row">
        <div class="col-12 col-lg-4">
          <a class='btn-danger-link' (click)="cancelRequestInProgres()">Cancel Request</a>
        </div>
      </div>
</form>

<app-launch-camera-modal #launchCameraModalComponent></app-launch-camera-modal>