import { FlushPhoto } from './flushPhoto';
import { PointOfContact } from './pointOfContact';
import { ParkingRestriction } from './parking-restriction.model';

export class AccessInformation {
  photos: FlushPhoto[];
  hasSufficientAccess: boolean;
  isStructureOncriticalRoadway: boolean;
  areConeHiveRequired: boolean;
  areFlaggersRequired: boolean;
  isCustomerRequired: boolean;
  areParkingRestriction: boolean;
  parkingRestrictions: ParkingRestriction[];
  additionalAccessDetails: string;
  contactsDataGroup: PointOfContact[];
  isValid: boolean;
  isOnLocation?: boolean;
  isAccessObtained?: boolean;
  structureNumber?: string;
  constructor(
    photos: FlushPhoto[],
    hasSufficientAccess: boolean,
    isStructureOncriticalRoadway: boolean,
    areConeHiveRequired: boolean,
    areFlaggersRequired: boolean,
    isCustomerRequired: boolean,
    areParkingRestriction: boolean,
    parkingRestrictions: ParkingRestriction[],
    additionalAccessDetails: string,
    contactsDataGroup: PointOfContact[],
    structureNumber = null,
    isOnLocation = true,
    isAccessObtained = true
  ) {
    this.photos = photos;
    this.hasSufficientAccess = hasSufficientAccess;
    this.isStructureOncriticalRoadway = isStructureOncriticalRoadway;
    this.areConeHiveRequired = areConeHiveRequired;
    this.areFlaggersRequired = areFlaggersRequired;
    this.isCustomerRequired = isCustomerRequired;
    this.areParkingRestriction = areParkingRestriction;
    this.parkingRestrictions = parkingRestrictions;
    this.additionalAccessDetails = additionalAccessDetails;
    this.contactsDataGroup = contactsDataGroup;
    this.structureNumber = structureNumber;
    this.isOnLocation = isOnLocation;
    this.isAccessObtained = isAccessObtained;
  }
}
