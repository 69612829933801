import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ContractorAuthService } from '../services/auth/contractor-auth.service';

@Injectable({
  providedIn: 'root',
})
export class ContractorAuthGuard implements CanActivate {
  constructor(private contractorAuthService: ContractorAuthService) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.contractorAuthService.isAuthenticated()) {
      return true;
    } else {
      // this.contractorAuthService.authenticate().subscribe((res) => {
      //   window.location.replace(res.toString());
      // });
      return false;
    }
  }
}
