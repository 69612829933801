import { Component, Input, OnInit, OnChanges, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app-state/app.state';
import { WorkRequest } from 'src/app/models/work-request';
import { BannerService } from 'src/app/services/banner/banner.service';
import { CcRequestsService } from 'src/app/services/cc-requests/cc-requests.service';
import { RequestsService } from 'src/app/services/requests/requests.service';
import { StylerService } from 'src/app/services/styler/styler.service';
import { BaseComponent } from '../../base/base.component';

@Component({
  selector: 'app-flush-crew-panel-bar',
  templateUrl: './flush-crew-panel-bar.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class FlushCrewPanelBarComponent extends BaseComponent implements OnInit, OnChanges {
  flushData: any[];
  @Input() data: any[];
  @Input() userTypeRole: string = '';
  @Output() structureClicked = new EventEmitter();
  @Select(AppState.getWorkRequestGlobalId)
  getWorkRequestGlobalId$: Observable<string>;
  constructor(
    private ccRequestsService: CcRequestsService,
    private requestsService: RequestsService,
    private style: StylerService, 
    private banner: BannerService
  ) {
    super(style);
  }

  ngOnInit(): void {
    this.flushData = this.data;
  }

  ngOnChanges() {
    // Check 'data' array that feeds table
    console.log('User Type Role: ', this.userTypeRole);
    this.flushData = this.data;
  }

  onOpen(dataItem) {
    this.banner.resetBanner();
    this.requestsService.onOpenHandler(dataItem)
    .subscribe((res: WorkRequest ) => {
      this.ccRequestsService.handleNavigationFromDashboard(dataItem, 'eo');
    });
  }

  openMap(dataItem: any) {
    this.structureClicked.emit({ action: 'assetTag', value: dataItem });
  }
}
