import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { forkJoin, Observable, of } from 'rxjs';
import { map, catchError, tap, take, switchMap, filter } from 'rxjs/operators';
import { DeleteAccessInformation, DeleteEmergencyInformation } from 'src/app/app-state/actions/access-info.actions';
import { DeleteFlushInformation } from 'src/app/app-state/actions/flush-process-info.actions';
import { DeleteJobDetails } from 'src/app/app-state/actions/job-details.actions';
import { DeleteStartJobPhoto, DeletePausedJob, DeleteWorkDescription, AddWorkRequestDetail } from 'src/app/app-state/actions/start-job.actions';
import { DeleteStructureInformation } from 'src/app/app-state/actions/structure-info.actions';
import { AddWorkRequestGlobalId, AddFacilityGlobalId, AddWorkComponentGlobalId, AddWorkComponentId } from 'src/app/app-state/actions/work-request.actions';
import { WorkRequestRow } from 'src/app/interfaces/work-request-row';
import { Structure } from 'src/app/models/structure.model';
import { WorkRequest } from 'src/app/interfaces/work-request';
import { WorkRequestDetail } from 'src/app/models/work-request-detail';
import { WorkRequestsResponse } from 'src/app/models/work-requests-response';
import { LoggingService } from '../logging/logging.service';
import { CONFIG } from 'src/app/global/config';
import { UserInfo } from 'src/app/interfaces/user-info';
import { AssociatedWorkBody } from 'src/app/interfaces/associated-work-body';
import { Dashboard } from 'src/app/app-state/actions/dashboard-work-requests.actions';
import { WorkRequest as WRModel } from 'src/app/models/work-request';
import { BaseService } from '../base/base.service';
import { MapService } from '../map/map.service';
import { MapperService } from '../mapper/mapper.service';

@Injectable({
  providedIn: 'root',
})
export class RequestsService {
  constructor(
    private store: Store,
    private logger: LoggingService,
    private baseService: BaseService,
    private mapService: MapService,
    private mapperService: MapperService
  ) { }

  onOpenHandler(event: { action: any; value: any }) {
    console.log(event);
    const dataItem = event.value ?? event;
    const flushWRNumber = dataItem.workRequestGlobalId;
    const facilityGlobalId = dataItem?.facility[0]?.facilityID ?? '';

    // Empty AppState on new request
    this.store.dispatch([
      DeleteJobDetails,
      DeleteAccessInformation,
      DeleteFlushInformation,
      DeleteStructureInformation,
      DeleteStartJobPhoto,
      DeletePausedJob,
      DeleteWorkDescription,
      DeleteEmergencyInformation
    ]);
    this.store.dispatch(new AddWorkRequestGlobalId(flushWRNumber));
    this.store.dispatch(new AddWorkComponentGlobalId(dataItem.workComponentGlobalID));
    this.store.dispatch(new AddFacilityGlobalId(facilityGlobalId));
    this.store.dispatch(new AddWorkComponentId(dataItem.workComponentDescription));
    return this.store.select(store => store.AppState.assignedWRDetails as WorkRequest[])
      .pipe(
        // Using includes because ARM exists for flush mechanic workComponentGlobalID
        map(reqs => reqs.find(req => {
          return req.workRequestGlobalID.includes(dataItem.workRequestGlobalId) && req.workComponentGlobalID.includes(dataItem.workComponentGlobalID);
        })),
        catchError(err => {
          if (err instanceof TypeError) {
            console.log(`${dataItem.workComponentGlobalID}, ${dataItem.workComponentGlobalID} does not exist inside store`);
            console.log(this.store.selectSnapshot(store => store.AppState.assignedWRDetails as WorkRequest[]))
          }
          return of(null)
        }),
        tap((res: WorkRequest) => {
          if (res) {
            const reqInDashboard = this.store.selectSnapshot(store => store.AppState.dashboardWorkRequests as WorkRequestsResponse)?.entities
            const wr = reqInDashboard.find((req: WorkRequestRow) => req.workRequestGlobalId === flushWRNumber && req.workComponentGlobalID.includes(dataItem.workComponentGlobalID))
            const facilities = wr.facility.map(i => i).shift();
            const job = (res?.flushPriority ?? '').split('-').pop(); // Flush Priority usually comma delmitted: <lvl>-<jobName>
            let workRequestDetail = new WorkRequestDetail();
            workRequestDetail.workRequestGlobalId = flushWRNumber;
            workRequestDetail.bTicket = res.bTicket;
            workRequestDetail.appointmentId = res.crmsAppointmentId;
            workRequestDetail.sourceWrNumber = dataItem.sourceWrNumber ?? '';
            workRequestDetail.workRequestNo = res.workRequestNo;
            workRequestDetail.workRequestName = res.workRequestName;
            workRequestDetail.priorityLevel = dataItem.priorityLevel ?? '';
            workRequestDetail.job = job ?? '';
            workRequestDetail.priorityItem = res?.priorityItem ?? '';
            workRequestDetail.workRequestDesc = '';
            workRequestDetail.concatenatedWRAddress = res.concatenatedWRAddress;
            workRequestDetail.entryDate = res.entryDate;
            workRequestDetail.id = dataItem.id;
            workRequestDetail.facility = Object.assign({}, !!facilities ? {
              facilityId: facilities.facilityID.toString(),
              frontAddress: facilities.address,
              structureId: facilities.assetTag,
              borough: facilities.borough,
              facilityGlobalId: facilities.facilityGlobalId,
              type: facilities.facilityType,
              isCustomerOwned: false,
              isManual: facilities.isManualAdd,
              longitude: facilities.longitude,
              latitude: facilities.latitude
            } as Structure : null)
            //  = res.images;
            workRequestDetail.workRequestDesc = '';
            this.store.dispatch(new AddWorkRequestDetail(workRequestDetail));
          }
        }),
        take(1),
        catchError((err) => {
          if (facilityGlobalId.length > 0) {
            this.logger.logException(err);
          }
          return of(null)
        }),
      )
  }
  
  sortEntitiesByDate(workRequestRows: WRModel[] | WorkRequestRow[]): WRModel[] | WorkRequestRow[] {
    if (!workRequestRows || workRequestRows?.length < 1) return [];

    // Most recent date - Not most recent date
    return workRequestRows.sort((a: WRModel | WorkRequestRow, b: WRModel | WorkRequestRow) => {
      return new Date(b.date).getTime() - new Date(a.date).getTime();
    });
  }

  getWorkRequestsFromAssignedWork(): Observable<WorkRequestsResponse> {
    // use async pipe to subscribe and unsubscibe to result
    return this.store.selectOnce(store => store.AppState.assignedWork as AssociatedWorkBody[])
      .pipe(
        tap(assignedWork => {
          if (assignedWork?.length === 0) {
            throw { header: CONFIG.DASHBOARD_ERROR_MESSAGES.NO_WORKREQUESTS_ERROR.HEADER };
          }
        }),
        switchMap(assignedWork => forkJoin(assignedWork.map(body => this.baseService.getAssignedWork(body))).pipe(map(d => d))
        ),
        tap(data => {
          // Sort, might be used by state
          const entities = this.sortEntitiesByDate(data.map(item => item.dashBoard.entities).filter(item => item != undefined && !item.errorText)) as WorkRequestRow[];
          const submittedEntities = this.sortEntitiesByDate(data[0].dashBoard.flushSubmittedEntities?.map(i => i)) as WRModel[];
          // Filter out undefined/null workrequests
          const wrArray = data.map(response => this.mapperService.flattenWcListToWrList(response.workRequestWithComponentList)).map(wr => wr[0])?.filter(i => i);
          this.store.dispatch(new Dashboard.AddDetailedWork(wrArray, false));
          this.store.dispatch(new Dashboard.Add({ status: '200', message: '', crewCode: '', exception: '', entities: entities, flushSubmittedEntities: submittedEntities as any }));
        }),
        map(data => {
          // Sort on success, return object mapped for ui
          const entities = this.sortEntitiesByDate(data.map(item => (item.dashBoard as any).entities).filter(item => item != undefined && !item.errorText)) as WorkRequestRow[];
          const submittedEntities = this.sortEntitiesByDate(data[0].dashBoard.flushSubmittedEntities?.map(i => i)) as WRModel[];

          return { status: data[0].dashBoard.status, message: data[0].dashBoard.message, entities: entities, flushSubmittedEntities: submittedEntities as any } as WorkRequestsResponse;
        }),
        catchError((error) => {
          this.logger.logException(error);

          if (error?.header === CONFIG.DASHBOARD_ERROR_MESSAGES.NO_WORKREQUESTS_ERROR.HEADER) {
            // this.createBanner({}, CONFIG.DASHBOARD_ERROR_MESSAGES.NO_WORKREQUESTS_ERROR.HEADER, CONFIG.DASHBOARD_ERROR_MESSAGES.NO_WORKREQUESTS_ERROR.BODY);
            return of({} as WorkRequestsResponse);
          }
        })
      )
  }
  getWorkRequestsByType(type: string) {
    switch (type) {
      default:
      case 'ROUTINE':
        return this.store.select(store => store.AppState.userInfo as UserInfo)
        .pipe(
          filter((userInfo) => !!userInfo && userInfo.user.isLoggedin),
          switchMap(userInfo => this.getWorkRequestsFromAssignedWork()),
          catchError(err => {
            console.log(err);
            
            return of([])
          }),
        )
      // TODO: IMPLEMENT NEW AND PIROR FETCH FUNCTIONALITY
      // default: 
      //   return of([]);
    }
  }
}
