<svg xmlns="http://www.w3.org/2000/svg" style="display: none">
  <symbol id="checkmark" viewBox="0 0 26 34">
    <path stroke-linecap="round" stroke-miterlimit="10" fill="none"  d="M22.9 3.7l-15.2 16.6-6.6-7.1">
    </path>
  </symbol>
</svg>

<div class="promoted-checkbox">
  <input id="{{id}}" type="checkbox" (click)="clickChange(this)" [checked]="isChecked" [disabled]="isDisabled" [value]="value" class="promoted-input-checkbox"/>
  <label class="checkbox-label" for="{{id}}" [ngClass]="{
    'error-checkbox-btn': isError
  }">
    <svg><use xlink:href="#checkmark" /></svg>
  </label>
  <div class="checkbox-caption">
    <p [ngClass]="{
      'checkbox-error-color': isError,
      'checkbox-disabled-color': (isDisabled && !isError),
      'checkbox-checked-color': (isChecked && !isError && !isDisabled)
    }">{{text}}</p>
    <div *ngIf="isError">
      <span class='icon-error checkbox-error-color checkbox-msg-align-top checkbox-form-message'></span>
      <small class="checkbox-msg-align-top">{{errorMsg}}</small>
    </div>
    <div *ngIf="isDisabled && !isError">
      <small class="checkbox-msg-align-top checkbox-disabled-color"></small>
    </div>
    <div *ngIf="formMsg.length > 1 && !isError && !isDisabled">
      <small class="checkbox-msg-align-top checkbox-form-message" [ngClass]="{
        'checkbox-checked-color': (isChecked && !isError && !isDisabled)
      }">{{formMsg}}</small>
    </div>
  </div>
</div>
