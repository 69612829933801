import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable, combineLatest, BehaviorSubject, Subject, interval } from 'rxjs';
import { switchMap, map, filter, tap, distinctUntilChanged, scan, take} from 'rxjs/operators';
import { CONFIG } from 'src/app/global/config';
import { Store } from '@ngxs/store';
import { MapService } from 'src/app/services/map/map.service';
import { SortableItem, SortableItemData } from 'src/app/common/sortable/sortable.component';
import { CallToAction } from '../style-guide/style-guide.component';
import { UserInfo } from 'src/app/interfaces/user-info';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PwaService } from 'src/app/services/pwa/pwa.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { BaseService } from 'src/app/services/base/base.service';
import { UserSettings } from 'src/app/interfaces/user-settings';
import { AddUserInfo } from 'src/app/app-state/actions/user-info.actions';
import { environment } from 'src/environments/environment';
import { flushEndpoints } from 'src/environments/flush-endpoints';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Alert } from '@ce-lib/alert';
import { Router } from '@angular/router';
import { CloseTabItem, SupervisorHubService } from 'src/app/services/supervisor-hub/supervisor-hub.service';
import { User } from 'src/app/models/user.model';
import { SupervisorJobView } from 'src/app/interfaces/supervisor-job-view';
import { Meta } from '@angular/platform-browser';
import { Banner, BannerService } from 'src/app/services/banner/banner.service';
import { LoggingService } from 'src/app/services/logging/logging.service';
import { LeadsAndCrewCodes, SearchSuggestionsLeadsAndCrewCodes } from 'src/app/interfaces/leads-and-crew-codes';
import { PhotoService } from 'src/app/services/photo/photo.service';
import { AddStartJobPhotos, AddWorkRequestDetail } from 'src/app/app-state/actions/start-job.actions';
import { OptionModel } from 'src/app/common/multi-selectbox/multi-selectbox.component';
import { MasterDataService } from 'src/app/services/master-data/master-data.service';
import { AddStructureInformation } from 'src/app/app-state/actions/structure-info.actions';
import { AddFlushWRNumber, AddWorkComponentGlobalId, AddWorkRequestGlobalId } from 'src/app/app-state/actions/work-request.actions';
import { MapperService } from 'src/app/services/mapper/mapper.service';
import { WorkRequestDetail } from 'src/app/models/work-request-detail';

@Component({
  selector: 'app-supervisor-hub',
  templateUrl: './supervisor-hub.component.html',
  styleUrls: ['./supervisor-hub.component.scss']
})
export class SupervisorHubComponent implements OnInit, OnDestroy {
  private _appts = new BehaviorSubject<SortableItem[]>([]);
  private _prior = new BehaviorSubject<SortableItem[]>([]);
  private _new = new BehaviorSubject<SortableItem[]>([]);

  banner$: Observable<Banner>;
  $appts: Observable<SortableItem[]>;
  $prior: Observable<SortableItem[]>;
  $new: Observable<SortableItem[]>;
  $user: Observable<UserInfo>;
  $geolocationPerm = new BehaviorSubject<PermissionState>('prompt');
  calendarOptions: Array<OptionModel> = [];

  rejectReasonForm: FormGroup = new FormGroup({
    "rejectReason": new FormControl('', [Validators.required])
  });

  settingsForm: FormGroup = new FormGroup({
    "hour": new FormControl('', [Validators.required]),
    "minute": new FormControl('', [Validators.required]),
    "ampm": new FormControl('', [Validators.required]),
    "currentShiftLength": new FormControl('', [Validators.required]),
    "priorShiftLength": new FormControl('', [Validators.required]),
    "calendarname": new FormControl('', [Validators.required]),
    "isLocationEnabled": new FormControl('', [Validators.required]),
  });
  assignmentForm: FormGroup = new FormGroup({
    searchLeadOrCrewCode: new FormControl(''),
    assignLeadOrCrewCode: new FormControl(null, Validators.required)
  });
  backgroundUsersId: any;
  showModal: boolean;
  showAssignLeadOrCrewCode: boolean; // Variable used to show/hide the option to search and select the crew based on the work status
  isPendingFlushWR: boolean; //Variable used to represent the pending WR Status 
  showApproveButton: boolean; // Flag used to represent whether the approve/assign/re-assign button in modal is visible or hidden.
  showRejectButton: boolean; // Flag used to represent whether the reject button in modal is visible or hidden.
  showCloseButton: boolean; // Flag used to represent whether the close button in modal is visible or hidden.
  showCloseModal: boolean;
  showSettings: boolean;
  showReject: boolean;
  sourceForRejectReason: string; //Variale used to track the source from where the reject reason popup got triggered.
  modal: any;
  approveLabelText: string; //Varible used to dynamically change the apporve/assign button text in the popup modal
  isMobile = window.innerWidth < 769;
  firstName: string;
  defaultDate =  Date.now();
  selectedIndex = 0;
  skip = false;
  slides = [
    {
      heading: 'Bird\'s-eye view of your entire district',
      description: 'View your entire district with the scheduled, previous and new requests in one central location. By allowing Location Tracking see where you are in relation to the jobs.',
      src: 'assets/supervisor-map.png'
    },
    {
      heading: 'Review and edit new requests on the go',
      description: 'Easily receive new and view the details of new requests in and out of your office ',
      src: 'assets/supervisor-requests.png'
    }
  ]

  tutorials = [
    {
      heading: 'Map',
      paragraphs: [
        'The map displays all WR\'s and a satellite view. If there is spotty service, the map may take a long time to load.', 
        'You can turn on and off Location Awareness in the Settings page.'
      ]
    },
    {
      heading: 'Manage View',
      paragraphs: [
        'The manage view shows all of the jobs currently being worked on, and the prior shift.', 
        'The scheduled panel shows jobs that are scheduled for the current shift.',
        'The prior shift panel shows jobs that are from the shift immediately before the current shift.',
        'Emergent jobs will show as an alert on the top of this page. For emergent jobs, you can view, accept, deny, assign and reassign them directly in this application.',
        'You can change the length of your shifts in the settings page.'
      ]
    },
    {
      heading: 'Close View',
      paragraphs: [
        'The close view shows  jobs that have been closed out.', 
        'Completed in ERA section shows jobs that have been requested and fulfilled through ERA, and can be closed in WMS through this tool.'
      ]
    }
  ]

  $closedInERA: Observable<CloseTabItem[]>;
  $notClosedInERA: Observable<CloseTabItem[]>;
  private _closedInERA = new BehaviorSubject<CloseTabItem[]>([]);
  private _notClosedInERA = new BehaviorSubject<CloseTabItem[]>([]);
  closeActionsInERA = [
    {
      header: ' ', type: 'custom', action: 'Edit',
      content: `
        <b>Edit</b>
        <i class="flush-grid-default-action-right-arrow material-icons">chevron_right</i>
      `
    },
    {
      header: ' ', type: 'custom', action: 'Close',
      content: `
        <b>Close</b>
      `
    }
  ];
  closeActionsNotInERA = [
    {
      header: ' ', type: 'custom', action: 'Edit',
      content: ``
    },
    {
      header: ' ', type: 'custom', action: 'Close',
      content: ``
    }
  ]
  showPrior = true;
  isEmergencyJob: boolean = false;
  isCrewCodeReassigned: boolean = false;
  closeDesc = null;
  get closeGridColumns() {
    return CONFIG.SUPERVISOR_HUB.CLOSE_GRID_COLUMNS;
  }
  calendarDefaultIndexes: number[];
  isCalendarSelValid: boolean = true;
  newRequests = []; // Variable used to hold the current set of new requests.
  backgroundWorker: any;
  isUserScrolling = false;
  showTutorial = false;
  currentTutorial = 0;
  @HostListener('window:scroll', ['$event'])
  onScroll(e) { 
    if(!this.isUserScrolling) {
      this.saveScroll();
    }
  }
  @ViewChild("mapcontainer") block: ElementRef;
  @ViewChild("supervisorlists") supervisorLists: ElementRef; 
  @ViewChild("tabs") tabs: ElementRef;
  constructor(
    private store: Store,
    private mapService: MapService,
    private pwaService: PwaService,
    private baseService: BaseService,
    private authService: AuthService,
    private alert: Alert,
    private router: Router,
    private supervisorHubService: SupervisorHubService,
    private meta: Meta,
    private banner: BannerService,
    private logger: LoggingService,
    private photoService: PhotoService,
    private masterData: MasterDataService,
    private mapper: MapperService
  ) { 
    this.meta.updateTag({ name: 'viewport', content: 'initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no'})
  }
  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    this.isMobile = window.innerWidth < 769;
    this.setTutorial(this.currentTutorial);
  }

  ngOnInit(): void {
    // document.addEventListener('wheel', (e) => {
    //   this.saveScroll();
    // }); 
    document.addEventListener('touchmove', (e) => {
      this.saveScroll();
    });
    document.addEventListener('touchstart', (e) => {
      this.saveScroll();
    });
    this.banner.resetBanner();
    this.banner$ = this.banner.banner$;
    this.settingsForm.get('isLocationEnabled').valueChanges.subscribe(enabled => {
      if (enabled === 'true') {
        this.pwaService.enableGeolocation().then(pos => {
          if ('coords' in pos) {
            this.$geolocationPerm.next("granted");

            this.mapService.queueNewMarkers([{
              id: 'CURRENT_LOCATION',
              latitude: pos.coords.latitude,
              longitude: pos.coords.longitude,
              color: [0, 120, 207],
              wr: null
            }])
          } else {
            // Denied perms
            this.$geolocationPerm.next("denied");
          }
        }).catch(err => {
          console.log(err);
          this.settingsForm.get('isLocationEnabled').setValue('false');
          this.$geolocationPerm.next("denied");
        })
      } else {
        // Show instructions to remove 
        this.$geolocationPerm.next("denied");
      }
    })

    this.settingsForm.get('calendarname').valueChanges.subscribe( ()=> {
      const calendars = this.settingsForm.get('calendarname').value;
      this.isCalendarSelValid = calendars?.length  <= 2 ? true : false;
      this.calendarDefaultIndexes = this.calendarOptions
          .map((option, index) => calendars?.includes(option.value) ? index : null)
          .filter(index => index !== null);
    });    

    this.masterData.getCacheItem(CONFIG.MASTER_DATA.PRIORITY_ITEMS) as Observable<String[]>,
    this.masterData.getCacheItem(CONFIG.MASTER_DATA.CALENDARNAMES)
    .subscribe((calendars: string[]) => {
      for (const calendar of calendars) {
        const tempOption = new OptionModel();
        tempOption.option = calendar;
        tempOption.value = calendar;
        this.calendarOptions.push(tempOption);
      }
     })
    .unsubscribe();


    // Reset checkboxes and values if a new value is typed
    this.assignmentForm.controls.searchLeadOrCrewCode.valueChanges.subscribe(() => {
      this.selectedSearchIndex = null;
      this.assignmentForm.controls.assignLeadOrCrewCode.setValue(null);
    });
    this.skip = this.store.selectSnapshot(store => store.AppState?.userInfo?.user?.setting) ?? false;
  }

  ngOnDestroy() {
    clearTimeout(this.backgroundUsersId);
  }

  
  ngAfterViewInit() {
    
  }

  ngAfterViewInitonClick(event) {
    console.log('ngAfterViewInitonClick.event', event);
  }

  searchSuggestions: SearchSuggestionsLeadsAndCrewCodes[] = [];

  getYPosition(): number {
    return window.scrollY;
  }

  saveScroll(){
    const pos = this.getYPosition();
    this.masterData.setScrollYPosition(pos);
  }

  setTutorial(index) {
    let datas = this.block?.nativeElement.getBoundingClientRect();
    if(index > 0) {
      datas = this.supervisorLists?.nativeElement.getBoundingClientRect();
    }
    let d = document.getElementById('axismaptutorial');
    console.log("setTutorial.datas = ", datas);    
    if(datas && !this.isMobile && d) {
      d.style.left = datas.left +'px';
      //d.style.top = (datas.top < 0 ? (datas.top + this.getYPosition()) : datas.top) +'px';
      d.style.top = (datas.top + window.scrollY) +'px';
      if(index === 1) {
        d.style.height = "750px";
      } else {
        d.style.height = "525px";
      }
    } else if(datas && this.isMobile && d) {
      d.style.left = 0 +'px';
      d.style.top = (datas.top + window.scrollY) +'px';
      if(index === 1) {
        d.style.height = "750px";
      } else {
        d.style.height = "525px";
      }
    }
  }

  viewTutorial(i: number) {
    if ((i < this.tutorials.length) && (i > -1)) {
      this.currentTutorial = i+1;
      this.setTutorial(this.currentTutorial);
    }
  }
   mapLoadedEvent(status: boolean) {
    this.setTutorial(this.currentTutorial);
    document.querySelector('.map-wrapper').addEventListener('touchmove', function(event) {
      // Prevent page scrolling if touch device is on
      event.preventDefault();
    })
    this.baseService.$settingsModal.subscribe((val) => {
      this.showSettings = val
    })
    if (typeof Worker !== 'undefined') {
      // Create a new worker 
      const worker = new Worker(new URL('./supervisor-hub.worker', import.meta.url));
      this.backgroundWorker = worker;
      this.baseService.$settingsModal.subscribe((val) => {
        //this.showSettings = val        
        if(!val) {
          this.startProcessingMessages(worker);
        }
      })
      worker.onmessage = ({ data }) => {
        switch (data.message) {
          case 'USERS':
            const {user, supRequestLists, workAssignments} = data.data as UserInfo;
            // Noticed this call forces a scroll event?
            this.store.dispatch(new AddUserInfo({
              workAssignments, 
              user: new User(
                user.flushUserId, 
                true, 
                user.name, 
                user.crewCode, 
                user.flushRoleType, 
                user.email, 
                user.flushRoleType, 
                user.isProvisioned, 
                user.isCrewCodeAssigned,
                user.department,
                user.flushRoleDescription,
                user.setting),
                supRequestLists: supRequestLists ?? []
            }))
            break;
          case 'NOT-CLOSED-IN-ERA':
            this._notClosedInERA.next(data.data);
            break;
          case 'CLOSED-IN-ERA':
            this._closedInERA.next(data.data);
            break;
          case 'NEW':
            const oData = data.data;
            console.log('NEW.data :>> ', data);
            const { newOverrides, assignedOverrides } = oData.reduce((acc, item) => {
              if (item.itemStatus !== 'AlreadyAssigned') {
                acc.newOverrides.push(item);
              } else if (item.itemStatus === 'AlreadyAssigned')  {
                acc.assignedOverrides.push(item);
              }
              return acc;
            }, { newOverrides: [], assignedOverrides: [] });

            this.banner.resetBanner();

            if (newOverrides.length > 0) {
              this.banner.banner.next({
                type: 'info',
                show: true,
                showIcon: true,
                details: {
                  header: "New Flush Request",
                  body: ["Please approve or reject the request."]
                }
              });
            }
            //TODO: Do we need a banner for already Assigned or Reject Flush request after the user approves/rejected 
            if(data.isNoCalendarsSelected) {
              this.newRequests = [];  
            } else {
              this.newRequests = this.newRequests.filter(nItem => nItem.itemStatus !== 'AlreadyAssigned' && newOverrides.findIndex((a) => a.itemId == nItem.itemId) === -1  && assignedOverrides.findIndex((a) => a.itemId == nItem.itemId) === -1)
                .concat(newOverrides);
            }            
            //console.log('NEW.this.newRequests :>> ', this.newRequests);
            this._new.next(this.newRequests);
            break;
          case 'PRIOR':
            this._prior.next(data.data);
            break;
          case 'ROUTINE':
          default:
          this._appts.next(data.data);
            break;
        }
        this.isUserScrolling = true;
        interval(3000).subscribe(() => {
          this.isUserScrolling = false;
          window.scrollTo(0, this.masterData.getScrollYPostition());
        });
      };
      
      const flatten = (all, incoming) => {
        const flatten = (items) => {
          let flat = [];
      
          items.forEach(i => {
            if (Array.isArray(i)) {
              flat.push(...flatten(i))
            } else {
              flat.push(i)
            }
          });
      
          return flat;
        }
        const result = flatten(all);
        const incomingFlat = flatten(incoming);
        incomingFlat.forEach(i => {
          result.push(i);
        })
        return result
      }
      const uniqueJobEntries = (items: any[]) => {
        const result: any[] = [];
        const map = new Map<string, any>();
        items.forEach(j => {
          const wc = j.itemId;
          if (map.has(wc)) {
            // Pick item that has been updated most recently
            const incoming =  new Date(j.wr?.wmsUpdateDate).getTime();
            const current =  new Date(map.get(wc).wr?.wmsUpdateDate).getTime();
            if (incoming - current > 0) {
              // current item is fresher than one already in map
              map.set(wc, j)
            } 
          } else {
            map.set(wc, j)
          }
        })
        map.forEach(value => {result.push(value)});

        return result;
      }
      const sortByWmsDate = (arr: SupervisorJobView[]) => {
        const a = arr.map(i => i);
        return a.sort((a,b) => new Date(b?.wmsUpdateDate).getTime() - new Date(a?.wmsUpdateDate).getTime())
      }

      this.backgroundUsersId = setInterval(() => {  
        this.startProcessingMessages(worker);
      }, 1000 * 60);
      this.startProcessingMessages(worker);

      this.$appts = this._appts.asObservable().pipe(tap((a) => {
        this.mapService.postMarker(a)
      }),
        scan(
          (all, incoming) => incoming.length < 1 ? [] :
            sortByWmsDate(
              uniqueJobEntries(
                flatten(all, incoming)
              )
            ),
          []
        )
      );

      // New Overrides
      this.$new = this._new.asObservable()
        .pipe(
          tap((n) => {
            let alreadyAssigned = n?.filter((item) => item['itemStatus'] === 'AlreadyAssigned')?.map(i => i.itemId);
            let newOverrides = n?.filter((item) => item['itemStatus'] != 'AlreadyAssigned');
            if (newOverrides?.length > 0) this.mapService.postMarker(newOverrides);
            if (alreadyAssigned?.length > 0) this.mapService.clearMarkers(alreadyAssigned);
            /* 
              Scanner removes items in 'all' that exists in 'incoming', then removes AlreadyAssigned wrs from 'incoming', 
              before flatten + uniqueJobEntries + sortByWmsDate.
              AlreadyAssigned will not have a date so uniqueJobEntries won't work
            */
          }), 
          map(items => items?.filter((item) => item['itemStatus'] != 'AlreadyAssigned')),
          scan((all, incoming) => incoming.length < 1 ? [] : 
            sortByWmsDate(uniqueJobEntries(flatten(all?.filter((override) => !((incoming?.map((override) => override.itemId) ?? []).includes(override.itemId))), incoming))), 
            []
          )
        );
      this.$prior = this._prior.asObservable().pipe(tap((p) => { this.mapService.postMarker(p) }), scan((all, incoming) => incoming.length < 1 ? [] : sortByWmsDate(uniqueJobEntries(flatten(all, incoming))), []));
      this.$closedInERA = this._closedInERA.asObservable().pipe(scan((all, incoming) => incoming.length < 1 ? [] : sortByWmsDate(uniqueJobEntries(flatten(all, incoming))), []));
      this.$notClosedInERA = this._notClosedInERA.asObservable().pipe(scan((all, incoming) => incoming.length < 1 ? [] : sortByWmsDate(uniqueJobEntries(flatten(all, incoming))), []));

      // Once map loads, load the sortable cards where each card is a item to be placed on the map
      this.$user = this.store.select(store => store.AppState.userInfo as UserInfo)
      .pipe(
        filter((userInfo) => !!userInfo && userInfo.user.isLoggedin),
        distinctUntilChanged((prev, cur) => {
          const curSorted = sortByWmsDate(cur.supRequestLists);
          const prevSorted = sortByWmsDate(prev.supRequestLists);
          return _.isEqual(prevSorted, curSorted) && _.isEqual(prev.user.setting, cur.user.setting)
        }),
        tap(({ user }) => {
          if (!user.setting) {
            this.settingsForm.reset();
            this.showSettingsModal();
            this.settingsForm.patchValue({minute: '00'});
            this.firstName = user.name;
            this.pwaService.handlePermission("geolocation").then((status: PermissionState) => {
              this.$geolocationPerm.next(status);
              if (status != 'prompt') {
                this.settingsForm.get('isLocationEnabled').setValue(status === 'granted' ? 'true' : 'false')
              }
            });
          } else {
            // Settings are set already
            // this.$geolocationPerm.next(""); // Set this to whatever came back from api
            const startTime = moment(user.setting.shiftStartTime, "HH:mm:ss").format("h:mm:A")
            const enabled = user?.setting?.isLocationEnabled ? 'granted' : 'denied'
            this.$geolocationPerm.next(enabled);
            this.calendarDefaultIndexes = [];            
            if(user?.setting.calendarName){
               const calendarNames = user.setting.calendarName.split(',');
               this.calendarDefaultIndexes = this.calendarOptions
               .map((option, index) => calendarNames.includes(option.value) ? index : null)
               .filter(index => index !== null);
            }

            this.settingsForm.patchValue({
              isLocationEnabled: user.setting.isLocationEnabled ? 'true': 'false',
              priorShiftLength: user?.setting.priorShiftLength,
              currentShiftLength: user.setting.currentShiftLength,
              hour: startTime.split(':')[0],
              minute: startTime.split(':')[1],
              ampm: startTime.split(':')[2],
              calendarname: user.setting.calendarName.split(',')
            });
          }
        }),
        switchMap(() => this.baseService.getUserInfo()),
        map(userInfo => {
          if (!!userInfo && userInfo.hasOwnProperty('workAssignments')) {
            // For some reason, /users returns and is missing workAssignments, causes bugs
            return {...userInfo, workAssignments: [] }
          } else {
            return userInfo;
          }
        }),
        tap(({user, workAssignments, supRequestLists}) => {
          const wrs = supRequestLists.map(j => ({ ...j, requestBody: { wrId: j.workRequestGlobalID, wcId: j.workComponentGlobalID } }));
          console.log(wrs);
          const defaultBody = { email: user.email, role: user.role, excludeCache: true, crewCode: user.crewCode };
          worker.postMessage({
            action: 'locations', 
            data: wrs, 
            body: defaultBody, 
            url: `${environment.flushApiBaseUrl}${flushEndpoints.assignedWork}`,
            headers: {
              'Authorization': 'Bearer ' + this.authService.accesstoken,
              APIEnvironment: environment.apimEnvironment,
              'Ocp-Apim-Subscription-Key': environment.apiSubKey,
              "Content-Type": "application/json; charset=utf-8"
            }
          });
        })
      )
    } else {
      // Web Workers are not supported in this environment.
      // You should add a fallback so that your program still executes correctly.
    }
  }

  startProcessingMessages(worker) {
    worker.postMessage({
      action: 'users', 
      url: `${environment.flushApiBaseUrl}${flushEndpoints.users}`,
      headers: {
        'Authorization': 'Bearer ' + this.authService.accesstoken,
        APIEnvironment: environment.apimEnvironment,
        'Ocp-Apim-Subscription-Key': environment.apiSubKey,
        "Content-Type": "application/json; charset=utf-8"
      }
    });
    worker.postMessage({
      action: 'overrides', 
      url: `${environment.flushApiBaseUrl}${flushEndpoints.overrides}`,
      body: {
        appointmentIds: this._new.getValue().map(({itemId, itemWorkStatus}) => ({itemId, itemWorkStatus}))
      },
      headers: {
        'Authorization': 'Bearer ' + this.authService.accesstoken,
        APIEnvironment: environment.apimEnvironment,
        'Ocp-Apim-Subscription-Key': environment.apiSubKey,
        "Content-Type": "application/json; charset=utf-8" 
      }
    })
  }

  isItemNewOrWIP(itemRow: any): boolean {
    return itemRow?.itemType == 'NEW' || itemRow?.itemType == 'ROUTINE' || itemRow?.itemType == 'PRIOR';
  }


  isCrewCodeNotAssignable(item: any): boolean {
    const appointmentID = item?.appointmentID ?? "NONE";
    return appointmentID === 'NONE';
  }
  

  isJobNotReassignable(item: any): boolean {
    const { itemType, data } = item;
    const index = ['PRIOR', 'ROUTINE'].includes(itemType) ? 6 : 7;
    const appointmentID = data[index]?.value[0]?.value?.appointmentID ?? "NONE";
    return appointmentID === 'NONE';
  }

  reassignJob(event, item: any) {
    const { itemType, data } = item;
    const index = ['PRIOR', 'ROUTINE'].includes(itemType) ? 6 : 7;
    const row = data[index]?.value[0] ?? null;
    const rowValue = data[index]?.value ?? null;
    const appointmentID = row?.value?.appointmentID ?? "NONE";

    if (appointmentID === "NONE") {
      event.preventDefault();
    } else {
      this.ctaClick(row, rowValue, true, true);
    }
    
  }

  ctaClick(row: any, all: any[], itemRow=null, isEmergency = false, isReject = false) {  
    console.log('Row: ', row, ' All: ', all, ' itemRow: ', itemRow, ' isEmergency: ', isEmergency , ' isReject: ', isReject);
    let cta: CallToAction = row;    
    this.modal = {...cta.value}
    if (cta.type === 'modal' && isEmergency) {
      this.isEmergencyJob = true;
    } else if (isEmergency) {
      const modalIdx = all.length - 1;
      this.modal = all[modalIdx]?.value[0]?.value;
      this.isEmergencyJob = true;
    }

    //Reset settings
    this.isCrewCodeReassigned = this.modal.workStatus !== 'Pending-Confirmed';

    if(isReject) {
      this.showReject = true;
      this.sourceForRejectReason = 'dashboard';
    } else {
      this.showModal = true;
      
      const pendingStatuses = ['Pending-Scheduled', 'Pending-Confirmed'];
      this.showAssignLeadOrCrewCode = pendingStatuses.includes(this.modal.workStatus);


      const approveButtonStatuses = ['Pending-Approval', 'Pending-Scheduled','Pending-Confirmed'];
      this.showApproveButton = approveButtonStatuses.includes(this.modal.workStatus);
   
      const rejectButtonStatuses = ['Pending-Approval', 'Pending-Scheduled'];
      this.showRejectButton = rejectButtonStatuses.includes(this.modal.workStatus);

      const showCloseButtonStatuses = ['Pending-FlushWR','Pending-Confirmed'];
      this.showCloseButton = showCloseButtonStatuses.includes(this.modal.workStatus);

      const workStatus = this.modal.workStatus;
      let approveLabelText = '';
      switch (workStatus) {
        case 'Pending-Approval':
          approveLabelText = 'Approve';
          break;
        case 'Pending-Scheduled':
          approveLabelText = 'Assign';
          break;
        case 'Pending-Confirmed':
          approveLabelText = 'Reassign';
          break;
        default:
          approveLabelText = 'Approve';
          break;
      }

      this.approveLabelText = approveLabelText;
      this.sourceForRejectReason = 'modal';

      let appointId;
      let images = [];

      if(cta.type === 'modal') {
        appointId = row.value.appointmentID;
        images = row.value.images?.map((image) => this.photoService.MapFlushPhotoToPhoto(image)) ?? [];
      } else {
        appointId = itemRow.itemId
      }
      this.store.dispatch(new AddStartJobPhotos(images));      
      
      this.supervisorHubService.getLeadNamesAndCrewCodes(appointId).subscribe((crewLeadsAndNames: LeadsAndCrewCodes[]) => {
        // Map array of values
        this.searchSuggestions = crewLeadsAndNames.map((obj: LeadsAndCrewCodes) => {
          return { "crewCode": obj.crew_code, "crewLead": obj.crew_contact }
        });

        // Unique objects only
        this.searchSuggestions = this.searchSuggestions.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.crewCode === value.crewCode && t.crewLead === value.crewLead
          ))
        )
      });
    }
  }

  selectedSearchIndex: number;

  changeSearchSelection(value, index, isSelected) {
    if (!isSelected) {
      this.assignmentForm.get('assignLeadOrCrewCode').setValue(value);
      this.selectedSearchIndex = index;
    } else {
      this.assignmentForm.get('assignLeadOrCrewCode').setValue(null);
      this.selectedSearchIndex = null;
    }
  }

  //Function to assing the new work request when the user clicks "Approve/Assign" in the modal.
  assignNewWorkRequest(appointmentID, crewCode)  {
    if (this.modal.workStatus === 'Pending-Approval') {
      const approveOverrideCall = this.supervisorHubService.putApproveOverride(appointmentID, crewCode);
      this.handleSubscription(approveOverrideCall);
    } else if (this.modal.workStatus === 'Pending-Scheduled' || this.modal.workStatus === 'Pending-Confirmed') {
      const changeCrewCall = this.supervisorHubService.putChangeCrew(appointmentID, crewCode);
      this.handleSubscription(changeCrewCall);
    }
  }

  handleSubscription(serviceCall) {
    serviceCall.subscribe(
      (val) => {
        console.log("Successfully approved override");
        this.showModal = false;
        this.modal = null;
        this.sourceForRejectReason = '';
        this.startProcessingMessages(this.backgroundWorker);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  //Function to show the reject reason Modal when the user clicks "Reject" in the modal
  displayRejectReasonModal(showReject: boolean, srcTriggered: string = '') {
    if (srcTriggered !== '') {
      this.sourceForRejectReason = srcTriggered;
    }
  
    this.showReject = showReject;
    switch (this.sourceForRejectReason) {
      case 'modal':
        this.showModal = true;
        break;
      case 'dashboard':
        this.showModal = false;
        this.startProcessingMessages(this.backgroundWorker);
        break;
      default:
        break;
    }
  }
  

  RejectNewWorkRequest(appointmentID) {
    const rejectReason = this.rejectReasonForm.controls.rejectReason.value;
    //Pass reject reason to the updated service as an additional parameter
    this.supervisorHubService.putRejectOverride(appointmentID, rejectReason).subscribe(
      (val) => {
        console.log("Successfully rejected override");
        this.showModal = false;
        this.modal = null;
        this.showReject = false;
        this.rejectReasonForm.get('rejectReason').setValue('');
        this.startProcessingMessages(this.backgroundWorker);
      },
      (err) => {
        console.error(err);
      }
    )
  }

  iconClicked(row, rowIdx) {
    // Click handler for icon
    console.log(row,rowIdx);
    
    const undefinedCoords = !row.longitude || !row.latitude;
    const invalidCoords = (lat, lng) => { return Math.abs(lat) > 85 || Math.abs(lng) > 180 }
    if (!undefinedCoords && !invalidCoords(row.latitude, row.longitude)) {
      document.getElementById('main-navigation').scrollIntoView();
      this.mapService.queueNewPan(row.longitude, row.latitude);
    } else {
      this.alert.urgent('Map coordinates not found: This request is missing coordinates to show on the map. If no address is provided, reference the Structure Number.', 'Dismiss')
    }
  }
  changeVisibility(event, modal) {    
    switch (modal) {
      case 'settings':
        this.settingsForm.markAsUntouched();
        this.baseService.controlSettingsModal(event);
        break;
      case 'reject':
        this.showReject = event;
        break;
      case 'showClose':
        this.showCloseModal = event;
      case 'modal':
        this.showModal = event;
        this.assignmentForm.reset();
        this.sourceForRejectReason = '';
        break;
      default:
          break;
    }
  }
  onCloseRowClick(event) {
    switch (event.action) {
      case 'Edit':
        this.supervisorHubService.closeTabItem.next(event.value);
        const wr = event.value as CloseTabItem;
        const savePhotos = wr?.workRequest?.images?.map((image) => this.photoService.MapFlushPhotoToPhoto(image)) ?? [];
        const structureInfo = wr?.workRequest?.structureInfo ?? [];
        const wrGlobalId = wr.workRequest?.workRequestGlobalID.startsWith('ARM') ? wr.workRequest?.workRequestGlobalID.split('~')[1] :  wr.workRequest?.workRequestGlobalID
        const wrDetail = {
          appointmentId: wr.appointmentId,
          workRequestNo: wr.wrNo,
          sourceWrNumber: wr?.sourceWrNumber ?? '',
          priorityLevel: wr.priorityLevel ?? '',
          workRequestGlobalId: wrGlobalId,
          bTicket: wr?.workRequest?.bTicket,
          workRequestName: wr?.workRequest?.workRequestName,
          workRequestDesc: wr?.workRequest?.wR_DESC ?? '',
          facility: null,
          job: wr.priorityLevel ?? '',
          workRequestId: wr.workRequest?.armWorkRequestID,
          wmsComments: wr.workRequest?.workRequestComments,
          status: wr.status
        } as WorkRequestDetail;
        this.store.dispatch([
          new AddStructureInformation({ 
            isValid: true,
            isManual: false,
            structures: Array.isArray(structureInfo) ? structureInfo.map(i => this.mapper.mapStructureInfoToStructure(i) ) :  [this.mapper.mapStructureInfoToStructure(structureInfo)]
          }),
          new AddStartJobPhotos(savePhotos),
          new AddFlushWRNumber(wrDetail.sourceWrNumber),
          new AddWorkComponentGlobalId(wr.workRequest?.workComponentGlobalID),
          new AddWorkRequestGlobalId(wrGlobalId),
          new AddWorkRequestDetail(wrDetail),
        ]).subscribe(() => {
          this.router.navigate(['era-axis/close']);
        })
        break;
      case 'Close':
        this.openCloseAppointment(event.value);        
        break;
    }
  }

  openCloseAppointment(objWr) {
    this.showCloseModal = true;
    this.modal = {...objWr};
    this.closeDesc = null;
    objWr?.workRequest?.workRequestComments?.map((workReqComment) => {
      this.closeDesc = this.closeDesc === null ? workReqComment.description : this.closeDesc + ' ' + (workReqComment.description ?? ''); 
      return workReqComment.description; 
    });   
    const images = objWr?.workRequest?.images?.map((image) => this.photoService.MapFlushPhotoToPhoto(image)) ?? [];
    this.store.dispatch(new AddStartJobPhotos(images));
  }

  closeAppointment(appointmentID) {
    this.supervisorHubService.putCloseAppointment(appointmentID).subscribe(
      (val) => {
        console.log("Successfully Closed Appointment");
        this.showCloseModal = false;
        this.modal = null;
        this.closeDesc = null;
      },
      (err) => {
        console.error(err);
      }
    )
  }

  onSelectionChange(show) {
    const priors = this._prior.getValue().map(p => p);

    if (!show) {
      if (priors?.length > 0) this.mapService.clearMarkers(priors.map(i => i.itemId));
    } else {
      this.mapService.postMarker(priors);
    }
    this.showPrior = show;
  } 
  showSettingsModal() {
    this.baseService.controlSettingsModal(true);
  }
  saveSettings() {
    const { user, workAssignments, supRequestLists } = this.store.selectSnapshot(store => store.AppState.userInfo as UserInfo)
    const newSettings: UserSettings = {
      flushUserID: user.flushUserId,
      priorShiftLength: this.settingsForm.get('priorShiftLength').value,
      currentShiftLength: this.settingsForm.get('currentShiftLength').value,
      calendarName: this.settingsForm.get('calendarname').value.join(","),
      isLocationEnabled: this.$geolocationPerm.value === 'granted',
      shiftStartTime: moment(`${this.settingsForm.get('hour').value}:${this.settingsForm.get('minute').value} ${this.settingsForm.get('ampm').value}`, "H:mm A").format("HH:mm:ss"),
      isActive: true,
      startTime: { ticks: 0 }
    };
    this.baseService.saveUserSettings(newSettings)
    .pipe(
      tap((data) => {
        this.store.dispatch(new AddUserInfo({ user: {...user, setting: {...newSettings} }, workAssignments, supRequestLists:  []}));
        this._notClosedInERA.next([]);
        this._closedInERA.next([]);
        this._new.next([]);
        this._prior.next([]);
        this._appts.next([]);
        this.mapService.clearMarkers();
      }),
      switchMap(() => this.baseService.getUserInfo())
    ) 
    .subscribe(userInfo => {
      this.store.dispatch(new AddUserInfo(userInfo));
      this.baseService.controlSettingsModal(false);
    }, err => {
      console.log(err);
      this.alert.urgent('Settings not saved.', 'Dismiss')
    })
  }
  onSkip(skipped) {
    if(!skipped) {
      this.showTutorial = true;
    }    
    this.skip = !skipped;
    // setTimeout(() => {
    //   document.querySelector('div.supervisor-lists').querySelector('div.ce-tab-container li:last-child')
    //                               .addEventListener('click', this.ngAfterViewInitonClick.bind(this));
    // }, 3000);    
  }
  navigateTo(route) {
    this.router.navigate(route);
  }
  testClick() {
    console.log('testClick :>> ');
  }
}