<div class="select-box-wrapper" (mouseover)="arrowsBtnPressed = false">
  <div class="select-box" [ngClass]="{'disabled': isReadOnly, 'focused': openDropdown, 'errorState': !isValid, 'completeState': isCompleted}"
   (click)="toggleDropDown()" tabindex="0" [attr.aria-placeholder]="placeholder"  appSelectDirective>
      <span class="top-select-label" *ngIf="selectedValues &&(selectedValues.length > 0)"> {{placeholder}} </span>
      <span class="select-label" [ngClass]="{'focused': openDropdown}" *ngIf="(selectedValues.length == 0)">{{ placeholder }}</span>
      <span class="select-label-after" [ngClass]="{'focused': openDropdown}" *ngIf="selectedValues">{{ selectedOptions.join(', ') }}</span>
      <i class="icon icon-caret-button select-box-caret" [ngClass]="{'focused': openDropdown}"></i>
  </div>
  <!-- <small class="form-text small error-msg" [ngClass]="{'hide-selectbox-error': openDropdown}" *ngIf="!isValid && (errorMsg.length > 0)"><i class="icon icon-error"></i>{{ errorMsg }}</small> -->
  <div class="selectbox-relative-selection">
    <div class="options" [ngClass]="{'hidden': !openDropdown}" appSelectDirective>
        <span class="disabled px-3 py-2">-Select all that apply-</span>
        <span
          class="option px-3 py-2"
          [ngClass]="{'hover' : ((selectedIndexes.includes(i)) || arrowsBtnPressed), 'noHover': arrowsBtnPressed}"
          *ngFor="let option of options; let i = index;"
          (click)="onOptionClick(option.value, option.option, i)"
          ><small class="form-text option-icon"><i class="icon icon-check" [ngStyle]="{'opacity' : (selectedIndexes.includes(i))? '1': '0'}"></i></small>{{option.option}}
        </span>
    </div>
  </div>
  <div class="row no-gutters form-text-row">
    <div class="col-auto">
      <i class="icon icon-error error-icon" *ngIf="!isValid && (errorMsg.length > 0)"></i>
    </div>
    <div class="col">
      <small class="form-text small error-msg" *ngIf="!isValid && (errorMsg.length > 0)">{{ errorMsg }}</small>
    </div>
  </div>
</div>
