import { FlushPhoto } from 'src/app/models/flushPhoto';

export class FlushReview {
  workRequestNumber: string;
  crewCode: string;
  comments: string;
  images: FlushPhoto[];
  username: string;

  // Contractor questions
  isCrewConductingRepairs: boolean; 
  canOriginalWorkContinue: boolean; 
  hasStrayVoltage: boolean;
  hasLeadStabilizerUsed:boolean;
  noOfStabilizers: number;

  constructor(workRequestNumber: string, crewCode: string, comments: string, images: FlushPhoto[], username: string, isCrewConductingRepairs: boolean, canOriginalWorkContinue: boolean, hasStrayVoltage: boolean, hasLeadStabilizerUsed:boolean,
    noOfStabilizers: number) {
    this.workRequestNumber = workRequestNumber;
    this.crewCode = crewCode;
    this.comments = comments;
    this.images = images;
    this.username = username;
    this.isCrewConductingRepairs = isCrewConductingRepairs;
    this.canOriginalWorkContinue = canOriginalWorkContinue;
    this.hasStrayVoltage = hasStrayVoltage;
    this.hasLeadStabilizerUsed = hasLeadStabilizerUsed;
    this.noOfStabilizers= noOfStabilizers;
  }
}
