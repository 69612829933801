import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { tracker } from 'src/app/common/top-tracker/top-tracker.component';
import { ActivatedRoute } from '@angular/router';
import { SortableItem } from 'src/app/common/sortable/sortable.component';
import { Subscription } from 'rxjs';
export interface CallToAction {
  type: "modal" | "redirect" | "func",
  value: any
}
@Component({
  selector: 'app-style-guide',
  templateUrl: './style-guide.component.html',
})
export class StyleGuideComponent implements OnInit, OnDestroy {
  //You can use params or router-outlet (any url will do)
  topTracker: Array<tracker> = [
    {
      text: 'One',
      isActive: true,
      url: '/style-guide;page=one',
    },
    {
      text: 'Two',
      isActive: false,
      url: '/style-guide;page=two',
    },
    {
      text: 'Three',
      isActive: false,
      url: '/style-guide;page=three',
    },
  ];
  
  styleGuideGridCols = [
    { field: 'facilityID', title: 'Facility ID' },
    { field: 'one', title: 'Col One' },
    { field: 'two', title: 'Col Two' },
    { field: 'three', title: 'Col Three' }
  ];

  styleGuideGridData = [
    {
      facilityID: '1',
      one: 'row 1',
      two: 'row 1',
      three: 'row 1',
      four: 'row 1'
    },
    {
      facilityID: '2',
      one: 'row 2',
      two: 'row 2',
      three: 'row 2',
      four: 'row 2'
    },
    {
      facilityID: '3',
      one: 'row 3',
      two: 'row 3',
      three: 'row 3',
      four: 'row 3',
    }
  ];
  defaultItems = [
    {
      itemId: "17192658",
      itemType: "PRIOR",
      titleName: "Crew Code",
      titleValue: "X_FLCO_01",
      data: [
        {
          header: '',
          value: [{type: 'icon', icon: 'icon-outage-map', iconColor: '#00882B', isClickable: true}, {type: 'icon', icon: 'icon-outage-map', iconColor: '#00882B', isClickable: true}]
        },
        {
          header: "Address",
          value: ['53 Broadway', '4 Irving Pl, NY 10021']
        },
        {
          header: "WR",
          value: ['4421412', '321351']
        },
        {
          header: '',
          value: [{
            type: 'modal',
            value: { title: "Modal test", body: "Clicked 4421412 the sortable component.", argument: '4421412', createTable: true, tableData: this.styleGuideGridData, tableColumns: this.styleGuideGridCols }
          },
          {
            type: 'modal',
            value: { title: "Modal test", body: "Clicked 321351 from the sortable component." }
          }
          ]
        }
      ]
    }, 
    {
      itemId: "17192658",
      itemType: "PRIOR",
      titleName: "Crew Code",
      titleValue: "M_FLCO_05",
      data: [
        {
          header: '',
          value: [{type: 'icon', icon: 'icon-outage-map', iconColor: '#00882B', isClickable: true}, {type: 'icon', icon: 'icon-outage-map', iconColor: '#00882B', isClickable: true}]
        },
        {
          header: "Address",
          value: ['E 16th Street, NY 10021', '67 Pl 53 Dr']
        },
        {
          header: "WR",
          value: ['123456', '654321']
        }, 
        {
          header: '',
          value: [{
            type: 'modal',
            value: { title: "Modal test", body: "Clicked from the sortable component." }
          },{
            type: 'modal',
            value: { title: "Modal test", body: "Clicked from the sortable component." }
          }]
        }
      ]
    },
    {
      itemId: "17192658",
      itemType: "PRIOR",
      titleName: "Crew Code",
      titleValue: "M_FLCO_05",
      data: [
        {
          header: '',
          value: [{type: 'icon', icon: 'icon-outage-map', iconColor: '#00882B', isClickable: true}]
        },
        {
          header: "Address",
          value: ['123 W 4 ST, NY 10021']
        },
        {
          header: "WR",
          value: ['123456']
        }, 
        {
          header: '',
          value: [{
            type: 'modal',
            value: { title: "Modal test", body: "Clicked from the sortable component." }
          }]
        }
      ]
    }
  ]
  items: SortableItem[] =[];
  stylesForm: FormGroup = new FormGroup({
    textbox: new FormControl(''),
    textboxError: new FormControl('', [Validators.required]),
    selectbox: new FormControl(''),
    checkbox: new FormControl(''),
    radiobtn: new FormControl(''),
    multiSelectbox: new FormControl(''),
    tile: new FormControl('')
  });;
  isCameraComponentOn = false;
  isUsingViewPort = false;
  isModalDisplay = false;

  isOne = false;
  isTwo = false;
  isThree = false;
  showModal = false;
  modal: { title: string, body: string, createTable?: boolean, tableData: any[], tableColumns: any[]} = null;
  isMobile: boolean;
  routeSub: Subscription;

  constructor(private activatedRoute: ActivatedRoute) {
    this.routeSub = this.activatedRoute.params.subscribe((params) => {
      const page = params['page'];
      switch (page) {
        case 'one':
          this.isOne = true;
          this.isTwo = false;
          this.isThree = false;
          break;
        case 'two':
          this.isOne = false;
          this.isTwo = true;
          this.isThree = false;
          break;
        case 'three':
          this.isOne = false;
          this.isTwo = false;
          this.isThree = true;
          break;
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  private onResize() {
    this.items = window.innerWidth < 768 ? this.defaultItems.map(i => ({...i, data: typeof i.data === 'object' ? i.data.filter(di => di.header != 'WR') : i.data  })) : this.defaultItems;
  }

  ngOnInit(): void {
    this.items = window.innerWidth < 768 ? this.defaultItems.map(i => ({...i, data: typeof i.data === 'object' ? i.data.filter(di => di.header != 'WR') : i.data  })) : this.defaultItems;
  }
  ngOnDestroy(): void {
    this.routeSub.unsubscribe();
  }
  onPhotosAdded(photos) {
    this.isCameraComponentOn = false;
    this.isUsingViewPort = false;
    console.log(photos);
  }

  testVideo
  showImageInformation(event) {
    console.log("Show Image Event: ", event);
    this.testVideo = event;
  }
  testFileType
  setFileType(event) {
    this.testFileType = event;
    console.log({ event });
  }

  onMediaSelected(event) {
    console.log("Media selected: ", event);
  }

  onViewClick(event) {
    console.log("View click event: ", event);
  }
  deleteClick(event) {
    console.log("deleteClick: ", event);
  }
  mediaUpdate(event) {
    console.log("mediaUpdate: ", event);
  }

  clickEvent(){}
  uploadedMedias = [
    {
      path: `https://picsum.photos/200/300?random=2`,
      type: 'video'
    }
    ,
    {
      path: `https://picsum.photos/200/300?random=3`,
      type: 'video'
    }
    ,
    {
      path: `https://picsum.photos/200/300?random=4`,
      type: 'video'
    }
    ,
    {
      path: `https://picsum.photos/200/300?random=5`,
      type: 'video'
    }
  ];

  uploadedPhoto = [

    {
      path: `https://picsum.photos/200/300?random=6`,
      type: 'photo'
    }
    ,
    {
      path: `https://picsum.photos/200/300?random=7`,
      type: 'photo'
    },
    {
      path: `https://picsum.photos/200/300?random=8`,
      type: 'photo'
    },
    {
      path: `https://picsum.photos/200/300?random=9`,
      type: 'photo'
    }
  ];


  relayAction(item) {
    switch (item.action) {
      case 'add':
        const found = this.styleGuideGridData.find((el) => el.facilityID === item.value.facilityID);
        if (!found) {
          const flushStructure = { ...item.value, customerOwned: false };
          this.styleGuideGridData.push(flushStructure);
        }
        break;
      case 'delete':
        const ind = this.styleGuideGridData.findIndex((el) => el.facilityID === item.value.facilityID);
        this.styleGuideGridData.splice(ind, 1);
        break;
      case 'remove':
        const removing = new Promise(async (resolve, reject) => {
          for (const selected of item.value) {
            const ind = await this.styleGuideGridData.findIndex((el) => el.facilityID === selected.facilityID);
            this.styleGuideGridData.splice(ind, 1);
          }
          resolve(true);
        });
        removing.then((cleared) => {
          console.log('cleared', cleared);
        });
        break;
      default:
        break;
    }
  }

  changeVisibility(event) {
    // Modal visibility change
    this.showModal = event;
  }

  ctaClick(row: any, rowIdx: number) {
    this.showModal = true;

    const cta: CallToAction = row;
    if (cta.type === 'modal') {
      this.modal = {...cta.value}
    }
  }
  iconClicked(row, rowIdx) {
    // Click handler for icon
  }
}
