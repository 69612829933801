<div>
  <h3>Job Details</h3>
  <p>Please provide details on the scope of work that is to be performed in the structure.</p>
  <form *ngIf="jobDetailsForm" [formGroup]="jobDetailsForm">
    <div class="form-group row">
      <div class="col-lg-5 ">
        <P><b>Please select the job to be performed.</b><a *ngIf="!this.jobDetailsForm.controls['electricConst'].valid" class="flush-process-error"> *</a></P>
        <ce-select formControlName="electricConst" placeholder="Electric construction job">
          <ce-option *ngFor="let option of electricJobs | async" [value]="option">
            {{ option.option }}
          </ce-option>
        </ce-select>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-lg-6 ">
        <p><b>Please provide a description of the area that work is to be performed.</b></p>
        <ce-input inputType="textarea" label="Area of work description" formControlName="areaOfWork" name="areaOfWorkDesc" id="areaOfWorkDesc"></ce-input>
      </div>
    </div>
    <div class="col-auto">
      <ce-button *ngIf="!isEdit" data-custom-id="CCJobDetailsPage" data-custom-bhvr="PROCESSCHECKPOINT" (clickEvent)="nextClick()" label="Next" type="primary" [disabled]="!this.jobDetailsForm.valid" size="large"></ce-button>
      <ce-button *ngIf="isEdit" (clickEvent)="saveClick()" label="Save" type="primary" [disabled]="!this.jobDetailsForm.valid" size="large"></ce-button>
    </div>
    <div class="form-group row">
      <div class="col-12 col-lg-4">
        <a class='btn-danger-link' (click)="cancelRequestInProgres()">Cancel Request</a>
      </div>
    </div>
  </form>
</div>

