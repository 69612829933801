<div @modalTrigger class="modal-container" *ngIf="showModal">
  <div class="modal-background"></div>
 <!-- <div class="modal" #modal [ngStyle]="getTopStyle()">-->
    <div class="modal" #modal >
    <div class="modal-content-items">
      <div class="modal-close" *ngIf="showClose">
        <span class="icon icon-close modal-close" (click)="closeDialogClick.emit()"></span>
      </div>
      <div class="container">
        <div class="row" [ngClass]="{'no-gutters': showErrorIcon || showSuccessIcon}">
          <div class="col">
            <h4>
              <span *ngIf="showErrorIcon" class='icon-error modal-heading-icon modal-heading-error'></span>
              <span *ngIf="showSuccessIcon" class='icon-check modal-heading-icon modal-heading-success'></span>
              <span *ngIf="showBellIcon" class='icon-bell modal-heading-icon modal-heading-error'></span>
              {{modalHeading}}
            </h4>
          </div>
        </div>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
      <div class="modal-footer"></div>
    </div>
  </div>
</div>
