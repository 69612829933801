import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
export interface GridAction {
  action: string;
  value: any[] | any;
}
@Injectable({
  providedIn: 'root',
})
export class GridService {
  private clearSelections = new BehaviorSubject<boolean>(false);
  $clearSelections = this.clearSelections.asObservable();

  constructor() {}

  updateClearSelections(val) {
    this.clearSelections.next(val);
  }
}
