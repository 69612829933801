import { UserInfo } from 'src/app/interfaces/user-info';

export class AddUserInfo {
  static readonly type = '[UserInfo] Add';

  constructor(public payload: UserInfo) {}
}

export class DeleteUserInfo {
  static readonly type = '[UserInfo] Delete';

  constructor() {}
}

export class ResetAppStateToDefault {
  static readonly type = 'Reset State';

  constructor() {}
}
