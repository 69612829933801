export class User {
  flushUserId: string;
  isLoggedin: boolean;
  name: string;
  crewCode: string;
  flushRoleType: string;
  email: string;
  role: string;
  isProvisioned: boolean;
  isCrewCodeAssigned: boolean;
  department: string;
  flushRoleDescription: string;
  setting: any;
  constructor(
    id: string,
    isLoggedin: boolean,
    userName: string,
    crewCode?: string,
    flushRoleType?: string,
    email?: string,
    role?: string,
    isProvisioned?: boolean,
    isCrewCodeAssigned?: boolean,
    department?: string,
    flushRoleDescription?: string,
    setting?: any,
  ) {
    this.flushUserId = id;
    this.isLoggedin = isLoggedin;
    this.name = userName;
    this.crewCode = crewCode;
    this.flushRoleType = flushRoleType;
    this.email = email;
    this.role = role;
    this.isProvisioned = isProvisioned;
    this.isCrewCodeAssigned = isCrewCodeAssigned;
    this.department = department;
    this.flushRoleDescription = flushRoleDescription;
    this.setting = setting;
  }
}
