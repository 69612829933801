import { Component, OnInit, Input, HostListener } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  //   styleUrls: ['../.component.scss']
})
export class CardComponent implements OnInit {
  ngOnInit() {}
}
