import { Injectable } from '@angular/core';
import { CONFIG } from 'src/app/global/config';
import * as _ from 'lodash';
import { FormValidationModel } from 'src/app/models/form-validation.model';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { AppState } from 'src/app/app-state/app.state';
import { Observable } from 'rxjs';
import { JobDetail } from 'src/app/models/jobDetail';
import { StructureInformation } from 'src/app/models/structureInformation';
import { AccessInformation } from 'src/app/models/accessInformation';
import { FlushInformation } from 'src/app/models/flushInformation';
import { catchError, map, take, tap } from 'rxjs/operators';
import { Photo } from 'src/app/models/photo';
import { WorkRequestDetail } from 'src/app/models/work-request-detail';

@Injectable({
  providedIn: 'root',
})
export class FormValidationService {
  forms: FormValidationModel[] = [];
  $flushRequestValid = new BehaviorSubject(false);
  $flushInformationValid = new BehaviorSubject(false);
  $flushPhotos = new BehaviorSubject(false);
  $flushSummary = new BehaviorSubject(false);
  @Select(AppState.getJobDetails) getJobDetails$: Observable<JobDetail>;
  @Select(AppState.getStructureInformation)
  getStructureInformation$: Observable<StructureInformation>;
  @Select(AppState.getAccessInformation)
  getAccessInformation$: Observable<AccessInformation>;
  @Select(AppState.getFlushInformation)
  getFlushInformation$: Observable<FlushInformation[]>;
  @Select(AppState.getStartJobPhotos) getStartJobPhotos$: Observable<Photo[]>;
  constructor(private store: Store) {}

  // Return the validity at this moment
  getInstantValidity(formName: string): boolean {
    const form = this.getFormByFormName(formName);
    return form.isValid;
  }
  // Return behavior subject to check validity
  getListenerValidity(formName: string, parentForm: string): BehaviorSubject<boolean> {
    const form = this.getFormByFormName(formName);
    return form.$isValid;
  }
  // Set the latest validation state in the form
  setValidity(value: boolean, formName: string, parent: string) {
    const form = this.getFormByFormName(formName, parent);
    form.setValidity(value);
    this.updateParentForm(parent);
  }
  getFormByFormName(formName: string, parent?: string): FormValidationModel {
    // Find the form in the existing array
    let form: FormValidationModel;
    form = _.find(this.forms, { name: formName });

    // Create a validation form if it does not exist
    if (!form) {
      form = new FormValidationModel(formName, parent);
      this.forms.push(form);
    }
    return form;
  }
  async updateParentForm(formName: string) {
    const subForms = await _.filter(this.forms, { parent: formName });
    const validForms = await _.filter(subForms, { isValid: true });

    switch (formName) {
      case CONFIG.FORMS.PARENT_FORMS.INFORMATION:
        this.$flushInformationValid.next(validForms.length === CONFIG.FORMS.FLUSH_REQUEST.INFORMATION.FORMS);
        break;
      case CONFIG.FORMS.PARENT_FORMS.PHOTOS:
        this.$flushPhotos.next(validForms.length > 0);
        break;
      default:
        break;
    }
  }
  getFlushDataValidityFromState(): Promise<Map<string, boolean>> {
    return new Promise((resolve, reject) => {
      Promise.all([
        this.getJobDetails$
          .pipe(take(1))
          .toPromise()
          .then((details) => details.isValid)
          .catch((r) => false),
        this.getStructureInformation$
          .pipe(take(1))
          .toPromise()
          .then((struct) => struct.structures.length > 0)
          .catch((r) => false),
        this.getAccessInformation$
          .pipe(take(1))
          .toPromise()
          .then((access) => access.isValid)
          .catch((r) => false),
        this.getFlushInformation$
          .pipe(take(1))
          .toPromise()
          .then((flush) => flush.length > 0 ? flush?.every((val) => {
            return val.isValid === true;
          }) : false)
          .catch((r) => false),
      ])
        .then(([detailsValid, structValid, accessValid, infoValid]) => {
          const formsMap = new Map();
          formsMap.set(CONFIG.FORMS.FLUSH_REQUEST.INFORMATION.JOB, detailsValid);
          formsMap.set(CONFIG.FORMS.FLUSH_REQUEST.INFORMATION.STRUCTURE, structValid);
          formsMap.set(CONFIG.FORMS.FLUSH_REQUEST.INFORMATION.ACCESS, accessValid);
          formsMap.set(CONFIG.FORMS.FLUSH_REQUEST.INFORMATION.FLUSH, infoValid);
          
          resolve(formsMap);
        })
        .catch((r) => {
          console.log('Error happened in function getFlushDataValidityFromState', r);
          reject(r);
        });
    });
  }

  checkIfFlushRequestValid() {
    return Promise.all([
      this.getFlushDataValidityFromState(),
      this.getStartJobPhotos$
      .pipe(take(1)).toPromise()
    ]).then(([formsMap, photos]) => {
      let formsValid = true;
      for (let [form, valid] of formsMap) {
        this.setValidity(valid, form, CONFIG.FORMS.PARENT_FORMS.INFORMATION);
        formsValid = valid;
      }
      const photosAreCapturedAndValid = this.checkPhotoStructureValidity(photos) && photos.length > 0;
      const isBTicket = ( this.store
        .selectSnapshot(store => store.AppState.workRequestDetail as WorkRequestDetail)?.bTicket ?? '').length > 4;
      const photosValid = isBTicket ? (formsValid || photosAreCapturedAndValid) : photosAreCapturedAndValid;

      return [formsValid, photosValid]
    });
  }

  checkPhotoStructureValidity(photos : Photo[]) : boolean {
    let structureIds = new Array<string>();
    const info = this.store.selectSnapshot(store => store.AppState.structureInfo as StructureInformation);
    
    const new_structures = info?.structures?.map((structure) => structure.structureId);
    
    const all_structures_found = new_structures?.every((structureId) => photos?.filter((photo) => photo?.structureId == structureId)?.length > 0);
    
    if(!all_structures_found || new_structures.length === 0) {
      // Photos do not cover all structures
      return false;
    } 

    // if(info?.structures) {
    //   for(let structure of info?.structures) {
    //     structureIds.push(structure.structureId);
    //   }
    // }
    
    // if (structureIds?.length === 1) {
    //   // Photo structureId is assigned on photos component, which includes the review page.
    //   return photos.length > 0;
    // } else {
    //   for(let photo of photos) {
    //     if(!photo?.structureId) {
    //       return false;
    //     } else if(structureIds.findIndex(id => { return id == photo?.structureId }) < 0) {
    //       return false;
    //     }
    //   }
    // }
    // if(!structureIds?.every((structureId) => photos?.find((photo) => photo?.structureId == structureId))) {
    //   // Photos do not cover all structures
    //   return false;
    // } 
    return true;
  }

  listenToValidityOfRequest() {
    // For sidebar validity state
    return combineLatest([
      this.getJobDetails$
        .pipe(map((details) => details.isValid), catchError(err => of(false))),
      this.getStructureInformation$
        .pipe(map((struct) => struct.structures.length > 0), catchError(err => of(false))),
      this.getAccessInformation$
        .pipe(map((access) => access.isValid), catchError(err => of(false))),
      this.getFlushInformation$
        .pipe(map((flush) => flush.length > 0 ? flush?.every((val) => { return val.isValid === true; }) : false), 
          catchError(err => of(false))),
      this.getStartJobPhotos$    
    ]).pipe(
      tap(([detailsValid, structValid, accessValid, infoValid]) => {
        const formsMap = new Map();
        formsMap.set(CONFIG.FORMS.FLUSH_REQUEST.INFORMATION.JOB, detailsValid);
        formsMap.set(CONFIG.FORMS.FLUSH_REQUEST.INFORMATION.STRUCTURE, structValid);
        formsMap.set(CONFIG.FORMS.FLUSH_REQUEST.INFORMATION.ACCESS, accessValid);
        formsMap.set(CONFIG.FORMS.FLUSH_REQUEST.INFORMATION.FLUSH, infoValid);
        
        for (let [form, valid] of formsMap) {
          this.setValidity(valid, form, CONFIG.FORMS.PARENT_FORMS.INFORMATION);
        }
      }),
      map(([job, struct, access, flush, photos]) => {
        const isFlushUser = this.store.selectSnapshot(store => store.AppState.userInfo.user.role as string) != 'cc';
        const formsValid = isFlushUser ? true : [job, struct, access, flush].every(val => val); // if cc user, all forms must be valid
        const photosAreCapturedAndValid = this.allPhotosValid(photos, isFlushUser);
        const isBTicket = !isFlushUser && 
          (this.store.selectSnapshot(store => store.AppState.workRequestDetail as WorkRequestDetail)?.bTicket ?? '').length > 4;
        const photosValid = isBTicket ? (formsValid || photosAreCapturedAndValid) : photosAreCapturedAndValid;     
        return [formsValid, photosValid]
      })
    )
  }
  allPhotosValid(photos: Photo[], checkAfterPhotos: boolean) {
    if (photos.length < 1) {
      return false;
    }
    const beforePhotos = photos.filter(p => p.status === 'Before').map(p => p);
    const afterPhotos = photos.filter(p => p.status === 'After').map(p => p);
    const isBeforeValid = beforePhotos.length > 0 && this.checkPhotoStructureValidity(beforePhotos);
    const isAfterValid = afterPhotos.length > 0 && this.checkPhotoStructureValidity(afterPhotos);
    const isJobIncompleteAndValid = this.store.selectSnapshot(store => store.AppState?.pausedJob) && isBeforeValid;

    return checkAfterPhotos ?  isJobIncompleteAndValid || (isBeforeValid && isAfterValid) : isBeforeValid;
  }
}
