export const CONFIG = {
  API: {
    FEATURE_TOGGLES: {
      PHOTO_ACCOUNTABILITY: 'PhotoAccountability',
      JOB_DETAILS: 'JobDetails',
      STRUCTURE_INFORMATION: 'StructureInfo',
      ACCESS_INFORMATION: 'AccessInfo',
      FLUSH_INFORMATION: 'FlushInfo',
      WMS_INFORMATION: 'WmsInfo',
      CRMS_INFORMATION: 'CrmsInfo',
      CPMS_INFORMATION: 'CpmsInfo',
    },
  },
  FORMS: {
    PARENT_FORMS: {
      FLUSH_REQUEST: 'Flush Request',
      INFORMATION: 'Flush Information',
      PHOTOS: 'Flush Photos',
    },
    FLUSH_REQUEST: {
      FORMS: 2,
      PHOTOS: 'Structure Photos',
      INFORMATION: {
        FORMS: 4,
        JOB: 'Job Details',
        STRUCTURE: 'Structure Information',
        ACCESS: 'Access Information',
        FLUSH: 'Flush Information',
      },
    },
  },
  MASTER_DATA: {
    CALENDARNAMES: 'calendarNames',
    QUESTIONS: 'questions',
    FEATURES: 'featureToggles',
    PRIORITIES: 'priorities',
    PRIORITY_ITEMS: 'priorityItems',
    STRUCTURE_OPTIONS: 'structureMenuOptions',
    FLUSH_SUBTYPES: 'flushSubTypes',
    PARKING_RULES: 'parkingRestrictions',
  },
  SEARCH_WR_ERROR_MESSAGES : {
    COULD_NOT_BE_FOUND: 'The work request that you searched for could not be found. Please try again or contact your supervisor.',
    DOES_NOT_EXIST: 'This WR does not exist, please ensure that you have typed it in correctly.',
    NOT_FLUSH_WR: 'This work request is not a flush WR. Please try again.'
  },
  DASHBOARD_ERROR_MESSAGES : {
    WMS_DOWN : 'Can not connect to WMS to get work requests',
    USER_OFFLINE : 'You are currently offline.',
    LOADED_FROM_STATE: 'No worries! We managed to save the last list before we lost connection.',
    NO_WORKREQUESTS_ERROR: {
      HEADER: 'It looks like you don\'t have any work requsts assigned.',
      BODY: 'Please create work request through search or contact your manager for further instructions.',
    },
    NOT_PROVISIONED: {
      HEADER: 'Before you use the ERA app, you have one more step!',
      BODY: 'If you are an Environmental Ops Mechanic and do not see jobs assigned. Please click here to see steps on how to get provisioned in WMS so that you are able to use ERA',
    }
  },
  ESRI_MAP_SERVERS: {
    ELECTRIC_ASSETS: [
      {
        name: "Electric M&S Plate",
        type: "Feature",
        id: 58,
        url: "https://arcgisportalgstmob-consolidatededison.msappproxy.net/server/rest/services/Electric/Electric_Asset_Features/MapServer/58",
        visible: false
      },
      {
        name: "Secondary Cable - UG",
        type: "Feature",
        id: 41,
        url: "https://arcgisportalgstmob-consolidatededison.msappproxy.net/server/rest/services/Electric/Electric_Asset_Features/MapServer/41",
        visible: false
      },
      {
        name: "Vault",
        type: "Feature",
        id: 37,
        url: "https://arcgisportalgstmob-consolidatededison.msappproxy.net/server/rest/services/Electric/Electric_Asset_Features/MapServer/37",
        visible: false
      },
      {
        name: "Transformer",
        type: "Feature",
        id: 34,
        url: "https://arcgisportalgstmob-consolidatededison.msappproxy.net/server/rest/services/Electric/Electric_Asset_Features/MapServer/34",
        visible: false
      },
      {
        name: "Service Box",
        type: "Feature",
        id: 28,
        url: "https://arcgisportalgstmob-consolidatededison.msappproxy.net/server/rest/services/Electric/Electric_Asset_Features/MapServer/28",
        visible: false
      },
      {
        name: "Riser",
        type: "Feature",
        id: 26,
        url: "https://arcgisportalgstmob-consolidatededison.msappproxy.net/server/rest/services/Electric/Electric_Asset_Features/MapServer/26",
        visible: false
      },
      {
        name: "Pole",
        type: "Feature",
        id: 23,
        url: "https://arcgisportalgstmob-consolidatededison.msappproxy.net/server/rest/services/Electric/Electric_Asset_Features/MapServer/23",
        visible: false
      },
      {
        name: "Manhole",
        type: "Feature",
        id: 17,
        url: "https://arcgisportalgstmob-consolidatededison.msappproxy.net/server/rest/services/Electric/Electric_Asset_Features/MapServer/17",
        visible: false
      },
      {
        name: "LSE",
        type: "Feature",
        id: 16,
        url: "https://arcgisportalgstmob-consolidatededison.msappproxy.net/server/rest/services/Electric/Electric_Asset_Features/MapServer/16",
        visible: false
      },
    ],
    ELECTRIC_ANNOTATIONS: {
      url: "https://arcgisportalgstmob-consolidatededison.msappproxy.net/server/rest/services/Electric/Electric_Asset_Annotation/MapServer",
      name: 'Annotations',
      type: "Annotation",
      id: 0,
      layers: [
        {
          name: "ServiceBoxAnno",
          id: 639,
          url: "https://arcgisportalgstmob-consolidatededison.msappproxy.net/server/rest/services/Electric/Electric_Asset_Annotation/MapServer/639"
        },
        {
          name: "TransformerAnno",
          id: 622,
          url: "https://arcgisportalgstmob-consolidatededison.msappproxy.net/server/rest/services/Electric/Electric_Asset_Annotation/MapServer/622"
        },
        {
          name: "VaultAnno",
          id: 606,
          url: "https://arcgisportalgstmob-consolidatededison.msappproxy.net/server/rest/services/Electric/Electric_Asset_Annotation/MapServer/606"
        },
        {
          name: "ManholeAnno",
          id: 567,
          url: "https://arcgisportalgstmob-consolidatededison.msappproxy.net/server/rest/services/Electric/Electric_Asset_Annotation/MapServer/567"
        }, 
        {
          name: "GenericTextAnno",
          id: 519,
          url: "https://arcgisportalgstmob-consolidatededison.msappproxy.net/server/rest/services/Electric/Electric_Asset_Annotation/MapServer/519"
        }
      ],
      visible: false
    }
  },
  SUPERVISOR_HUB: {
    CLOSE_GRID_COLUMNS: [
      { field: 'crewCode', title: 'Crew Code' },
      { field: 'address', title: 'Address' },
      { field: 'structureId', title: 'Structure Number' },
      { field: 'priorityLevel', title: 'Priority' },
      { field: 'status', title: 'Completion Status' },
      { field: 'crewLead', title: 'Crew Lead' },
    ]
  },
  MEDIA_TYPE: {
    VIDEO: 'Video',
    IMAGE: 'Image'
  },
  MEDIA_STATUS: {
    GENERATED: 'Generated',
    PROCESSING: 'Processing',
    QUEUED: 'Queued'
  }
};
