import { AfterContentInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Router } from '@angular/router';
import { BaseService } from 'src/app/services/base/base.service';
import { FlushInformation } from 'src/app/models/flushInformation';
import { StylerService } from 'src/app/services/styler/styler.service';
import { FlushInfoSelect } from 'src/app/models/construction-crew/flush-info-select';
import { Structure } from 'src/app/models/structure.model';
import * as _ from 'lodash';
import { StructureMenuOptions } from 'src/app/interfaces/structure-menu-options';
import { MasterDataService } from 'src/app/services/master-data/master-data.service';
import { CONFIG } from 'src/app/global/config';
import { LaunchCameraModalComponent } from 'src/app/common/launch-camera-modal/launch-camera-modal.component';
import { CcRequestsService } from 'src/app/services/cc-requests/cc-requests.service';
@Component({
  selector: 'app-flush-process',
  templateUrl: './flush-process.component.html'
})
export class FlushProcessComponent extends BaseComponent implements OnInit, AfterContentInit {

  @Input() selectedFlushProcess : FlushInformation;
  @Input() selectedStructure : Structure;
  @Output() outputValidity : EventEmitter<boolean>= new EventEmitter();  

  flushInfoFormGroup: FormGroup;

  get debrisReason(): Array<string> {
    return this.flushInfoFormGroup.get('noDebrisPumpReason').value as Array<string>;
  }

  // Selectboxes
  selectOptionDefaultIndex: FlushInfoSelect;
  @ViewChild('noPumpReasonSelectBox') noPumpReasonSelectBox;
  @ViewChild('waterDescSelectBox') waterDescSelectBox;
  @ViewChild('waterAmountSelectBox') waterAmountSelectBox;
  @ViewChild('noDebrisPumpReasonSelectBox') noDebrisPumpReasonSelectBox;
  @ViewChild('debrisAmountSelectBox') debrisAmountSelectBox;
  @ViewChild('infestationTypeSelectBox') infestationTypeSelectBox;
  
  @ViewChild('launchCameraModalComponent') launchCameraModal: LaunchCameraModalComponent;


  // For selectboxes
  selectBoxOptions = {};
  showPerliteBagModal:boolean = false;

  constructor(
    private router: Router,
    private baseService: BaseService,
    styler: StylerService,
    private masterData: MasterDataService,
    private ccService: CcRequestsService
  ) {
    super(styler);
  }

  ngOnInit(): void {
    this.flushInfoFormGroup = new FormGroup({
      isRelevantEquipment: new FormControl('', Validators.required),
      isWater: new FormControl('', Validators.required),
      canDewater: new FormControl(''),
      noPumpReason: new FormControl(''),
      waterDesc: new FormControl(''),
      waterAmount: new FormControl(''),
      isDebris: new FormControl('', Validators.required),
      noDebrisPumpReason: new FormControl(''),
      oilPresenceTest: new FormControl(''),
      debrisAmount: new FormControl(''),
      infestationType: new FormControl('', [Validators.required, Validators.minLength(1)]),
      isPearliteBags: new FormControl('', Validators.required),
      isPearliteBagsCompromised: new FormControl(''),
      additionalDesc: new FormControl(''),
    });
    this.selectOptionDefaultIndex = this.selectedFlushProcess?.selectedOptionDefaultIndex ?? new FlushInfoSelect();

    this.flushSubscriptions.push(
    this.masterData.getCacheItem(CONFIG.MASTER_DATA.STRUCTURE_OPTIONS)
    .subscribe((res: StructureMenuOptions) => {
        if (res != null) {
          this.selectBoxOptions = this.mapToSelectBox(res);
        }
      })
    )
  }

  ngAfterContentInit(): void {
    // Initialize form when given selectedStructure and selectedFlushInformation
    if (!!this.flushInfoFormGroup) {
      // Default flushFormGroup validity and dynamicFormPaths
      this.dynamicFormPaths(this.flushInfoFormGroup);
      this.assignSelectFlushProcessToFormValues(this.selectedFlushProcess);
      // this.selectOptionDefaultIndex = this.selectedFlushProcess?.selectedOptionDefaultIndex ?? new FlushInfoSelect();

      this.flushSubscriptions.push(
        this.flushInfoFormGroup.statusChanges.subscribe((res) => {
          this.outputValidity.next(this.flushInfoFormGroup.valid);
        }),
        this.flushInfoFormGroup.valueChanges.subscribe((valueChanges) => {
          this.dynamicFormPaths(this.flushInfoFormGroup);
        })
      );
    }
  }
  ngOnChanges(): void {
    // New form scroll to top
    window.scroll(0,0);
    
    if (!!this.flushInfoFormGroup) {
      console.log(this.selectedFlushProcess);
      
      this.assignSelectFlushProcessToFormValues(this.selectedFlushProcess);

      this.selectOptionDefaultIndex = this.selectedFlushProcess?.selectedOptionDefaultIndex ?? new FlushInfoSelect();
    }
  }

  showPerliteModal() {
    this.showPerliteBagModal = true;
  }
  assignSelectFlushProcessToFormValues(selectedFlushProcess: FlushInformation) {
    this.flushInfoFormGroup.controls.isRelevantEquipment.patchValue(this.ccService.valueToString(selectedFlushProcess?.canSeeAllEquipement));
    this.flushInfoFormGroup.controls.isWater.patchValue(this.ccService.valueToString(selectedFlushProcess?.isWaterFoundOnStructure));
    this.flushInfoFormGroup.controls.canDewater.patchValue(this.ccService.valueToString(selectedFlushProcess?.canStructureDewaterUsingPump));
    this.flushInfoFormGroup.controls.noPumpReason.patchValue(this.ccService.valueToString(selectedFlushProcess?.selectedOptionDefaultIndex?.noPumpReason));
    this.flushInfoFormGroup.controls.waterDesc.patchValue(this.ccService.valueToString(selectedFlushProcess?.structureWaterId));
    this.flushInfoFormGroup.controls.waterAmount.patchValue(this.ccService.valueToString(selectedFlushProcess?.structureWaterQuantityId));
    this.flushInfoFormGroup.controls.isDebris.patchValue(this.ccService.valueToString(selectedFlushProcess?.isDebrisEnvironmentConditionFound));
    this.flushInfoFormGroup.controls.noDebrisPumpReason.patchValue(this.ccService.valueToString(selectedFlushProcess?.structureDebrisTypeId));
    this.flushInfoFormGroup.controls.oilPresenceTest.patchValue(this.ccService.valueToString(selectedFlushProcess?.isDiaperOildTesterUser));
    this.flushInfoFormGroup.controls.debrisAmount.patchValue(this.ccService.valueToString(selectedFlushProcess?.structureDebrisQuantityId));
    this.flushInfoFormGroup.controls.infestationType.patchValue(this.ccService.valueToString(selectedFlushProcess?.structureInfestationId));
    this.flushInfoFormGroup.controls.isPearliteBags.patchValue(this.ccService.valueToString(selectedFlushProcess?.areTherePearliteBags));
    this.flushInfoFormGroup.controls.isPearliteBagsCompromised.patchValue(this.ccService.valueToString(selectedFlushProcess?.havePearliteBagsCompromized));
    this.flushInfoFormGroup.controls.additionalDesc.patchValue(this.ccService.valueToString(selectedFlushProcess?.additionalInformation));
  }

  dynamicFormPaths(flushInfoFormGroup : FormGroup) {
    // Dynamic form
    // Water is found in structure
    if (flushInfoFormGroup.controls.isWater.value == 'true') {
      // Set Validation
      flushInfoFormGroup.controls.canDewater.setValidators([Validators.required]);
      flushInfoFormGroup.controls.waterDesc.setValidators([Validators.required, Validators.minLength(1)]);
      flushInfoFormGroup.controls.waterAmount.setValidators([Validators.required, Validators.minLength(1)]);
      flushInfoFormGroup.controls.canDewater.updateValueAndValidity({
        emitEvent: false,
      });
      flushInfoFormGroup.controls.waterDesc.updateValueAndValidity({
        emitEvent: false,
      });
      flushInfoFormGroup.controls.waterAmount.updateValueAndValidity({
        emitEvent: false,
      });
    } else {
      // Clear Validation
      flushInfoFormGroup.controls.canDewater.setValidators(null);
      flushInfoFormGroup.controls.waterDesc.setValidators(null);
      flushInfoFormGroup.controls.waterAmount.setValidators(null);
      // clear Values
      flushInfoFormGroup.controls.canDewater.patchValue('', {
        emitEvent: false,
      });
      flushInfoFormGroup.controls.waterDesc.patchValue('', {
        emitEvent: false,
      });
      flushInfoFormGroup.controls.waterAmount.patchValue('', {
        emitEvent: false,
      });
      // update
      flushInfoFormGroup.controls.canDewater.updateValueAndValidity({
        emitEvent: false,
      });
      flushInfoFormGroup.controls.waterDesc.updateValueAndValidity({
        emitEvent: false,
      });
      flushInfoFormGroup.controls.waterAmount.updateValueAndValidity({
        emitEvent: false,
      });
    }

    // Dewater is not possible
    if (flushInfoFormGroup.controls.canDewater.value == 'false') {
      // Set Validation
      flushInfoFormGroup.controls.noPumpReason.setValidators([Validators.required, Validators.minLength(1)]);
      flushInfoFormGroup.controls.noPumpReason.updateValueAndValidity({
        emitEvent: false,
      });
    } else {
      // Clear Validation
      flushInfoFormGroup.controls.noPumpReason.setValidators(null);
      // clear Values
      flushInfoFormGroup.controls.noPumpReason.patchValue('', {
        emitEvent: false,
      });
      // update
      flushInfoFormGroup.controls.noPumpReason.updateValueAndValidity({
        emitEvent: false,
      });
    }

    // Environmental condition can not be pumped
    if (flushInfoFormGroup.controls.isDebris.value === 'true') {
      // Set Validators
      flushInfoFormGroup.controls.noDebrisPumpReason.setValidators([
        Validators.required,
        Validators.minLength(1),
      ]);
      flushInfoFormGroup.controls.debrisAmount.setValidators([Validators.required, Validators.minLength(1)]);
      flushInfoFormGroup.controls.noDebrisPumpReason.updateValueAndValidity({ emitEvent: false });
      flushInfoFormGroup.controls.debrisAmount.updateValueAndValidity({
        emitEvent: false,
      });
    } else {
      // Clear Validators
      flushInfoFormGroup.controls.noDebrisPumpReason.setValidators(null);
      flushInfoFormGroup.controls.debrisAmount.setValidators(null);
      // clear Values
      flushInfoFormGroup.controls.noDebrisPumpReason.patchValue(new Array<string>(), { emitEvent: false });
      flushInfoFormGroup.controls.debrisAmount.patchValue('', {
        emitEvent: false,
      });
      // update
      flushInfoFormGroup.controls.noDebrisPumpReason.updateValueAndValidity({ emitEvent: false });
      flushInfoFormGroup.controls.debrisAmount.updateValueAndValidity({
        emitEvent: false,
      });
    }

    // Environmental condition is oily
    if (flushInfoFormGroup.controls.noDebrisPumpReason.value.includes('Oil') || flushInfoFormGroup.controls.noDebrisPumpReason.value.includes('Grease')) {
      flushInfoFormGroup.controls.oilPresenceTest.setValidators(Validators.required);
      flushInfoFormGroup.controls.oilPresenceTest.updateValueAndValidity({ emitEvent: false });
    } else {
      // clear validators
      flushInfoFormGroup.controls.oilPresenceTest.setValidators(null);
      // clear values
      flushInfoFormGroup.controls.oilPresenceTest.patchValue('', {
        emitEvent: false,
      });
      // update
      flushInfoFormGroup.controls.oilPresenceTest.updateValueAndValidity({ emitEvent: false });
    }

    // Pearlite bags present
    if (flushInfoFormGroup.controls.isPearliteBags.value == 'true') {
      flushInfoFormGroup.controls.isPearliteBagsCompromised.setValidators(Validators.required);
      flushInfoFormGroup.controls.isPearliteBagsCompromised.updateValueAndValidity({ emitEvent: false });
    } else {
      // clear validators
      flushInfoFormGroup.controls.isPearliteBagsCompromised.setValidators(null);
      // clear values
      flushInfoFormGroup.controls.isPearliteBagsCompromised.patchValue('', { emitEvent: false });
      // update
      flushInfoFormGroup.controls.isPearliteBagsCompromised.updateValueAndValidity({ emitEvent: false });
    }
  }
  


  mapToSelectBox(res: StructureMenuOptions) : Object {
    let tempOptions = {};
    for (const opts of Object.keys(res)) {
      const arr = [];
      res[opts].forEach(function (value) {
        const om = { option: value, value };
        arr.push(om);
      });
      tempOptions[opts] = arr;
    }
    return tempOptions;
  }

  takePhoto() {
    this.launchCameraModal.takePhoto();
  }

  getFormValues() : FlushInformation {
    const form = new FlushInformation(
      this.ccService.stringToBoolean(this.flushInfoFormGroup?.controls.isRelevantEquipment.value),
      this.ccService.stringToBoolean(this.flushInfoFormGroup?.controls.isWater.value),
      this.ccService.stringToBoolean(this.flushInfoFormGroup?.controls.canDewater.value),
      this.flushInfoFormGroup?.controls.noPumpReason.value,
      this.flushInfoFormGroup?.controls.waterDesc.value,
      this.flushInfoFormGroup?.controls.waterAmount.value,
      this.ccService.stringToBoolean( this.flushInfoFormGroup?.controls.isDebris.value),
      this.flushInfoFormGroup?.controls.noDebrisPumpReason.value,
      this.ccService.stringToBoolean(this.flushInfoFormGroup?.controls.oilPresenceTest.value),
      this.flushInfoFormGroup?.controls.debrisAmount.value,
      this.flushInfoFormGroup?.controls.infestationType.value,
      this.ccService.stringToBoolean(this.flushInfoFormGroup?.controls.isPearliteBags.value),
      this.ccService.stringToBoolean(this.flushInfoFormGroup?.controls.isPearliteBagsCompromised.value),
      this.flushInfoFormGroup?.controls.additionalDesc.value
    );
    // Store currently selected indexes
    const temp = new FlushInfoSelect();
    temp.debrisAmount = this.debrisAmountSelectBox?.selectedIndex;
    temp.infestationType = this.infestationTypeSelectBox?.selectedIndex;
    temp.noDebrisPumpReason = this.noDebrisPumpReasonSelectBox?.selectedIndexes;
    temp.noPumpReason = this.noPumpReasonSelectBox?.selectedIndex;
    temp.waterAmount = this.waterAmountSelectBox?.selectedIndex;
    temp.waterDesc = this.waterDescSelectBox?.selectedIndex;
    form.selectedOptionDefaultIndex = temp;
    form.isValid = this.flushInfoFormGroup?.valid;
    form.structureNumber = this.selectedFlushProcess?.structureNumber;
    return _.cloneDeep(form);
  }
}
