import { WorkRequestDetail } from 'src/app/models/work-request-detail';
import { Photo } from 'src/app/models/photo';

export class AddWorkRequestDetail {
  static readonly type = '[WorkRequestDetail] Add';

  constructor(public payload: WorkRequestDetail) {}
}
export class DeleteWorkRequestDetail {
  static readonly type = '[WorkRequestDetail] Delete';

  constructor() {}
}

export class AddStartJobPhotos {
  static readonly type = '[StartJobPhotos] Add';

  constructor(public payload: Photo[]) {}
}

export class DeleteStartJobPhoto {
  static readonly type = '[StartJobPhotos] Delete';

  constructor() {}
}

export class AddWorkDescription {
  static readonly type = '[WorkDescription] Add';

  constructor(public payload: {workDescription:string, isCrewConductingRepairs: boolean, canOriginalWorkContinue: boolean, hasStrayVoltage: boolean, hasLeadStabilizerUsed:boolean,
    noOfStabilizers: number}) {}
}

export class DeleteWorkDescription {
  static readonly type = '[WorkDescription] Delete';

  constructor() {}
}
export class AddPausedJob {
  static readonly type = '[PausedJob] Add';

  constructor(public payload: boolean) {}
}
export class DeletePausedJob {
  static readonly type = '[PausedJob] Delete';

  constructor() {}
}