import { FlushInfoSelect } from 'src/app/models/construction-crew/flush-info-select';

export class FlushInformation {
  canSeeAllEquipement: boolean;
  isWaterFoundOnStructure: boolean;
  canStructureDewaterUsingPump: boolean;
  structurePumpRestrictionId: string;
  structureWaterId: string;
  structureWaterQuantityId: string;
  isDebrisEnvironmentConditionFound: boolean;
  structureDebrisTypeId: string[];
  isDiaperOildTesterUser: boolean;
  structureDebrisQuantityId: string;
  structureInfestationId: string;
  areTherePearliteBags: boolean;
  havePearliteBagsCompromized: boolean;
  additionalInformation: string;
  selectedOptionDefaultIndex: FlushInfoSelect;
  isValid: boolean;
  structureNumber: string;
  constructor(
    canSeeAllEquipement: boolean,
    isWaterFoundOnStructure: boolean,
    canStructureDewaterUsingPump: boolean,
    structurePumpRestrictionId: string,
    structureWaterId: string,
    structureWaterQuantityId: string,
    isDebrisEnvironmentConditionFound: boolean,
    structureDebrisTypeId: string[],
    isDiaperOildTesterUser: boolean,
    structureDebrisQuantityId: string,
    structureInfestationId: string,
    areTherePearliteBags: boolean,
    havePearliteBagsCompromized: boolean,
    additionalInformation: string,
    structureNumber = null,
    isValid = false
  ) {
    this.canSeeAllEquipement = canSeeAllEquipement;
    this.isWaterFoundOnStructure = isWaterFoundOnStructure;
    this.canStructureDewaterUsingPump = canStructureDewaterUsingPump;
    this.structurePumpRestrictionId = structurePumpRestrictionId;
    this.structureWaterId = structureWaterId;
    this.structureWaterQuantityId = structureWaterQuantityId;
    this.isDebrisEnvironmentConditionFound = isDebrisEnvironmentConditionFound;
    this.structureDebrisTypeId = structureDebrisTypeId;
    this.isDiaperOildTesterUser = isDiaperOildTesterUser;
    this.structureDebrisQuantityId = structureDebrisQuantityId;
    this.structureInfestationId = structureInfestationId;
    this.areTherePearliteBags = areTherePearliteBags;
    this.havePearliteBagsCompromized = havePearliteBagsCompromized;
    this.additionalInformation = additionalInformation;
    this.structureNumber = structureNumber;
    this.isValid = isValid;
  }
}
