import {
  Component,
  OnInit,
  OnChanges,
  HostListener,
  EventEmitter,
  Input,
  Output,
  ElementRef,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';
export class OptionModel {
  value: string;
  option: string;
}
@Component({
  selector: 'app-multi-selectbox',
  templateUrl: './multi-selectbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectboxComponent),
      multi: true,
    },
  ],
})
export class MultiSelectboxComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() placeholder = 'Selection(s)';
  @Input() options: Array<OptionModel>;
  @Input() defaultIndexes: Array<number>;
  @Input() className: string;
  @Input() isReadOnly = false;
  //Error indicator
  @Input() isValid = true;
  @Input() errorMsg = '';
  @Input() isCompleted = false;
  // Default to error icon
  @Input() icon = '';

  // Additional variables
  openDropdown = false;
  selectedValues = new Array<String>();
  selectedOptions = new Array<String>();
  selectedIndexes = new Array<number>();
  arrowsBtnPressed = false;
  constructor(private _eref: ElementRef) {}

  // In order to connect this to a form, we need to implement ControlValueAccessor

  // Function to call when the value of the input changes
  onChange(value: Array<String>) {}
  // Function to call when the input is touched (onBlur)
  onTouched() {}

  // Implementation of ControlValueAccessor
  // Allows ng to update the input
  writeValue(value: Array<String>) {}
  // Allows ng to register a function to call when input changes
  registerOnChange(fn: (value: Array<String>) => void) {
    this.onChange = fn;
  }
  // Allows ng to register a function to call on input's blur event
  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  ngOnInit(): void {}

  ngOnChanges() {
    if (this.defaultIndexes != null && this.defaultIndexes.length >= 0 && this.options != null) {
      this.selectedValues = new Array<String>();
      this.selectedOptions = new Array<String>();
      this.selectedIndexes = new Array<number>();
      for (let i = 0; i < this.defaultIndexes.length; i++) {
        this.selectedValues.push(_.cloneDeep(this.options[this.defaultIndexes[i]]?.value));
        this.selectedOptions.push(_.cloneDeep(this.options[this.defaultIndexes[i]]?.option));
        this.selectedIndexes.push(_.cloneDeep(this.defaultIndexes[i]));
      }
    } else {
      this.defaultIndexes == null;
    }
  }

  toggleDropDown() {
    if (!this.isReadOnly) {
      this.openDropdown = !this.openDropdown;
    }
  }

  onOptionClick(value, option, index) {
    this.selectedValues = Object.assign([], this.selectedValues);
    if (this.selectedValues.includes(value)) {
      let indx = this.selectedValues.indexOf(value);
      this.selectedValues.splice(indx, 1);
    } else {
      this.selectedValues.push(value);
    }

    if (this.selectedOptions.includes(option)) {
      let indx = this.selectedOptions.indexOf(option);
      this.selectedOptions.splice(indx, 1);
    } else {
      this.selectedOptions.push(option);
    }

    if (this.selectedIndexes.includes(index)) {
      let indx = this.selectedIndexes.indexOf(index);
      this.selectedIndexes.splice(indx, 1);
    } else {
      this.selectedIndexes.push(index);
    }

    this.writeValue(this.selectedValues);
    this.onChange(this.selectedValues);
  }

  @HostListener('keydown', ['$event']) onkeydown($event) {
    $event.preventDefault();
    this.arrowsBtnPressed = true;
    // if ($event.key === 'ArrowDown') {
    //   if (this.selectedIndex !== this.options.length - 1) {
    //     this.selectedIndex++;
    //   }
    // }

    // if ($event.key === 'ArrowUp') {
    //   if (this.selectedIndex !== 0) {
    //     this.selectedIndex--;
    //   }
    // }

    if (this.openDropdown) {
      if ($event.key === 'Enter') {
        for (let i = 0; i < this.selectedIndexes.length; i++) {
          this.selectedValues.push(this.options[this.selectedIndexes[i]].value);
          this.writeValue(this.selectedValues);
          this.onChange(this.selectedValues);
          this.openDropdown = false;
          this._eref.nativeElement.blur();
        }
      }
    }
    this.onTouched();
  }

  @HostListener('document:mousedown', ['$event']) onDocumentClick($event) {
    if (!this._eref.nativeElement.contains($event.target)) {
      this.openDropdown = false;
    }
    this.onTouched();
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this._eref.nativeElement.contains(event.target)) {
      // this.text = "clicked inside";
    } else {
      // this.text = "clicked outside";
      this.openDropdown = false;
    }
  }
}
