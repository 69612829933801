<app-sidebar-mobile [hidden]="!isMobile" [wrNum]="workRequest?.workRequestNo" [bTicket]="workRequest?.bTicket" [sourceWrNo]="workRequest?.sourceWrNumber" [showFirst]="showing"></app-sidebar-mobile>
<div class="request-wrapper">
  <div class="request-card container-fluid card">
    <div class="row">
      <div class="col" [ngStyle]="!isPhotoReview && {'min-height': '700px'}">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <app-sidebar [hidden]="isMobile" [wrNum]="workRequest?.workRequestNo" [bTicket]="workRequest?.bTicket" [disableAll]="isSummary" [showFirst]="showing"></app-sidebar>
</div>
<app-modal [showModal]="showingConfirmSubmit" modalHeading="Are you ready to submit this job?" (closeDialogClick)="handleModalClose()">
  <div class="container">
    <div class="form-group row">
      <div class="col">
        <span>Confirm all the information is correct before submitting your job.</span><br>
        <span>Once you submit your job, a notification will be sent to your management dl with the information and photos taken.</span>
      </div>
    </div>
    <div class="mb-4 row">
      <div class="col-md-3 col-lg-2">
          <ce-button type="secondary" label="Cancel" (clickEvent)="handleModalClose()"></ce-button>
      </div>
      <div class="col-md-3 col-lg-2">
          <ce-button label="Confirm" (clickEvent)="submitJob()"></ce-button>
      </div>
    </div>
  </div>
</app-modal>
<app-modal [showModal]="showingSubmitResult" modalHeading="Job successfully submitted" [showClose]="false">
  <div class="container">
    <div class="form-group row">
      <div class="col">
        <span>Your job was successfully submitted</span><br>
        <span>The customer will be alerted with the details of the job.</span>
      </div>
    </div>
    <div class="mb-4 row">
      <div class="col-md-3 col-lg-2">
          <ce-button label="Close" (clickEvent)="closeJob()"></ce-button>
      </div>
    </div>
  </div>
</app-modal>
