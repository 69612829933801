<svg xmlns="http://www.w3.org/2000/svg" style="display: none">
  <symbol id="checkmark" viewBox="0 0 26 34">
    <path stroke-linecap="round" stroke-miterlimit="10" fill="none" d="M22.9 3.7l-15.2 16.6-6.6-7.1">
    </path>
  </symbol>
</svg>
<div class="flush-grid-wrapper" [ngClass]="{ 'flush-grid-simple' : isFlushGridSimple, 'vertical-data-style-mobile' : allowVerticalDataTable }" [formGroup]="checkboxFormGroup">
  <kendo-grid [style.maxHeight.px]="700" [data]="gridData" [rowClass]="rowCallback" [autoSize]="true" formArrayName="checkboxArray">
    <kendo-grid-column *ngFor="let column of gridColumns" field="{{column.field}}" title="{{column.title}}"
                       hidden="{{column.hidden}}">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <!-- For Desktop Version: Validation against the column attributes -->
        <!-- For Mobile Version: validation against the dataItem properties.
             Reason: Capturing and retain the required data for map coordinates during transpose of existing data objects. Check the function transposeArrayOfObjects for more clarity -->
        <a class="flush-grid-default-action-anchor" *ngIf="(column?.actionType === 'anchor' || (dataItem['ActionType'] === 'anchor' && column?.field ==='Value')) && (dataItem[column?.field])"
          data-custom-id="DashboardPage" data-custom-bhvr="STARTPROCESS"  (click)="customAnchorClick(dataItem, column?.action ?? dataItem['Action'])">
          <b>{{dataItem[column?.field] | dash }}</b>
        </a>
        <p *ngIf="!(column?.actionType === 'anchor' || (dataItem['ActionType'] === 'anchor' && column?.field ==='Value')) || !(dataItem[column?.field])" class="flush-grid-cell"
          [ngClass]="{ 'dash-text' : (dataItem[column?.field] == undefined), 'center-blue-check': (dataItem[column?.field] === '\u2714')}">
          {{dataItem[column?.field] | dash }}</p>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="" *ngFor="let action of actions;  let i = index">
      <ng-template kendoGridHeaderTemplate>
        <span *ngIf="displayActionsHeader">{{action?.header ? action.header: 'Actions'}}</span>
      </ng-template>
      <!-- Mock Template for start job, view job..to be replaced by actual actions -->
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" *ngIf="displayActionsHeader">
        <div *ngIf="dataItem.status === 'Ready'; else defaultAction" class="flush-grid-action-header">
          <a href="" (click)="handleAnchorClick(rowIndex, dataItem)"><b>{{dataItem['action']}}</b></a>
          <i class="material-icons flush-grid-right-arrow-icon">chevron_right</i>
        </div>
        <ng-template #defaultAction>
          <!-- {{dataItem.status}} {{i}}  -->
          <ng-container *ngIf="page === 'StrucutreInfo' && dataItem.status === 'Submitted' && i === 1;">
            <div class="flush-grid-action-header">
              <a href="" style="pointer-events: none;color: grey;" (click)="handleAnchorClick(rowIndex, dataItem)"><b>Flush Pending</b></a>
            </div>
          </ng-container>
          <ng-container *ngIf="page !== 'StrucutreInfo' || (dataItem.status !== 'Submitted' && page === 'StrucutreInfo')">
            <a class="flush-grid-default-action-anchor" *ngIf="action.type === 'anchor'" data-custom-id="DashboardPage"
              data-custom-bhvr="STARTPROCESS" (click)="handleAnchorClick(rowIndex, dataItem)">
              <b>{{this.selections.indexOf(rowIndex) < 0 ? action.caption : action.caption2}}</b>
              <i *ngIf="this.selections.indexOf(rowIndex) < 0"
                class="flush-grid-default-action-right-arrow material-icons">chevron_right</i>
              <svg *ngIf="this.selections.indexOf(rowIndex) > -1" class="flush-grid-checkmark-action">
                <use xlink:href="#checkmark" />
              </svg>
            </a>
            <a class="flush-grid-default-action-anchor" *ngIf="action.type === 'data' && dataItem.status != 'Pending CRMS'" data-custom-id="DashboardPage"
              data-custom-bhvr="STARTPROCESS" (click)="handleAnchorClick(rowIndex, dataItem)">
              <b>{{dataItem.action.text}}</b>
              <i *ngIf="this.selections.indexOf(rowIndex) < 0"
                class="flush-grid-default-action-right-arrow material-icons">chevron_right</i>
              <svg *ngIf="this.selections.indexOf(rowIndex) > -1" class="flush-grid-checkmark-action">
                <use xlink:href="#checkmark" />
              </svg>
            </a>
            <a class="flush-grid-disabled-custom-action" *ngIf="action.type === 'data' && dataItem.status == 'Pending CRMS'" data-custom-id="DashboardPage"
              data-custom-bhvr="STARTPROCESS">
              <b>{{dataItem.action.text}}</b>
              <i *ngIf="this.selections.indexOf(rowIndex) < 0"
                class="flush-grid-default-action-right-arrow material-icons">chevron_right</i>
              <svg *ngIf="this.selections.indexOf(rowIndex) > -1" class="flush-grid-checkmark-action">
                <use xlink:href="#checkmark" />
              </svg>
            </a>
            <div *ngIf="action.type === 'checkbox'" class="flush-grid-check-action">
              <app-checkbox text="" [value]="true" [formControl]="getCheckboxFormControl(rowIndex)"
                [isChecked]="evauluateBoolean(rowIndex)">
              </app-checkbox>
            </div>
            <div *ngIf="action.type === 'edit'" class="flush-grid-edit-action">
              <a class="btn-flex details-link-btn" [ngClass]="{'btn-flex-disabled': !isOnline}"
                (click)="isOnline && actionClicked.emit({action: 'edit', value: dataItem})">
                <div class="icon icon-pencil"></div>Edit
              </a>
            </div>
            <div *ngIf="action.type === 'add'" class="flush-grid-edit-action">
              <a class="details-box-header details-link-btn"
                (click)="actionClicked.emit({action: 'add', value: dataItem})">
                Select
              </a>
            </div>
            <a *ngIf="action.type === 'delete'" class="btn-flex btn-danger-link i-text"
              (click)="actionClicked.emit({action: 'delete', value: dataItem})">
              <div class="icon icon-trash"></div>Remove
            </a>

            <div *ngIf="action.type === 'custom'">
              <a *ngIf="!(dataItem?.action?.noAppointmentId === true); else noAppointmentId"
                [ngClass]="{ 'flush-grid-disabled-custom-action': dataItem?.action?.disabled === true}"
                class="flush-grid-default-action-anchor" (click)="customAnchorClick(dataItem, action?.action)">
                <div innerHTML="{{action?.content}}"></div>
              </a>
              <ng-template #noAppointmentId>
                <p>No Appointment ID</p>
              </ng-template>
            </div>
            <div *ngIf="action.type === 'structure'" class="flush-grid-edit-action">
              <a *ngIf="!(dataItem?.isFacilityRequestInProgress); else structureInProgress"
                class="details-box-header details-link-btn"
                (click)="actionClicked.emit({action: 'add', value: dataItem})">
                Select
              </a>
              <ng-template #structureInProgress>
                <p class="disabled btn-flex btn-flex-disabled">Flush Pending</p>
              </ng-template>
            </div>
          </ng-container>
        </ng-template>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
