import { JobDetail } from 'src/app/models/jobDetail';

export class AddJobDetails {
  static readonly type = '[JobDetails] Add';

  constructor(public payload: JobDetail) {}
}

export class DeleteJobDetails {
  static readonly type = '[JobDetails] Delete';

  constructor() {}
}
