export const flushEndpoints = {
  /* // 1. PRE-CACHE-ABLE
    TODO: 
      Consolidate all endpoints into one  
      Refactor FE so that endpoint is cached on initial app load
  */

  data: '/lookups',

  /*   // 2. CACHE ONCE LOGGED IN | AFTER CREW CODE IS VERIFIED

    TODO: 
    USER TYPES: EMPLOYEE | CONTRACTOR
    USER ROLES TYPES: FLUSH MECH (eo), CONSTRUCTION CREW (cc)
      EMPLOYEE EO USER AND CC USER WILL have all jobs/wrs associated to them available as part of /users call 
        - allows for offline work 


      CONTRACTOR (eo) EO USER WILL CONTINUE TO USE /workrequests/crew/ to continue with flush job 
        - crew code must be validated first before caching list of jobs 
  */

  users: '/users',  // USER INFO 
  userpreferences: '/userpreferences',
  assignedWork: '/workrequests',
  userRoleByCrewCode: '/users/role/', // 
  workrequest: '/workrequests/', // BOTH
  searchWorkrequest: '/searchworkrequest/', // Create a wr by search
  ccRequestHistory: '/ccsubmittedworkrequest/', //Submitted work request for CC User
  eoRequestHistory: '/flushsubmittedworkrequest/', //Submitted work request for EO User

  // EO CONTRACTOR USERS
  workrequestbycrewcode: '/crews', 
  /*
    Response Model

    crewCodes: [
      code: REGION_CREW_ID,
      requests: [{..wr && wr.details}, {}]
    ]
    
  */

  /*  // 3. 4. Custom service worker functionality to handle side effects
      
    TODO: 
      Create custom service worker file that extends ngsw

  */
  // 3. 
  facilities: '/facilities', // Facility Lookup - HANDLE RESULT OF FACILITY LOOKUP
  photos: '/images', // HANDLE POST (success and failure)
  videos: '/videos',
  // 4. FLUSH REQUEST (cc user)
  ccRequests: '/ccrequests',  // HANDLE POST 
  ccJobDetails: '/jobs',
  ccAccessInfo: '/access',
  CCFlushInfo: '/flush',
  ccStructures: '/structures',
  cancelCCRequest: '/cancel', // DELETE FLUSH REQUEST WORK AND PHOTOS CAPTURED

  // EMAIL Pages: Need service in order to open the page and fetch the details associated to the page
  // /ccrequests?wrNumber=1123&structureNumber=123 // EMAIL PAGE FOR FLUSH REQUEST
  flushRequests: '/flushrequests', // EMAIL PAGE FOR FLUSH JOB
  structureHistory: '/structurehistory',

  //AXIS Hub
  overrides: '/overrides',
  teamLeadsAndCrewcodes: '/crewcodes',
  approveOverride: '/approve',
  rejectOverride: '/reject',
  changeCrew: '/changecrew',
  closeAppointment: '/complete',
  releaseNotes: '/releaseNotes',
  releaseFlag: '/releaseFlag',
  surveyQuestions:'/questions',
  surveyAnswers:'/answers',
};
