import { PhotoTag } from 'src/app/models/photo-tag';

export class FlushPhoto {
  id: string;
  crewCode: string;
  structureId: string;
  workRequestNumber: string;
  workComponentGlobalId: string;
  wrId: string;
  imageData: any ;
  timestamp: string;
  tags: PhotoTag[];
  isUploaded: boolean;
  classification: string;
  comments: string;
  crmsAppointmentId: string;
  contentSize: string;
  isProcessed: boolean;
  mediaId: string;
  mediaStatus: string;
  mediaType: string;
  streamingUrls: Array<string>;
  thumbnailUrl: string;
  workComponentGlobalID?: string;
  storageId?: string;
  constructor(
    id: string,
    crewCode: string,
    structureId: string,
    workRequestNumber: string,
    workComponentGlobalId: string,
    wrId: string,
    imageData: string,
    timestamp: string,
    tags: PhotoTag[],
    isUploaded: boolean,
    classification: string,
    comments: string,
    crmsAppointmentId: string,
    contentSize: string,
    isProcessed: boolean,
    mediaId: string,
    mediaStatus: string,
    mediaType: string,
    streamingUrls: Array<string>,
    thumbnailUrl: string
  ) {
    this.id = id;
    this.crewCode = crewCode;
    this.structureId = structureId;
    this.timestamp = timestamp;
    this.comments = comments;
    this.tags = tags;
    this.isUploaded = isUploaded;
    this.workRequestNumber = workRequestNumber;
    this.workComponentGlobalId = workComponentGlobalId;
    this.wrId = wrId;
    this.imageData = imageData;
    this.classification = classification;
    this.crmsAppointmentId = crmsAppointmentId;
    this.contentSize = contentSize;
    this.isProcessed = isProcessed;
    this.mediaId = mediaId;
    this.mediaStatus = mediaStatus;
    this.mediaType = mediaType;
    this.streamingUrls = streamingUrls;
    this.thumbnailUrl = thumbnailUrl;
  }
}
