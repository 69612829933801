<div class="side-nav-mobile" [ngClass]="{'offline-offset': !isAppOnline}">
    <div class="wrapper" [ngClass]="{'offline-banner-offset': !isAppOnline}" [@openClose]= "showNavItems ? true : false">
      <div class="side-nav-button" [ngClass]="{'show': showNavItems}"  (click)="handleMobileNav()">
        <span *ngIf="showNavItems"><b class="heading">Flush Request</b></span>
        <span *ngIf="!isFlushRequest">{{showNavItems ? 'Source WR#' : 'Flush WR #'}} <b>{{showNavItems ? sourceWrNo : wrNum}} </b></span>
        <span *ngIf="isFlushRequest && showNavItems">Source WR# <b>{{ wrNum }}</b></span>
        <span *ngIf="isFlushRequest">WC: <span>{{wcDesc}}</span></span>
        <span *ngIf="showNavItems && bTicket">B-Ticket: <b>{{bTicket}}</b></span>
        <div class="close-wrapper">
          <span class="icon icon-caret-button"></span>
        </div>
      </div>
      <div class="side-nav-items-wrapper">
        <ul #sideNav class="side-nav-items" @fade *ngIf="showNavItems">
          <ng-container *ngIf="isFlushRequestBTicket; else defaultItems">
            <li class="side-nav-item flex" [ngClass]="{'active': isShowingStructurePhotos, 'disableElement' : disableAll}" (click)="handleNavigation(['photos'])"><a [routerLink]="" class="router-link" [ngClass]="{'complete': isStructurePhotosValid}" (click)="handleNavigation(['photos'])">Environment Photo</a><span *ngIf="isStructurePhotosValid" class="icon icon-check-circle"></span></li>
            <li class="side-nav-item flex" [ngClass]="{'active': isShowingJobDetails, 'needs-separator': isShowingReviewAndSubmit, 'disableElement' : disableAll}" (click)="handleNavigation(['job-environment'])"><a [routerLink]="" class="router-link" [ngClass]="{'complete': isJobDetailsValid}" (click)="handleNavigation(['job-environment'])">Job Environment</a><span *ngIf="isJobDetailsValid" class="icon icon-check-circle"></span></li>
          </ng-container>
          <ng-template #defaultItems>
            <li class="side-nav-item flex" [ngClass]="{'active': isShowingJobDetails, 'disableElement' : disableAll}" (click)="handleNavigation(isFlushRequest ? ['job-details'] : ['job-details-request'])"><a [routerLink]="" class="router-link" [ngClass]="{'complete': isJobDetailsValid}" (click)="handleNavigation(isFlushRequest ? ['job-details'] : ['job-details-request'])">Flush Details</a><span *ngIf="isJobDetailsValid" class="icon icon-check-circle"></span></li>
            <li class="side-nav-item flex" [ngClass]="{'active': isShowingStructurePhotos, 'needs-separator': isShowingReviewAndSubmit, 'disableElement' : disableAll}" (click)="handleNavigation(['photos'])"><a [routerLink]="" class="router-link" [ngClass]="{'complete': isStructurePhotosValid}" (click)="handleNavigation(['photos'])">Structure Photos</a><span *ngIf="isStructurePhotosValid" class="icon icon-check-circle"></span></li>
          </ng-template>
          <li class="side-nav-item flex" [ngClass]="{'active': isShowingReviewAndSubmit, 'disabled': !(isJobDetailsValid && isStructurePhotosValid)}" (click)="handleNavigation(['review'])"><a [routerLink]="" class="router-link" (click)="handleNavigation(['review'])">Review and Submit</a><span *ngIf="isFlushRequestValid" class="icon icon-check-circle"></span></li>
        </ul>
      </div>
    </div>
  </div>
