import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonComponentsModule } from './common/common.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CardComponent } from './common/card/card.component';
import { RequestsComponent } from './pages/requests/requests.component';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel, IPublicClientApplication } from "@azure/msal-browser";
import { LogoutComponent } from './common/logout/logout.component';
import { initSettings, EnvironmentService, getConfig } from './services/environment/environment.service';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { StylerService } from './services/styler/styler.service';
import { PhotosComponent } from './pages/photos/photos.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { FlushInfoComponent } from './pages/flush-info/flush-info.component';
import { JobDetailsComponent } from './pages/flush-info/job-details/job-details.component';
import { StructureInfoComponent } from './pages/flush-info/structure-info/structure-info.component';
import { FlushProcessComponent } from './pages/flush-info/flush-process-nav/flush-process/flush-process.component';
import { AccessInfoComponent } from './pages/flush-info/access-info/access-info.component';
import { FlushLandingComponent } from './pages/flush-landing/flush-landing.component';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { GridService } from './services/grid/grid.service';

//Ngxs State management
import { NgxsModule } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { AppState } from './app-state/app.state';
import { JobDetailsRequestComponent } from './pages/start-job/job-details-request/job-details-request.component';
import { StartJobComponent } from './pages/start-job/start-job.component';
import { ReviewComponent } from './pages/start-job/review/review.component';
import { FlushReviewComponent } from './pages/flush-info/review/review.component';
import { CrewCodeComponent } from './pages/crew-code/crew-code.component';
import { PathLocationStrategy, LocationStrategy } from '@angular/common';
import { FlushRequestComponent } from './pages/flush-request/flush-request.component';
import { DashPipe } from 'src/app/common/pipes/dash.pipe';
import { FlushJobComponent } from './pages/flush-job/flush-job.component';
import { AtomicAssetTagsForParkingResPipe } from './common/pipes/atomic-asset-tags-for-parking-res.pipe';
import { CrewCodeInterceptor } from 'src/app/interceptors/crew-code-interceptor';
import { FlushProcessNavComponent } from './pages/flush-info/flush-process-nav/flush-process-nav.component';
import { LinksDirectoryComponent } from './pages/links-directory/links-directory.component';
import { StyleGuideComponent } from './pages/style-guide/style-guide.component';
import { CclModule } from './modules/ccl.module';
import { AuthInterceptor } from './interceptors/interceptor.service';
import { JobEnvironmentComponent } from './pages/flush-info/job-environment/job-environment.component';
import { CEALERT_CONFIG_DATA } from '@ce-lib/alert';
import { SupervisorHubComponent } from './pages/supervisor-hub/supervisor-hub.component';
import { CESwitchModule } from '@ce-lib/switch';
import { EditCloseWrComponent } from './pages/supervisor-hub/edit-close-wr/edit-close-wr.component';
import { FtuxComponent } from './pages/ftux/ftux.component';
import { NgxImageCompressService } from 'ngx-image-compress';

export function loggerCallback(logLevel: LogLevel, message: string) {
  //console.log(logLevel, message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId, 
      authority: environment.authority,
      redirectUri: environment.redirectUri,
      postLogoutRedirectUri: environment.postLogoutRedirectUri
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, 
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']); // Prod environment. Uncomment to use.
  // protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', ['user.read']);
  protectedResourceMap.set(environment.flushApiBaseUrl, [environment.aadScopes]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [environment.aadScopes]
    },
    loginFailedRoute: '/sign-in'
  };
}

@NgModule({
  declarations: [
    AppComponent,
    CardComponent,
    RequestsComponent,
    StyleGuideComponent,
    LogoutComponent,
    SignInComponent,
    PhotosComponent,
    FlushInfoComponent,
    JobDetailsComponent,
    StructureInfoComponent,
    FlushProcessComponent,
    AccessInfoComponent,
    FlushLandingComponent,
    LinksDirectoryComponent,
    JobDetailsRequestComponent,
    StartJobComponent,
    ReviewComponent,
    FlushReviewComponent,
    CrewCodeComponent,
    FlushRequestComponent,
    FlushJobComponent,
    FlushProcessNavComponent,
    JobEnvironmentComponent,
    SupervisorHubComponent,
    EditCloseWrComponent,
    FtuxComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    GridModule,
    BrowserAnimationsModule,
    LayoutModule,
    CclModule,
    CESwitchModule,
    ServiceWorkerModule.register('./service-worker.js', {
      enabled: environment.production
    }),
    NgxsModule.forRoot([AppState], {
      developmentMode: !environment.production,
    }),
    NgxsStoragePluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({
      logger: console,
      collapsed: false,
      disabled: environment.production,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
  ],
  providers: [
    NgxImageCompressService,
    StylerService,
    GridService,
    DashPipe,
    AtomicAssetTagsForParkingResPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: APP_INITIALIZER,
      useFactory: initSettings,
      deps: [EnvironmentService],
      multi: true,
    },
    {
      provide: 'adalConfig',
      useFactory: getConfig,
      deps: [],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CrewCodeInterceptor,
      multi: true,
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    {
      //optional global level config
      provide: CEALERT_CONFIG_DATA,
      useValue: {
        timeOut: 20000,
        horizontalpositionType: 'center'
      } 
    }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
