import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../services/loader/loader.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { Router, ActivationEnd, ActivationStart } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  animations: [
    trigger('row', [
      transition(':increment', [
        style({ opacity: 0, transform: 'translateY(10px)', height: '0' }),
        animate('300ms 250ms ease-in', style({ opacity: 1, transform: 'translateY(0px)', height: '*' })),
      ]),
      transition(':decrement', [
        style({ opacity: 1, height: '*' }),
        animate('200ms 0ms ease-out', style({ opacity: 0, transform: 'translateY(-10px)', height: '0' })),
      ]),
    ]),
    trigger('container', [
      transition(':enter', [style({ opacity: 0 }), animate('250ms ease-in-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('250ms 100ms ease-out', style({ opacity: 0 }))]),
    ]),
  ],
})
export class LoaderComponent {
  messages = 1;
  currentPage = '';
  clearMessages = false;
  isLoading = true;
  messageQueue = [
    `We're still working on it`,
    `Sorry, this is taking longer than usual, please do not refresh the page.`,
  ];
  messageList = ['Please wait...'];
  timer: any;
  constructor(private loaderService: LoaderService, private router: Router) {
    this.router.events.pipe(filter((event) => event instanceof ActivationStart)).subscribe((e: ActivationStart) => {
      if (e.snapshot.routeConfig.path !== this.currentPage && this.messageList.length > 1) {
        // We've navigated to a new page
        this.timer = undefined;
        this.currentPage = e.snapshot.routeConfig.path;
        this.messageList.splice(1);
        this.messageQueue = [
          `We're still working on it`,
          `Sorry, this is taking longer than usual, please do not refresh the page.`,
        ];
        this.clearMessages = true;
      }
    });
    this.loaderService.getLoadingStatus().subscribe((requests: number) => {
      if (requests < 1) {
        clearInterval(this.timer);
        this.timer = undefined;
        this.isLoading = false;
        this.clearMessages = true;
        this.messages = 0;
      } else {
        this.isLoading = true;
        if (!this.timer) {
          if (this.clearMessages) {
            this.clearMessages = false;
            this.messageList.splice(1);
            this.messageQueue = [
              `We're still working on it`,
              `Sorry, this is taking longer than usual, please do not refresh the page.`,
            ];
          }
          this.startTime();
        }
      }
    });
  }
  addMessage = () => {
    if (this.messageQueue.length === 0) {
      // We spent too long waiting
      // TODO: Throw another message/log the request that is taking a long time/ throw error
      clearInterval(this.timer);
    } else {
      this.messages += 1;
      this.messageList.push(this.messageQueue.shift());
    }
  };
  startTime() {
    this.timer = setInterval(() => {
      this.addMessage();
    }, 8500);
  }
}
